import { ISgwRequest, ISgwRequestBE, ICountry, ISgwPhaseMainLocation, ISgwPhase } from '../../types';
import { mapToCountryObject } from './country.utils';

export const mapToISgwRequestBE = ({
  referenceId,
  country,
  crane,
  isUrgentRequest,
  ...request
}: ISgwRequest): ISgwRequestBE => {
  return {
    ...request,
    country: country.country,
    crane: {
      ...crane,
      isUsed: crane?.isUsed === 'true',
      moreThan25Tons: crane?.moreThan25Tons === 'true',
    },
    urgentRequest: isUrgentRequest,
  };
};

export const mapToISgwRequest = (
  { country, sgwProject, crane, urgentRequest, ...request }: ISgwRequestBE,
  countries: ICountry[],
): ISgwRequest => ({
  ...request,
  country: mapToCountryObject(country, countries),
  ...(sgwProject && { sgwProject: typeof sgwProject === 'string' ? sgwProject : `${sgwProject.id}` }),
  crane: {
    ...crane,
    isUsed: `${!!crane.isUsed}`,
    moreThan25Tons: `${!!crane.moreThan25Tons}`,
  },
  isUrgentRequest: urgentRequest,
});

export const getSgwRequestMainLocation = ({
  street,
  streetNumberFrom,
  streetNumberTo,
  bus,
  streetNumberUnknown,
}: ISgwPhaseMainLocation): string =>
  street && `${street}${!streetNumberUnknown ? ` ${streetNumberFrom}-${streetNumberTo}${bus ? ` ${bus}` : ''}` : ''}`;

export const validateRequestPhases = (phases: ISgwPhase[], phaseIds?: string[]) =>
  !!phases.find((phase) => !phase.isRecurringPhaseTemplate);
