import { IDefaultAction, IGenericAction } from '../../../store/actions/actions';
import { defaultAction, genericAction } from '../../../store/actions';
import { IPublicDomainIntake, IRequest } from '../../../types';
import { DESELECT_PDI, PDI_PATCH_LICENCEPLATES_REQUESTED, SELECT_PDI } from '../types';

export type PublicDomainIntakeLicencePlatePatchPayload = {
  request: IRequest;
  intake: IPublicDomainIntake;
};

export function createDeselectPublicDomainIntakeAction(): IDefaultAction {
  return defaultAction(DESELECT_PDI);
}

export function createSelectPublicDomainIntakeAction(payload: number): IGenericAction<number> {
  return genericAction(SELECT_PDI, payload);
}

export function createPublicDomainIntakeLicencePlatePatch(
  request: IRequest,
  intake: IPublicDomainIntake,
): IGenericAction<PublicDomainIntakeLicencePlatePatchPayload> {
  return genericAction(PDI_PATCH_LICENCEPLATES_REQUESTED, { request, intake });
}
