import { FC } from 'react';
import { MapContainer } from 'react-leaflet';
import { MAP_DEFAULT_POSITION, MAP_DEFAULT_ZOOM } from '../../../common/constants';
import { FlyToBounds } from './FlyToBounds.component';
import { Visible } from '../layout/Visible.component';
import classnames from 'classnames';
import { WaitAnim } from '../layout/WaitAnim.component';
import { IBaseMapProps } from '../../../types/map.types';
import { MapDrawOverlay, MapLayersControl } from '../../molecules';
import { MapBaseOverlay } from '../../atoms';
import { ZoomControl } from './ZoomControl.component';

const extraProps = {
  center: MAP_DEFAULT_POSITION,
  scrollWheelZoom: false,
  zoom: MAP_DEFAULT_ZOOM,
  zoomControl: false,
};

export const BaseMap: FC<IBaseMapProps> = ({
  busy = false,
  bounds,
  children,
  className,
  extraLayers,
  geometryBounds,
  maxZoom = 19,
  zoomControl = false,
  enableDrawing = false,
  onDrawingCreated,
  onStartDrawing,
  showLayersControl = false,
}) => {
  return (
    <div data-testid={'GeoDrawingMap'} className={classnames('leaflet__map', className)} aria-hidden tabIndex={-1}>
      <Visible visible={busy}>
        <div className={'leaflet__editmap__busy'}>
          <WaitAnim />
        </div>
      </Visible>
      <MapContainer {...extraProps}>
        <Visible visible={zoomControl}>
          <ZoomControl />
        </Visible>
        <FlyToBounds bounds={bounds} geometryBounds={geometryBounds} maxZoom={maxZoom} />
        <Visible
          visible={showLayersControl}
          defaultComponent={
            <>
              <MapBaseOverlay />
              {extraLayers}
              {children}
            </>
          }
        >
          <MapLayersControl>
            {extraLayers}
            {children}
            <Visible visible={enableDrawing}>
              <MapDrawOverlay key="MapDrawOverlay" onCreated={onDrawingCreated} startDrawing={onStartDrawing} />
            </Visible>
          </MapLayersControl>
        </Visible>
      </MapContainer>
    </div>
  );
};
