import { useEffect, useState } from 'react';
import { getImageReason, TImageReason } from '../../../common/utils/assetsUtils';
import { IIntlProperties, IRequestReason } from '../../../types';
import { CardSelectorItemProperties } from './CardSelectorItem.component';
import { GenericCardSelector } from '../cardselector/GenericCardSelector.component';
import { CardSelectorTag } from './CardSelectorTag.component';
import { Required } from '../forms/Required.component';
import { FieldError } from 'react-hook-form';
import { translate } from '../../../translations/translate';

export type ReasonSelectorProperties = {
  reasons: IRequestReason[];
  error?: FieldError;
  input?: {
    onChange: (value: IRequestReason) => void;
    value: IRequestReason | null;
  };
} & IIntlProperties;

/**
 * React Component ReasonSelector
 */
export const ReasonSelector = ({ reasons, input, error }: ReasonSelectorProperties) => {
  const [value, setValue] = useState<IRequestReason | null>();
  const activeIndex = reasons.findIndex(({ reason }) => reason === value?.reason);

  useEffect(() => {
    setValue(input?.value);
  }, [input?.value]);

  const onChange = (rr: IRequestReason): void => {
    input?.onChange?.(rr);
    setValue(rr);
  };

  const mapItem = (reason: IRequestReason): CardSelectorItemProperties => ({
    image: getImageReason(reason.reason as TImageReason),
    imageDescription: reason.displayImageDescription,
    remarks: reason.displayRemarks,
    title: reason.displayName,
  });

  return (
    <div className="reasonselector cardselector__wrapper">
      <GenericCardSelector activeIndex={activeIndex} data={reasons} mapItem={mapItem} onChange={onChange} />
      <Required required={!!error} message={translate('reasonform.requiredReason')} />
      <CardSelectorTag name={value?.name} />
    </div>
  );
};
