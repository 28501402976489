export function formatHour(i: number, subtractMinuteFrom24?: boolean): string {
  if (subtractMinuteFrom24 && i === 24) {
    return '23:59';
  }
  return `${i < 10 ? '0' : ''}${i}:00`;
}

export function parseHour(value?: string): number {
  if (value) {
    return parseInt(value.split(':')[0], 10);
  }
  return -1;
}

export function parseMinutes(value?: string): number {
  if (value) {
    return parseInt(value.split(':')[1], 10);
  }
  return -1;
}

export function hourIsLowerThan(a: string, b: string): boolean {
  try {
    return parseHour(a) < parseHour(b);
  } catch (e) {
    return false;
  }
}
