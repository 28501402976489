import { FC, PropsWithChildren } from 'react';
import { LayersControl } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { BASE_MAP_OVERLAY_LAYERS, BaseMapDefaultLayers, getBaseMapLayers } from '../../../config/map.config';
import { useGeoDrawingContext } from '../../../hooks';
import { selectApplicationFeatures } from '../../../store/selectors';
import { getPhase, getRequest } from '../../../store/selectors/sgw';
import { translateIgnoreTS } from '../../../translations/translate';
import { ISgwPhase, ISgwRequest, TimeBasedTypes } from '../../../types';
import { MapLayer } from './MapLayer.component';

export const MapLayersControl: FC<PropsWithChildren> = ({ children }) => {
  const applicationFeatures = useSelector(selectApplicationFeatures);
  const { requestId, phaseId } = useGeoDrawingContext();
  const sgwRequest = useSelector(getRequest(requestId));
  const sgwPhase = useSelector(getPhase(phaseId)) as ISgwPhase;

  const timeConstraints = (
    timeBasedType?: TimeBasedTypes,
    sgwRequest?: ISgwRequest,
    sgwPhase?: ISgwPhase,
  ): { from: Date; to: Date } | undefined => {
    if (timeBasedType === TimeBasedTypes.PHASE && sgwPhase)
      return { from: new Date(sgwPhase.dateFrom), to: new Date(sgwPhase.dateUntil) };
    if (timeBasedType === TimeBasedTypes.REQUEST && sgwRequest)
      return { from: new Date(sgwRequest.dateFrom), to: new Date(sgwRequest.dateUntil) };
  };

  return (
    <LayersControl data-testid="LayersControl" position="topleft">
      {getBaseMapLayers(applicationFeatures).map((layer) => (
        <LayersControl.BaseLayer
          name={translateIgnoreTS(`leaflet.layers.${layer.name}`)}
          key={layer.id}
          checked={layer.id === BaseMapDefaultLayers.baseLayer}
        >
          <MapLayer overlay={layer} />
        </LayersControl.BaseLayer>
      ))}
      {applicationFeatures?.['integrateDigipolisArcGis'] &&
        BASE_MAP_OVERLAY_LAYERS.map((layer) => (
          <LayersControl.Overlay name={translateIgnoreTS(`leaflet.layers.${layer.name}`)} key={layer.id}>
            <MapLayer overlay={layer} timeConstraints={timeConstraints(layer.timeBasedType, sgwRequest, sgwPhase)} />
          </LayersControl.Overlay>
        ))}
      {children}
    </LayersControl>
  );
};
