import React, { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';

interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  disabled: boolean;
}

const useStyles = createUseStyles({
  disabled: {
    filter: 'opacity(0.3) blur(0.1px)',
    pointerEvents: 'none',
  },
});

export const Disableable: FC<PropsWithChildren<IProps>> = ({ children, disabled = false, className, ...props }) => {
  const C = useStyles();
  return (
    <div {...props} className={classNames(className, disabled && C.disabled)}>
      {children}
    </div>
  );
};
