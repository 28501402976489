import { FC, useEffect } from 'react';
import intl from 'react-intl-universal';
import { Fieldset, RadioButtonsControlled, TextInput } from '../../../atoms';
import { Visible } from '../../../common/layout/Visible.component';
import { useFormContext } from 'react-hook-form';
import { ISgwRequest } from '../../../../types';
import { useGenericStyles } from '../../../../hooks';
import { translate } from '../../../../translations/translate';

export const WorkCraneFields: FC = () => {
  const { spacing } = useGenericStyles();
  const { register, watch, setValue } = useFormContext<ISgwRequest>();
  const workCraneNeeded = watch('crane.isUsed');

  useEffect(() => {
    if (workCraneNeeded === 'false') {
      setValue('crane.type', null);
      setValue('crane.maxHeight', null);
      setValue('crane.moreThan25Tons', 'false');
    }
  }, [setValue, workCraneNeeded]);

  return (
    <Fieldset legend={intl.get('sgw.workType.workCrane.legend')}>
      <RadioButtonsControlled
        {...register('crane.isUsed', { required: true })}
        options={[
          { label: intl.get('general.yes'), value: 'true' },
          { label: intl.get('general.no'), value: 'false' },
        ]}
        label={intl.get('sgw.workType.workCrane.label')}
        extensiveLabel={intl.getHTML('sgw.workType.workCrane.info')}
      />
      <Visible visible={workCraneNeeded === 'true'}>
        <div className={spacing.marginTop.ML}>
          <RadioButtonsControlled
            {...register('crane.moreThan25Tons', { required: workCraneNeeded === 'true' })}
            options={[
              { label: intl.get('general.yes'), value: 'true' },
              { label: intl.get('general.no'), value: 'false' },
            ]}
            extensiveLabel={intl.getHTML('sgw.workType.workCrane.weight.info')}
            label={intl.getHTML('sgw.workType.workCrane.weight.label')}
            required
          />
          <TextInput
            className="col-xs-6"
            {...register('crane.type', { required: workCraneNeeded === 'true' })}
            label={intl.get('sgw.workType.workCrane.type.label')}
            required
          />
          <TextInput
            className="col-xs-6"
            type="number"
            {...register('crane.maxHeight', {
              required: workCraneNeeded === 'true',
              min: { value: 1, message: translate('error.greaterThanZero') },
            })}
            label={intl.get('sgw.workType.workCrane.height.label')}
            required
            extensiveLabel={intl.get('sgw.workType.workCrane.height.info')}
            min={1}
          />
        </div>
      </Visible>
    </Fieldset>
  );
};
