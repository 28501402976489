import React, { FC, SyntheticEvent, useState } from 'react';
import { ButtonVariant, GeoDrawingType, ISgwGeoDrawing } from '../../../types';
import { TextInput, Button } from '../../atoms';
import { translate, translateHtml, translateIgnoreTS } from '../../../translations/translate';
import { useGenericStyles, useGeoDrawingContext, useLeafletDrawButtons } from '../../../hooks';
import classNames from 'classnames';
import { Visible } from '../../common/layout/Visible.component';
import { useGeoDrawingListItemStyles } from './GeoDrawingListItem.styles';
import { useDispatch } from 'react-redux';
import { createWarningAlertAction } from '../../../common/actions/creators/alert';
import { ActionButtons } from './ActionButtons.component';
import { Select } from '../../atoms/Select/Select.component';

interface IProps {
  index?: number;
  isEditMode?: boolean;
  countOfType: number;
  disableControls?: boolean;
  geoDrawing: ISgwGeoDrawing;
  readOnly: boolean;
}

export const GeoDrawingListItem: FC<IProps> = ({
  index = -1,
  isEditMode = false,
  countOfType,
  disableControls = false,
  geoDrawing: currentGeoDrawingItem,
  readOnly = false,
}) => {
  const dispatch = useDispatch();
  const C = useGeoDrawingListItemStyles();
  const { spacing, flex } = useGenericStyles();
  const { addNewGeoDrawings, geoDrawingIndexInEdit, inDrawingMode, cancelEditGeoDrawing, updateGeoDrawing, workZones } =
    useGeoDrawingContext();
  const { startDrawing } = useLeafletDrawButtons();
  const [selectedType, setSelectedType] = useState<GeoDrawingType>(
    currentGeoDrawingItem.geoDrawingType || GeoDrawingType.constructionzone,
  );
  const [customName, setCustomName] = useState<string | undefined>(currentGeoDrawingItem.customName);

  const onChangeDrawingType = (e: React.SyntheticEvent<HTMLSelectElement>) =>
    setSelectedType(e.currentTarget.value as GeoDrawingType);

  const onSubmitDrawingType = () => {
    currentGeoDrawingItem.geoDrawingType = selectedType;
    startDrawing(selectedType);
  };

  const typeOptions = Object.keys(GeoDrawingType).map((value) => ({
    value,
    label: translateIgnoreTS(`sgw.map.drawOnMap.phase.geoDrawing.types.${value}`),
    disabled: value !== GeoDrawingType.constructionzone && !workZones.length,
  }));

  const getPlaceholderTranslation = (): string => {
    const currentDrawingType = typeOptions.find(({ value }) => value === selectedType);
    return translate('sgw.map.drawOnMap.phase.geoDrawing.customNamePlaceholder', {
      type: currentDrawingType?.label.toLowerCase(),
    });
  };

  const onAddDrawing = () => {
    if (currentGeoDrawingItem?.geometry) {
      const newGeoDrawing = { ...currentGeoDrawingItem, customName };
      if (index === geoDrawingIndexInEdit) {
        updateGeoDrawing(newGeoDrawing);
      } else {
        addNewGeoDrawings([newGeoDrawing]);
      }
    } else {
      dispatch(
        createWarningAlertAction(
          translate('sgw.map.drawOnMap.phase.noDrawingFound', {
            type: translateIgnoreTS(`sgw.map.drawOnMap.phase.geoDrawing.types.${selectedType}`),
            number: countOfType,
          }),
          translate('sgw.map.drawOnMap.phase.noDrawingFoundTitle'),
        ),
      );
    }
  };

  const onCancelGeoDrawing = () => {
    cancelEditGeoDrawing();
    setCustomName(currentGeoDrawingItem.customName);
  };

  const onNameInputChange = (e: SyntheticEvent<HTMLInputElement>) => setCustomName(e.currentTarget.value);

  return (
    <div
      data-testid={'GeoDrawingListItem'}
      className={classNames(
        flex.column,
        C.container,
        spacing.paddingLeft.XXS,
        spacing.paddingRight.XXS,
        spacing.paddingTop.XXS,
        spacing.paddingBottom.SM,
      )}
    >
      <Visible visible={!currentGeoDrawingItem?.geoDrawingType}>
        <Select
          data-testid={'GeoDrawingTypeSelect'}
          onSelect={onChangeDrawingType}
          options={typeOptions}
          label={translate('sgw.map.drawOnMap.phase.geoDrawing.label')}
          name="type"
          emptyOption={false}
        ></Select>
        <div className={classNames(flex.column, C.next)}>
          <Button size="small" variant={ButtonVariant.Negative} onClick={onSubmitDrawingType} type="button">
            {translate('form.next')}
          </Button>
        </div>
      </Visible>
      <Visible visible={!!currentGeoDrawingItem?.geoDrawingType}>
        <div className={classNames(flex.row, flex.centerVertical)}>
          <div className={classNames(flex.column, C.type)}>
            {translate('sgw.map.drawOnMap.phase.geoDrawing.numberingLabel', {
              type: translateIgnoreTS(
                `sgw.map.drawOnMap.phase.geoDrawing.types.${currentGeoDrawingItem?.geoDrawingType}`,
              ),
              number: countOfType,
            })}
          </div>
          <div className={classNames(flex.column)}>
            <Visible visible={!readOnly}>
              <ActionButtons
                index={index}
                isEditMode={isEditMode}
                disableControls={disableControls || inDrawingMode}
                geoDrawingType={currentGeoDrawingItem?.geoDrawingType}
                onCancel={onCancelGeoDrawing}
              />
            </Visible>
          </div>
        </div>
        <div className={classNames(flex.row, spacing.marginBottom.XS)}>
          <Visible visible={isEditMode} defaultComponent={currentGeoDrawingItem?.customName}>
            <TextInput
              label={translate('sgw.map.drawOnMap.phase.geoDrawing.customNameLabel')}
              name="customName"
              onChange={onNameInputChange}
              className={C.customName}
              placeholder={getPlaceholderTranslation()}
              value={customName}
              data-testid={'customNameInput'}
            />
          </Visible>
        </div>
        <div className={classNames(flex.row, flex.centerVertical)}>
          <div className={classNames(flex.column, C.area)}>
            <Visible
              visible={currentGeoDrawingItem?.geoDrawingType === GeoDrawingType.constructionzone}
              defaultComponent={translate(`sgw.map.drawOnMap.phase.geoDrawing.distance`, {
                distance: currentGeoDrawingItem?.surfaceArea,
              })}
            >
              {translateHtml(`sgw.map.drawOnMap.phase.geoDrawing.areaSize`, {
                size: currentGeoDrawingItem?.surfaceArea,
              })}
            </Visible>
          </div>
          <Visible visible={isEditMode && !!currentGeoDrawingItem?.geometry}>
            <div className={classNames(flex.column, C.done)}>
              <Button size="small" variant={ButtonVariant.Negative} onClick={onAddDrawing} type="button">
                {translate('sgw.map.drawOnMap.phase.geoDrawing.done')}
              </Button>
            </div>
          </Visible>
        </div>
      </Visible>
    </div>
  );
};
