import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { COLORS, useSpacingStyles } from '../../../theme';
import { translate } from '../../../translations/translate';
import { IRequestMessage } from '../../../types';
import DateFormatter from '../../common/formatters/dateFormatter';
import { MessageAttachmentsList } from '../MessageAttachmentList/MessageAttachment.componentList';

const useStyles = createUseStyles({
  message: {
    whiteSpace: 'pre',
    padding: '15px',
    margin: '10px 5px',
    backgroundColor: COLORS.lightGrey,
  },
  content: {
    padding: ' 10px ',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },
});

export const Message: FC<IRequestMessage> = (message) => {
  const C = useStyles();
  const G = useSpacingStyles();

  return (
    <div className={C.message} key={Math.random()}>
      <strong className={G.marginRight.XXS} style={{ color: message.sender.type === 'user' ? COLORS.blue : '' }}>
        {message.sender.type === 'user' ? message.sender.fullName : translate('sgw.overview.messages.admin')}
      </strong>
      <DateFormatter date={message.createdAt} /> <br />
      <div className={C.content}>{message.content}</div>
      <MessageAttachmentsList attachments={message.attachments} />
    </div>
  );
};
