import React from 'react';
import { createUseStyles } from 'react-jss';
import { APP_CORPORATE_MAIN_SITE } from '../../../common/constants';
import { Environment } from '../../../config/environment';
import { translate } from '../../../translations/translate';
import { ReactComponent as Logo } from '../../common/header/a-logo.svg';

const useStyles = createUseStyles(() => ({
  page: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    filter: 'grayscale(100%) opacity(30%)',
  },
  title: {
    margin: 0,
    fontSize: '22px',
    marginTop: '20px',
  },
  text: {
    marginTop: '10px',
    fontSize: '16px',
  },
  link: {
    color: '#266ca3',
  },
}));

export const Error = () => {
  const C = useStyles();
  return (
    <div className={C.page}>
      <div className={C.container}>
        <a href={APP_CORPORATE_MAIN_SITE} rel="noreferrer">
          <Logo title="Rechtstreekse link naar website Stad Antwerpen" className={C.image} />
        </a>
        <h2 className={C.title}>{translate('error.page.title')}</h2>

        <h4 className={C.text}>
          {translate('error.page.description')}

          <a href={Environment.baseFrontEndUrl} className={C.link}>
            {translate('error.page.again')}
          </a>
        </h4>
      </div>
    </div>
  );
};
