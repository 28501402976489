import { IPublicDomainIntakeType, IRequest, IRequestFile } from '../../types';
import { APP_MAX_ATTACH_SIZE, MEGABYTE } from '../constants';
import { PublicDomainType } from '../enums';
import { ValidateResult } from 'react-hook-form/dist/types/validator';
import { translate } from '../../translations/translate';

export const validateAttachmentStep2 = (attachments: IRequestFile[], type?: IPublicDomainIntakeType): ValidateResult =>
  validateAttachment(attachments?.[0], type?.type === PublicDomainType.MinorConstruction);

export const validateAttachmentStep4 = (attachment: File | undefined) => validateAttachment(attachment, false);

export const validateAttachmentStep5 = (attachments: IRequestFile[], request: Partial<IRequest>): ValidateResult =>
  validateAttachment(attachments?.[0], false);

export const validateAttachment = (
  attachment: File | undefined,
  required: boolean,
  maxSize?: number,
): ValidateResult => {
  if (attachment && attachment.size > (maxSize || APP_MAX_ATTACH_SIZE) * MEGABYTE) {
    return translate('attachments.validation.tooBig', { maxsize: maxSize || APP_MAX_ATTACH_SIZE });
  }

  if (!attachment && required) {
    return translate('fields.attachmentRequired');
  }

  // All checks valid
  return true;
};
