import React, { FC } from 'react';
import { BasemapLayer, DynamicMapLayer, TiledMapLayer } from 'react-esri-leaflet';
import { MAP_MAX_ZOOM } from '../../../common/constants';
import { IMapLayer, MapLayerTypes } from '../../../types';
import { MapBaseOverlay, MapSatelliteOverlay } from '../../atoms';

interface IProps {
  overlay: IMapLayer;
  timeConstraints?: { from: Date; to: Date } | undefined;
}

export const MapLayer: FC<IProps> = ({ overlay, timeConstraints }) => {
  const { enabled = true, url, baseMap, type, layer } = overlay;
  const id = `${overlay.id}`;

  if (!enabled) return null;

  switch (type) {
    case MapLayerTypes.SATELLITE:
      return <MapSatelliteOverlay id={id} />;
    case MapLayerTypes.DYNAMIC:
      return (
        <DynamicMapLayer
          id={id}
          layers={[layer]}
          //@ts-ignore
          url={url}
          maxZoom={MAP_MAX_ZOOM}
          // Because of an issue in current version of leaflet we need to swap from and until dates to make it work
          // TODO: check after leaflet update
          from={timeConstraints?.to}
          to={timeConstraints?.from}
        />
      );
    case MapLayerTypes.TILED:
      return <TiledMapLayer id={id} url={url!} maxZoom={MAP_MAX_ZOOM} />;
    case MapLayerTypes.BASE_MAP:
      return <BasemapLayer id={id} name={baseMap!} />;
    case MapLayerTypes.BASE:
      return <MapBaseOverlay id={id} />;
    default:
      return null;
  }
};
