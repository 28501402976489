import * as React from 'react';
import intl from 'react-intl-universal';

import * as A from 'antwerp-core-react-branding';

import {
  APP_LINK_INFOFICHE_CARFREE,
  APP_LINK_INFOFICHE_MINORCONSTRUCTION,
  APP_LINK_INFOFICHE_PARKINGBAN,
} from '../../../common/constants';
import { APP_LINK_EXAMPLE_PLANS } from '../../../common/constants';
import { PublicDomainType } from '../../../common/enums';
import { IPublicDomainIntakeType } from '../../../types';

export type InfoFicheProperties = {
  type: IPublicDomainIntakeType;
};

/**
 * React Component InfoFiche
 */
export default class InfoFiche extends React.Component<InfoFicheProperties, {}> {
  public render(): JSX.Element {
    if (this.props.type) {
      const link = this.getInfoFicheLink(this.props.type);
      return (
        // @ts-ignore
        <A.Spacing type={A.SpacingStyle.MarginTop}>
          {/*@ts-ignore*/}
          <A.Paragraph spacing>
            {intl.getHTML('typeform.info', { link })} {this.renderExamplesText()}
          </A.Paragraph>
        </A.Spacing>
      );
    }
    return null!;
  }

  private renderExamplesText(): string {
    if (this.props.type && this.props.type.type === PublicDomainType.MinorConstruction) {
      return intl.getHTML('locationsform.exampleplans', { link: APP_LINK_EXAMPLE_PLANS });
    }
    return null!;
  }

  private getInfoFicheLink(type: IPublicDomainIntakeType): string {
    switch (type.type) {
      case PublicDomainType.CarfreeZone:
        return APP_LINK_INFOFICHE_CARFREE;
      case PublicDomainType.MinorConstruction:
        return APP_LINK_INFOFICHE_MINORCONSTRUCTION;
      case PublicDomainType.ParkingBan:
      default:
        return APP_LINK_INFOFICHE_PARKINGBAN;
    }
  }
}
