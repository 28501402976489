import { FC } from 'react';
import { WMSTileLayer } from 'react-leaflet';
import { MAP_MAX_ZOOM, MAP_SATELLITE_LAYER_URL, MAP_SATELLITE_PARAMS } from '../../../common/constants';

interface IProps {
  id?: string;
}

export const MapSatelliteOverlay: FC<IProps> = ({ id }) => (
  <WMSTileLayer
    id={id}
    format="image/png"
    maxZoom={MAP_MAX_ZOOM}
    params={MAP_SATELLITE_PARAMS}
    url={MAP_SATELLITE_LAYER_URL}
  />
);
