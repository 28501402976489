import { Actions } from './generic.actions';
import { ISgwPhase } from '../../types';

class PhaseActionsClass extends Actions {
  delete = this.createPayloadAction<{ requestId: string; id: string; index: number }>('delete');
  fetch = this.createPayloadAction<{ requestId: string; id: string }>('fetch');
  fetchList = this.createPayloadAction<string>('fetchList');
  formFieldsChanged = this.createPayloadAction<boolean>('formFieldsChanged');
  set = this.createPayloadAction<ISgwPhase>('set');
  setList = this.createPayloadAction<ISgwPhase[]>('setList');
  save = this.createPayloadAction<{ requestId: string; hideSnackbar: boolean; phase: ISgwPhase }>('save');
}

export const PhaseActions = new PhaseActionsClass('phase');
