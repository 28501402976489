import { all, fork } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import busySaga from './busy.sagas';
import titleSaga from './title.sagas';
import carFreeZonesSaga from './carFreeZones.sagas';
import costSaga from './cost.sagas';
import dataSaga from './data.sagas';
import extensionSaga from './extensions.sagas';
import layoutSaga from './layout.sagas';
import loginSaga from './login.sagas';
import partnerSaga from './partner.sagas';
import paymentsSaga from './payments.sagas';
import requestsSaga from './requests.sagas';
import requestMessagesSaga from './requestsMessages.sagas';
import storageSaga from './storage.sagas';
import workflowSaga from './workflow.sagas';
import sgwRequestsSagas from './sgwRequests.sagas';
import { snackbarSaga } from './snackbar.sagas';
import { phaseSaga } from './phase.sagas';
import conflictSaga from './conflicts.sagas';
import sgwRequestsMessagesSagas from './sgwRequestsMessages.sagas';
import sgwRequestsReschedulingSagas from './sgwRequestRescheduling.sagas';
import alertsSagas from './alerts.sagas';

export default function* rootSaga(): SagaIterator {
  yield all([
    fork(carFreeZonesSaga),
    fork(conflictSaga),
    fork(busySaga),
    fork(costSaga),
    fork(dataSaga),
    fork(extensionSaga),
    fork(layoutSaga),
    fork(loginSaga),
    fork(partnerSaga),
    fork(paymentsSaga),
    fork(phaseSaga),
    fork(requestsSaga),
    fork(requestMessagesSaga),
    fork(snackbarSaga),
    fork(storageSaga),
    fork(titleSaga),
    fork(workflowSaga),
    fork(sgwRequestsSagas),
    fork(sgwRequestsMessagesSagas),
    fork(sgwRequestsReschedulingSagas),
    fork(alertsSagas),
  ]);
}
