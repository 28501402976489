import moment, { Moment } from 'moment';
import {
  APP_SHORT_DATE_FORMAT,
  APP_DEFAULT_DATE_FORMAT,
  APP_DISPLAY_DATE_FORMAT,
  APP_DEFAULT_DATETIME_FORMAT,
  APP_DISPLAY_DATETIME_FORMAT,
} from '../constants';

export function convertStringToTimestamp(date: string, format?: string): number {
  const isIso = date.indexOf('T') > -1;
  format = format || APP_DEFAULT_DATE_FORMAT;
  if (isIso) {
    return moment(date).toDate().getTime();
  } else {
    return moment(date, format).toDate().getTime();
  }
}
export const formatDate = (date: Moment, format: string = APP_DEFAULT_DATE_FORMAT) => date.format(format);

export const formatDateToDisplayDate = (date: Moment) => formatDate(date, APP_DISPLAY_DATE_FORMAT);

export const formatToDisplayDate = (date: string, daysToAdd: number = 0) =>
  date ? moment(date, APP_DEFAULT_DATE_FORMAT).add(daysToAdd, 'days').format(APP_DISPLAY_DATE_FORMAT) : '';

export function formatDateTimeToDisplayDate(date: string, time?: string): string {
  if (date && time) {
    return moment(`${date} ${time}`, APP_DEFAULT_DATETIME_FORMAT).format(APP_DISPLAY_DATETIME_FORMAT);
  } else if (date) {
    return formatToDisplayDate(date);
  }
  return '';
}

export function formatToShortDisplayDate(date: string): string {
  return date ? moment(date, APP_DEFAULT_DATE_FORMAT).format(APP_SHORT_DATE_FORMAT) : '';
}

export function hasWeekDays(from?: string, until?: string): boolean {
  if (from && until) {
    let momentFrom = moment(convertStringToTimestamp(from));
    const untilTimestamp = moment(convertStringToTimestamp(until)).unix();

    while (momentFrom.unix() <= untilTimestamp) {
      const day = momentFrom.day();
      if (day === 6 || day === 0) {
        return true;
      }
      momentFrom = momentFrom.add(1, 'd');
    }
  }

  return false;
}

export const calculateInterval = (from: string, to: string) =>
  convertStringToTimestamp(to) - convertStringToTimestamp(from);

export const calculateDaysInterval = (from: string, to: string) =>
  Math.floor((convertStringToTimestamp(to) - convertStringToTimestamp(from)) / (3600 * 24 * 1000));

export const calculateFromTimestamp = (from: string) => convertStringToTimestamp(from) - 1000 * 60 * 60 * 24;

//TODO: to be improved with national holidays
export const addBusinessDays = (originalDateInMs: number, daysToAdd: number) => {
  const sunday = 0;
  const saturday = 6;
  const newDate = moment(originalDateInMs);

  newDate.add(Math.round(daysToAdd / 5) * 7 + (daysToAdd % 5), 'days');
  switch (newDate.day()) {
    case sunday:
      return newDate.add(1, 'days');
    case saturday:
      return newDate.add(2, 'days');
    default:
      return newDate;
  }
};

export const isValidDate = (dateInString: string) => {
  return moment(dateInString, APP_DISPLAY_DATE_FORMAT).isValid();
};

const latestDate = (dates: (string | undefined)[]) =>
  formatDate(moment.max(...dates.filter((date) => !!date).map((date) => moment(date, APP_DEFAULT_DATE_FORMAT))));

export const earliestDate = (dates: (string | undefined)[]) =>
  formatDate(moment.min(...dates.filter((date) => !!date).map((date) => moment(date, APP_DEFAULT_DATE_FORMAT))));

// returns either the current date, or the provided date if it is later than today
export const latestDateComparedToToday = (dates: (string | undefined)[]) =>
  latestDate(dates.concat(formatDate(moment())));

export const sortByDate = (date1: string, date2: string) =>
  moment(date1, APP_DEFAULT_DATE_FORMAT).diff(moment(date2, APP_DEFAULT_DATE_FORMAT));
