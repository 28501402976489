import * as A from 'antwerp-core-react-branding';

import { CONFIRM, CONFIRM_CANCEL, CONFIRM_OK, CONFIRM_RESET } from '../../common/actions/types';
import { IGenericAction } from '../actions/actions';

const INITIAL_STATE: A.AlertProperties | {} = {};

export default function confirm(
  state: A.AlertProperties | {} = INITIAL_STATE,
  action: IGenericAction<A.AlertProperties>,
): A.AlertProperties | {} {
  switch (action.type) {
    case CONFIRM:
      return { ...action.payload, timestamp: new Date().getTime() };
    case CONFIRM_RESET:
    case CONFIRM_OK:
    case CONFIRM_CANCEL:
      return {};
    default:
      return state;
  }
}
