import { ICostComponent } from '../../types/cost.types';
import { APP_DEFAULT_CURRENCY } from '../constants';

export function formatPrice(price?: number): string {
  price = price === undefined ? 0 : price;
  return `${APP_DEFAULT_CURRENCY} ${price.toFixed(2)}`;
}

export function calculateNumberOfParkingSpots(costComponents: ICostComponent[]): number {
  return costComponents
    .filter((x) => x.numberOfParkingSpots)
    .map((x) => x.numberOfParkingSpots)
    .reduce((p, c) => p + c, 0);
}
