import { FC } from 'react';
import { ISoftDeletableField } from '../../../types';
import { useGenericStyles } from '../../../hooks';
import { Visible } from '../../common/layout/Visible.component';

interface IProps {
  fields: ISoftDeletableField[];
}

export const SoftDeletableFields: FC<IProps> = ({ fields }) => (
  <Visible
    visible={!!fields.length}
    defaultComponent={<div className={useGenericStyles().spacing.marginBottom.SM}>/</div>}
  >
    {fields?.map(({ id, ...field }) => (
      <SoftDeletableField key={id} field={field} />
    ))}
  </Visible>
);

const SoftDeletableField = ({ field: { name } }: { field: ISoftDeletableField }) => (
  <div className={useGenericStyles().spacing.marginBottom.SM}>{name}</div>
);
