import * as L from 'leaflet';
import { Geometry } from '@turf/turf';

export enum GateType {
  in = 'IN',
  out = 'UIT',
  both = 'IN/UIT',
}

export interface IGate {
  id: number;
  name: string;
  address: string;
  gisId: string;
  sas: GateType;
  active: boolean;
}

export type TCarFreeZone = {
  gates: IGate[];
  id: number;
  active: boolean;
  city: string;
  description: string;
  externalReference: string;
  gisId: string;
  latitude: number;
  longitude: number;
  name: string;
  street: string;
  streetNumber: number;
  type: CarFreeZoneType;
  zipCode: number;
};

export enum CarFreeZoneType {
  anpr = 'anpr',
  krautli = 'krautli',
  limited = 'limited',
  vialis = 'vialis',
}

export interface ICarFreeZoneFeatureProperties {
  FIRST_NAAM_GEBIED: string;
  GISID_GEBIED: string;
  HANDHAVING: string;
  OBJECTID: number;
  SHAPE_Area: number;
  SHAPE_Length: number;
  STATUS: string;
  UITZONDERING: string;
  VENSTERTIJD: string;
  ZONE: string;
}

export interface ICarFreeZoneFeature {
  geometry: any;
  properties: ICarFreeZoneFeatureProperties;
}

export interface IGateFeature {
  geometry: Geometry;
  properties: {
    Adres_Stuur_Masterzuil?: string;
    GISID: string;
    Krautli_Vialis: string;
    NAAM: string;
    OBJECTID: string;
    SAS: string;
    Type: string;
    VAZ: string;
    VAZ_GEBIED: string;
    VDPnr_nieuw: string;
    VDPnr_oud: string;
    Zone: string;
  };
}

export interface IStyleableLayer extends L.Layer {
  setStyle?(style: Object): void;
  setIcon?(icon: L.Icon): void;
}
