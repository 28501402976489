import { ISgwPhaseMainLocation, IAddress, ISgwGeoDrawing, StreetNumberGeoCodeRetryPolicy } from '../../types';

export const getStreetNumberForGeocoding = (
  { streetNumberUnknown, streetNumberFrom, streetNumberTo }: Partial<ISgwPhaseMainLocation>,
  retryPolicy: StreetNumberGeoCodeRetryPolicy = StreetNumberGeoCodeRetryPolicy.calculateAvg,
) => {
  const regex = /[^\d]/g;
  const nrFrom = Number(streetNumberFrom?.replace(regex, ''));
  const nrTo = Number(streetNumberTo?.replace(regex, ''));

  if (streetNumberUnknown) {
    return '';
  }

  if (nrFrom === nrTo) {
    return streetNumberFrom;
  }

  switch (retryPolicy) {
    case StreetNumberGeoCodeRetryPolicy.calculateAvg:
      return `${Math.round((nrTo + nrFrom) / 2)}`;
    case StreetNumberGeoCodeRetryPolicy.useFrom:
      return `${nrFrom}`;
    case StreetNumberGeoCodeRetryPolicy.useTo:
      return `${nrTo}`;
    default:
      return '';
  }
};

export const mapMainLocationToAddress = (mainLocation: ISgwPhaseMainLocation, tries?: number): IAddress => ({
  streetNumber: getStreetNumberForGeocoding(mainLocation, tries),
  city: mainLocation.city,
  zipCode: mainLocation.zipCode,
  street: mainLocation.street,
});

export const getCurrentGeoDrawingNumberingByType = (
  drawings: ISgwGeoDrawing[],
  current?: ISgwGeoDrawing,
  isAddNew: boolean = false,
): number => {
  let number = 1;
  if (!current) return number;

  const filteredByType = drawings.filter((d) => d.geoDrawingType === current.geoDrawingType);
  if (isAddNew) {
    return filteredByType.length + 1;
  } else {
    filteredByType.every((d, index) => {
      if (d === current) {
        number = index + 1;
        return false;
      }
      return true;
    });
    return number;
  }
};

export const mapToGeoDrawingBe = (geoDrawing: ISgwGeoDrawing) => {
  if (typeof geoDrawing.id === 'string') {
    delete geoDrawing.id;
  }
  return {
    ...geoDrawing,
    sgwTrafficMeasures: geoDrawing.sgwTrafficMeasures || [],
  };
};

export const mapToGeoDrawingsBE = (geoDrawings?: ISgwGeoDrawing[]) => geoDrawings?.map(mapToGeoDrawingBe) || [];
