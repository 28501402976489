import { Environment } from '../../config/environment';

export function urlDisplayName(url: string) {
  if (url) {
    return url.toLowerCase().replace('http://', '').replace('https://', '').replace('www.', '');
  }
  return '';
}

export function splitPathname(pathname: string): string[] {
  if (pathname) {
    return pathname.split('/').filter((x) => x !== '');
  }
  return [];
}

export function getLocalUrl(...path: any[]): string {
  return absoluteUrl(getUrl(Environment.baseFrontEndUrl, ...path));
}

export function getUrl(host: string, ...path: any[]): string {
  if (host !== undefined || host !== null) {
    if (path && path.length > 0) {
      return joinUrl(host, path.reduce(joinUrl, ''));
    }
    return host;
  }
  return '';
}

export function cleanUrl(url: string): string {
  url = url.replace(/\/\//gi, '');
  if (!isAbsoluteUrl(url)) {
    url = '/' + url;
  }
  return url;
}

export function absoluteUrl(url: string) {
  if (url) {
    return isAbsoluteUrl(url) ? url : '/' + url;
  }
  return '/';
}

export function isAbsoluteUrl(url: string) {
  if (url) {
    return url[0] === '/';
  }
  return false;
}

export function joinUrl(first: string, last: string) {
  if (!first) {
    return last;
  }

  if (!last) {
    return first;
  }

  first = first.slice(-1) === '/' ? first : first + '/';
  last = isAbsoluteUrl(last) ? last.slice(1) : last;

  return first + last;
}
