import { ISgwPhase } from '../../../types';
import { createNormalizedReducer } from '../pagedTable.reducer';
import { PhaseActions } from '../../actions';
import { combineReducers } from 'redux';
import { generatePayloadReducer } from '../generic.reducers';

export default combineReducers({
  list: createNormalizedReducer<ISgwPhase>({
    addEntityActions: [PhaseActions.set.type],
    addEntitiesActions: [PhaseActions.setList.type],
    fetchAction: PhaseActions.fetchList.type,
    setAllIdsAction: PhaseActions.setList.type,
  }),
  formFieldsChanged: generatePayloadReducer<boolean>([PhaseActions.formFieldsChanged.type], false),
});
