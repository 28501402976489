import './WorkflowButtons.component.scss';
import React, { FC, useCallback } from 'react';
import * as A from 'antwerp-core-react-branding';
import { WORKFLOW_MAX_STEP, WORKFLOW_MIN_STEP } from '../../../common/constants';
import { WorkflowButton } from '../../atoms';
import { WorkflowButtonOrientations, WorkflowType } from '../../../types';
import { submitId } from '../../../common/utils/formUtils';
import { createUseStyles } from 'react-jss';
import { translate } from '../../../translations/translate';

export type FormButtonsProperties = {
  nextDisabled?: boolean;
  previousDisabled?: boolean;
  forcePrevious?: boolean;
  onNext?(): void;
  onPrevious?: any;
  step?: number;
  submit?: boolean;
  type: WorkflowType;
  onNextLabel?: string;
};

const useStyles = createUseStyles({
  marginBetween: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  rowEnd: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  formButtons: {
    paddingRight: '0.75rem',
    paddingLeft: '0.75rem',
  },
});

export const WorkflowButtons: FC<FormButtonsProperties> = (props) => {
  const C = useStyles();

  const {
    nextDisabled = false,
    previousDisabled = false,
    onNext,
    onPrevious,
    forcePrevious = false,
    step,
    submit = true,
    onNextLabel,
  } = props;
  const isPrevious = useCallback(
    () => !!onPrevious && (forcePrevious || (step! > WORKFLOW_MIN_STEP && step! < WORKFLOW_MAX_STEP)),
    [forcePrevious, onPrevious, step],
  );

  return (
    <section className={C.formButtons}>
      {/*@ts-ignore*/}
      <A.Spacing type={A.SpacingStyle.MarginTopLg} />
      <div className={isPrevious() ? C.marginBetween : C.rowEnd}>
        <WorkflowButton
          disabled={previousDisabled}
          label={translate('form.previous')}
          onClick={(e) => onPrevious(props as any)}
          visible={isPrevious()}
        />
        <WorkflowButton
          disabled={nextDisabled}
          label={onNextLabel || translate('form.next')}
          onClick={onNext}
          orientation={WorkflowButtonOrientations.right}
          type={submit ? 'submit' : 'button'}
          form={submitId(props.type, props.step)}
        />
      </div>
      {/*@ts-ignore*/}
      <A.Spacing type={A.SpacingStyle.MarginBottomLarge} />
    </section>
  );
};
