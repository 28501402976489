import intl from 'react-intl-universal';

import validateRequired from './validateRequired';
import { ValidateResult } from 'react-hook-form/dist/types/validator';

export const validateEmail = (value: string): ValidateResult => {
  const format =
    // eslint-disable-next-line no-useless-escape
    /^(([a-zA-Z0-9!#$%&'*+\-\/=?^_`{|}~]+(\.[[a-zA-Z0-9!#$%&'*+\-\/=?^_`{|}~@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const requiredMessage = validateRequired(value);
  if (requiredMessage) {
    return requiredMessage;
  }

  if (!format.test(value)) {
    return intl.get('fields.novalidemail');
  }

  return true;
};
