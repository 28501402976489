import { IGenericAction } from '../actions';
import { BUSYSTATE_CHANGED, PROCESS_IDLE, PROCESS_STARTED } from '../types';
import { genericAction } from './baseCreators';

export function createProcessStartedAction(process: string): IGenericAction<string> {
  return genericAction<string>(PROCESS_STARTED, process);
}

export function createProcessIdleAction(process: string): IGenericAction<string> {
  return genericAction<string>(PROCESS_IDLE, process);
}

export function createBusyChangedAction(busy: boolean): IGenericAction<boolean> {
  return genericAction(BUSYSTATE_CHANGED, busy);
}
