import { Flyout, Locations, Sizes } from 'antwerp-core-react-branding';
import classNames from 'classnames';
import * as React from 'react';
import { FC, useState } from 'react';
import { Visible } from '../../common/layout/Visible.component';
import { createUseStyles } from 'react-jss';
import { CheckBox, IconButton } from '../../atoms';
import { ButtonVariant } from '../../../types';
import { useOnClickOutside } from '../../../hooks';

interface IProps {
  onChangeSelectedValues?: (values: string[]) => void;
  options: IOption[];
  label?: string;
  name: string;
  values: string[];
}

const useStyles = createUseStyles({
  button: {
    width: '200px',
    font: 'inherit',
    color: 'inherit',
    boxShadow: 'inherit',
    border: '1px solid #b0b0b0',
    backgroundColor: 'transparent',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      color: 'inherit',
    },
  },
  options: {
    maxHeight: '400px',
    overflow: 'auto',
    whiteSpace: 'nowrap',
  },
});

export interface IOption {
  label: string;
  value: string;
}

const MAX_STRING_LENGTH = 30;

export const MultiSelect: FC<IProps> = ({ label, values, onChangeSelectedValues, options }) => {
  const [visible, setVisible] = useState(false);
  const C = useStyles();

  const _onSelectOption = (changedOption: string): void => {
    const newItems = values.includes(changedOption)
      ? values.filter((item) => item !== changedOption)
      : [...values, changedOption];
    onChangeSelectedValues?.(newItems);
  };

  const ref = useOnClickOutside(() => setVisible(false));

  const getLabel = () => {
    const fullLabel =
      options
        .filter(({ value }) => values.includes(value))
        .map(({ label }) => label)
        .join(', ') ||
      label ||
      '';

    return fullLabel.length >= MAX_STRING_LENGTH ? fullLabel.slice(0, MAX_STRING_LENGTH - 3).concat('...') : fullLabel;
  };

  return (
    <div ref={ref}>
      <Flyout
        content={
          <Visible visible={!!options.length}>
            <ul className={classNames('m-selectable-list', 'm-selectable-list--no-border', C.options)}>
              {options.map(({ label, value }, i) => (
                <li key={i}>
                  <div className="m-selectable-list__item">
                    <CheckBox checked={values.includes(value)} label={label} onChange={() => _onSelectOption(value)} />
                  </div>
                </li>
              ))}
            </ul>
          </Visible>
        }
        label={
          <IconButton
            type="button"
            className={C.button}
            location={Locations.Right}
            icon="angle-down"
            variant={ButtonVariant.Outline}
            onClick={() => setVisible(!visible)}
          >
            {!!label && getLabel()}
          </IconButton>
        }
        size={Sizes.Default}
        visible={visible}
      />
    </div>
  );
};
