import React, { FC, useCallback, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { createAlertResetAction, createConfirmOKAction } from '../../../common/actions/creators/alert';
import { useDispatch, useSelector } from 'react-redux';
import { selectAlert } from '../../../store/selectors';
import { AntwerpAlert } from './AntwerpAlert.component';

export const Alert: FC = () => {
  const dispatch = useDispatch();
  const alert = useSelector(selectAlert);
  const [timestamp, setTimestamp] = useState<number | undefined>();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    // @ts-ignore
    if (alert.timestamp && timestamp !== alert.timestamp) {
      setVisible(true);
      // @ts-ignore
      setTimestamp(alert.timestamp);
    } else {
      setVisible(false);
      setTimestamp(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  const getTitle = useCallback(
    () => (
      <div role="alert" aria-live="polite">
        {alert?.title ? alert?.title : `${intl.get('general.pageTitle')} - ${intl.get('general.pageSubTitle')}`}
      </div>
    ),
    [alert?.title],
  );

  const _onOk = useCallback(() => {
    dispatch(createAlertResetAction());
    if (alert?.onOk) {
      alert.onOk();
    } else {
      dispatch(createConfirmOKAction());
    }
  }, [alert, dispatch]);

  return <AntwerpAlert {...alert} onOk={_onOk} title={getTitle()} visible={visible} />;
};
