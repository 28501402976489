import '../../../../node_modules/leaflet-draw/dist/leaflet.draw.css';
import '../../../../node_modules/leaflet/dist/leaflet.css';
import './viewmap.scss';

import * as A from 'antwerp-core-react-branding';

import { MAP_CARFREEZONE_URL, MAP_DEFAULT_POSITION, MAP_FEATURE_SELECTED_COLOR } from '../../../common/constants';
import { PublicDomainType } from '../../../common/enums';
import { getLatLng2D, getLatLng3D, intakeReverseCoordinates } from '../../../common/utils/mapUtils';
import { BaseMap } from './BaseMap.component';
import { Polyline } from './features/Polyline.component';
import { Polygon } from './features/Polygon.component';
import { Position } from 'geojson';
import { FeatureLayer } from 'react-esri-leaflet';
import { IPublicDomainGeometryCoord, IPublicDomainIntake, IRequest, ICarFreeZoneFeature } from '../../../types';
import { Component } from 'react';

export type ViewMapProperties = {
  request: IRequest;
};

/**
 * React Component ViewMap
 */
export default class ViewMap extends Component<ViewMapProperties, {}> {
  private static markerIndex: number = 0;

  private map: any;

  public render(): any {
    if (this.props.request) {
      const { request } = this.props;
      return (
        // @ts-ignore
        <A.Spacing type={A.SpacingStyle.MarginBottom}>
          <div className="leaflet__viewmap" style={{ width: '100%', height: '350px' }}>
            <BaseMap bounds={this.calculateBounds(request)} zoomControl>
              {request.publicDomainIntakes
                .filter(({ geometry }) => geometry?.type === 'LineString')
                .map(({ id, geometry }) => (
                  <Polyline key={id} positions={getLatLng2D(geometry!.coordinates as Position[])} />
                ))}
              {request.publicDomainIntakes
                .filter(({ geometry }) => geometry?.type === 'Polygon')
                .map(({ id, geometry }) => (
                  <Polygon key={id} positions={getLatLng3D(geometry!.coordinates as unknown as Position[][])} />
                ))}
              {this.hasCarFreeZoneIntakes() ? (
                <FeatureLayer
                  key="cfzi"
                  url={MAP_CARFREEZONE_URL}
                  onEachFeature={this.onEachFeature(this.getGisIds())}
                />
              ) : null}
            </BaseMap>
          </div>
        </A.Spacing>
      );
    }
    return null!;
  }

  private getGisIds = (): string[] =>
    this.props.request.publicDomainIntakes
      .filter(({ type }) => type.type === PublicDomainType.CarfreeZone)
      .map(({ gisId }) => gisId)
      .filter((gisId) => !!gisId) as string[];

  private onEachFeature = (gisIds: string[]) => (feature: ICarFreeZoneFeature, layer: any) => {
    const { GISID_GEBIED } = feature.properties;
    if (gisIds.includes(GISID_GEBIED)) {
      layer.setStyle({
        color: MAP_FEATURE_SELECTED_COLOR,
        strokeColor: MAP_FEATURE_SELECTED_COLOR,
      });
    }
  };

  // #endregion

  // #region utils

  private invertCoordinates(request: IRequest): IPublicDomainIntake[] {
    const intakes = [...request.publicDomainIntakes];

    return intakes.map((x) => ({
      ...x,
      geometry: intakeReverseCoordinates(x)!,
    }));
  }

  private calculateBounds(request: IRequest): IPublicDomainGeometryCoord[] {
    if (request.publicDomainIntakes) {
      const intakes = this.invertCoordinates(request);

      return intakes.reduce((p, c) => {
        if (c.type.type === PublicDomainType.CarfreeZone && c.geometry!.type !== 'Polygon') {
          p.push([(c.geometry!.coordinates as any)[0] - 0.002, (c.geometry!.coordinates as any)[1] - 0.002] as any);
          p.push([(c.geometry!.coordinates as any)[0] + 0.002, (c.geometry!.coordinates as any)[1] + 0.002] as any);
        } else {
          c.geometry!.coordinates!.forEach((x) => p.push(x));
        }
        return p;
      }, [] as IPublicDomainGeometryCoord[]);
    }
    return [MAP_DEFAULT_POSITION];
  }

  private hasCarFreeZoneIntakes(): boolean {
    if (this.props.request && this.props.request.publicDomainIntakes) {
      const intakes = this.props.request.publicDomainIntakes;
      if (intakes.filter((x) => x.type.type === PublicDomainType.CarfreeZone).length > 0) {
        return true;
      }
    }
    return false;
  }

  // #endregion
}
