import { Fieldset, IconButton } from '../../atoms';
import { translate } from '../../../translations/translate';
import * as A from 'antwerp-core-react-branding';
import { FileSelect } from '../../molecules';
import React, { SyntheticEvent } from 'react';
import { createUseStyles } from 'react-jss';
import { useFormContext, useWatch } from 'react-hook-form';
import { isValid } from '../../../common/utils/formUtils';
import { SgwRequestActions } from '../../../store/actions';
import { validateAttachment } from '../../../common/validation/validateRequiredAttachment';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEachPhaseHasAtLeastOneAttachmentWithSignalisationMap,
  getFirstUrgentAttachment,
  getPhaseOptions,
  getRequest,
} from '../../../store/selectors/sgw';
import { ButtonVariant, categories, Category, IAttachment, TRouterWithId } from '../../../types';
import { useParams } from 'react-router';
import { Select } from '../../atoms/Select/Select.component';
import { Levels } from '../../../common/enums';
import { MultiSelect } from '../../atoms/MultiSelect/MultiSelect.component';

import { COLORS } from '../../../theme';
import { Required } from '../../common/forms/Required.component';
import { SpinnerLoader } from '../../atoms/SpinnerLoader/SpinnerLoader.component';

const useStyles = createUseStyles({
  button: { boxShadow: 'none !important' },
  dropdowns: { display: 'flex', gap: '9px', alignItems: 'center' },
  fileName: {
    fontSize: 'large',
    color: COLORS.oliveBlack,
  },
  fileSelect: {
    padding: '30px 30px 50px',
  },
  list: {
    marginLeft: '10%',
    marginRight: '10%',
  },
  listItem: {
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '30px',
    borderBottom: '1px solid #b0b0b0',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const AttachmentFields = () => {
  const { id } = useParams<keyof TRouterWithId>();
  const request = useSelector(getRequest(id));
  const phaseOptions = useSelector(getPhaseOptions(id!));
  const firstUrgentAttachment = useSelector(getFirstUrgentAttachment);
  const eachPhaseHasAtLeastOneAttachmentWithSignalisationMap = useSelector(
    getEachPhaseHasAtLeastOneAttachmentWithSignalisationMap(id!),
  );
  const C = useStyles();
  const dispatch = useDispatch();
  const {
    setError,
    clearErrors,
    register,
    control,
    formState: { errors },
  } = useFormContext<{ attachments: IAttachment[] }>();
  // Filter out urgent file attachments option if request is not urgent.
  const filteredCategories = request?.isUrgentRequest
    ? categories
    : categories.filter((c) => c.label !== Category.urgentRequest);
  const attachments = useWatch({ control, name: 'attachments' });
  const hasUrgentAttachment = firstUrgentAttachment !== undefined;
  const hasOneUrgentRequestOnly = attachments?.filter((a) => a.category === Category.urgentRequest).length === 1;

  const _validateAttachment = (file?: File) => {
    const error = validateAttachment(file, false, 25);
    if (isValid(error) && file) {
      clearErrors('attachments');
    } else {
      setError('attachments', { type: 'manual', message: error as string });
    }
    return error;
  };

  const onFileSelect = (file?: File) => {
    const error = _validateAttachment(file);
    if (isValid(error) && file) {
      dispatch(SgwRequestActions.attachments.upload({ requestId: request?.id!, file }));
    }
  };

  const onDeleteAttachment = (id: number) => () =>
    dispatch(SgwRequestActions.attachments.remove({ requestId: request?.id!, attachmentId: id }));

  const onChangePhases = (id: number) => (sgwPhases: string[]) =>
    dispatch(SgwRequestActions.attachments.link({ requestId: request?.id!, attachment: { id, sgwPhases } }));

  const onSelectCategory = (id: number) => (e: SyntheticEvent<HTMLSelectElement>) => {
    const category = e.currentTarget.value as Category;
    dispatch(SgwRequestActions.attachments.link({ requestId: request?.id!, attachment: { id, category } }));
  };

  return (
    <>
      <SpinnerLoader loading={!request}>
        <Fieldset legend={translate('sgw.attachments.add')}>
          {/*@ts-ignore*/}
          <A.Paragraph spacing>{translate('sgw.attachments.info')}</A.Paragraph>
          <FileSelect
            description={translate('sgw.startRequest.period.attachmentDescription', {
              maxsize: 25,
            })}
            name={'attachments.0.file'}
            className={C.fileSelect}
            onFileSelect={onFileSelect}
          />
        </Fieldset>
        <Fieldset legend={translate('sgw.attachments.files')}>
          {/* @ts-ignore */}
          <Required
            required={!!errors.attachments || !eachPhaseHasAtLeastOneAttachmentWithSignalisationMap}
            message={errors.attachments?.message || translate('sgw.attachments.signalisationMapRequired')}
          />
          <div className={C.list}>
            {attachments?.map((field, index) => (
              <div className={C.listItem}>
                <div className={C.row}>
                  <p className={C.fileName}>{field.fileStorage.name}</p>
                  <div className={C.dropdowns}>
                    <Select
                      label={translate('sgw.attachments.selectCategory')}
                      options={filteredCategories}
                      {...register(`attachments.${index}.category` as const)}
                      onSelect={(e: SyntheticEvent<HTMLSelectElement>) => onSelectCategory(field.id!)(e)}
                    />
                    <MultiSelect
                      label={translate('sgw.attachments.selectPhases')}
                      options={phaseOptions}
                      {...register(`attachments.${index}.sgwPhases` as const)}
                      onChangeSelectedValues={onChangePhases(field.id!)}
                      values={field.sgwPhases.map((phase) => `${phase}`)}
                    />
                    <IconButton
                      className={C.button}
                      type="button"
                      onClick={onDeleteAttachment(field.id!)}
                      icon="trash"
                      variant={ButtonVariant.Transparent}
                      level={Levels.Danger}
                      disabled={
                        field.category === Category.urgentRequest && hasUrgentAttachment && hasOneUrgentRequestOnly
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Fieldset>
      </SpinnerLoader>
    </>
  );
};
