import React, { FC, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { Visible } from '../../common/layout/Visible.component';
import { Button } from '../../atoms';
import { COLORS } from '../../../theme';
import { translate } from '../../../translations/translate';
import { useGenericStyles, useGeoDrawingContext } from '../../../hooks';
import { useSelector } from 'react-redux';
import { getPhaseOptions } from '../../../store/selectors/sgw';
import { Select } from '../../atoms/Select/Select.component';
import { useAtom } from 'jotai';
import { ConflictsShownOnMapAtom } from '../../pages/MapPage/Map.page';
import { phaseIdAtom } from '../../forms/sgw';

export interface IProps {
  disableSave?: boolean;
  readOnly?: boolean;
}

const useStyles = createUseStyles({
  actionContainer: {
    borderBottom: `1px solid ${COLORS.lightSilver}`,
  },
  phaseSelectContainer: {
    width: '80%',
  },
  error: {
    color: COLORS.red,
    marginLeft: '10px',
    marginBottom: '5px',
    fontSize: 'smaller',
  },
});

export const MapActionHeader: FC<IProps> = ({ disableSave, readOnly }) => {
  const C = useStyles();
  const { flex, spacing } = useGenericStyles();
  const { requestId, shouldSave, savePhase, setShouldSave, isAddNew, geoDrawingIndexInEdit } = useGeoDrawingContext();
  const [, setSelectedConflicts] = useAtom(ConflictsShownOnMapAtom);
  const [, setPhaseId] = useAtom(phaseIdAtom);

  const options = useSelector(getPhaseOptions(requestId));

  const _onChangePhase = (e: SyntheticEvent<HTMLSelectElement>) => {
    savePhase(true)(e);
    setPhaseId(e.currentTarget.value);
    setShouldSave(false);
    setSelectedConflicts([]);
  };

  const _onSave = (e: SyntheticEvent<HTMLButtonElement>) => {
    savePhase(false)(e);
    setShouldSave(false);
  };

  return (
    <div data-testid={'MapActionHeader'} className={classNames(flex.column, C.actionContainer)}>
      <div className={flex.centerVerticalSpaceBetween}>
        <div className={classNames(flex.column, C.phaseSelectContainer, spacing.marginLeft.XXS)}>
          <Select
            data-testid={'PhasesSelect'}
            options={options}
            label={''}
            name={'id'}
            onSelect={_onChangePhase}
            emptyOption={false}
            disabled={isAddNew || shouldSave || !!geoDrawingIndexInEdit}
          />
        </div>
        <div className={classNames(flex.column, flex.center, spacing.marginRight.XXS, spacing.marginLeft.XXS)}>
          <Visible visible={shouldSave}>
            <Button data-testid={'SaveButton'} size="small" type={'button'} onClick={_onSave} disabled={disableSave}>
              {translate('sgw.map.drawOnMap.phase.save')}
            </Button>
          </Visible>
        </div>
      </div>
      <Visible visible={!!disableSave && !readOnly}>
        <span className={C.error}>{translate('sgw.map.trafficMeasures.errorNotComplete')}</span>
      </Visible>
    </div>
  );
};
