import React, { FC, PropsWithChildren } from 'react';
import { IWithComponentName, SgwWorkflowStep, TRouterWithId } from '../../../types';
import { generatePath, useParams } from 'react-router';
import { Environment } from '../../../config/environment';
import { appUrls } from '../../../common/constants';
import { Children } from '../Children/Children.component';
import { useOverviewlistStyles } from './OverviewList.styles';
import { Visible } from '../../common/layout/Visible.component';
import classNames from 'classnames';
import { useFlexStyles } from '../../../theme';
import { translate } from '../../../translations/translate';

interface IOverviewTitleProps extends IWithComponentName, PropsWithChildren {
  step?: SgwWorkflowStep;
}

interface IOverviewItemProps extends IWithComponentName, PropsWithChildren {
  title: string;
  description?: string | JSX.Element;
  visible?: boolean;
  isSubtitle?: boolean;
}
interface IOverviewCustomItemProps extends IWithComponentName, PropsWithChildren {
  visible?: boolean;
  title?: string;
  titleClassName?: string;
  childrenClassName?: string;
  className?: string;
  wrapperClassName?: string;
}
interface IOverviewSublistProps extends IWithComponentName, PropsWithChildren {
  title: string;
  description?: string | JSX.Element;
  visible?: boolean;
}

interface OverviewListFC extends FC<PropsWithChildren> {
  Title: FC<IOverviewTitleProps>;
  Item: FC<IOverviewItemProps>;
  Sublist: FC<IOverviewSublistProps>;
  CustomItem: FC<IOverviewCustomItemProps>;
}

const OverviewTitle: FC<IOverviewTitleProps> = ({ children, step }) => {
  const { id } = useParams<keyof TRouterWithId>();
  const { title } = useOverviewlistStyles();

  const path = (step: SgwWorkflowStep) =>
    generatePath(`${Environment.baseFrontEndUrl}${appUrls.sgw.request.base}${appUrls.sgw.request.edit()}`, {
      step,
      id,
    });

  return (
    <div className={title}>
      <h5>{children}</h5>
      {step ? <a href={path(step)}>{translate('sgw.overview.edit')}</a> : null}
    </div>
  );
};

const OverviewItem: FC<IOverviewItemProps> = ({ title, description, visible = true, isSubtitle }) => {
  const { subtitle } = useOverviewlistStyles();
  return (
    <Visible visible={!!description && visible}>
      <li>
        <div className={classNames('row', isSubtitle && subtitle)}>
          <div className="col-xs-12 col-md-6 u-text-light">{title}</div>
          <div className="col-xs-12 col-md-6">{description}</div>
        </div>
      </li>
    </Visible>
  );
};
const OverviewCustomItem: FC<IOverviewCustomItemProps> = ({
  title,
  children,
  visible = true,
  titleClassName,
  className,
  childrenClassName,
  wrapperClassName,
}) => {
  const { centerVerticalSpaceBetween } = useFlexStyles();
  return (
    <Visible visible={visible}>
      <li className={className}>
        <div className={wrapperClassName || centerVerticalSpaceBetween}>
          <div className={classNames('u-text-light', titleClassName)}>{title}</div>
          <div className={classNames(childrenClassName)}>{children}</div>
        </div>
      </li>
    </Visible>
  );
};

const OverviewSublist: FC<IOverviewSublistProps> = ({ children, visible = true, ...props }) => (
  <Visible visible={visible}>
    <OverviewItem {...props} isSubtitle visible />
    {children}
  </Visible>
);

const OverviewList: OverviewListFC = ({ children }) => {
  const { list } = useOverviewlistStyles();
  return (
    <div className={list}>
      <Children componentNames={['OverviewTitle']}>{children}</Children>
      <ul className="a-list a-list--lined has-icon-left">
        <Children componentNames={['OverviewItem']}>{children}</Children>
        <Children componentNames={['OverviewSublist']}>{children}</Children>
        <Children componentNames={['OverviewCustomItem']}>{children}</Children>
      </ul>
    </div>
  );
};

OverviewTitle.defaultProps = { componentName: 'OverviewTitle' };
OverviewItem.defaultProps = { componentName: 'OverviewItem' };
OverviewSublist.defaultProps = { componentName: 'OverviewSublist' };
OverviewCustomItem.defaultProps = { componentName: 'OverviewCustomItem' };

OverviewList.Title = OverviewTitle;
OverviewList.Item = OverviewItem;
OverviewList.Sublist = OverviewSublist;
OverviewList.CustomItem = OverviewCustomItem;

export { OverviewList };
