import './App.routes.scss';
import React, { FC } from 'react';
import NotAuthText from '../common/layout/notAuthText';
import { WaitText } from '../common/layout/WaitText.component';
import { useSelector } from 'react-redux';
import { selectAuthenticated, selectInitialized } from '../../store/selectors';
import { ISelect } from '../../types';
import { Route, Routes } from 'react-router';
import { getLocalUrl } from '../../common/utils/urlUtils';
import { appUrls } from '../../common/constants';
import { MyRequestsDetails } from './my-requests/my-requests-details/MyRequestsDetails.page';
import { MyRequestsExtensions } from './my-requests/my-requests-extension/MyRequestsExtensions.page';
import { Workflow } from './workflow/Workflow.page';
import { Success } from './submit/Success.component';
import { Fail } from './submit/Fail.component';
import { FailedPayment } from './submit/FailedPayment.component';
import { ErrorBoundary } from '../common/errors/ErrorBoundary.component';
import { ProjectTypeComponent } from './projectType/ProjectType.component';
import { SgwWorkflowComponent, MyRequestsPage, MySgwRequestDetails } from '../pages';
import { Feature } from '../atoms';
import { NotAuthorizedPage } from '../pages/NotAuthorizedPage/NotAuthorizedPage.component';
import { Navigate } from 'react-router-dom';

export const AppRoutes: FC = () => {
  const authenticated: ISelect<typeof selectAuthenticated> = useSelector(selectAuthenticated);
  const initialized: ISelect<typeof selectInitialized> = useSelector(selectInitialized);
  return authenticated && initialized ? (
    <ErrorBoundary>
      <Routes>
        <Route path={appUrls.notAuthorized} element={<NotAuthorizedPage />} />
        <Route path={appUrls.myRequests.base} element={<MyRequestsPage />} />
        <Route path={appUrls.myRequests.detail} element={<MyRequestsDetails />} />
        <Route path={appUrls.myRequests.sgwDetail()} element={<MySgwRequestDetails />} />
        <Route
          path={appUrls.projectType}
          element={
            <Feature.CreateSgwRequest
              fallbackComponent={<Navigate to={getLocalUrl(appUrls.request.create('1'))} replace />}
            >
              <ProjectTypeComponent />
            </Feature.CreateSgwRequest>
          }
        />
        <Route path={appUrls.myRequests.extension} element={<MyRequestsExtensions />} />
        <Route path={appUrls.request.create()} element={<Workflow />} />
        <Route path={appUrls.request.edit()} element={<Workflow />} />
        <Route path={appUrls.request.submit.success} element={<Success path={appUrls.myRequests.base} />} />
        <Route path={appUrls.request.submit.fail} element={<Fail />} />
        <Route path={appUrls.request.submit.failedPayment} element={<FailedPayment />} />
        <Route
          path={appUrls.sgw.request.baseWildcard}
          element={
            <Feature.CreateSgwRequest
              fallbackComponent={<Navigate to={getLocalUrl(appUrls.request.create('1'))} replace />}
            >
              <SgwWorkflowComponent />
            </Feature.CreateSgwRequest>
          }
        />
        <Route path={'*'} element={<Navigate to={getLocalUrl(appUrls.projectType)} replace />} />
      </Routes>
    </ErrorBoundary>
  ) : (
    <section className="approutes__notready">{!initialized ? <WaitText /> : <NotAuthText />}</section>
  );
};
