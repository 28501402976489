import { push } from 'connected-react-router';
import { Action, Dispatch } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, Effect, put, race, select, take, takeEvery } from 'redux-saga/effects';
import { createConfirmAction, createErrorAlertAction } from '../../common/actions/creators/alert';
import { createClearCostAction } from '../../common/actions/creators/cost';
import { createPaymentRequestAction } from '../../common/actions/creators/payments';
import {
  createDeleteTempRequestAction,
  createRequestCreatedAction,
  createRequestReceivedAction,
  createRequestRequestedAction,
  createRequestUpdatedAction,
} from '../../common/actions/creators/requests';
import {
  createNextStepGrantedAction,
  createPreviousStepGrantedAction,
  createResetStepGrantedAction,
  createSetStepAction,
  createStepGrantedAction,
} from '../../common/actions/creators/workflow';
import * as T from '../../common/actions/types';
import { CONFIRM_CANCEL, CONFIRM_OK, REQUEST_SUBMIT } from '../../common/actions/types';
import { partialSaveRequest, saveRequest } from '../../common/api';
import { appUrls } from '../../common/constants';
import * as Constants from '../../common/constants';
import debug from '../../common/debug';
import { PublicDomainType } from '../../common/enums';
import * as TempRequest from '../../common/tempRequestStorage';
import { convertStringToTimestamp } from '../../common/utils/dateUtils';
import { historyPush } from '../../common/utils/historyUtils';
import { formatHour } from '../../common/utils/hourUtils';
import { getWorkflowPath, getWorkFlowTypeFromPath } from '../../common/utils/pathUtils';
import { isRequestId, requestContainsOnlyWeekendDays } from '../../common/utils/requestUtils';
import { translate } from '../../translations/translate';
import {
  AsignWorkflowSteps,
  FeatureName,
  IProfile,
  IPublicDomainIntake,
  IRequest,
  ISelect,
  ITenant,
  SymphonyErrorResponse,
} from '../../types';
import { IGenericAction } from '../actions/actions';
import { createProcessIdleAction, createProcessStartedAction } from '../actions/creators';
import { ApplicationActions } from '../actions/workflow.actions';
import {
  isFeatureEnabled,
  selectCarFreeZones,
  selectProfile,
  selectRequest,
  selectStep,
  selectTenant,
} from '../selectors';
import { requireConnectionErrorHandler } from './errors.sagas';
import { saveTempAttachment } from './requests.sagas';

function* initialize(dispatch: Dispatch<Action>): IterableIterator<Effect> {
  // Get the pathname

  const step: ISelect<typeof selectStep> = yield select(selectStep);
  const id = getWorkFlowTypeFromPath(window.location.pathname) || '';

  if (isRequestId(id)) {
    // Remove the temp request to avoid unwanted side effects
    TempRequest.removeTempRequest();

    // Load the request
    yield put(createRequestRequestedAction(id));
  } else {
    if (TempRequest.isTempRequest()) {
      const now = new Date().getTime();

      // Remove the temp request when the maximum retention time has passed (currently 24h)
      if (now - TempRequest.getTempRequestTimeStamp() > getRestoreDue()) {
        TempRequest.removeTempRequest();
        yield call(createNewRequest);
      } else {
        // Restore the temp request
        yield put(createRequestReceivedAction(TempRequest.getTempRequest()!));

        yield put(
          createConfirmAction(
            `Er werd een onafgewerkte aanvraag teruggevonden. Klik op OK om deze
                    verder af te werken. Klik op Annuleren om van een lege aanvraag te starten.`,
          ),
        );

        // @ts-ignore
        const { cancel, ok } = yield race({
          cancel: take(CONFIRM_CANCEL),
          ok: take(CONFIRM_OK),
        });

        if (ok) {
          yield put(createRequestReceivedAction(TempRequest.getTempRequest()!));
        }

        if (cancel) {
          yield put(createDeleteTempRequestAction());
          yield call(createNewRequest);
        }
      }
    } else {
      yield call(createNewRequest);
    }
  }

  // Correct the step
  if (step! > Constants.WORKFLOW_MIN_STEP) {
    yield put(createResetStepGrantedAction());
  }
}

function* nextStepRequested(action: IGenericAction<IRequest>): IterableIterator<Effect> {
  const process: string = 'nextStepRequested';

  yield put(createProcessStartedAction(process));

  const step: ISelect<typeof selectStep> = yield select(selectStep);
  const tenant: ISelect<typeof selectTenant> = yield select(selectTenant);
  let request: IRequest;
  const stateRequest: ISelect<typeof selectRequest> = yield select(selectRequest);
  let stepGranted: boolean = true;

  // Merge the payload into the state
  switch (step!) {
    // In case of this step, the payload is a pdi and not a request, merge it accordingly
    case AsignWorkflowSteps.Type:
      // Upload the file
      const publicDomainIntake = action.payload.publicDomainIntakes[0];
      // @ts-ignore - because of not clear which payload this is...
      publicDomainIntake.conditions = action.payload.conditions;
      const { attachments } = action.payload;

      // If the type is minor constructions and there are attachments, the attachment should be saved
      if (
        publicDomainIntake.type.type === PublicDomainType.MinorConstruction &&
        attachments?.[0] &&
        (!publicDomainIntake.attachments || attachments[0].lastModified)
      ) {
        const file = yield call(saveTempAttachment, attachments[0]);
        if (file) {
          publicDomainIntake.attachments = [file];
        } else {
          stepGranted = false;
        }
      }

      // Merge the public domain intake into the request
      request = mergePublicDomainIntake(stateRequest!, publicDomainIntake);

      // When no address information is present for the PDI, reset the request data for safety.
      if (!publicDomainIntake.street) {
        resetRequest(request);
      }

      break;
    case AsignWorkflowSteps.Period:
      request = action.payload as IRequest;
      // If the request only contains weekenddays, switch off the onlyonweekdays flag
      if (requestContainsOnlyWeekendDays(request) && request.onlyOnWeekdays) {
        request.onlyOnWeekdays = false;
      }

      request = mergeRequests(stateRequest!, request);
      break;
    case AsignWorkflowSteps.Location:
      request = mergeRequests(stateRequest!, action.payload);
      break;
    default:
      // Create the new request by merging the payload into the state request
      request = mergeRequests(stateRequest!, action.payload as IRequest);
      break;
  }

  // Perform logical cleanup and apply businessrules on the data provided
  applyDataRules(request, tenant!);
  const existingRequest = !!request.id;

  // Store the request in the local storage, but only when we are editing an unsaved request
  TempRequest.storeTempRequest(request);

  // Try save the request
  try {
    // If the request has not been saved yet, we need be coming from at least step 'Personal information'
    if (
      (step! >= AsignWorkflowSteps.Personal || existingRequest) &&
      request.publicDomainIntakes.filter((intake) => intake.valid).length
    ) {
      const carFreeZones: ISelect<typeof selectCarFreeZones> = yield select(selectCarFreeZones);
      // @ts-ignore
      request = yield call(saveRequest, request, carFreeZones);

      // Run action to update a saved request ?
      if (existingRequest) {
        yield put(createRequestUpdatedAction(request));
      } else {
        yield put(createRequestCreatedAction(request));
      }

      // We can now safely remove any existing Temp request because it has now been saved.
      TempRequest.removeTempRequest();
    }
  } catch (e: any) {
    const response: SymphonyErrorResponse = e.response;
    if (response?.data?.msgs) {
      // The translated message is stored in the messages as a string
      const msg = response.data.msgs.filter((x) => x.code === response.status.toString())[0] || response.data.msgs[0];

      let message = msg.message;
      // Clean the error message
      if (msg.message.includes('This value should not be blank.')) {
        // AS-4608 Fix unknown English error-message.
        const parts = message.split(':');
        message = translate('error.blank_field', { field: parts[0] });
      } else {
        message = msg.message.split(':').slice(-1)[0];
      }

      // Push the alert
      yield put(createErrorAlertAction(message));

      // Dump the messages
      debug(`Saving request failed with code ${response.status}`, response.data);
    } else {
      debug(`Saving the request failed for some other reasons`, e);
    }
    stepGranted = false;
  } finally {
    yield put(createProcessIdleAction(process));
  }

  // Push to the next step
  if (stepGranted) {
    // For an existing request, skip the TYPE step and jump straight to the period step.
    if (step === AsignWorkflowSteps.Reason && request.id) {
      yield put(createSetStepAction(AsignWorkflowSteps.Period));
      yield put(createStepGrantedAction(request));
    } else {
      yield put(createNextStepGrantedAction(request));
    }
  }
}

function* previousStepRequested(action: IGenericAction<IRequest>): IterableIterator<Effect> {
  const stateRequest: ISelect<typeof selectRequest> = yield select(selectRequest);
  const step: ISelect<typeof selectStep> = yield select(selectStep);

  // For an existing request, skip the TYPE step and jump back to the reason step.
  if (step === AsignWorkflowSteps.Period && stateRequest!.id) {
    yield put(createSetStepAction(AsignWorkflowSteps.Reason));
    yield put(createStepGrantedAction(stateRequest!));
  } else {
    yield put(createPreviousStepGrantedAction(stateRequest!));
  }
}

function* stepRequested(action: IGenericAction<number>): IterableIterator<Effect> {
  const stateRequest: ISelect<typeof selectRequest> = yield select(selectRequest);

  // When we get here from an existing draft, we need to clear out all previous data.
  if (stateRequest!.publicDomainIntakes.length === 0 && action.payload === AsignWorkflowSteps.Type) {
    if (stateRequest!.id) {
      delete stateRequest!.id;
      delete stateRequest!.state;
      // @ts-ignore
      delete stateRequest!.referenceId;
      // @ts-ignore
      delete stateRequest!.cost;
    }

    yield put(createClearCostAction());
  }

  // Force the step in the state
  yield put(createSetStepAction(action.payload));

  // Grand the step
  yield put(createStepGrantedAction(stateRequest!));
}

function* submitRequest(action: IGenericAction<IRequest>): IterableIterator<Effect> {
  const process = 'submitRequest';
  yield put(createProcessStartedAction(process));
  const paymentType = action.payload.paymentType;
  let request = action.payload;
  const step: ISelect<typeof selectStep> = yield select(selectStep);
  try {
    if (step === Constants.WORKFLOW_MAX_STEP && request.state) {
      if (request.state.state === Constants.WORKFLOW_STATE_DRAFT) {
        // Update the status to waiting_for_payment
        const carFreeZones: ISelect<typeof selectCarFreeZones> = yield select(selectCarFreeZones);
        // @ts-ignore
        request = yield call(
          partialSaveRequest,
          {
            id: request.id,
            state: { state: (request.state.transitions || Constants.WORKFLOW_STATE_WAITING_FOR_PAYMENT)[0] },
          },
          carFreeZones!,
        );

        // Reassign payment type, it is lost on partially submitting the request
        request.paymentType = paymentType;

        // Cleanup, remove the temp request from storage and from state
        TempRequest.removeTempRequest();
        // Start the payment
        const integrateDigipolisOrder = yield select(isFeatureEnabled(FeatureName.integrateDigipolisOrder));
        if (integrateDigipolisOrder) {
          yield put(createPaymentRequestAction(request));
        } else {
          historyPush(appUrls.request.submit.success.replace(':id', `${request.id}`));
        }
      } else {
        yield put(
          createErrorAlertAction('De aanvraag bevindt zich niet in de gewenste status en kan niet worden opgeslagen'),
        );
      }
    } else {
      yield put(
        createErrorAlertAction('De aanvraag bevindt zich niet in de laatste stap en kan niet worden opgeslagen'),
      );
    }
  } catch (e) {
    debug(e);
    yield put(createErrorAlertAction('Er is iets foutgelopen tijdens het opslaan van de aanvraag'));
  } finally {
    yield put(createProcessIdleAction(process));
  }
}

// #region util workers

function* manageNavigation(action: IGenericAction<IRequest>): IterableIterator<Effect> {
  const step: ISelect<typeof selectStep> = yield select(selectStep);

  yield put(push(getWorkflowPath(action.payload.id ? action.payload.id : Constants.WORKFLOW_PATH_NEW, step!)));
}

function mergeRequests(stateRequest: IRequest, request: IRequest): IRequest {
  if (stateRequest) {
    return {
      ...stateRequest,
      ...request,
      publicDomainIntakes: request ? (request.publicDomainIntakes ? request.publicDomainIntakes : []) : [],
    };
  }
  return request;
}

function mergePublicDomainIntake(stateRequest: IRequest, intake: IPublicDomainIntake, index?: number) {
  // Fiex the index optional argument
  index = index ? index : 0;

  // Get the requested intake
  const stateIntake = stateRequest.publicDomainIntakes[index];

  // Merge the requests
  stateRequest.publicDomainIntakes[index] = { ...stateIntake, ...intake, index };

  return stateRequest;
}

function applyDataRules(request: IRequest, tenant: ITenant): void {
  if (request.dateFrom) {
    const timestampFrom = convertStringToTimestamp(request.dateFrom);
    const timestampUntil = convertStringToTimestamp(request.dateUntil);

    const minTimeStamp = convertStringToTimestamp(tenant.earliestStartDateAllowedForRequests);
    const minEmergencyTimeStamp = convertStringToTimestamp(tenant.earliestStartDateAllowedForEmergencyRequests);

    const minorConstructionIntakes = request.publicDomainIntakes.filter(
      (x) => x.type.type === PublicDomainType.MinorConstruction,
    );

    // By default set emergencyProcedure to false
    request.emergencyProcedure = false;

    // In case of editing of a draft, a request with a MCSI can NEVER be an emergency request.
    if (minorConstructionIntakes.length) {
      if (timestampFrom < minTimeStamp) {
        request.dateFrom = tenant.earliestStartDateAllowedForRequests;

        if (timestampUntil < minTimeStamp) {
          request.dateUntil = tenant.earliestStartDateAllowedForRequests;
        }
      }
    } else {
      if (timestampFrom < minTimeStamp) {
        request.emergencyProcedure = true;

        // In case a request is edited that had a date from the past, we need to correct dates to avoid an error when saving.
        if (timestampFrom < minEmergencyTimeStamp) {
          request.dateFrom = tenant.earliestStartDateAllowedForEmergencyRequests;

          if (timestampUntil < minEmergencyTimeStamp) {
            request.dateUntil = tenant.earliestStartDateAllowedForEmergencyRequests;
          }
        }
      }
    }
  }
}

function* createNewRequest(): IterableIterator<Effect> {
  const request: Partial<IRequest> = {};

  if (!request.id) {
    //@ts-ignore
    const profile: IProfile = yield select(selectProfile);

    if (profile) {
      request.bus = profile.bus;
      request.city = profile.city;
      request.country = profile.country;
      request.emailAddress = profile.email;
      request.firstName = profile.firstName;
      request.ibanNumber = profile.ibanNumber;
      request.lastName = profile.lastName;
      request.phoneNumber = profile.phoneNumber;
      request.ssn = profile.ssn;
      request.street = profile.street;
      request.streetNumber = profile.streetNumber;
      request.zipCode = profile.zipCode;
    }
    //@ts-ignore
    const tenant: ITenant = yield select(selectTenant);
    if (tenant) {
      request.dateFrom = tenant.earliestStartDateAllowedForRequests;
      request.dateUntil = tenant.earliestStartDateAllowedForRequests;
    }

    request.timeFrom = formatHour(Constants.APP_DEFAULT_START_HOUR);
    request.timeUntil = formatHour(Constants.APP_DEFAULT_STOP_HOUR);
  }
  yield put(createRequestReceivedAction(request as IRequest));
}

// #endregion

// #region utils

function getRestoreDue(): number {
  return 24 * 1000 * 60 * 60;
}

// #endregion

export default function* workflowSaga(): SagaIterator {
  yield takeEvery(T.INITIALIZE_WORKFLOW as any, initialize);
  yield takeEvery(ApplicationActions.requestNextStep.type, requireConnectionErrorHandler(nextStepRequested));
  yield takeEvery(T.REQUEST_PREVIOUS_STEP, requireConnectionErrorHandler(previousStepRequested));
  yield takeEvery(T.STEP_REQUESTED, requireConnectionErrorHandler(stepRequested));
  yield takeEvery([REQUEST_SUBMIT], requireConnectionErrorHandler(submitRequest));
  yield takeEvery(
    [T.NEXT_STEP_GRANTED, T.PREVIOUS_STEP_GRANTED, T.STEP_GRANTED],
    requireConnectionErrorHandler(manageNavigation),
  );
}

function* resetRequest(request: IRequest): IterableIterator<Effect> {
  if (!request.id) {
    //@ts-ignore
    const profile: IProfile = yield select(selectProfile);
    if (profile) {
      request.bus = profile.bus;
      request.city = profile.city;
      request.country = profile.country;
      request.emailAddress = profile.email;
      request.firstName = profile.firstName;
      request.ibanNumber = profile.ibanNumber;
      request.lastName = profile.lastName;
      request.phoneNumber = profile.phoneNumber;
      request.ssn = profile.ssn;
      request.street = profile.street;
      request.streetNumber = profile.streetNumber;
      request.zipCode = profile.zipCode;
    }
    //@ts-ignore
    const tenant: ITenant = yield select(selectTenant);
    if (tenant) {
      request.dateFrom = tenant!.earliestStartDateAllowedForRequests;
      request.dateUntil = tenant!.earliestStartDateAllowedForRequests;
    }

    request.timeFrom = formatHour(Constants.APP_DEFAULT_START_HOUR);
    request.timeUntil = formatHour(Constants.APP_DEFAULT_STOP_HOUR);
  }
}
