import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Fieldset } from '../../atoms';
import { translate } from '../../../translations/translate';
import * as A from 'antwerp-core-react-branding';
import { useSelector } from 'react-redux';
import { getConflictGroupsWithPhaseIds } from '../../../store/selectors/sgw';
import { SgwConflictAccordion } from '../../molecules/SgwConflictAccordion/SgwConflictAccordion.component';
import { Visible } from '../../common/layout/Visible.component';
import { useParams } from 'react-router';
import { TRouterWithId } from '../../../types';

const useStyles = createUseStyles({
  legend: {
    color: 'black',
    fontWeight: 600,
    padding: '0.25rem 0.5rem',
    marginTop: '40px',
    marginBottom: '10px',
  },
  readOnly: {
    border: 'none',
    padding: '0px',
  },
});

interface IProps {
  readOnly?: boolean;
}
export const ConflictOverview: FC<IProps> = ({ readOnly = false }) => {
  const { id: requestId } = useParams<keyof TRouterWithId>();

  const C = useStyles();
  const conflictGroups = useSelector(getConflictGroupsWithPhaseIds(requestId!));

  return (
    <Fieldset
      legend={readOnly ? '' : translate('sgw.map.conflictOverview.legend')}
      className={readOnly ? C.readOnly : undefined}
      noBottomBorder={readOnly}
    >
      <Visible visible={!readOnly}>
        {/*@ts-ignore*/}
        <A.Paragraph spacing={true}>{translate('sgw.map.conflictOverview.info')}</A.Paragraph>
        <div className={C.legend}>{translate('sgw.map.conflictOverview.listLegend')}</div>
      </Visible>
      {conflictGroups.map((conflictGroup, index) => (
        <SgwConflictAccordion conflictGroup={conflictGroup} index={index} key={conflictGroup.id} />
      ))}
    </Fieldset>
  );
};
