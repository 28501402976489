import { PUBLICDOMAINTYPES_RECEIVED } from '../../common/actions/types';
import { IGenericAction } from '../actions/actions';
import { IPublicDomainIntakeType } from '../../types';

const INITIAL_STATE: IPublicDomainIntakeType[] = [];

export default function publicDomainIntakeTypes(
  state: IPublicDomainIntakeType[] = INITIAL_STATE,
  action: IGenericAction<IPublicDomainIntakeType[]>,
): IPublicDomainIntakeType[] {
  switch (action.type) {
    case PUBLICDOMAINTYPES_RECEIVED:
      return action.payload;
    default:
      return state;
  }
}
