export function storeLocalStorage<T>(key: string, payload: T): void {
  if (canUseLocalStorage()) {
    window.localStorage.setItem(key, JSON.stringify(payload));
  }
}

export function isLocalStorage(key: string): boolean {
  if (canUseLocalStorage()) {
    return window.localStorage.getItem(key) ? true : false;
  }
  return false;
}

export function getLocalStorage<T>(key: string): T | undefined {
  if (canUseLocalStorage() && isLocalStorage(key)) {
    return JSON.parse(window.localStorage.getItem(key)!) as T;
  }
}

export function removeLocalStorage(key: string): void {
  if (canUseLocalStorage()) {
    window.localStorage.removeItem(key);
  }
}

function canUseLocalStorage(): boolean {
  if (window && window.localStorage) {
    return true;
  }
  return false;
}
