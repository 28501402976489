import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { TextInput } from '../../atoms';
import { IContactPerson } from '../../../types';
import { translate, translateHtml } from '../../../translations/translate';
import { validateEmail } from '../../../common/validation/validateEmail';

const useStyles = createUseStyles({
  twoRows: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '20px',
  },
  label: {
    marginTop: '20px',
    marginBottom: '20px',
  },
});

export const ContactPersonFields: FC = () => {
  const C = useStyles();
  const { register } = useFormContext<IContactPerson>();
  return (
    <>
      <div className={C.label}>{translateHtml('sgw.startRequest.contactPerson')}</div>
      <div className={C.twoRows}>
        <TextInput
          {...register('contactFirstName', { required: true })}
          label={translate('personalform.firstname')}
          required
        />
        <TextInput
          {...register('contactLastName', { required: true })}
          label={translate('personalform.lastname')}
          required
        />
      </div>
      <div className={C.twoRows}>
        <TextInput
          {...register('contactPhoneNumber', { required: true })}
          label={translate('personalform.phonenumber')}
          required
        />
        <TextInput
          {...register('contactEmailAddress', { required: true })}
          label={translate('personalform.emailaddress')}
          required
          validate={validateEmail}
          trimSpaces
        />
      </div>
    </>
  );
};
