import * as A from 'antwerp-core-react-branding';
import * as React from 'react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import intl from 'react-intl-universal';
import { convertStringToTimestamp, formatToDisplayDate } from '../../common/utils/dateUtils';
import { validateDate } from '../../common/validation/validateTime';
import { DatePicker, Feature } from '../atoms';
import { PeriodFormFields } from '../../common/form';
import { PaymentDisabled } from '../atoms/PaymentDisabled/PaymentDisabled.component';
import { WorkflowButtons } from '../molecules';
import { IRequestExtension, IRequest, IFormProperties, IMetadata, WorkflowType } from '../../types';
import { submitId } from '../../common/utils/formUtils';

export type ExtensionFormProperties = {
  earliestStartDataAllowed: string;
  goBack: () => void;
  onChange?: (extension: Partial<IRequestExtension>) => void;
  onSubmit: (extension: Partial<IRequestExtension>) => void;
  metadata: IMetadata;
  request: IRequest;
} & IFormProperties;

export type IRequestExtensionForm = {
  [PeriodFormFields.dateUntil]: string | undefined;
};

/**
 * React Component ExtensionForm
 */
export const ExtensionForm = ({ request, onChange, earliestStartDataAllowed, ...props }: ExtensionFormProperties) => {
  const formMethods = useForm<IRequestExtensionForm>({
    defaultValues: { dateUntil: earliestStartDataAllowed },
  });
  const { handleSubmit, watch } = formMethods;

  const dateUntil = watch(PeriodFormFields.dateUntil);

  useEffect(() => {
    onChange?.({ dateUntil });
  }, [onChange, dateUntil]);

  const validateDateUntil = (value: string) => {
    return validateDate(intl.get('fields.endDate'), value, earliestStartDataAllowed);
  };

  return (
    <section>
      <FormProvider {...formMethods}>
        {/*@ts-ignore*/}
        <A.Form onSubmit={handleSubmit(props.onSubmit)} id={submitId(WorkflowType.ASign, 1)}>
          {/*@ts-ignore*/}
          <A.Paragraph spacing>
            <Feature.IntegrateDigipolisOrder fallbackComponent={intl.get('extensionform.description')}>
              {intl.get('extensionform.description')} {intl.get('extensionform.description_payment')}
            </Feature.IntegrateDigipolisOrder>
          </A.Paragraph>
          {new Date().getTime() > convertStringToTimestamp(request.latestRequestDateForExtension) && (
            //@ts-ignore
            <A.Spacing type={A.SpacingStyle.MarginBottom}>
              {/* @ts-ignore */}
              <A.Paragraph type={A.ParagraphStyle.Alert}>
                {intl.get('extensionform.emergency', {
                  extensionFixedEmergencyCost: props.metadata.prices.extensionFixedEmergencyCost,
                })}
              </A.Paragraph>
            </A.Spacing>
          )}
          <Feature.IntegrateDigipolisOrder
            fallbackComponent={
              //@ts-ignore
              <A.Spacing type={A.SpacingStyle.MarginBottom}>
                <PaymentDisabled />
              </A.Spacing>
            }
          ></Feature.IntegrateDigipolisOrder>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {/*@ts-ignore*/}
              <A.TextInput
                disabled
                label={intl.get('general.from')}
                name="dateFrom"
                value={formatToDisplayDate(request.dateFrom)}
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <DatePicker
                label={intl.get('general.to')}
                minDate={earliestStartDataAllowed}
                name={PeriodFormFields.dateUntil}
                validate={validateDateUntil}
              />
            </div>
          </div>
          <WorkflowButtons forcePrevious onPrevious={props.goBack} step={1} type={WorkflowType.ASign} />
        </A.Form>
      </FormProvider>
    </section>
  );
};
