import axios from 'axios';
import { getApiUserUrl } from '../../common/utils/apiUtils';
import { IApiPromise, IPartnerBE } from '../../types';

export const sgwPartnersApi = {
  fetch: (id: string): IApiPromise<IPartnerBE> => axios.get(getApiUserUrl(`/sgw/partners/${id}`)),
  fetchBulk: (ids: string[]): IApiPromise<IPartnerBE[]> =>
    axios.get(getApiUserUrl(`/sgw/partners/`, { params: { ids } })),
  save: (partner: IPartnerBE): IApiPromise<IPartnerBE> =>
    partner.id
      ? axios.patch(getApiUserUrl(`/sgw/partners/${partner.id}`), partner)
      : axios.post(getApiUserUrl('/sgw/partners'), partner),
};
