import { Spinner } from 'antwerp-core-react-branding';
import React, { FC, PropsWithChildren } from 'react';
import { Visible } from '../../common/layout/Visible.component';

interface IProps extends PropsWithChildren {
  loading: boolean;
  small?: boolean;
}

export const SpinnerLoader: FC<IProps> = ({ loading, children, small = false }) => (
  <Visible visible={!loading} defaultComponent={<Spinner small={small} />}>
    {children}
  </Visible>
);
