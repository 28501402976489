import debug from '../debug';

const type: string = 'storage';

function generateDeletedStorageHandler(key: string): (e: StorageEvent) => void {
  return (e: StorageEvent) => {
    if (e.type === type && e.key === key && e.oldValue && e.newValue === null) {
      if (window) {
        debug(`Item ${key} deleted from storage, reloading window`);
        window.location.reload();
      }
    }
  };
}

export function registerStorageDeletedHandler(key: string): void {
  if (window) {
    // @ts-ignore
    window.addEventListener(type, generateDeletedStorageHandler(key), false);
  }
}
