import React, { FC, useCallback } from 'react';
import { translate, translateIgnoreTS } from '../../../translations/translate';
import { Fieldset, OverviewList } from '../../atoms';
import { createUseStyles } from 'react-jss';
import { InfoText } from '../../atoms/InfoText/InfoText.component';
import { UseFormReturn } from 'react-hook-form';
import { useGenericStyles, useGeoDrawingContext } from '../../../hooks';
import { useSelector } from 'react-redux';
import { getCurrentGeoDrawingNumberingByType } from '../../../common/utils/sgwPhaseUtils';
import { MultiSelect } from '../../atoms/MultiSelect/MultiSelect.component';
import { getTrafficMeasuresOptions, selectTrafficMeasures } from '../../../store/selectors';
import classNames from 'classnames';
import { GeoDrawingType, ISgwPhase } from '../../../types';
import { numberToStringArray, stringToNumberArray } from '../../../common/utils/array.utils';
import { COLORS } from '../../../theme';
import { Visible } from '../../common/layout/Visible.component';

const useStyles = createUseStyles({
  validation: {
    border: `1px solid ${COLORS.red} !important`,
  },
  error: {
    color: COLORS.red,
  },
});

export interface IProps {
  formMethods: UseFormReturn<ISgwPhase>;
  trafficMeasureError: boolean;
}

export const MapTrafficMeasures: FC<IProps> = ({ formMethods, trafficMeasureError }) => {
  const C = useStyles();
  const G = useGenericStyles();
  const trafficMeasures = useSelector(selectTrafficMeasures);
  const trafficMeasureOptions = useSelector(getTrafficMeasuresOptions);
  const { setValue } = formMethods;
  const { geoDrawings, setShouldSave } = useGeoDrawingContext();

  const onChange = useCallback(
    (values: string[], index: number) => {
      setShouldSave(true);
      geoDrawings[index].sgwTrafficMeasures = stringToNumberArray(values);
      setValue(`sgwGeoDrawings` as const, geoDrawings);
    },
    [geoDrawings, setValue, setShouldSave],
  );

  return (
    <Fieldset legend={translate('sgw.map.trafficMeasures.legend')}>
      <InfoText>{translate('sgw.map.trafficMeasures.info')}</InfoText>
      <OverviewList>
        {geoDrawings.map((geoDrawing, index) => {
          const countOfType = getCurrentGeoDrawingNumberingByType(geoDrawings, geoDrawing);
          return (
            geoDrawing.geoDrawingType === GeoDrawingType.constructionzone && (
              <OverviewList.CustomItem
                title={
                  geoDrawing.customName
                    ? geoDrawing.customName
                    : `${translateIgnoreTS(
                        `sgw.map.drawOnMap.phase.geoDrawing.types.${geoDrawing.geoDrawingType}`,
                      )} ${countOfType}`
                }
                key={geoDrawing.id}
                childrenClassName={classNames(G.flex.fullWidth, G.flex.spaceBetween)}
                className={classNames(!geoDrawing.sgwTrafficMeasures?.length && C.validation, G.spacing.padding.XS)}
                titleClassName="col-xs-3"
              >
                <div className={classNames(G.flex.columnCenter)}>
                  <Visible
                    visible={!!geoDrawings[index].sgwTrafficMeasures?.length}
                    defaultComponent={translate('sgw.map.trafficMeasures.selectTrafficMeasures')}
                  >
                    {trafficMeasures
                      ?.filter(({ id }) => geoDrawings[index].sgwTrafficMeasures?.includes(id))
                      .map(({ name }) => (
                        <div className={G.spacing.marginTop.XXS}>{name}</div>
                      ))}
                  </Visible>
                </div>
                <MultiSelect
                  name="trafficMeasureSelect"
                  options={trafficMeasureOptions}
                  values={numberToStringArray(geoDrawings[index].sgwTrafficMeasures)}
                  onChangeSelectedValues={(values) => onChange(values, index)}
                />
              </OverviewList.CustomItem>
            )
          );
        })}
      </OverviewList>
      <Visible visible={trafficMeasureError}>
        <div className={C.error}>{translate('sgw.map.trafficMeasures.errorTrafficMeasures')}</div>
      </Visible>
    </Fieldset>
  );
};
