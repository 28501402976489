import { Polygon as IPolygon } from '@turf/turf';
import { FC } from 'react';
import { getLatLng1D, getLatLng3D } from '../../../common/utils/mapUtils';
import { COLORS } from '../../../theme';
import markerIconSvg from '../../../../src/assets/images/conflict.svg';
import { Polygon } from '../../common/leaflet/features/Polygon.component';
import L from 'leaflet';
import { ConflictCategory } from '../../../types/sgwConflict.types';
import { translateIgnoreTS } from '../../../translations/translate';
import { Marker } from '../../common/leaflet/features/Marker.component';

const markerIcon = L.icon({
  iconAnchor: [15, 15],
  iconUrl: markerIconSvg,
  iconRetinaUrl: markerIconSvg,
  iconSize: [30, 30],
  className: 'leaflet-venue-icon',
});
interface IProps {
  geometry: IPolygon;
  conflictCategory: ConflictCategory;
}
export const ConflictDrawingPolygon: FC<IProps> = ({ geometry, conflictCategory }) => {
  return (
    <>
      <Polygon
        positions={getLatLng3D(geometry.coordinates)}
        color={COLORS.red}
        lineWeight={5}
        info={translateIgnoreTS(`sgw.map.conflictOverview.conflictCategory.${conflictCategory}`)}
      />
      {geometry.coordinates[0].map((coord) => (
        <Marker icon={markerIcon} position={getLatLng1D(coord)} key={`${coord}`} />
      ))}
    </>
  );
};
