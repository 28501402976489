import React, { FC } from 'react';
import { IWithClassName, KeyEvent, PartnerType } from '../../../types';
import classNames from 'classnames';
import partnerClient from '../../../assets/images/partner.client.svg';
import partnerContractor from '../../../assets/images/partner.contractor.svg';
import partnerFee from '../../../assets/images/partner.fee.svg';
import partnerSignalisation from '../../../assets/images/partner.signalisation.svg';
import conflictPublicTransport from '../../../assets/images/conflict.bus.svg';
import conflictStreetInfrastructure from '../../../assets/images/conflict.bench.svg';
import conflictEvent from '../../../assets/images/conflict.calendar.svg';
import conflictConstructionZone from '../../../assets/images/conflict.road-closure.svg';
import conflictWaste from '../../../assets/images/conflict.waste.svg';
import conflictParking from '../../../assets/images/conflict.parking.svg';
import conflictGreenZone from '../../../assets/images/conflict.tree.svg';
import conflictMobility from '../../../assets/images/conflict.change.svg';
import conflictPort from '../../../assets/images/conflict.port.svg';
import conflictParkingBan from '../../../assets/images/conflict.unavailable.svg';
import conflictRailway from '../../../assets/images/conflict.train.svg';
import conflictCrane from '../../../assets/images/conflict.crane.svg';
import conflictDetour from '../../../assets/images/conflict.detour.svg';
import conflictGeneric from '../../../assets/images/conflict.generic.svg';

import addIcon from '../../../assets/images/add.svg';
import { Levels, Sizes } from 'antwerp-core-react-branding';
import { useIconStyles } from './Icon.styles';
import { translate, translateIgnoreTS } from '../../../translations/translate';
import { onEnter } from '../../../common/utils/keyboardUtils';
import { IConflictSvgIcon } from '../../../types/sgwConflict.types';

const icons = {
  partner: {
    client: partnerClient,
    contractor: partnerContractor,
    fee: partnerFee,
    requester: partnerClient,
    signalisation: partnerSignalisation,
  },
  conflict: {
    crane: conflictCrane,
    constructionZone: conflictConstructionZone,
    parkingBan: conflictParkingBan,
    event: conflictEvent,
    publicTransport: conflictPublicTransport,
    cityCleaning: conflictWaste,
    dedicatedParkingCambio: conflictParking,
    dedicatedParkingCarSharing: conflictParking,
    dedicatedParkingChargingStation: conflictParking,
    dedicatedParkingDisabledPerson: conflictParking,
    dedicatedParkingTaxi: conflictParking,
    greenZone: conflictGreenZone,
    mobility: conflictMobility,
    portZone: conflictPort,
    railway: conflictRailway,
    streetInfrastructure: conflictStreetInfrastructure,
    detour: conflictDetour,
    fireDepartment: conflictGeneric,
    utilityCompanies: conflictGeneric,
    shippingPolice: conflictGeneric,
    cityHarbour: conflictGeneric,
    trafficPolice: conflictGeneric,
    projectSites: conflictGeneric,
    tunnel: conflictGeneric,
    markets: conflictGeneric,
    trafficSignaling: conflictGeneric,
    brabantsTunnel: conflictGeneric,
    neighborhoodParking: conflictGeneric,
    zoo: conflictGeneric,
    retailCatering: conflictGeneric,
    prison: conflictGeneric,
    railwayInfrastructure: conflictGeneric,
    artWork: conflictGeneric,
    loadingZone: conflictGeneric,
    airport: conflictGeneric,
    oosterweelZone: conflictGeneric,
    parkingMachine: conflictGeneric,
    highway: conflictGeneric,
    diamantwijk: conflictGeneric,
    publicOrder: conflictGeneric,
    football: conflictGeneric,
    railroadPolice: conflictGeneric,
    velo: conflictGeneric,
    streetLightning: conflictGeneric,
    terrace: conflictGeneric,
    temporaryTrafficLights: conflictGeneric,
    garbageCans: conflictGeneric,
  },
};

export interface IAwesomeProps extends IWithClassName {
  tabIndex?: number;
  title?: string;
  name?: string;
  onClick?(): void;
  size?: Sizes;
  level?: Levels;
  disabled?: boolean;
  children?: any;
}

interface ISvgIcon extends IWithClassName {
  image: string;
  description: string;
}

interface IPartnerSvgIcon extends IWithClassName {
  variant: PartnerType;
}

const AwesomeIcon: FC<IAwesomeProps> = (props) => {
  const { className, name, onClick, size, title, disabled } = props;
  const C = useIconStyles(props);

  const buttonProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> = onClick && !disabled
    ? {
        'aria-hidden': true,
        role: 'button',
        title,
        tabIndex: 0,
        onClick: (event) => {
          event.stopPropagation();
          onClick();
        },
        onKeyPress: onEnter((event?: KeyEvent<HTMLElement>) => {
          event?.stopPropagation();
          onClick();
        }),
      }
    : {};

  return (
    <i
      {...buttonProps}
      className={classNames('fa', name, `fa-size-${size}`, C.root, className)}
      aria-hidden
      data-testid={`Icon-${name}`}
    >
      {props.children}
    </i>
  );
};

const SvgIcon: FC<ISvgIcon> = ({ description, image, ...props }) => (
  <img src={image} data-testid="Icon" {...props} alt={description} />
);

const PartnerSvgIcon: FC<IPartnerSvgIcon> = ({ className, variant }) => (
  <SvgIcon
    className={className}
    description={translateIgnoreTS(`assets.images.partners.${variant}`)}
    image={icons.partner[variant]}
  />
);

const ConlictSvgIcon: FC<IConflictSvgIcon> = ({ className, variant }) => (
  <SvgIcon
    className={className}
    description={translateIgnoreTS(`assets.images.conflicts.${variant}`)}
    image={icons.conflict[variant]}
  />
);

export const Icon = {
  AngleDown: (props: IAwesomeProps) => <AwesomeIcon {...props} name="fa-angle-down" />,
  AngleUp: (props: IAwesomeProps) => <AwesomeIcon {...props} name="fa-angle-up" />,
  Check: (props: IAwesomeProps) => <AwesomeIcon {...props} name="fa-check" />,
  Copy: (props: IAwesomeProps) => <AwesomeIcon title={translate('general.copy')} {...props} name="fa-copy" />,
  Edit: (props: IAwesomeProps) => <AwesomeIcon {...props} name="fa-edit" title={translate('general.edit')} />,
  Delete: (props: IAwesomeProps) => <AwesomeIcon {...props} name="fa-trash" title={translate('general.delete')} />,
  Add: (props: Omit<ISvgIcon, 'image'>) => <SvgIcon {...props} image={addIcon} />,
  PartnerSvgIcon,
  ConlictSvgIcon,
  AwaitingFeedback: (props: IAwesomeProps) => (
    <AwesomeIcon {...props} name="fa-envelope-o" title={translate('myRequests.messageState.awaiting_feedback')} />
  ),
  EmergencyRequest: (props: IAwesomeProps) => (
    <AwesomeIcon {...props} name="fa-clock-o" title={translate('general.emergencyRequest')} />
  ),
};
