import './forms.scss';

import * as React from 'react';
import { FC, useCallback, useEffect } from 'react';
import intl from 'react-intl-universal';

import * as A from 'antwerp-core-react-branding';

import { PublicDomainType } from '../../common/enums';
import { hasWeekDays } from '../../common/utils/dateUtils';
import {
  validateDate,
  validateDateInterval,
  validateTime,
  validateTimeInterval,
} from '../../common/validation/validateTime';
import { IMetadata, IRequest, ITenant, IWaitableProperties, WorkflowType } from '../../types';
import EmergencyLabel from './3-periodform/EmergencyLabel';
import { FormProvider, useForm } from 'react-hook-form';
import { TimePicker } from '../common/forms/TimePicker';
import { IPeriodForm, PeriodFormFields } from '../../common/form';
import { CheckboxWithLabelComponent } from '../common/forms/CheckboxWithLabel.component';
import { useDispatch } from 'react-redux';
import { isValid, submitId } from '../../common/utils/formUtils';
import { WorkflowButtons } from '../molecules';
import { PublicDomainIntakeActions } from '../../store/actions';
import { createCalculateCostAction } from '../../common/actions/creators/cost';
import { DatePicker, Fieldset } from '../atoms';

export type PeriodFormProperties = {
  initialValues?: Partial<IRequest>;
  onPrevious: (value: IRequest) => void;
  onSubmit: (values: IRequest) => void;
  metadata: IMetadata;
  request?: IRequest;
  step: number;
  tenant: ITenant;
} & IWaitableProperties;
/**
 * React Component PeriodForm
 */

export const PeriodForm: FC<PeriodFormProperties> = ({
  initialValues,
  onSubmit,
  onPrevious,
  metadata,
  request,
  step,
  tenant,
}) => {
  const dispatch = useDispatch();
  const formMethods = useForm<IPeriodForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    shouldFocusError: false,
  });
  const { handleSubmit, reset, getValues, watch, setValue, trigger } = formMethods;
  const currentValues = watch();

  const earliestStartDataAllowed = useCallback((): string => {
    if (request) {
      if (request.publicDomainIntakes?.length > 0) {
        return request.publicDomainIntakes[0].type.type === PublicDomainType.MinorConstruction
          ? tenant.earliestStartDateAllowedForRequests
          : tenant.earliestStartDateAllowedForEmergencyRequests;
      }
      return tenant.earliestStartDateAllowedForRequests;
    }
    return '';
  }, [request, tenant]);

  const validateFromDate = useCallback(
    (value: string) => validateDate(intl.get('fields.startDate'), value, earliestStartDataAllowed()),
    [earliestStartDataAllowed],
  );
  const checkIfContainer = (): boolean => initialValues?.reason?.reason === 'container';

  useEffect(() => {
    if (currentValues?.dateFrom && isValid(validateFromDate(currentValues?.dateFrom))) {
      setValue('dateUntil', currentValues?.dateFrom);
    }
  }, [currentValues.dateFrom, setValue, validateFromDate]);

  useEffect(() => {
    dispatch(createCalculateCostAction(currentValues));
    // eslint-disable-next-line
  }, [
    dispatch,
    currentValues.dateFrom,
    currentValues.dateUntil,
    currentValues.timeFrom,
    currentValues.timeUntil,
    currentValues.onlyOnWeekdays,
  ]);

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  useEffect(() => {
    trigger(PeriodFormFields.timeUntil);
  }, [trigger, currentValues.timeFrom]);

  const _onSubmit = useCallback(
    (values: Partial<IRequest>) => {
      if (
        (values.dateFrom === initialValues?.dateFrom && values.dateUntil === initialValues?.dateUntil) ||
        !values.publicDomainIntakes?.length
      ) {
        onSubmit(values as IRequest);
      } else {
        const intake = values.publicDomainIntakes[0];
        const requestToSave = {
          ...values,
          publicDomainIntakes: [
            {
              index: 0,
              type: intake.type,
              attachments: intake.attachments,
              conditions: intake.conditions,
            },
          ],
        };
        dispatch(PublicDomainIntakeActions.save(requestToSave as IRequest));
        onSubmit(requestToSave as IRequest);
      }
    },
    // eslint-disable-next-line
    [reset, dispatch, onSubmit],
  );

  useEffect(() => {
    if (
      currentValues?.dateFrom &&
      isValid(validateFromDate(currentValues?.dateFrom)) &&
      currentValues?.dateFrom !== initialValues?.dateFrom
    ) {
      setValue('dateUntil', currentValues?.dateFrom);
    }
  }, [currentValues.dateFrom, setValue, validateFromDate, initialValues?.dateFrom]);

  const showWarning = (): boolean =>
    !!currentValues?.timeFrom &&
    !currentValues?.entireDay &&
    initialValues?.publicDomainIntakes?.[0]?.type?.type === PublicDomainType.ParkingBan;

  const requestHasParkingBans = currentValues?.publicDomainIntakes?.some(
    ({ type }) => type.type === PublicDomainType.ParkingBan,
  );

  return (
    <FormProvider {...formMethods}>
      <section>
        {/*@ts-ignore*/}
        <A.Form onSubmit={handleSubmit(_onSubmit)} id={submitId(WorkflowType.ASign, step)}>
          <Fieldset legend={intl.get('periodform.period')}>
            {/*@ts-ignore*/}
            <A.Paragraph spacing>{intl.get('periodform.whentakesitplace')}</A.Paragraph>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <DatePicker
                  label={intl.get('general.from')}
                  minDate={earliestStartDataAllowed()}
                  name={PeriodFormFields.dateFrom}
                  validate={validateFromDate}
                  validationLabel={intl.get('fields.startDate')}
                  required
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <DatePicker
                  label={intl.get('general.to')}
                  minDate={earliestStartDataAllowed()}
                  name={PeriodFormFields.dateUntil}
                  validate={(value) => validateDateInterval(intl.get('fields.endDate'), value, getValues())}
                  validationLabel={intl.get('fields.endDate')}
                  required
                />
              </div>
            </div>
            <EmergencyLabel
              earliestStartDateAllowedForRequests={tenant.earliestStartDateAllowedForRequests}
              showIfDateBeforeEarliesAllowed={!!requestHasParkingBans}
              dateFrom={currentValues?.dateFrom}
              metadata={metadata}
            />
            {!checkIfContainer() && (
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  {/*@ts-ignore*/}
                  <A.Paragraph spacing>{intl.get('periodform.activitybetweenhours')}</A.Paragraph>
                </div>
              </div>
            )}
            {!currentValues?.entireDay && !checkIfContainer() && (
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <TimePicker
                    name={PeriodFormFields.timeFrom}
                    start
                    validate={(value) => validateTime(value, getValues(PeriodFormFields.entireDay))}
                    required
                  />
                </div>
                <div className="col-xs-12 col-md-6">
                  <TimePicker
                    name={PeriodFormFields.timeUntil}
                    end
                    validate={(value) => validateTimeInterval(getValues(PeriodFormFields.timeFrom), value)}
                    required
                  />
                </div>
              </div>
            )}
            {showWarning() && !checkIfContainer() && (
              <div className="row">
                <div className="col-lg-12 replacecar-alert">
                  {/*@ts-ignore*/}
                  <A.Paragraph type={A.ParagraphStyle.Alert}>{intl.get('periodform.replacecar')}</A.Paragraph>
                </div>
              </div>
            )}
            {!checkIfContainer() && (
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  {/*@ts-ignore*/}
                  <A.Spacing type={A.SpacingStyle.MarginTop}>
                    <CheckboxWithLabelComponent
                      label={intl.get('periodform.entireday')}
                      name={PeriodFormFields.entireDay}
                    />
                  </A.Spacing>
                </div>
              </div>
            )}
            {hasWeekDays(currentValues?.dateFrom, currentValues?.dateUntil) && !checkIfContainer() && (
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  {/*@ts-ignore*/}
                  <A.Spacing type={A.SpacingStyle.MarginTop}>
                    <CheckboxWithLabelComponent
                      label={intl.get('periodform.onlyonweekdays')}
                      name={PeriodFormFields.onlyOnWeekdays}
                    />
                  </A.Spacing>
                </div>
              </div>
            )}
          </Fieldset>
          <WorkflowButtons step={step} onPrevious={onPrevious} type={WorkflowType.ASign} />
        </A.Form>
      </section>
    </FormProvider>
  );
};
