import { createUseStyles } from 'react-jss';

export const useOverviewlistStyles = createUseStyles({
  list: {
    marginBottom: '30px',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  subtitle: {
    fontWeight: 'bold',
  },
});
