import React, { FC, useEffect, useState } from 'react';
import { FeatureGroup, Polygon as ReactLeafletPolygon, Tooltip } from 'react-leaflet';
import { Marker } from './Marker.component';
import { LatLngExpression, Marker as LeafletMarker } from 'leaflet';
import { IPolygonProps } from '../../../../types';
import { Visible } from '../../layout/Visible.component';
import { MAP_DEFAULT_DRAWINGS_WEIGHT } from '../../../../common/constants';

export const Polygon: FC<IPolygonProps> = ({
  children,
  draggable = false,
  onDragEnd,
  positions,
  info,
  lineWeight,
  ...props
}) => {
  const [polygonPositions, setPolygonPositions] = useState<LatLngExpression[][]>(positions);

  useEffect(() => {
    setPolygonPositions(positions);
  }, [positions]);

  const onDragMarker = (index: number) => (marker: LeafletMarker) => {
    const positions = [...polygonPositions[0]];

    if (index === 0) {
      positions[positions.length - 1] = marker.getLatLng();
    }
    positions[index] = marker.getLatLng();
    setPolygonPositions([positions]);
  };

  const onDragMarkerEnd = () => {
    onDragEnd?.(polygonPositions);
  };

  return (
    <FeatureGroup>
      <ReactLeafletPolygon {...props} positions={polygonPositions} weight={lineWeight || MAP_DEFAULT_DRAWINGS_WEIGHT}>
        <Visible visible={!!info}>
          <Tooltip>{info}</Tooltip>
        </Visible>
      </ReactLeafletPolygon>
      <Visible visible={draggable}>
        {positions[0].slice(0, -1).map((coords: any, i: number) => (
          <Marker
            draggable={draggable}
            key={`marker-${i}`}
            onDrag={onDragMarker(i)}
            onDragEnd={onDragMarkerEnd}
            position={coords}
          />
        ))}
      </Visible>
    </FeatureGroup>
  );
};
