import React, { FC } from 'react';
import { TRouterWithId } from '../../../types';
import { useParams } from 'react-router';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { useGenericStyles } from '../../../hooks';
import { GeoDrawingsForm } from '../../forms/sgw';
import { translate } from '../../../translations/translate';

const useStyles = createUseStyles({
  root: {
    marginBottom: '20px',
  },
  title: {
    marginBottom: '10px',
    padding: '0 10px',
  },
});

export const DrawingRequestInfo: FC = () => {
  const C = useStyles();

  const { id: requestId } = useParams<keyof TRouterWithId>();
  const { flex } = useGenericStyles();

  return (
    <div className={C.root}>
      <h5 className={C.title}>{translate('myRequests.details.map')}</h5>
      <div className={classNames(flex.column, flex.gap)}>
        <GeoDrawingsForm requestId={requestId!} readOnly />
      </div>
    </div>
  );
};
