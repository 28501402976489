import intl from 'react-intl-universal';

import validateRequired from './validateRequired';
import { ValidateResult } from 'react-hook-form/dist/types/validator';

export default function validateTelephone(
  value: string,
  allValues?: any[],
  props?: any,
  name?: string,
): ValidateResult {
  // eslint-disable-next-line no-useless-escape
  const format = /^(\+|[0-9])[0-9\ ]{2,20}$/i;

  const requiredMessage = validateRequired(value);
  if (requiredMessage) {
    return requiredMessage;
  }

  if (!format.test(value)) {
    return intl.get('fields.novalidtelephone');
  }

  return true;
}
