import { FC, useEffect } from 'react';
import intl from 'react-intl-universal';

export const AppTitle: FC = () => {
  useEffect(() => {
    const pageTitle = intl.formatMessage({ id: 'general.pageTitle' });
    const pageSubTitle = intl.formatMessage({ id: 'general.pageSubTitle' });
    if (window?.document) {
      window.document.title = `${pageTitle} - ${pageSubTitle}`;
    }
  }, []);

  return null;
};
