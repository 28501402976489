import intl from 'react-intl-universal';
import * as IBAN from 'iban';

import validateRequired from './validateRequired';
import { ValidateResult } from 'react-hook-form/dist/types/validator';

export default function validateIban(value: string): ValidateResult {
  const requiredMessage = validateRequired(value);
  if (requiredMessage) {
    return requiredMessage;
  }

  return IBAN.isValid(value) || intl.get('fields.novalidiban');
}
