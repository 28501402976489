import { createUseStyles } from 'react-jss';
import { ICON_COLORS } from '../../../theme';
import { IAwesomeProps } from './Icon.component';
import { Sizes } from 'antwerp-core-react-branding';

export const useIconStyles = createUseStyles<string, IAwesomeProps>({
  root: {
    cursor: ({ onClick }) => (onClick ? 'pointer' : 'default'),
    color: ({ level, disabled }) => {
      if (disabled) {
        return ICON_COLORS.disabled;
      }
      return (level && ICON_COLORS[level]) || 'inherit';
    },
    'font-size': ({ size }) => {
      switch (size) {
        case Sizes.Medium:
          return '1.4em';
        case Sizes.Large:
          return '3em';
        case Sizes.Small:
        case Sizes.Default:
        default:
          return '.875em';
      }
    },
  },
});
