import * as React from 'react';
import * as A from 'antwerp-core-react-branding';
import classNames from 'classnames';
import intl from 'react-intl-universal';

export type AlertProperties = {
  cancelText?: string;
  level?: A.Levels;
  message?: string;
  okText?: string;
  onCancel?: () => void;
  onOk?: () => void;
  title: JSX.Element | string;
  visible?: boolean;
};

export type AlertState = { visible?: boolean };

/**
 * Molecule: Alert
 * Taken and edited from https://github.com/rombit-be/antwerp-core-react-branding/blob/master/src/lib/molecules/alert.tsx
 */
export class AntwerpAlert extends React.Component<AlertProperties, AlertState> {
  public constructor(props: AlertProperties) {
    super(props);
    this.state = { visible: props.visible === undefined ? false : props.visible };
  }

  public render(): any {
    return (
      // @ts-ignore
      <A.Overlay dark visible={this.state.visible}>
        <div className={this.className()}>
          {this.props.onCancel && (
            // @ts-ignore
            <A.Button
              className="m-alert__close has-icon"
              level={this.props.level}
              onClick={() => this.onCancel()}
              type={A.ButtonType.Transparent}
            >
              <i className="fa fa-close" />
            </A.Button>
          )}
          <h5 className="u-margin-bottom-xs" role="alert" aria-live="polite">
            {this.props.title}
          </h5>
          <p className="u-margin-bottom" role="alert" aria-live="polite">
            {this.props.message}
          </p>
          <div className="m-alert__actions">
            {this.props.onCancel ? (
              // @ts-ignore
              <A.Button
                className="m-alert__cancel"
                level={this.props.level}
                onClick={() => this.onCancel()}
                size={A.Sizes.Small}
                type={A.ButtonType.Outline}
              >
                {this.props.cancelText || intl.formatMessage({ id: 'general.cancel' })}
              </A.Button>
            ) : null}
            {/*@ts-ignore*/}
            <A.Button
              autoFocus
              className={'m-alert__ok'}
              focus
              level={this.props.level}
              onClick={() => this.onOk()}
              size={A.Sizes.Small}
              submit
              tabIndex={0}
            >
              {this.props.okText || intl.formatMessage({ id: 'general.ok' })}
            </A.Button>
          </div>
        </div>
      </A.Overlay>
    );
  }

  public componentWillReceiveProps(nextProps: AlertProperties): void {
    this.setState({ visible: nextProps.visible });
    if (!nextProps.visible) {
      this.hideOverlay();
    }
  }

  private className(): string {
    return classNames('m-alert', this.props.level ? `m-alert--${this.props.level}` : '');
  }

  private onOk(): void {
    this.hideOverlay();
    if (this.props.onOk) {
      this.props.onOk();
    }
  }

  private onCancel(): void {
    this.hideOverlay();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  private hideOverlay(): void {
    this.setState({ visible: false });
  }
}
