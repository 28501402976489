import { Effect, put, select, takeEvery } from 'redux-saga/effects';
import { createInfoAlertAction, createWarningAlertAction } from '../../common/actions/creators/alert';
import { SagaIterator } from 'redux-saga';
import { AsignWorkflowSteps } from '../../types';
import { AlertsActions } from '../actions/alerts.actions';
import { selectStep } from '../selectors';

function* createInfoAlertActionStep4({
  payload,
}: ReturnType<typeof AlertsActions.createInfoAlertActionStep4>): IterableIterator<Effect> {
  const step = yield select(selectStep);
  if (step === AsignWorkflowSteps.Location) {
    yield put(createInfoAlertAction(payload));
  }
}

function* createWarningAlertActionStep4({
  payload,
}: ReturnType<typeof AlertsActions.createWarningAlertActionStep4>): IterableIterator<Effect> {
  const step = yield select(selectStep);
  if (step === AsignWorkflowSteps.Location) {
    yield put(createWarningAlertAction(payload));
  }
}

export default function* alertsSagas(): SagaIterator {
  yield takeEvery(AlertsActions.createInfoAlertActionStep4.type, createInfoAlertActionStep4);
  yield takeEvery(AlertsActions.createWarningAlertActionStep4.type, createWarningAlertActionStep4);
}
