import IState from '../../state';
import { createSelector } from '@reduxjs/toolkit';
import { calculateFromTimestamp, calculateInterval, convertStringToTimestamp } from '../../common/utils/dateUtils';
import { WORKFLOW_STATE_DRAFT, WORKFLOW_STATE_UNPAID } from '../../common/constants';
import { StateTimeLineItem } from '../../types';
import { PublicDomainType, RequestReasons } from '../../common/enums';

export const selectMyRequests = ({ myrequests }: IState) => myrequests || [];
export const selectRequest = ({ request }: IState) => request;
export const selectRequestAttachments = ({ request }: IState) => request?.attachments;
export const selectRequestMessages = ({ requestMessages }: IState) => requestMessages;
export const selectRequestReasons = ({ requestReasons }: IState) => requestReasons || [];
export const getRequestState = createSelector([selectRequest], (request) => request?.state);
export const getRequestId = createSelector([selectRequest], (request) => request?.id);
export const getRequestReason = createSelector([selectRequest], (request) => request?.reason);
export const selectIntersectingZones = (state: IState) => state.intersectingZones;

export const getRequestById = (requestId: string) =>
  createSelector([selectMyRequests], (requests) => requests.find(({ id }) => `${id}` === requestId));

export const getMaxTimeInterval = createSelector([selectRequest], (request) => {
  if (!request) return 1;
  const { dateFrom, dateUntil, originalDateUntil, extensions } = request;
  let interval = calculateFromTimestamp(dateFrom) - convertStringToTimestamp(originalDateUntil);
  if (extensions?.length) {
    const to = convertStringToTimestamp(dateUntil);
    interval =
      (extensions || [])
        .filter(({ state }) => state?.state !== WORKFLOW_STATE_UNPAID)
        .map(({ dateUntil }) => convertStringToTimestamp(dateUntil))
        .reduce((p, c) => (c > p ? c : p), to) - calculateFromTimestamp(dateFrom);
  }
  return interval;
});

export const getMapTimeLineItems = createSelector(
  [selectRequest, getMaxTimeInterval],
  (request, maxTimeLineInterval) => {
    if (!request?.extensions?.length || !request?.state || !request?.id) return [];
    const { originalDateUntil, dateFrom, extensions, state, id } = request;

    const items: StateTimeLineItem[] = [];
    // Add the request item
    items.push({
      id: id!,
      dateUntil: originalDateUntil,
      state,
      width:
        ((convertStringToTimestamp(originalDateUntil) - calculateFromTimestamp(dateFrom)) * 100) / maxTimeLineInterval,
    });

    // Add the extension items
    extensions.sort((a, b) => convertStringToTimestamp(a.dateUntil) - convertStringToTimestamp(b.dateUntil));

    const indexOfLastApprovedExtension = extensions.map(({ state }) => state?.state).lastIndexOf('approved');

    // Merge the extensions before the last approved extension into one approved extension
    const extensionItems = extensions
      .filter(
        ({ state, id }, i) => id && state && state.state !== WORKFLOW_STATE_UNPAID && i >= indexOfLastApprovedExtension,
      )
      .map(({ id, state, dateUntil }, i, a) => {
        const dateFrom = i === 0 ? originalDateUntil : a[i - 1].dateUntil;
        return {
          id: id!,
          dateUntil,
          state: state!,
          width: Math.abs((calculateInterval(dateFrom, dateUntil) * 100) / maxTimeLineInterval),
        };
      });

    let combinedItems = items.concat(extensionItems);

    // Validate and adjust widths so that all extensions are shown in UI
    const totalWidth = combinedItems.reduce((acc, item) => acc + item.width, 0);
    if (totalWidth !== 100) {
      combinedItems = combinedItems.map((item) => ({
        ...item,
        width: item.width * (100 / totalWidth),
      }));
    }

    // Ensure no widths are 0 by distributing minimal value if necessary
    if (combinedItems.some((item) => item.width === 0)) {
      const minimalWidth = 100 / combinedItems.length;
      combinedItems = combinedItems.map((item) => ({
        ...item,
        width: Math.max(item.width, minimalWidth),
      }));

      const adjustedTotalWidth = combinedItems.reduce((acc, item) => acc + item.width, 0);
      combinedItems = combinedItems.map((item) => ({
        ...item,
        width: item.width * (100 / adjustedTotalWidth),
      }));
    }

    return combinedItems;
  },
);

export const getDraftRequests = createSelector([selectMyRequests], (requests) =>
  requests.filter((request) => request?.state?.state === WORKFLOW_STATE_DRAFT),
);
export const getSubmittedRequests = createSelector([selectMyRequests], (requests) =>
  requests.filter((request) => request?.state?.state !== WORKFLOW_STATE_DRAFT),
);

export const getReasonsFrontOffice = createSelector([selectRequestReasons], (reasons) =>
  reasons.filter((r) => !!r.acl.showOnFrontOffice),
);

export const getHasCarFreeZones = createSelector([selectRequest], (request) =>
  request?.publicDomainIntakes?.some(({ type }) => type.type === PublicDomainType.CarfreeZone),
);

export const getShouldWarnAboutIntersectingZones = createSelector(
  [selectIntersectingZones, getRequestReason, getHasCarFreeZones],
  (zones, reason, hasCarFreeZones) => {
    return (
      zones.length > 0 &&
      !!reason &&
      [RequestReasons.Scaffold, RequestReasons.MovingLift, RequestReasons.Container].includes(reason?.reason) &&
      !hasCarFreeZones
    );
  },
);
