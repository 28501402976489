import './MyRequestsExtensions.page.scss';

import React, { FC, useCallback, useEffect } from 'react';
import * as A from 'antwerp-core-react-branding';
import moment from 'moment';
import { createCalculateExtensionCostAction, createClearCostAction } from '../../../../common/actions/creators/cost';
import { createExtensionSaveRequestedAction } from '../../../../common/actions/creators/requests';
import NotFound from '../../../common/errors/notFound';
import Price from '../../../common/price/price';
import { ExtensionForm } from '../../../forms/extensionform';
import { useNavigate, useParams } from 'react-router';
import { IRequestExtension, TRouterWithId } from '../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestById, selectCost, selectMetadata } from '../../../../store/selectors';
import { Visible } from '../../../common/layout/Visible.component';
import { translate } from '../../../../translations/translate';

export const MyRequestsExtensions: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { id } = useParams<keyof TRouterWithId>();
  const cost = useSelector(selectCost);
  const metadata = useSelector(selectMetadata);
  const request = useSelector(getRequestById(id!));

  useEffect(() => {
    if (request) {
      dispatch(createClearCostAction());
      dispatch(createCalculateExtensionCostAction({ request, extension: { dateUntil: earliestStartDataAllowed() } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const earliestStartDataAllowed = useCallback(
    () => (request ? moment(request.dateUntil).add(1, 'd').format(A.DatePickerInput.DefaultDateFormat) : ''),
    [request],
  );

  const onSubmit = useCallback(
    (data: Partial<IRequestExtension>) => {
      dispatch(createExtensionSaveRequestedAction({ ...data, permitRequest: request }));
    },
    [request, dispatch],
  );

  const onDateUntilChange = useCallback(
    (data: Partial<IRequestExtension>) => {
      request && dispatch(createCalculateExtensionCostAction({ request, extension: data }));
    },
    [request, dispatch],
  );

  return (
    <>
      <Visible visible={!!request}>
        <section className="my-requests__extensions">
          <div className="row my-requests__extensions__header">
            <h1>
              {translate('general.request')} {request!.referenceId} {translate('general.extend')}
            </h1>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-9">
              <ExtensionForm
                earliestStartDataAllowed={earliestStartDataAllowed()}
                goBack={() => history(-1)}
                onChange={onDateUntilChange}
                onSubmit={onSubmit}
                metadata={metadata!}
                request={request!}
              />
            </div>
            <div className="col-xs-12 col-md-3">
              <Price cost={cost} showGdpr={false} />
            </div>
          </div>
        </section>
      </Visible>
      <Visible visible={!request}>
        <NotFound description={translate('myRequests.details.notFound')} goBack={() => history(-1)} />
      </Visible>
    </>
  );
};
