import * as A from 'antwerp-core-react-branding';
import * as React from 'react';
import intl from 'react-intl-universal';

import { PaymentType } from '../../../common/enums';
import { canPayByBankTransfer } from '../../../common/validation/validateBankTransferDate';
import { IRequest } from '../../../types';
import { FC } from 'react';
import { IControllableForm } from '../../../common/form';
import { RadiobuttonsProperties } from 'antwerp-core-react-branding/dist/typings/lib/atoms/form/radiobuttons';
import { RadioButtonsControlled } from '../../atoms';

interface IProps extends IControllableForm, Omit<RadiobuttonsProperties, 'options'> {
  value?: string;
  input?: any;
  request?: IRequest;
}

/**
 * React Component PaymentType
 */

/**
 * React Component PaymentType
 */
export const PaymentTypeSelector: FC<IProps> = (props) => {
  const getOptions = (): A.RadiobuttonOption[] =>
    Object.keys(PaymentType)
      // @ts-ignore
      .map((key: any) => PaymentType[key])
      .map((paymentType: string) => {
        return {
          disabled: paymentType === PaymentType.Banktransfer && !canPayByBankTransfer(props.request!),
          label: intl.get(`paymenttype.${paymentType}`),
          value: paymentType,
        };
      });

  return <RadioButtonsControlled {...props} options={getOptions()} />;
};
