import React, { FC, PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
  defaultComponent?: React.ReactNode;
  visible: boolean;
}

export const Visible: FC<IProps> = ({ children, defaultComponent, visible = true }) =>
  // Need to wrap it in a fragment because of https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33006
  visible ? <>{children}</> : defaultComponent ? <>{defaultComponent}</> : null;
