import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../theme';

export const useGeoDrawingListItemStyles = createUseStyles({
  container: {
    borderBottom: `1px solid ${COLORS.lightSilver}`,
    height: 'fit-content',
    minHeight: '100px',
    width: '100%',
  },
  type: {
    flex: '1',
    fontWeight: 'bold',
  },
  area: {
    flexBasis: '75%',
    maxWidth: '75%',
    fontWeight: 'bold',
  },
  done: {
    flexBasis: '25%',
    maxWidth: '25%',
  },
  next: {
    width: 'fit-content',
    marginLeft: 'auto',
  },
  customName: {
    width: '100%',
    marginTop: '1rem',
  },
  button: {
    boxShadow: 'none !important',
  },
});
