import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { submitPartnerFormId } from '../../../../common/utils/formUtils';
import { IWithPartnerVariant } from '../../../../types';
import { useGenericStyles, usePartnerForm } from '../../../../hooks';
import { PartnerFields } from '../../../organisms';
import { IconButton } from '../../../atoms';
import { translate } from '../../../../translations/translate';
import classNames from 'classnames';
import { Visible } from '../../../common/layout/Visible.component';

interface IProps extends IWithPartnerVariant {
  partnerId?: number;
}

export const PartnerForm: FC<IProps> = ({ partnerId, variant }) => {
  const { flex, spacing } = useGenericStyles();
  const { isFormReady, formMethods, submitPartner } = usePartnerForm(variant, partnerId);

  return (
    <Visible visible={isFormReady}>
      <FormProvider {...formMethods}>
        <form id={submitPartnerFormId(variant)} onSubmit={submitPartner}>
          <PartnerFields variant={variant} />
          <div className={classNames(flex.alignRight, spacing.marginTop.LG)}>
            <IconButton icon="floppy-o" type="submit" form={submitPartnerFormId(variant)}>
              {translate('sgw.partners.form.submit')}
            </IconButton>
          </div>
        </form>
      </FormProvider>
    </Visible>
  );
};
