import React, { FC } from 'react';
import { usePartnerAccordionHeaderStyles } from './PartnerAccordionHeader.styles';
import { CheckBox } from '../../atoms';
import { Visible } from '../../common/layout/Visible.component';
import classNames from 'classnames';
import { IPartnerHeaderProps } from '../../../types';
import intl from 'react-intl-universal';
import { useGenericStyles } from '../../../hooks';

interface IProps extends IPartnerHeaderProps {
  onClickSameAsContractor(value: boolean): void;
  onClickSameAsRequestor(value: boolean): void;
  readOnly: boolean;
  title: string;
}

export const PartnerAccordionHeader: FC<IProps> = ({
  readOnly,
  sameAsContractor,
  sameAsRequestor,
  onClickSameAsContractor,
  onClickSameAsRequestor,
  showSameAsContractor = false,
  title,
}) => {
  const { flex } = useGenericStyles();
  const C = usePartnerAccordionHeaderStyles();

  return (
    <div className={classNames(flex.centerVertical, 'row')}>
      <div className={classNames(flex.grow, C.title, 'col-md-6', 'col-xs-12')}>{title}</div>
      <div className={classNames(flex.grow, 'col-md-6', 'col-xs-12')}>
        <Visible visible={!readOnly || sameAsRequestor}>
          <CheckBox
            checked={sameAsRequestor}
            label={intl.get('sgw.partners.accordion.sameAsRequester')}
            name={title}
            onChange={onClickSameAsRequestor}
            disabled={readOnly}
            className={C.checkbox}
          />
        </Visible>
        <Visible visible={showSameAsContractor && (!readOnly || sameAsContractor)}>
          <CheckBox
            checked={sameAsContractor}
            className={classNames(C.checkBoxContractor, C.checkbox)}
            label={intl.get('sgw.partners.accordion.sameAsContractor')}
            name={title + 'contractor'}
            onChange={onClickSameAsContractor}
            disabled={readOnly}
          />
        </Visible>
      </div>
    </div>
  );
};
