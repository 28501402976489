import React, { FC } from 'react';
import intl from 'react-intl-universal';
import env from '../../../../package.json';
import { GdprContent } from '../staticinfo/gdpr-content';
import { StaticInfoSnippet } from '../staticinfo/static-info-snippet';
import WcagComplianceContent from '../staticinfo/wcag-compliance-content';
import { INFO_GDPR, INFO_WCAG } from '../../../common/constants';

export const FooterLabel: FC = () => (
  <>
    <span>&copy;{new Date().getFullYear()}</span>
    {intl.get('general.footer')}
    {env.version}
    {' | '}
    <StaticInfoSnippet title={intl.getHTML('general.wcag', {})} url={INFO_WCAG}>
      <WcagComplianceContent />
    </StaticInfoSnippet>
    {' | '}
    <StaticInfoSnippet title={intl.getHTML('general.gdpr', {})} url={INFO_GDPR}>
      <GdprContent />
    </StaticInfoSnippet>
  </>
);
