import { DESELECT_PDI, SELECT_PDI } from '../../common/actions/types';
import { IGenericAction } from '../actions/actions';

const INITIAL_STATE: number = -1;

export default function selectedPublicDomainIntake(
  state: number = INITIAL_STATE,
  action: IGenericAction<number>,
): number {
  switch (action.type) {
    case SELECT_PDI:
      return action.payload;
    case DESELECT_PDI:
      return INITIAL_STATE;
    default:
      return state;
  }
}
