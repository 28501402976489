import { esri, LatLngBoundsExpression, LatLngExpression, Marker as LeafletMarker, Pattern } from 'leaflet';
import { IWithClassName } from './generic.types';
import React, { PropsWithChildren } from 'react';
import { IPublicDomainIntake, IRequestConflict } from '../types';
import { LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from 'geojson';
import { PolygonProps, PolylineProps } from 'react-leaflet';

export interface ILeafletMarkerEvents {
  onDragStart?(marker: LeafletMarker | null): void;
  onDrag?(marker: LeafletMarker | null): void;
  onDragEnd?(marker: LeafletMarker | null): void;
}

export interface ILeafletPolylineEvents {
  onDragEnd?(positions: LatLngExpression[]): void;
}

export interface ILeafletPolygonEvents {
  onDragEnd?(positions: LatLngExpression[][]): void;
}

export type IGeometry = Point | MultiPoint | LineString | MultiLineString | Polygon | MultiPolygon;

export interface IBaseMapProps extends IWithClassName, PropsWithChildren {
  bounds?: LatLngBoundsExpression;
  geometryBounds?: IGeometry;
  busy?: boolean;
  extraLayers?: React.ReactNode | React.ReactNode[];
  height?: number | string;
  maxZoom?: number;
  zoomControl?: boolean;
  enableDrawing?: boolean;
  onDrawingCreated?(geometry: IGeometry): void;
  onStartDrawing?(): void;
  showLayersControl?: boolean;
}

export interface IEditMapProps extends IBaseMapProps, IConflictProps {
  geometry: LineString | Polygon | MultiPolygon;
  onChange?(intakeChanges: Partial<IPublicDomainIntake>): void;
  onError?(message: string): void;
}

export interface IEditMapPolygonProps extends IEditMapProps {
  geometry: Polygon;
}

export interface IEditMapPolylineProps extends IEditMapProps {
  geometry: LineString;
}

export interface IEditMapCarFreeZoneProps extends IEditMapProps {
  geometry: Polygon | MultiPolygon;
  gisId?: string;
  selectedGisId?: string;
}

export interface IConflictProps {
  conflicts?: IRequestConflict[];
}

export interface IPolylineProps extends PolylineProps, ILeafletPolylineEvents {
  draggable?: boolean;
  positions: LatLngExpression[];
  arrow?: Pattern[];
  info?: string;
  infoSticky?: boolean;
  lineWeight?: number;
}

export interface IPolygonProps extends PolygonProps, ILeafletPolygonEvents {
  draggable?: boolean;
  positions: LatLngExpression[][];
  info?: string;
  lineWeight?: number;
}

export interface IMapLayer {
  id: number;
  baseMap?: esri.Basemaps;
  checked?: boolean;
  enabled?: boolean;
  layer?: number;
  name: string;
  url?: string;
  type: MapLayerTypes;
  timeBasedType?: TimeBasedTypes;
}

export enum TimeBasedTypes {
  REQUEST = 'request',
  PHASE = 'phase',
}

export enum MapLayerTypes {
  BASE,
  BASE_MAP,
  DYNAMIC,
  FEATURE,
  SATELLITE,
  TILED,
}
