import * as React from 'react';

import * as A from 'antwerp-core-react-branding';
import { FC, forwardRef, SyntheticEvent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import intl from 'react-intl-universal';
import { IControllableForm } from '../../../common/form';
import { InputProperties } from 'antwerp-core-react-branding';
import { STRING_MAX_LENGTH } from '../../../common/constants';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

export interface TextInputProperties extends InputProperties<any>, IControllableForm {
  customizeOnBlur?: boolean;
  extensiveLabel?: string;
  trimSpaces?: boolean;
  validationRules?: RegisterOptions;
}

export const TextInput: FC<TextInputProperties> = forwardRef(
  (
    {
      customizeOnBlur,
      min,
      max,
      name,
      onClick,
      onBlur,
      onFocus,
      trimSpaces,
      type,
      validate,
      extensiveLabel,
      label,
      required,
      className,
      maxLength = STRING_MAX_LENGTH,
      meta,
      validationRules,
      onChange,
      value,
    },
    ref,
  ) => {
    const { control, setValue } = useFormContext();
    const {
      field,
      fieldState: { error, isTouched },
    } = useController({
      name,
      control,
      rules:
        validationRules ||
        (validate
          ? {
              required: required ? intl.get('fields.labelRequired', { label }) : false,
              validate,
              maxLength: STRING_MAX_LENGTH,
            }
          : {
              required: required ? intl.get('fields.labelRequired', { label }) : false,
              maxLength: STRING_MAX_LENGTH,
            }),
    });

    return (
      <div className={className}>
        <A.TextInput
          required={required}
          type={type}
          {...field}
          value={value ?? field.value}
          onClick={onClick}
          onBlur={(e) => {
            field.onBlur();
            if (customizeOnBlur) {
              onBlur?.(e);
            }
          }}
          onFocus={onFocus}
          maxLength={maxLength}
          max={max}
          min={min}
          // @ts-ignore
          ref={ref}
          label={extensiveLabel ? extensiveLabel : label}
          meta={meta || { error: error?.message, touched: isTouched || !!error, valid: !error }}
          errorComponent={
            <div role="alert" aria-live="polite">
              {error?.message}
            </div>
          }
          onChange={(e: SyntheticEvent<HTMLInputElement>) => {
            onChange?.(e);
            setValue(name, trimSpaces ? e.currentTarget.value.trim() : e.currentTarget.value);
          }}
          onKeyDown={(e) => type === 'number' && ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
        />
      </div>
    );
  },
);
