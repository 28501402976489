import { combineReducers } from 'redux';
import { generatePayloadReducer } from '../generic.reducers';
import { ISgwProject } from '../../../types';
import { SgwRequestActions } from '../../actions';

const projectReducer = combineReducers({
  projects: generatePayloadReducer<ISgwProject[]>([SgwRequestActions.setProjects.type], []),
});

export default projectReducer;
