import { ISgwPhase, IWithId } from '.';

export interface IAttachment extends IWithId {
  createdAt: string;
  fileStorage: IFileStorage;
  category?: Category | '';
  sgwPhases: (string | number)[];
}

export interface IAttachmentBE extends IWithId {
  createdAt: string;
  fileStorage: IFileStorage;
  category?: Category | '';
  sgwPhases: ISgwPhase[];
}

export enum Category {
  signalisationMap = 'Signalisatieplan',
  detourMap = 'Omleidingsplan',
  letterToResidents = 'Bewonersbrief',
  urgentRequest = 'Hoogdringendheidsbewijs',
  feeResponsibleApproval = 'Retributie Akkoord',
  other = 'Andere',
  knowledge = 'Kennisname',
  advice = 'Advies',
  report = 'Verslag',
  trafficLightMatrix = 'Verkeerslichtenmatrix',
  mail = 'Mail',
  agreement = 'Overeenkomst',
}

export const categories = [
  { label: Category.signalisationMap, value: Category.signalisationMap },
  { label: Category.detourMap, value: Category.detourMap },
  { label: Category.knowledge, value: Category.knowledge },
  { label: Category.letterToResidents, value: Category.letterToResidents },
  { label: Category.urgentRequest, value: Category.urgentRequest },
  { label: Category.feeResponsibleApproval, value: Category.feeResponsibleApproval },
  { label: Category.advice, value: Category.advice },
  { label: Category.mail, value: Category.mail },
  { label: Category.agreement, value: Category.agreement },
  { label: Category.report, value: Category.report },
  { label: Category.trafficLightMatrix, value: Category.trafficLightMatrix },
  { label: Category.other, value: Category.other },
];

export interface IFileStorage extends File {
  id: number;
  url?: string;
}
