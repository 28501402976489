// tslint:disable max-line-length

import * as React from 'react';

type GdprContentProperties = {};

export default class WcagComplianceContent extends React.Component<GdprContentProperties> {
  public render() {
    return (
      <>
        <div className="wcag-compliance">
          <div className="row">
            <div className="col-xs-12">
              <div className="u-text-readable">
                <h4> Toegankelijkheidsverklaring</h4>

                <p className="u-margin-top">
                  Parkeerverbod antwerpen.be streeft ernaar om zijn digitale toepassingen zo toegankelijk mogelijk te
                  maken.Dit doen we in overeenstemming met het{' '}
                  <a
                    href="http://www.ejustice.just.fgov.be/cgi/api2.pl?lg=nl&#38;pd=2018-12-19&#38;numac=2018032457"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Vlaamse bestuursdecreet
                  </a>
                  .
                </p>
                <p className="u-margin-top">
                  Iedereen moet de informatie op Parkeerverbod antwerpen.be eenvoudig kunnen gebruiken en bekijken, dus
                  ook slechtzienden, blinden, slechthorenden, doven en personen met een andere beperking. Deze
                  toegankelijkheidsverklaring geeft aan in welke mate deze website voldoet aan de Web Content
                  Accessibility Guidelines versie 2.1 niveau AA of kortweg <strong>WCAG 2.1 niveau AA</strong>.
                </p>
                <p className="u-margin-top">
                  Deze toepassing voldoet gedeeltelijk aan de richtlijnen. Onderaan sommen we de resterende werkpunten
                  op inclusief een plan van aanpak, planning en alternatieven indien beschikbaar.
                </p>

                <h5>Opstelling van deze toegankelijkheidsverklaring</h5>

                <p className="u-margin-top">Deze toegankelijkheidsverklaring is opgesteld op 22 september 2020</p>
                <p className="u-margin-top">
                  De toegankelijkheid van Parkeerverbod antwerpen.be werd laatst gecontroleerd via een QuickScan,
                  uitgevoerd door de beheerder van de website.
                </p>
                <p className="u-margin-top">
                  De toegankelijkheidsverklaring is voor het laatst herzien op 24 augustus 2021.
                </p>

                <h5>Feedback en contactgegevens</h5>

                <p className="u-margin-top">
                  Hebt u opmerkingen of ervaart u toegankelijkheidsproblemen die nog niet zijn aangepakt en niet vermeld
                  zijn als werkpunt? Laat het ons dan weten.
                </p>

                <p className="u-margin-top">
                  Stuur een e-mail naar{' '}
                  <a href="mailto:info@antwerpen.be" title="Stuur een e-mail naar info@antwerpen.be">
                    info@antwerpen.be
                  </a>{' '}
                  of bel naar <a href="tel:3232211333">+32 3 22 11 333</a>.
                </p>

                <p className="u-margin-top">We streven ernaar om u een antwoord te bezorgen binnen de 7 werkdagen.</p>

                <h5>Ombudsvrouw</h5>

                <p className="u-margin-top">
                  Bent u niet of onvoldoende geholpen? De ombudsvrouw behandelt uw melding als u uitgepraat bent met de
                  stad, dus als u eerst uw probleem hebt voorgelegd aan de stad zelf. Zij behandelt uw melding
                  onafhankelijk en bemiddelt waar mogelijk.
                </p>

                <p className="u-margin-top">
                  Stuur een e-mail naar{' '}
                  <a href="mailto:ombudsvrouw@antwerpen.be" title="Stuur een e-mail naar ombudsvrouw@antwerpen.be">
                    ombudsvrouw@antwerpen.be
                  </a>
                  , bel naar +32 800 94 843 (gratis nummer) of ga langs op haar kantoor aan het De Coninckplein 25,
                  vlakbij de bibliotheek Permeke. De ingang ligt in de Muizenstraat en de openingsuren vindt u op:{' '}
                  <a
                    href="https://www.antwerpen.be/ombudsvrouw"
                    target="_blank"
                    title="Ga naar Stad &#38;amp; Beleid - Ombudsvrouw"
                    rel="noreferrer"
                  >
                    www.antwerpen.be/ombudsvrouw
                  </a>
                  .
                </p>

                <h5>Openstaande werkpunten</h5>

                <p className="u-margin-top">
                  Om helemaal te voldoen aan de WCAG, moeten we nog enkele inspanningen leveren. Die sommen we hieronder
                  op.
                </p>

                <h6>Richtlijn 1.1 Tekstalternatieven</h6>
                <div className="problem">
                  <h6>Werkpunt 1</h6>

                  <p className="problem-text-view">
                    Deze pagina('s) bevatten kaarten, infografieken of andere complexe afbeeldingen zonder uitgebreide
                    beschrijving.
                  </p>

                  <h6 className="subproblem">WCAG 2.1 A/AA Succescriteria waaraan niet is voldaan</h6>

                  <ul>
                    <li>1.1.1. Niet-tekstuele content</li>
                  </ul>
                  <details>
                    <summary>Gevolg, aanpak en alternatief voor werkpunt 1</summary>

                    <ul className="a-list" style={{ borderBottom: '1px solid black', paddingBottom: '2rem' }}>
                      <li>
                        <h5 className="h6">Gevolg</h5>
                        Met name bezoekers met een visuele beperking zullen het moeilijk vinden om de inhoud te
                        gebruiken of te begrijpen. De inname van het openbaar domein dient ingetekend te worden op een
                        kaartlaag, dit is noodzakelijk stap in het vergunningsproces. Deze stap is zeer moeilijk voor
                        een persoon met een visuele beperking.
                      </li>
                      <li>
                        <h5 className="h6">Alternatief</h5>
                        De vergunning kan momenteel worden aangevraagd zonder een correcte intekening van de inname van
                        het openbaar domein. De software doet zelf een voorstel, normaliter wordt deze correct gezet
                        door de aanvrager. Indien men dit als aanvrager niet in orde krijgt kan er steeds contact worden
                        opgenomen met de dienst Tijdelijke Signalisatie, via mail of telefoon. Vervolgens wordt de
                        aanvrager door de dienst verder geholpen.
                      </li>
                    </ul>
                  </details>
                </div>
                <h6>Richtlijn 2.1 Toetsenbordtoegankelijk</h6>

                <blockquote className="blockquote" style={{ marginTop: '2rem' }}>
                  <p>Maak alle functionaliteit beschikbaar vanaf een toetsenbord.</p>

                  <div id="eid-safari-extension-is-installed">&nbsp;</div>
                </blockquote>

                <div className="problem">
                  <h6>Werkpunt 1</h6>

                  <p className="problem-text-view">
                    Sommige onderdelen op onze website zijn niet bruikbaar voor mensen die enkel een toetsenbord kunnen
                    gebruiken. Een aanvraag voor het gebruik van een autovrije zone op basis van straat en huisnummer is
                    momenteel niet mogelijk zonder het gebruik van een muis. Ook markeringen verplaatsen op de kaart
                    zonder het gebruik van een muis is niet mogelijk.
                  </p>

                  <h6 className="subproblem">WCAG 2.1 A/AA Succescriteria waaraan niet is voldaan</h6>

                  <ul>
                    <li>2.1.1 Toetsenbord</li>
                    <li>2.1.2 Geen toetsenbordval</li>
                    <li>2.1.3 Toetsenbord (geen uitzondering)</li>
                  </ul>
                  <details>
                    <summary>Gevolg, aanpak en alternatief voor werkpunt 1</summary>

                    <p>Dit probleem doet zich voor op deze pagina's:</p>

                    <ul className="a-list page-inputs">
                      <li className="page-input-row">https://parkeerverbod.antwerpen.be/front/new/4</li>
                    </ul>

                    <ul className="a-list" style={{ borderBottom: '1px solid black', paddingBottom: '2rem' }}>
                      <li>
                        <h5 className="h6">Gevolg</h5>
                        Met name bezoekers met een visuele beperking zullen het moeilijk vinden om de inhoud te
                        gebruiken of te begrijpen.
                      </li>

                      <li>
                        <h5 className="h6">Alternatief</h5>
                        Voor het intekenen op kaart doet de software zelf een voorstel, normaliter wordt deze correct
                        gezet door de aanvrager. Indien men dit als aanvrager niet in orde krijgt kan er steeds contact
                        worden opgenomen met de dienst Tijdelijke Signalisatie, via mail of telefoon. Vervolgens wordt
                        de aanvrager door de dienst verder geholpen.
                        <br />
                      </li>
                    </ul>
                  </details>
                </div>

                <h5>Toegankelijkheid PDF-documenten</h5>

                <p className="u-margin-top">
                  Er staan PDF&#8217;s op onze website die niet volledig toegankelijk zijn. Ondervind je een probleem
                  bij het lezen of invullen van een document, neem dan contact op met ons op
                  tijdelijke.verkeerssignalisatie@antwerpen.be Wij engageren ons dan om een toegankelijke versie te
                  bezorgen binnen de 7 werkdagen.
                </p>

                <h5>Bijkomende maatregelen om de toegankelijkheid te bevorderen</h5>

                <p className="u-margin-top">
                  De volgende maatregelen werden genomen om naleving van richtlijnen te bevorderen:
                </p>
                <ul className="a-list">
                  <li>Toegankelijkheid is opgenomen in onze communicatiestrategie.</li>
                  <li>Toegankelijkheid is opgenomen in de waarden van onze organisatie.</li>
                  <li>Toegankelijkheid is opgenomen in ons interne beleid.</li>
                  <li>
                    Toegankelijkheid is een essentieel onderdeel van onze duurzaamheidsstrategie en een vast onderdeel
                    van ons aankoopbeleid.
                  </li>
                  <li>
                    Onze medewerkers worden ondersteund en aangespoord om hun kennis over toegankelijkheid up-to-date te
                    houden en te vergroten.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
