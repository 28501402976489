import { createUseStyles } from 'react-jss';

export const useFlexStyles = createUseStyles({
  alignRight: { display: 'flex', justifyContent: 'flex-end' },
  bottomVertical: { display: 'flex', alignItems: 'flex-end' },
  center: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  centerHorizontal: { display: 'flex', justifyContent: 'center' },
  centerVertical: { display: 'flex', alignItems: 'center' },
  centerVerticalSpaceBetween: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
  ColumnEnd: { display: 'flex', flexDirection: 'column', alignItems: 'end' },
  column: { display: 'flex', flexDirection: 'column' },
  columnCenter: { display: 'flex', flexDirection: 'column', justifyContent: 'center' },
  fullWidth: { width: '100%' },
  gap: { gap: '30px' },
  grow: { flexGrow: 1 },
  row: { display: 'flex' },
  half: { flexBasis: '50%', maxWidth: '50%' },
  spaceBetween: { display: 'flex', justifyContent: 'space-between' },
  topVertical: { display: 'flex', alignItems: 'flex-start' },
});
