import * as React from 'react';
import intl from 'react-intl-universal';
import * as A from 'antwerp-core-react-branding';
import { ApplicantType } from '../../../common/enums';
import { FC } from 'react';
import { IControllableForm } from '../../../common/form';
import { RadiobuttonsProperties } from 'antwerp-core-react-branding/dist/typings/lib/atoms/form/radiobuttons';
import { RadioButtonsControlled } from '../../atoms';

interface IProps extends IControllableForm, Omit<RadiobuttonsProperties, 'options'> {
  value?: string;
  input?: any;
}

/**
 * React Component ApplicantType
 */
export const ApplicantTypeSelector: FC<IProps> = (props) => {
  const getOptions = (): A.RadiobuttonOption[] => {
    return Object.values(ApplicantType).map((applicantType) => ({
      label: intl.get(`applicanttype.${applicantType.toLowerCase()}`),
      value: applicantType,
    }));
  };
  return <RadioButtonsControlled {...props} options={getOptions()} />;
};
