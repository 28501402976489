import { FC, useMemo, useCallback, useState, useEffect } from 'react';
import { validateEmail } from '../../../../common/validation/validateEmail';
import validateTelephone from '../../../../common/validation/validateTelephone';
import { Fieldset, RadioButtonsControlled, TextInput } from '../../../atoms';
import { useFormContext } from 'react-hook-form';
import { HttpStatusCodes, ISgwRequest, WorkType } from '../../../../types';
import { Visible } from '../../../common/layout/Visible.component';
import { createUseStyles } from 'react-jss';
import { translate, translateIgnoreTS } from '../../../../translations/translate';
import { SgwRequestApi } from '../../../../store/api';

const useStyles = createUseStyles({
  textField: {
    width: '50%',
  },
});

const defaultGipodValidation = {
  gipodId: 0,
  valid: translate('sgw.workType.type.gipodIdValidationError'),
};

export const TypeFields: FC = () => {
  const { register, setValue, watch } = useFormContext<ISgwRequest>();
  const C = useStyles();
  const isGroundworksToPublicDomain = watch('workType') === WorkType.GroundWorksToPublicDomain;
  const [checkedGipodId, setCheckedGipodId] = useState<{ gipodId: number; valid: boolean | string }>({
    ...defaultGipodValidation,
  });

  useEffect(() => {
    if (!isGroundworksToPublicDomain) {
      setValue('bonuNumber', null);
      setValue('gipodId', null);
      setValue('utilityCompanyNameResponsible', null);
      setValue('utilityCompanyPhoneResponsible', null);
      setValue('utilityCompanyEmailResponsible', null);
      setValue('utilityCompanyName', null);
    }
  }, [isGroundworksToPublicDomain, setValue]);

  const options = useMemo(
    () =>
      Object.values(WorkType).map((workType) => ({
        label: translateIgnoreTS(`sgw.workType.type.options.${workType}`),
        value: workType,
      })),
    [],
  );

  const checkIsValidGipodId = useCallback(
    async (gipodId?: number) => {
      if (gipodId === undefined || isNaN(gipodId)) {
        setCheckedGipodId({ gipodId: 0, valid: translate('sgw.workType.type.gipodIdDoesNotExist') });
        return translate('sgw.workType.type.gipodIdDoesNotExist');
      }

      if (checkedGipodId.gipodId !== gipodId) {
        let valid: string | boolean = '';
        try {
          const response = await SgwRequestApi.checkIsValidGipodId(gipodId);
          if (response?.status === HttpStatusCodes.NO_CONTENT)
            valid = translate('sgw.workType.type.gipodIdDoesNotExist');
          else valid = true;
        } catch (error) {
          valid = translate('sgw.workType.type.gipodIdValidationError');
        } finally {
          setCheckedGipodId({ gipodId, valid });
        }
        return valid;
      }

      return checkedGipodId.valid;
    },
    [checkedGipodId.gipodId, checkedGipodId.valid],
  );

  return (
    <Fieldset legend={translate('sgw.workType.type.legend')} noBottomBorder>
      <RadioButtonsControlled
        {...register('workType', { required: true })}
        options={options}
        label={translate('sgw.workType.type.label')}
        extensiveLabel={translate('sgw.workType.type.info')}
        required
      />
      <Visible visible={isGroundworksToPublicDomain}>
        <TextInput
          className={C.textField}
          {...register('bonuNumber', { required: isGroundworksToPublicDomain })}
          label={translate('sgw.workType.type.bonuNumber')}
          required
        />
        <TextInput
          type="number"
          className={C.textField}
          {...register('gipodId', {
            required: isGroundworksToPublicDomain,
            onChange: (event) => {
              // Prevent negative numbers
              const inputValue = parseFloat(event.target.value);
              if (inputValue < 0) {
                event.target.value = 0;
              }
            },
            validate: (value) => (isGroundworksToPublicDomain ? checkIsValidGipodId(parseInt(value!)) : true),
          })}
          label={translate('sgw.workType.type.gipodId')}
          required
        />
        <TextInput
          className={C.textField}
          {...register('utilityCompanyNameResponsible', { required: isGroundworksToPublicDomain })}
          label={translate('sgw.workType.type.utilityCompanyNameResponsible')}
          required
        />
        <TextInput
          className={C.textField}
          {...register('utilityCompanyPhoneResponsible', { required: isGroundworksToPublicDomain })}
          label={translate('sgw.workType.type.utilityCompanyPhoneResponsible')}
          validate={validateTelephone}
          required
        />
        <TextInput
          className={C.textField}
          {...register('utilityCompanyEmailResponsible', { required: isGroundworksToPublicDomain })}
          label={translate('sgw.workType.type.utilityCompanyEmailResponsible')}
          validate={validateEmail}
          required
        />
        <TextInput
          className={C.textField}
          {...register('utilityCompanyName', { required: isGroundworksToPublicDomain })}
          label={translate('sgw.workType.type.utilityCompanyName')}
          required
        />
      </Visible>
    </Fieldset>
  );
};
