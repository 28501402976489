import { WorkflowTemplate } from '../../templates/WorkflowTemplate/WorkflowTemplate.component';
import React from 'react';
import { SgwWorkflowStep, TRouterWithId, WorkflowType } from '../../../types';
import { PhasesForm } from '../../forms/sgw/PhasesForm/PhasesForm.component';
import { translate } from '../../../translations/translate';
import { atomWithHash } from 'jotai/utils';
import { InvalidSgwRequestStartDateAlert } from '../../organisms';
import { useSelector } from 'react-redux';
import { phasesHaveDateOverlap, selectPhaseFormFieldsChanges } from '../../../store/selectors/sgw';
import { useParams } from 'react-router';

export const openPhasesAtom = atomWithHash<string[]>('openPhases', [], { replaceState: true });

export const PhasesPage = () => {
  const isFormFieldsChanges = useSelector(selectPhaseFormFieldsChanges);
  const { id } = useParams<keyof TRouterWithId>();
  const haveDateOverlap = useSelector(phasesHaveDateOverlap(id!));
  return (
    <WorkflowTemplate
      title={translate('sgw.phases.title')}
      step={SgwWorkflowStep.Phases}
      type={WorkflowType.Sgw}
      nextDisabled={isFormFieldsChanges || haveDateOverlap}
    >
      <InvalidSgwRequestStartDateAlert />
      <PhasesForm />
    </WorkflowTemplate>
  );
};
