import { IGenericAction } from '../actions/actions';
import * as T from '../actions/types';

const INITIAL_STATE: string = '';

/**
 * Set the app title to the state
 */
export default function title(state: string = INITIAL_STATE, action: IGenericAction<string>): string {
  switch (action.type) {
    case T.SET_APP_TITLE:
      return action.payload;
    default:
      return state;
  }
}
