import { FC, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { translate } from '../../../translations/translate';
import { IRequestMessages } from '../../../types';
import { Visible } from '../../common/layout/Visible.component';
import { Message } from './Message.component';

const useStyles = createUseStyles({
  messages: {
    maxHeight: '400px',
    overflowY: 'scroll',
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
});

interface IProps {
  messages: IRequestMessages;
}

export const MessageList: FC<IProps> = ({ messages }) => {
  const C = useStyles();
  const messageRef = useCallback((node: HTMLDivElement) => {
    node?.scrollTo(0, node.scrollHeight);
  }, []);

  return (
    <Visible visible={!!messages?.data.length} defaultComponent={translate('sgw.overview.messages.noMessages')}>
      <div className={C.messages} ref={messageRef}>
        {messages?.data.map((message) => (
          <Message {...message} />
        ))}
      </div>
    </Visible>
  );
};
