import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { FeatureName } from '../../../types';
import { useFeatures } from '../../../hooks';
import { Visible } from '../../common/layout/Visible.component';

interface IFeatureProps {
  fallbackComponent?: ReactNode;
}

interface IFeatureFlagProps extends IFeatureProps {
  featureName: FeatureName;
}

const FeatureFlag: FC<PropsWithChildren<IFeatureFlagProps>> = ({ children, fallbackComponent, featureName }) => {
  const { isFeatureEnabled } = useFeatures(featureName);

  return (
    <Visible
      visible={!!isFeatureEnabled}
      defaultComponent={<Visible visible={!!fallbackComponent}>{fallbackComponent}</Visible>}
    >
      {children}
    </Visible>
  );
};

export const Feature = {
  CreateSgwRequest: (props: PropsWithChildren<IFeatureProps>) => (
    <FeatureFlag {...props} featureName={FeatureName.createSgwRequest} />
  ),
  IntegrateDigipolisOrder: (props: PropsWithChildren<IFeatureProps>) => (
    <FeatureFlag {...props} featureName={FeatureName.integrateDigipolisOrder} />
  ),
};
