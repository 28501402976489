import { METADATA_RECEIVED } from '../../common/actions/types';
import { IGenericAction } from '../actions/actions';
import { IMetadata } from '../../types';

const INITIAL_STATE: IMetadata | {} = {};

export default function metadata(
  state: IMetadata | {} = INITIAL_STATE,
  action: IGenericAction<IMetadata>,
): IMetadata | {} {
  switch (action.type) {
    case METADATA_RECEIVED:
      return action.payload;
    default:
      return state;
  }
}
