import * as React from 'react';
import intl from 'react-intl-universal';

import * as A from 'antwerp-core-react-branding';
import { APP_DEFAULT_COUNTRY } from '../../../common/constants';

import { ApplicantType } from '../../../common/enums';
import { OverviewEditLink, OverviewItem, OverviewList, OverviewSection } from './overviewElements';
import { OverviewProperties } from './overViewType';

/**
 * React Component PersonalInfo
 */
export default class PersonalInfo extends React.Component<OverviewProperties, {}> {
  public render(): any {
    const request = this.props.request;

    const isCitizen = request?.applicantType === ApplicantType.Citizen;
    const isNonBelgianCitizen = isCitizen && request?.country?.country !== APP_DEFAULT_COUNTRY;

    return (
      <OverviewSection>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.NoMargin} className="row between-md">
          <h6>{intl.get('paymentform.personalinfo')}</h6>
          <OverviewEditLink {...this.props} />
        </A.Spacing>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginTop}>
          <OverviewList>
            <OverviewItem
              title={intl.get('paymentform.type')}
              description={intl.get(`applicanttype.${(request.applicantType || ApplicantType.Citizen).toLowerCase()}`)}
            />
            <OverviewItem
              title={intl.get('paymentform.name')}
              description={`${request.firstName} ${request.lastName}`}
            />
            <OverviewItem title={intl.get('personalform.emailaddress')} description={request.emailAddress} />
            <OverviewItem title={intl.get('personalform.phonenumber')} description={request.phoneNumber} />
            <OverviewItem title={intl.get('personalform.ibannumber')} description={request.ibanNumber || ''} />
            {isCitizen && !isNonBelgianCitizen && request.ssn && (
              <OverviewItem title={intl.get('personalform.ssn')} description={request.ssn} />
            )}
            {isNonBelgianCitizen && request.birthday && (
              <OverviewItem title={intl.get('personalform.birthday')} description={request.birthday} />
            )}
          </OverviewList>
        </A.Spacing>
      </OverviewSection>
    );
  }
}
