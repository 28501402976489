import { FC, PropsWithChildren, ReactNode } from 'react';
import { Accordion, IAccordionProps } from '../../atoms';
import { useGenericStyles } from '../../../hooks';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../theme';
import { Visible } from '../../common/layout/Visible.component';

interface IProps extends IAccordionProps, PropsWithChildren {
  icon: ReactNode;
  className?: string;
  recurringPhaseTemplate?: boolean;
  recurringPhase?: boolean;
}

const useStyles = createUseStyles({
  header: {
    border: `2px solid ${COLORS.borderGrey}`,
    borderRadius: 5,
    '& div': {
      '&.accordion__header': {
        height: 76,
        border: 'none',
        borderRadius: 5,
      },
      '&.accordion__content': {
        border: 'none',
        borderRadius: 5,
      },
    },
    width: '100%',
  },
  recurringPhaseHeader: {
    marginLeft: '110px',
  },
  recurringPhaseTemplateHeader: {
    marginLeft: '0',
  },
  image: {
    border: `2px solid ${COLORS.borderGrey}`,
    borderRadius: 5,
    height: 80,
    width: 80,
    padding: 15,
    textAlign: 'center',
    marginRight: 30,
  },
  visibleMd: {
    '@media only screen and (min-width: 48rem)': {
      display: 'block',
      visibility: 'visible',
    },
  },
  invisibleXs: {
    display: 'none',
    visibility: 'hidden',
  },
});

export const SgwAccordion: FC<IProps> = ({
  icon,
  className,
  recurringPhase,
  recurringPhaseTemplate,
  ...accordionProps
}) => {
  const { flex } = useGenericStyles();
  const C = useStyles();

  return (
    <div className={flex.row}>
      <Visible visible={!recurringPhase && !recurringPhaseTemplate}>
        <div className={classNames(C.visibleMd, C.invisibleXs, C.image, className)}>{icon}</div>
      </Visible>
      <Accordion
        {...accordionProps}
        className={classNames(
          flex.grow,
          C.header,
          className,
          recurringPhase && C.recurringPhaseHeader,
          recurringPhaseTemplate && C.recurringPhaseTemplateHeader,
        )}
      />
    </div>
  );
};
