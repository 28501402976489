import { combineReducers } from 'redux';
import { requestReducer } from './request.reducer';
import phase from './phase.reducer';
import project from './project.reducer';

const sgwReducer = combineReducers({
  request: requestReducer,
  project,
  phase,
});

export default sgwReducer;
