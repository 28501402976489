import * as A from 'antwerp-core-react-branding';
import { Sizes } from 'antwerp-core-react-branding';
import * as React from 'react';
import intl from 'react-intl-universal';
import TextareaAutosize from 'react-textarea-autosize';
import { Action, Dispatch } from 'redux';
import { createErrorAlertAction } from '../../../common/actions/creators/alert';
import { createRequestMessagesPostAction } from '../../../common/actions/creators/requestsMessages';
import { uploadFileToStorage } from '../../../common/api';
import DateFormatter from '../formatters/dateFormatter';
import { OverviewSection } from '../overview/overviewElements';
import './messages.scss';
import { IconButton } from '../../atoms';
import {
  IMessageAttachment,
  IRequestMessage,
  IRequestMessageAttachment,
  IRequestMessages,
  ButtonVariant,
} from '../../../types';
import { translate } from '../../../translations/translate';

export interface IMessagesProps {
  requestId: string;
  messages: IRequestMessages;
  dispatch?: Dispatch<Action>;
}

interface IUploadedFile {
  name: string;
  key: number;
  id?: string;
  percent?: number;
}

interface IState {
  message: string;
  uploadedFiles: IUploadedFile[];
}

export default class Messages extends React.Component<IMessagesProps> {
  public state: IState = {
    message: '',
    uploadedFiles: [],
  };

  public uploadFileTypes =
    'image/*, .heic, ' +
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document, ' +
    'application/msword,  application/pdf';

  public uploadFileRef: any;

  public constructor(props: IMessagesProps) {
    super(props);
    // @ts-ignore Will be fixed after upgrading of react to new version
    this.uploadFileRef = React.createRef();
  }

  public render(): JSX.Element | null {
    const props = this.props;
    const { message, uploadedFiles } = { ...this.state };

    const setMessage = (text: string) => {
      this.setState({ message: text });
    };

    const setUploadedFiles = (files: IUploadedFile[]) => {
      this.setState({ uploadedFiles: files });
    };

    if (!props.messages.data || !props.messages.acl.messages_can_view) {
      return null;
    }

    const getUploadedFileByKey = (key: number): IUploadedFile | undefined => {
      return uploadedFiles.find((f) => f.key === key);
    };

    const updateUploadedFiles = () => {
      setUploadedFiles([...uploadedFiles]);
    };

    // Message entered and all files uploaded
    const canSend = () => {
      return message && message.length && uploadedFiles.filter((f) => !f.id).length === 0;
    };

    const renderAttachments = (attachments: IRequestMessageAttachment[]) => {
      if (attachments.length < 1) {
        return null;
      }

      return (
        <div className="messages-attachments">
          {attachments.map((file: IRequestMessageAttachment) => (
            <a key={file.url} className="messages-attachments-file" href={file.url} target="_blank" rel="noreferrer">
              {file.name}
            </a>
          ))}
        </div>
      );
    };

    const renderForm = () => {
      if (!props.messages.acl.messages_can_edit) {
        return null;
      }

      const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
      };

      const sendMessageHandler = () => {
        if (message) {
          const attachments: IMessageAttachment[] = [];
          uploadedFiles.forEach((f) => {
            if (f.id) {
              attachments.push({ id: f.id });
            }
          });

          if (this.props.dispatch) {
            this.props.dispatch(
              createRequestMessagesPostAction({
                message: {
                  attachments,
                  content: message,
                },
                requestId: props.requestId,
              }),
            );
          }

          setMessage('');
          setUploadedFiles([]);
        }
      };

      const handleUploadClick = () => {
        // @ts-ignore
        this.uploadFileRef.current.click();
      };

      const onUploadProgress = (key: number) => {
        return (progressEvent: any): void => {
          getUploadedFileByKey(key)!.percent = Math.round((100 * progressEvent.loaded) / progressEvent.total);
          updateUploadedFiles();
        };
      };

      const uploadFileToServer = async (file: File, key: number) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        await uploadFileToStorage(formData, onUploadProgress(key))
          .then((response) => {
            getUploadedFileByKey(key)!.id = response[0].id;
            getUploadedFileByKey(key)!.percent = undefined;
            updateUploadedFiles();
          })
          .catch((event: any) => {
            setUploadedFiles(uploadedFiles.filter((f) => f.key !== key));
            if (event.response!.data!.msgs!.length >= 0 && this.props.dispatch) {
              this.props.dispatch(createErrorAlertAction(event.response.data.msgs[0].message));
            }
          });
      };

      const fileUploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length) {
          // validate file extensions
          for (const file of event.target.files) {
            if (!file.name.includes('.')) {
              const errorMessage = translate('messages.form.invalidFile');
              this.props.dispatch ? this.props.dispatch(createErrorAlertAction(errorMessage)) : alert(errorMessage);
              return;
            }
          }

          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i] as File;
            const key = uploadedFiles.length + 1;
            uploadedFiles.push({ name: file.name, key });
            uploadFileToServer(file, key);
          }
          setUploadedFiles([...uploadedFiles]);
        }
      };

      const deleteAttachmentHandler = (file: IUploadedFile) => {
        return () => {
          setUploadedFiles(uploadedFiles.filter((f) => f.key !== file.key));
        };
      };

      return (
        <div className="messages-form">
          <div className="row">
            <div className="col-xs-12">
              <TextareaAutosize
                className="messages-form-input"
                placeholder={intl.get('messages.form.placeholder')}
                // @ts-ignore
                onChange={handleMessageChange}
                minRows={4}
                value={message}
              />
            </div>
            <div className="col-xs-12">
              {uploadedFiles.map((file) => {
                return (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a className="messages-attachments-file messages-attachments-file-new" key={file.key}>
                    {file.name}
                    {file.percent ? (
                      <div className="a-spinner messages-attachments-spinner"></div>
                    ) : (
                      <i
                        className="fa fa-remove messages-attachments-delete"
                        color={'error'}
                        onClick={deleteAttachmentHandler(file)}
                      />
                    )}
                  </a>
                );
              })}
            </div>
            <div className="col-xs-12">
              <div className="messages-form-button">
                <IconButton
                  disabled={!canSend()}
                  location={A.Locations.Right}
                  icon="chevron-right"
                  variant={ButtonVariant.Default}
                  level={A.Levels.Primary}
                  size={Sizes.Small}
                  onClick={sendMessageHandler}
                  type={'submit'}
                >
                  {intl.get('messages.form.send')}
                </IconButton>
                <div className="messages-form-attachment" onClick={handleUploadClick}>
                  <i className="fa fa-paperclip messages-form-attachment-icon" />
                  <input
                    type="file"
                    name="file"
                    onChange={fileUploadHandler}
                    multiple={true}
                    ref={this.uploadFileRef}
                    style={{ display: 'none' }}
                    accept={this.uploadFileTypes}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const renderMessage = (item: IRequestMessage) => {
      const content = item.content.split('\n').map((val, key) => <p key={Math.random()}>{val}</p>);

      return (
        <div className="messages-item" key={Math.random()}>
          <strong>{item.sender.type === 'user' ? item.sender.fullName : intl.get('messages.admin.name')}</strong>{' '}
          <DateFormatter date={item.createdAt} /> <br />
          <div className="messages-content">{content}</div>
          {renderAttachments(item.attachments)}
        </div>
      );
    };

    return (
      <OverviewSection>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginBottom}>
          <h6>{intl.get('messages.form.label')}</h6>
        </A.Spacing>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.NoMargin}>
          <div className="messages">
            <div className="messages-scroll">
              <div>{props.messages.data.map((item: IRequestMessage) => renderMessage(item))}</div>
            </div>
            {props.messages.data.length === 0 && <div>{intl.get('Messages.Form.NoMessages')}</div>}
            {renderForm()}
          </div>
        </A.Spacing>
      </OverviewSection>
    );
  }
}
