import './Fail.component.scss';
import React, { FC } from 'react';
import * as A from 'antwerp-core-react-branding';
import { appUrls } from '../../../common/constants';
import { historyPush } from '../../../common/utils/historyUtils';
import { translate } from '../../../translations/translate';

export const FailedPayment: FC = () => {
  const onClick = (url: string) => {
    historyPush(url);
  };

  const onClickFactory = (url: string) => () => onClick(url);

  return (
    <div className="submit-page submit-fail">
      <div className="row submit-page__icon animated bounceIn">
        <i className="fa fa-remove" />
      </div>
      <h1>{translate('submit.failedpayment.title')}</h1>
      <div className="submit-page__text">{translate('submit.failedpayment')}</div>
      <div className="row submit-page__buttons">
        <div className="col-xs-12">
          {/*@ts-ignore*/}
          <A.Button onClick={onClickFactory(appUrls.myRequests.base)} text={translate('myRequests.title')} />
        </div>
      </div>
    </div>
  );
};
