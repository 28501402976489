import { FC } from 'react';
import classNames from 'classnames';
import { getSelectorTitle } from '../../../common/utils/cardSelectorUtils';
import { Visible } from '../layout/Visible.component';
import { translate } from '../../../translations/translate';
import { useGenericStyles } from '../../../hooks';

interface IProps {
  name?: string;
}

export const CardSelectorTag: FC<IProps> = ({ name }) => {
  const { spacing } = useGenericStyles();

  return (
    <Visible visible={!!name}>
      <div className={classNames('cardselector__selected', spacing.marginTop.ML)}>
        <div className={'m-tag d-tag'}>
          <div className={classNames('a-button', `a-button--success`, 'a-button--small has-icon', 'cardselector__tag')}>
            <i className="fa fa-check" />
          </div>
          <span className="m-tag__label">
            <span role="alert" aria-live="polite">
              {translate('cardselector.youChose')} {getSelectorTitle(name)}
            </span>
          </span>
        </div>
      </div>
    </Visible>
  );
};
