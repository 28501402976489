import * as React from 'react';
import intl from 'react-intl-universal';

import * as A from 'antwerp-core-react-branding';
import moment from 'moment';

import { formatToDisplayDate } from '../../../common/utils/dateUtils';
import { IMetadata } from '../../../types';

export type EmergencyLabelProperties = {
  dateFrom?: string;
  earliestStartDateAllowedForRequests: string;
  showIfDateBeforeEarliesAllowed: boolean;
  metadata: IMetadata;
};

/**
 * React Component EmergencyLabel
 */
export default class EmergencyLabel extends React.Component<EmergencyLabelProperties, {}> {
  public render(): any {
    if (this.props.dateFrom) {
      const format = A.DatePickerInput.DefaultDateFormat;
      const dateFrom = moment(this.props.dateFrom, format);
      const earliestDate = moment(this.props.earliestStartDateAllowedForRequests, format);
      if (dateFrom.diff(earliestDate) < 0 && this.props.showIfDateBeforeEarliesAllowed) {
        return (
          <div className="row animated fadeIn">
            <div className="col-lg-12 u-margin-top">
              {/*@ts-ignore*/}
              <A.Paragraph spacing type={A.ParagraphStyle.Alert}>
                {intl.get('periodform.changedatetoifnoemergency', {
                  date: formatToDisplayDate(this.props.earliestStartDateAllowedForRequests),
                  requestFixedEmergencyCost: this.props.metadata.prices.requestFixedEmergencyCost,
                })}
              </A.Paragraph>
            </div>
          </div>
        );
      }
    }
    return null!;
  }
}
