import classNames from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { IWithClassName } from '../../../types';
import { Visible } from '../layout/Visible.component';
import './cardSelector.scss';
import { CardSelectorItem } from './CardSelectorItem.component';

interface IProps extends IWithClassName {
  activeIndex?: number;
  errorComponent?: JSX.Element;
  error?: FieldError;
  onChange: (index: number) => void;
  items: JSX.Element[];
}

const useStyles = createUseStyles({
  errorComponent: {
    marginTop: '1rem',
  },
});

export const CardSelector: FC<IProps> = ({ className, items, activeIndex, error, errorComponent, onChange }) => {
  const C = useStyles();
  const [localActiveIndex, setLocalActiveIndex] = useState(activeIndex);

  useEffect(() => {
    setLocalActiveIndex(activeIndex);
  }, [activeIndex]);

  const _onChange = useCallback(
    (_activeIndex: number) => {
      if (localActiveIndex !== _activeIndex) {
        setLocalActiveIndex(_activeIndex);
        if (onChange) {
          onChange(_activeIndex);
        }
      }
    },
    [localActiveIndex, onChange],
  );

  return (
    <div>
      <div className={classNames('cardselector', 'a-input', className)} role="radiogroup" aria-invalid={!!error}>
        {items.map((item, i) => (
          <CardSelectorItem selected={i === localActiveIndex} key={i} onClick={() => _onChange(i)} {...item.props}>
            {item.props.children}
          </CardSelectorItem>
        ))}
      </div>
      <Visible visible={!!error}>
        <div className={classNames('has-error', C.errorComponent)} aria-invalid>
          {errorComponent}
        </div>
      </Visible>
    </div>
  );
};
