import * as React from 'react';

import * as A from 'antwerp-core-react-branding';
import classNames from 'classnames';

export interface SectionRowProperties extends React.PropsWithChildren {
  className?: string;
  style?: any;
}

/**
 * React Component SectionRow
 */
export default class SectionRow extends React.Component<SectionRowProperties, {}> {
  public render(): any {
    return (
      <section>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginBottom} className={this.className()} style={this.props.style}>
          {/*@ts-ignore*/}
          {this.props.children}
        </A.Spacing>
      </section>
    );
  }

  private className(): string {
    return classNames('row', this.props.className);
  }
}
