import { SnackBarActions } from '../actions/Snackbar.actions';
import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { SnackBarVariant } from '../../types';
import { genericErrorHandler } from './errors.sagas';

function* onSetFailure({ payload }: ReturnType<typeof SnackBarActions.setFailure>): SagaIterator {
  yield put(
    SnackBarActions.setFeedback({
      duration: 5000,
      feedback: payload.feedback,
      visitLink: payload.visitLink,
      visitLinkText: payload.visitLinkText,
      variant: SnackBarVariant.error,
    }),
  );
}

function* onSetSuccess({ payload }: ReturnType<typeof SnackBarActions.setSuccess>): SagaIterator {
  yield put(
    SnackBarActions.setFeedback({
      duration: 5000,
      feedback: payload,
      variant: SnackBarVariant.success,
    }),
  );
}

export function* snackbarSaga(): SagaIterator {
  yield takeEvery(SnackBarActions.setFailure.type, genericErrorHandler(onSetFailure));
  yield takeEvery(SnackBarActions.setSuccess.type, genericErrorHandler(onSetSuccess));
}
