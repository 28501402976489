import { Flyout, InputProperties, Sizes } from 'antwerp-core-react-branding';
import classNames from 'classnames';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Visible } from '../../common/layout/Visible.component';
import { createUseStyles } from 'react-jss';
import { STRING_MAX_LENGTH } from '../../../common/constants';
import { TextInput } from '../../atoms';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { translate } from '../../../translations/translate';

type Event = React.SyntheticEvent<HTMLInputElement>;
type KeyEvent = React.KeyboardEvent<HTMLInputElement>;

export type AutoCompleteProperties = {
  filterOnType?: boolean;
  onSelectOption?: (value: string) => void;
  options: IOption[];
  validationRules?: RegisterOptions;
  updateSearchTextWhenInputOrOptionsChange?: boolean; // set this to true to fix AS-9145, SGW-888, but needs to be false to fix AS-9258
} & InputProperties<string>;

const useStyles = createUseStyles({
  options: {
    maxHeight: '400px',
    overflow: 'auto',
  },
});

export interface IOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export const Autocomplete: FC<AutoCompleteProperties> = ({
  filterOnType,
  onSelectOption,
  options,
  errorComponent,
  validationRules,
  updateSearchTextWhenInputOrOptionsChange = false,
  ...inputProps
}) => {
  const [inputValue, setInputValue] = useState(inputProps.value || '');
  const inputLabel = useMemo(
    () => options.find(({ value }) => value === inputValue)?.label || inputValue,
    [inputValue, options],
  );
  const [searchText, setSearchText] = useState(inputLabel);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [visible, setVisible] = useState(false);
  const C = useStyles();
  const optionContainsTheInput = useMemo(
    () => options.some(({ label }) => label.includes(searchText)),
    [searchText, options],
  );
  const inputValueDependency = updateSearchTextWhenInputOrOptionsChange ? inputValue : '';

  useEffect(() => {
    if (inputProps.value) {
      setInputValue(inputProps.value);
    }
    if (options.some(({ value }) => value === inputValue)) {
      setSearchText(inputLabel);
    }
    if (!options.length) {
      setSearchText('');
    }
    if (inputProps.value === '') {
      setInputValue(inputProps.value);
      setSearchText('');
    }
    // do not add inputValue to the dependency array, it breaks the autocomplete when inputting numbers (see AS-9145, SGW-888)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, inputValueDependency]);

  const onClick = useCallback(() => setVisible(!visible), [visible, setVisible]);

  const renderOptions = (): JSX.Element => (
    <ul className={classNames('m-selectable-list', 'm-selectable-list--no-border', C.options)}>
      {getOptions().map(({ label }, i) => (
        <li key={i}>
          <div
            tabIndex={0}
            className={classNames('autocomplete-item', 'm-selectable-list__item', i === selectedIndex ? 'hovered' : '')}
            onClick={() => _onSelectOption(i)}
          >
            {label}
          </div>
        </li>
      ))}
    </ul>
  );

  const onKeyDown: (e: KeyEvent) => void = (e: KeyEvent) => {
    if (e.key === 'Enter' && filterOnType) {
      _onSelectOption();
      setVisible(false);
    }
    if (e.key === 'ArrowDown' && filterOnType) {
      setSelectedIndex(Math.min(selectedIndex + 1, getOptions().length - 1));
    }
    if (e.key === 'ArrowUp' && filterOnType) {
      setSelectedIndex(Math.max(selectedIndex - 1, 0));
    }
    if (e.key === 'Escape') {
      setVisible(false);
    }
  };

  const onChange: (e: Event) => void = (e: Event) => {
    setSearchText(e.currentTarget.value || '');
    setInputValue(e.currentTarget.value || '');
    setSelectedIndex(-1);
    setVisible(true);
    inputProps.onChange?.(e);
  };

  const _onSelectOption = (index?: number): void => {
    const newIndex = index || (selectedIndex > -1 ? selectedIndex : 0);
    const newInputValue = getOptions()[newIndex].value;
    setInputValue(newInputValue);
    setSelectedIndex(newIndex);
    onSelectOption?.(getOptions()[newIndex].value);
    setSearchText(options.find(({ value }) => value === newInputValue)?.label || newInputValue);
    setVisible(false);
  };

  const hasOptions = (): boolean => getOptions().length > 0;

  const getOptions = (): IOption[] => {
    let sortedOptions = (options || []).sort();
    if (filterOnType && inputValue) {
      // Filter the options and remove duplicates
      sortedOptions = sortedOptions.filter(
        (option, index, self) =>
          option.label.toLowerCase().includes(searchText.trim().toLowerCase()) &&
          index === self.findIndex(({ value }) => value === option.value),
      );
    }
    return sortedOptions;
  };

  return (
    <>
      <Flyout
        content={hasOptions() ? renderOptions() : undefined}
        label={
          <TextInput
            {...inputProps}
            meta={{
              ...inputProps.meta,
              error: inputProps?.meta?.error || !optionContainsTheInput,
            }}
            value={searchText}
            onChange={onChange}
            onKeyDown={onKeyDown}
            maxLength={STRING_MAX_LENGTH}
            onClick={onClick}
            customizeOnBlur
            onBlur={(e) => {
              // @ts-ignore
              if (!e.relatedTarget?.classList.contains('autocomplete-item')) {
                setVisible(false);
              }
              inputProps.onBlur?.(e);
            }}
            autoComplete="off"
            aria-label={
              inputProps.required
                ? translate('fields.labelRequiredBrackets', { label: inputProps.label })
                : `${inputProps.label}`
            }
            validationRules={validationRules}
          />
        }
        size={Sizes.Default}
        visible={visible}
      />
      <Visible visible={!!inputProps.meta?.error}>{errorComponent as React.ReactNode}</Visible>
    </>
  );
};
