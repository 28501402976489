import { call, Effect, put, race, select, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { createErrorAlertAction } from '../../common/actions/creators/alert';
import { CONFIRM_OK } from '../../common/actions/types';
import {
  getApplicationFeatures,
  getExchangeToken,
  getLogoutUrl,
  getOAuthURL,
  getProfile,
  getRefreshToken,
} from '../../common/api';
import { APP_LINK_INFOFICHE_PARKINGBAN, appUrls, STORE_OAUTH_REFRESH } from '../../common/constants';
import debug from '../../common/debug';
import { createProcessIdleAction, createProcessStartedAction } from '../actions/creators';
import { IApplicationFeatures, ICall, IProfile, ITokenResponse } from '../../types';
import { selectBusy, selectProfile } from '../selectors';
import { SagaIterator } from 'redux-saga';
import { ENVIRONMENT, Environment, IS_DEVELOPMENT, SENTRY_DSN, VERSION } from '../../config/environment';
import intl from 'react-intl-universal';
import { getLocalStorage, removeLocalStorage, storeLocalStorage } from '../../common/utils/localStorageUtils';
import { getLocalUrl } from '../../common/utils/urlUtils';
import { ApplicationActions } from '../actions/workflow.actions';
import { IGenericAction } from '../actions/actions';
import {
  cleanHash,
  getAccessToken,
  getOriginalPathAndRemove,
  hasCancelToken,
  hasHashToken,
  hashToken,
  removeTokenData,
  setupAxiosAuthorization,
  storeOriginalPath,
  storeTokenResponse,
} from '../../common/utils/token.utils';
import axios, { AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';

export function* redirectIfUnauthorized(e: any): IterableIterator<Effect> {
  if (e.response?.status === 401) {
    yield put(createErrorAlertAction(intl.get('error.session_expired')));

    const { ok } = (yield race({
      ok: take(CONFIRM_OK),
    }))!;

    if (ok) {
      removeTokenData();
      goToAStadLogin();
    }
  }
}

function* login(): SagaIterator {
  if ([getLocalUrl(appUrls.driverPrivacyPolicy), getLocalUrl(appUrls.token)].includes(window.location.pathname)) {
    return;
  }

  // Check the login token
  const token = getAccessToken();

  // Check if there is a cancel token
  if (hasCancelToken()) {
    window.location.href = APP_LINK_INFOFICHE_PARKINGBAN;
    return;
  }
  const applicationFeatures: IApplicationFeatures = yield call(getApplicationFeatures);
  yield put(ApplicationActions.applicationFeatures(applicationFeatures));

  if (applicationFeatures.sentryLoggingFrontoffice && !IS_DEVELOPMENT) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration()],
      environment: ENVIRONMENT,
      release: VERSION,
      normalizeDepth: 10,
      tracesSampleRate: 1.0,
    });
  }
  if (!token) {
    // Redirect to the login
    if (hasHashToken()) {
      yield call(exchangeToken, hashToken());
    } else {
      const busy = yield select(selectBusy);
      if (window && !busy) {
        // Store the original path
        storeOriginalPath(window.location.pathname);

        // Jump to the A-Stad login
        goToAStadLogin();
        return;
      } else {
        debug('App is running outside a window context');
      }
    }
  }
  setupAxiosAuthorization();
  yield put(ApplicationActions.authenticated());
}

function* exchangeToken(token: string): IterableIterator<Effect> {
  const process: string = 'exchangeToken';
  yield put(createProcessStartedAction(process));

  try {
    // Clean the hash
    cleanHash();
    // Exchange the refresh token
    const response: ICall<typeof getExchangeToken> = yield call(getExchangeToken, token);
    // Process the response
    yield put(ApplicationActions.tokenReceived(response!));
  } catch (e) {
    debug(e);
    yield put(createErrorAlertAction('Gebruiker kan niet aanmelden'));
  } finally {
    yield put(createProcessIdleAction(process));
  }
}

function* refreshToken(action: IGenericAction<AxiosRequestConfig>): SagaIterator {
  const refreshToken = getLocalStorage<string>(STORE_OAUTH_REFRESH);
  if (refreshToken) {
    const process: string = 'refreshToken';
    yield put(createProcessStartedAction(process));
    try {
      const newResponse: ICall<typeof getRefreshToken> = yield call(getRefreshToken, refreshToken);
      storeTokenResponse(newResponse!);
      const originalRequest = action.payload;
      originalRequest.headers!['Authorization'] = `Bearer ${newResponse!.access_token}`;
      yield call(axios.request, originalRequest);
      yield call(restorePath);
    } catch (e) {
      debug('Refresh failed', e);
      yield put(ApplicationActions.logout());
    } finally {
      yield put(createProcessIdleAction(process));
    }
  }
}

function* tokenReceived(action: IGenericAction<ITokenResponse>): SagaIterator {
  // Store the refreshtoken into the sessionStorage and configure axios
  storeTokenResponse(action.payload);

  // Get the original url and push it to the history
  yield call(restorePath);

  // Send out the authenticated message
  yield put(ApplicationActions.authenticated());
}

function* authenticated(): IterableIterator<Effect> {
  const process: string = 'authenticated';
  yield put(createProcessStartedAction(process));

  try {
    const profile: ICall<typeof getProfile> = yield call(getProfile);
    yield put(ApplicationActions.profileReceived(profile!));
    redirectUri();
  } catch (e) {
    yield put(ApplicationActions.logout());
  } finally {
    yield put(createProcessIdleAction(process));
  }
}

function* logout() {
  const token = getAccessToken();
  const profile: IProfile = yield select(selectProfile);

  const process: string = 'logout';

  if (token && profile.sourceId) {
    yield put(createProcessStartedAction(process));

    const redirectUri = Environment.oauthParamsAntwerpen.redirectUri;
    const logoutUrlResponse: ICall<typeof getLogoutUrl> = yield call(getLogoutUrl, redirectUri);
    const url = logoutUrlResponse?.url;

    if (url) {
      window.location.href = url;
    }
    yield put(createProcessIdleAction(process));
  } else {
    window.location.href = Environment.baseApiIUrl;
  }

  removeTokenData();
}

function restorePath() {
  const path = getOriginalPathAndRemove();
  if (path && path.length > 2) {
    window.location.pathname = path;
  } else {
    if ((window.location && !window.location.pathname) || window.location.pathname === getLocalUrl(appUrls.token)) {
      window.location.pathname = getLocalUrl();
    }
  }
}

export function goToAStadLogin(): void {
  storeLocalStorage('redirectUri', window.location.href);
  window.location.href = getOAuthURL();
}

export const redirectUri = () => {
  const url = getLocalStorage('redirectUri') as string;
  if (url) {
    window.location.href = url;
    removeLocalStorage('redirectUri');
  }
};

export default function* loginSaga(): SagaIterator {
  yield takeEvery(ApplicationActions.authenticated, authenticated);
  yield takeLeading([ApplicationActions.init, ApplicationActions.login], login);
  yield takeEvery(ApplicationActions.logout, logout);
  yield takeEvery(ApplicationActions.refreshToken, refreshToken);
  yield takeEvery(ApplicationActions.tokenReceived.type, tokenReceived);
}
