import IState from '../../../state';
import { createSelector } from '@reduxjs/toolkit';

export const selectProjects = (state: IState) => state.sgw?.project?.projects;

export const getProjectOptions = createSelector([selectProjects], (projects) => {
  return projects
    ? projects.filter(({ deleted }) => !deleted).map(({ id, name }) => ({ label: name, value: `${id}` }))
    : [];
});
