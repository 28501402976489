import './hero.scss';
import React, { FC, useCallback } from 'react';
import * as A from 'antwerp-core-react-branding';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { selectStep } from '../../../store/selectors';
import classnames from 'classnames';
import { Environment } from '../../../config/environment';
import { getUrl } from '../../../common/utils/urlUtils';
import { HeroComponent } from '../header/Hero.component';
import { appUrls } from '../../../common/constants';
import { translate, translateHtml, translateIgnoreTS } from '../../../translations/translate';

export const Hero: FC = () => {
  const location = useLocation();
  const step = useSelector(selectStep);

  const routeIsMyRequests = useCallback(() => location.pathname.includes(appUrls.myRequests.base), [location.pathname]);

  const rootClassNames = useCallback(
    () =>
      classnames(
        'mobile-no-hero-items',
        { 'small-subtitle': routeIsMyRequests() },
        { 'remove-aui-hero-line': !routeIsMyRequests() && step !== 1 },
      ),
    [routeIsMyRequests, step],
  );

  const getSubTitle = useCallback(
    () =>
      routeIsMyRequests()
        ? translateHtml('myRequests.subtitle', { link: getUrl(Environment.baseFrontEndUrl, appUrls.projectType) })
        : translate('general.heroSubTitle'),
    [routeIsMyRequests],
  );

  return (
    <div className={rootClassNames()}>
      <HeroComponent
        title={translateIgnoreTS(`${routeIsMyRequests() ? 'myRequests.title' : 'general.newRequestTitle'}`)}
        subtitle={getSubTitle()}
      >
        {/*@ts-ignore*/}
        <A.HeroItem
          title={translate('newrequest.title')}
          href={getUrl(Environment.baseFrontEndUrl, appUrls.projectType)}
        />
        {/*@ts-ignore*/}
        <A.HeroItem
          title={translate('myRequests.title')}
          href={getUrl(Environment.baseFrontEndUrl, appUrls.myRequests.base)}
        />
      </HeroComponent>
    </div>
  );
};
