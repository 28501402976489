import './stateTimeLine.scss';

import * as React from 'react';

import * as A from 'antwerp-core-react-branding';
import classNames from 'classnames';

import { formatToShortDisplayDate } from '../../../common/utils/dateUtils';
import { OverviewSection } from './overviewElements';
import { FC } from 'react';
import { Visible } from '../layout/Visible.component';
import { useSelector } from 'react-redux';
import { getMapTimeLineItems, selectRequest } from '../../../store/selectors';
import { translate } from '../../../translations/translate';

export const StateTimeLine: FC = () => {
  const request = useSelector(selectRequest);
  const mapTimeLineItems = useSelector(getMapTimeLineItems);

  return (
    <Visible visible={!!request?.extensions?.length}>
      <OverviewSection>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.NoMargin} className="row between-md">
          <h6>{translate('myRequests.details.timelineTitle')}</h6>
        </A.Spacing>
        {/*@ts-ignore*/}
        <A.Paragraph spacing className="state-timeline__description">
          {translate('myRequests.details.timeline')}
        </A.Paragraph>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.NoMargin} className="row">
          <div className="state-timeline">
            {mapTimeLineItems.map(({ width, state, id }) => (
              <div
                className={classNames(
                  'state-timeline__item',
                  'state-timeline__item--state',
                  `state-timeline__item--${state.state}`,
                )}
                key={`states-${id}`}
                style={{ width: `${width}%` }}
                title={state.name}
              >
                <span className="state-timeline__item__label">{state.name}</span>
              </div>
            ))}
          </div>
        </A.Spacing>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.NoMargin} className="row">
          <div className="state-timeline">
            {mapTimeLineItems.map(({ width, state, dateUntil, id }) => (
              <div
                className={classNames('state-timeline__item', 'state-timeline__item--label')}
                key={`dates-${id}`}
                style={{ width: `${width}%` }}
                title={state.name}
              >
                <span className="state-timeline__item__date">{formatToShortDisplayDate(dateUntil)}</span>
              </div>
            ))}
          </div>
        </A.Spacing>
      </OverviewSection>
    </Visible>
  );
};
