import { useForm } from 'react-hook-form';
import { ISgwRequest, TRouterWithId, WorkType } from '../types';
import { useCallback, useEffect, useState } from 'react';
import { SgwRequestActions } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getInitialSgwValues, getRequest } from '../store/selectors/sgw';

export const useSgwRequestForm = (step: number) => {
  const dispatch = useDispatch();
  const { id } = useParams<keyof TRouterWithId>();
  const defaultValues = useSelector(getInitialSgwValues(id));
  const [isFormReady, setIsFormReady] = useState<boolean>(!id || !!defaultValues?.id);
  const request = useSelector(getRequest(id));
  const formMethods = useForm<ISgwRequest>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    // have to make a copy of the defaultValues, because useForm will update the provided object
    defaultValues: { ...defaultValues },
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const {
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    if (!isFormReady) {
      formMethods.reset({ ...defaultValues });
      setIsFormReady(!id || !!defaultValues?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, id]);

  useEffect(() => {
    // This is needed to make sure that after refresh or manual go to previous step that the field values are not
    // reset to undefined
    Object.keys(defaultValues).forEach((key) => {
      formMethods.setValue(key as keyof ISgwRequest, defaultValues[key as keyof ISgwRequest]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMethods, isFormReady]);

  useEffect(() => {
    if (id) {
      dispatch(SgwRequestActions.fetch(id));
      dispatch(SgwRequestActions.attachments.fetch(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    formMethods.setValue('initialFile', defaultValues.initialFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues.initialFile, formMethods.setValue]);

  const _onSubmit = useCallback(
    (values: Partial<ISgwRequest>) => {
      const { workCraneNeeded, bonuNumber, gipodId, workType, mainLocation, ...valuesToSave } = values;
      if (!Object.keys(errors).length) {
        dispatch(
          SgwRequestActions.save({
            step,
            request: {
              ...defaultValues,
              ...valuesToSave,
              workType,
              mainLocation: mainLocation
                ? {
                    ...mainLocation,
                    streetNumberFrom: mainLocation.streetNumberUnknown ? '0' : mainLocation.streetNumberFrom,
                    streetNumberTo: mainLocation.streetNumberUnknown ? '0' : mainLocation.streetNumberTo,
                  }
                : undefined,
              bonuNumber: workType === WorkType.GroundWorksToPublicDomain ? bonuNumber : null,
              gipodId: workType === WorkType.GroundWorksToPublicDomain ? gipodId : null,
            },
            id: id || `${defaultValues.id}`,
          }),
        );
      }
    },
    [defaultValues, dispatch, errors, step, id],
  );

  const saveReduxRequest = useCallback(() => {
    if (request && id) {
      dispatch(SgwRequestActions.save({ step, request, id }));
    }
  }, [dispatch, id, request, step]);

  const submitRequest = formMethods.handleSubmit(_onSubmit);

  return { formMethods, isFormReady, request, requestId: id, saveReduxRequest, submitRequest };
};
