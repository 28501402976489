import { IDefaultAction } from '../actions/actions';
import { PROCESS_STARTED } from '../actions/types';
import { PROCESS_IDLE } from '../actions/types';

const INITIAL_STATE: number = 0;

export default function processes(state: number = INITIAL_STATE, action: IDefaultAction): number {
  switch (action.type) {
    case PROCESS_STARTED:
      return state + 1;
    case PROCESS_IDLE:
      return state - 1;
    default:
      return state;
  }
}
