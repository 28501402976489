import { createUseStyles } from 'react-jss';
import { COLORS } from './colors.theme';

export const useFocusStyles = createUseStyles({
  before: {
    '&:focus::before': {
      zIndex: '2',
      outline: `${COLORS.lightBlue} solid 3px !important`,
    },
  },
  on: {
    '&:focus': {
      zIndex: '2',
      outline: `${COLORS.lightBlue} solid 3px !important`,
    },
  },
  within: {
    '&:focus-within': {
      zIndex: '2',
      outline: `${COLORS.lightBlue} solid 3px !important`,
    },
  },
});
