import classNames from 'classnames';
import * as React from 'react';
import { Required } from './Required.component';
import { InputTypes } from 'antwerp-core-react-branding';
import { forwardRef, InputHTMLAttributes } from 'react';
import intl from 'react-intl-universal';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  meta?: {
    error?: boolean;
    touched?: boolean;
  };
  required?: boolean;
  errorMessage?: string;
  type?: InputTypes;
}

export const FormInput = forwardRef<HTMLInputElement, IProps>(({ label, meta, errorMessage, ...props }, ref) => (
  <div className={classNames('a-input', 'is-required', meta?.touched && (meta?.error ? 'has-error' : 'has-success'))}>
    <label className="a-input__label">{label}</label>
    <div className="a-input__wrapper">
      <input
        {...props}
        aria-label={props.required ? intl.get('fields.labelRequiredBrackets', { label }) : label}
        aria-invalid={!!meta?.error && meta?.touched}
        ref={ref}
      />
    </div>
    <Required required={!!meta?.touched && !!meta?.error} message={errorMessage} />
  </div>
));
