import { IPublicDomainIntake } from '../../../../types';
import { PublicDomainType } from '../../../../common/enums';
import { RequiredStar } from '../RequiredStar.component';
import * as A from 'antwerp-core-react-branding';
import {
  APP_ALLOWED_FILE_FORMATS,
  APP_LINK_INFOFICHE_MINORCONSTRUCTION,
  APP_LINK_ONDERNEMEN,
  APP_LINK_REQUIREMENTS,
} from '../../../../common/constants';
import { urlDisplayName } from '../../../../common/utils/urlUtils';
import { Required } from '../Required.component';
import { Fieldset } from '../../../atoms';
import { FileSelect } from '../../../molecules';
import * as React from 'react';
import { FC, forwardRef, useEffect } from 'react';
import { validateAttachment } from '../../../../common/validation/validateRequiredAttachment';
import { isValid } from '../../../../common/utils/formUtils';
import { useFormContext } from 'react-hook-form';
import { CheckboxWithLabelComponent } from '../CheckboxWithLabel.component';
import { translate, translateHtml } from '../../../../translations/translate';

interface IProps {
  isGeocoding: boolean;
  onChange(e: React.SyntheticEvent<HTMLInputElement>): void;
  required?: boolean;
}

export const Requirements: FC<IProps> = forwardRef(({ isGeocoding, required = false }, ref) => {
  const { setValue, register, formState, setError, clearErrors, getValues, watch, trigger } =
    useFormContext<IPublicDomainIntake>();
  const intake = watch();
  const { errors } = formState;

  useEffect(() => {
    register('attachments', { required: required && translate('locationsform.attachmentsRequired') });
    setValue('attachments', getValues().attachments);
  }, [getValues, register, required, setValue]);

  if (intake.type.type === PublicDomainType.MinorConstruction && intake.geometry && intake.geometry.coordinates) {
    const labelAttachmentDescription = translate('typeform.attachmentdescription');
    const labelAttachmentLabel = translate('typeform.attachmentlabel');
    const labelAttachmentInfo = <>{translate('fields.attachment')}</>;

    const onChangeAttachments = (file?: File | null): void => {
      if (file === null) {
        setValue('attachments', undefined, { shouldTouch: true });
      }
      const fieldError = validateAttachment(file || undefined, required);
      if (isValid(fieldError)) {
        setValue('attachments', [file], { shouldTouch: true });
        clearErrors('attachments');
      } else {
        setError('attachments', { type: 'required', message: `${fieldError}` });
      }
      trigger();
    };

    return (
      <section className="typeform__requirements">
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginTop}>
          <h4>{translate('typeform.requirements')}</h4>
          {/*@ts-ignore*/}
          <A.Paragraph spacing>
            {translateHtml('typeform.requirementstext')}
            {translateHtml('typeform.requestconforms', {
              linkondernemen: APP_LINK_ONDERNEMEN,
              linkvoorwaarden: APP_LINK_INFOFICHE_MINORCONSTRUCTION,
              textondernemen: urlDisplayName(APP_LINK_ONDERNEMEN),
            })}
          </A.Paragraph>
        </A.Spacing>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginTop}>
          <CheckboxWithLabelComponent
            {...register('conditions', { validate: (checked) => !!checked })}
            label={
              <>
                {translateHtml('typeform.requirementslink', { link: APP_LINK_REQUIREMENTS })}
                <RequiredStar />
              </>
            }
            required={translate('locationsform.conditionsRequired')}
          />
        </A.Spacing>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginTop}>
          <Fieldset legend={labelAttachmentLabel}>
            <FileSelect
              name="attachments"
              accept={APP_ALLOWED_FILE_FORMATS}
              description={labelAttachmentDescription}
              disabled={isGeocoding}
              info={labelAttachmentInfo}
              required={required}
              // @ts-ignore
              meta={{ error: errors.attachments?.message, touched: !!errors.attachments?.message }}
              onFileSelect={(file?: File | null) => onChangeAttachments(file)}
              onDeleteAll={() => onChangeAttachments(null)}
              uploadedFiles={intake.attachments || []}
              // @ts-ignore
              errorComponent={<Required required message={errors.attachments?.message} />}
            />
          </Fieldset>
        </A.Spacing>
      </section>
    );
  }
  return null!;
});
