import { SgwRequestActions } from '../../actions';
import { IGenericAction } from '../../actions/actions';
import { IAttachment } from '../../../types';
import { mapToIAttachment, mapToIAttachments } from '../../../common/utils/sgwAttachments.utils';

export const requestAttachmentReducer = (state: IAttachment[] = [], action: IGenericAction<any>): IAttachment[] => {
  switch (action.type) {
    case SgwRequestActions.attachments.set.type: {
      return mapToIAttachments(action.payload);
    }
    case SgwRequestActions.attachments.clear.type: {
      return [];
    }
    case SgwRequestActions.attachments.add.type: {
      const newState = [...state];
      const index = state.findIndex(({ id }) => id === action.payload.id);
      if (index >= 0) {
        newState[index] = mapToIAttachment(action.payload);
      } else {
        newState.push(mapToIAttachment(action.payload));
      }
      return newState;
    }
    case SgwRequestActions.attachments.remove.type: {
      return state.filter(({ id }) => id !== action.payload.attachmentId);
    }
    default:
      return state;
  }
};
