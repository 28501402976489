import { IDefaultAction, IGenericAction } from '../../../store/actions/actions';
import { defaultAction, genericAction } from '../../../store/actions';
import { ICountry, IPublicDomainIntakeType, IRequestReason, ITenant, IMetadata } from '../../../types';
import * as T from '../types';

export function createDataLoadedAction(): IDefaultAction {
  return defaultAction(T.DATA_LOADED);
}

export function createCountriesReceivedAction(payload: ICountry[]): IGenericAction<ICountry[]> {
  return genericAction(T.COUNTRIES_RECEIVED, payload);
}

export function createTenantReceivedAction(payload: ITenant): IGenericAction<ITenant> {
  return genericAction(T.TENANT_RECEIVED, payload);
}

export function createRequestReasonsReceivedAction(payload: IRequestReason[]): IGenericAction<IRequestReason[]> {
  return genericAction(T.REQUESTREASONS_RECEIVED, payload);
}

export function createMetadataReceivedAction(payload: IMetadata): IGenericAction<IMetadata> {
  return genericAction(T.METADATA_RECEIVED, payload);
}

// tslint:disable-next-line:max-line-length
export function createPDITypesReceivedAction(
  payload: IPublicDomainIntakeType[],
): IGenericAction<IPublicDomainIntakeType[]> {
  return genericAction(T.PUBLICDOMAINTYPES_RECEIVED, payload);
}
