import moment from 'moment';
import * as React from 'react';

export type IProps = {
  date?: string | null;
};

export default class DateFormatter extends React.Component<IProps, {}> {
  public render(): JSX.Element {
    return <>{this.props.date && moment(this.props.date).format('DD/MM/YY HH:mm')}</>;
  }
}
