import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { PHASE_CREATE } from '../common/constants';
import { PhaseActions } from '../store/actions/phase.actions';
import { getInitDateFrom, getMinDateFrom, getPhase, hasDateOverlap } from '../store/selectors/sgw';
import { ISgwPhase, TRouterWithId } from '../types';

export const usePhaseForm = (id: string, index: number, copyId?: string) => {
  const dispatch = useDispatch();
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const phaseValues = useSelector(getPhase(id, copyId));
  const minDateFrom = useSelector(getMinDateFrom(index, requestId!));
  const initialDateFrom = useSelector(getInitDateFrom(index, requestId!));

  const defaultValues = useMemo(
    () => ({
      dateFrom: initialDateFrom,
      dateUntil: initialDateFrom,
      exemptedSurfaceArea: 0,
      ...phaseValues,
      sgwNatureOfWorks: phaseValues.sgwNatureOfWorks?.map((id) => `${id}`),
    }),
    [initialDateFrom, phaseValues],
  );
  const formMethods = useForm<ISgwPhase>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
    shouldUnregister: true,
  });
  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = formMethods;
  const _hasDateOverlap = useSelector(hasDateOverlap(index, requestId!));

  useEffect(() => {
    if (id !== PHASE_CREATE) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId, id, defaultValues]);

  useEffect(() => {
    dispatch(PhaseActions.formFieldsChanged(isDirty));
  }, [dispatch, isDirty]);

  const _onSubmit =
    (hideSnackbar: boolean = false, recurringPhaseTemplate: boolean = false) =>
    (phase: ISgwPhase) => {
      dispatch(
        PhaseActions.save({
          requestId: requestId!,
          hideSnackbar,
          phase: { ...defaultValues, ...phase, ...(recurringPhaseTemplate && { isRecurringPhaseTemplate: true }) },
        }),
      );
    };

  const submitPhase = (hideSnackbar: boolean = false, recurringPhaseTemplate: boolean = false) =>
    handleSubmit(_onSubmit(hideSnackbar, recurringPhaseTemplate));
  return { formMethods, submitPhase, minDateFrom, hasDateOverlap: _hasDateOverlap };
};
