import { AsignWorkflowSteps, ButtonVariant, WorkflowType } from '../../../../types';
import * as React from 'react';
import { FC } from 'react';
import { Button } from '../../../atoms';
import { submitId } from '../../../../common/utils/formUtils';
import { translate } from '../../../../translations/translate';

interface IProps {
  disabled: boolean;

  onCancel(): void;
}

export const LocationButtons: FC<IProps> = ({ disabled, onCancel }) => {
  return (
    <div className="m-modal__footer">
      <Button type="submit" form={submitId(WorkflowType.ASign, AsignWorkflowSteps.Location)} disabled={disabled}>
        {translate('general.ok')}
      </Button>
      <Button variant={ButtonVariant.Outline} onClick={onCancel}>
        {translate('general.cancel')}
      </Button>
    </div>
  );
};
