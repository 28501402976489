import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectConflictGroups, selectPhasesAllIds } from '../../../store/selectors/sgw';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { useGenericStyles } from '../../../hooks';
import { translate } from '../../../translations/translate';
import { ConflictOverview } from '../ConflictOverview/ConflictOverview.component';
import { Visible } from '../../common/layout/Visible.component';
import { TRouterWithId } from '../../../types';
import { useParams } from 'react-router-dom';
import { SgwRequestActions } from '../../../store/actions';

const useStyles = createUseStyles({
  root: {
    marginBottom: '20px',
  },
  title: {
    marginBottom: '10px',
    padding: '0 10px',
  },
});

export const ConflictsRequestInfo: FC = () => {
  const C = useStyles();
  const [phaseId, setPhaseId] = useState<string>('');
  const phasesIds = useSelector(selectPhasesAllIds);
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const dispatch = useDispatch();
  const groups = useSelector(selectConflictGroups);

  useEffect(() => {
    if (!phaseId) setPhaseId(phasesIds[0]);
  }, [phaseId, phasesIds]);

  useEffect(() => {
    dispatch(SgwRequestActions.conflicts.fetchList(requestId!));
  }, [dispatch, requestId]);

  const { flex } = useGenericStyles();

  return (
    <Visible visible={!!groups.length}>
      <div className={C.root}>
        <h5 className={C.title}>{translate('myRequests.details.conflicts')}</h5>
        <div className={classNames(flex.column, flex.gap)}>
          <ConflictOverview readOnly />
        </div>
      </div>
    </Visible>
  );
};
