import React, { FC, PropsWithChildren, useCallback } from 'react';
import { usePhaseAccordionStyles } from './PhaseAccordion.styles';
import { SgwAccordion } from '../SgwAccordion/SgwAccordion.component';
import { Icon, LabelValue } from '../../atoms';
import { Levels } from 'antwerp-core-react-branding';
import { translate } from '../../../translations/translate';
import { ISgwPhase } from '../../../types';
import { formatDateTimeToDisplayDate } from '../../../common/utils/dateUtils';
import classNames from 'classnames';
import { useGenericStyles } from '../../../hooks';
import { useAtom } from 'jotai';
import { openPhasesAtom } from '../../pages/PhasesPage/Phases.page';
import { Visible } from '../../common/layout/Visible.component';
import { PHASE_CREATE } from '../../../common/constants';
import { Tooltip } from '@mui/material';

export interface IPhaseAccordionProps extends Omit<ISgwPhase, 'id'>, PropsWithChildren {
  id: string;
  index: number;
  recurringPhase?: boolean;
  recurringPhaseTemplate?: boolean;
  onCopy?(id: string): void;
  onDelete?(id: string, index: number): void;
  onEdit?(id: string): void;
  showError?: boolean;
}

export const PhaseAccordion: FC<IPhaseAccordionProps> = ({
  children,
  index,
  recurringPhase,
  recurringPhaseTemplate,
  onCopy,
  onDelete,
  id,
  showError = false,
  phaseName,
  dateFrom,
  dateUntil,
  timeFrom,
  timeUntil,
}) => {
  const C = usePhaseAccordionStyles();
  const { flex, focus } = useGenericStyles();
  const [openPhases, setOpenPhases] = useAtom(openPhasesAtom);

  const _onCopy = useCallback(() => {
    onCopy?.(id);
  }, [id, onCopy]);

  const _onDelete = useCallback(() => {
    onDelete?.(id, index);
  }, [id, index, onDelete]);

  const onClick = () => {
    if (openPhases.includes(id)) {
      setOpenPhases((phases) => phases.filter((phaseId) => phaseId !== id));
    } else {
      setOpenPhases((phases) => phases.concat(id));
    }
  };

  return (
    <SgwAccordion
      recurringPhase={recurringPhase}
      recurringPhaseTemplate={recurringPhaseTemplate}
      icon={
        <div className={C.phase}>
          <div>{translate('sgw.phases.accordion.phase')}</div>
          <div className={C.number}>{index + 1}</div>
        </div>
      }
      header={
        <div className={C.headerContent}>
          <div className={C.headerData}>
            <Tooltip title={phaseName || translate('sgw.phases.new')} placement={'bottom-start'}>
              <div className={flex.centerVertical}>
                <div className={classNames(C.name)}>
                  <Visible visible={!recurringPhase}>{phaseName || translate('sgw.phases.new')}</Visible>
                  <Visible visible={!!recurringPhase}>{phaseName || translate('sgw.phases.newRecurringPhase')}</Visible>
                </div>
              </div>
            </Tooltip>
            <Visible visible={!recurringPhase && !recurringPhaseTemplate}>
              <LabelValue
                direction="vertical"
                label={translate('general.from')}
                className={classNames(C.dateFrom, C.visibleMd, C.invisibleXs)}
              >
                {formatDateTimeToDisplayDate(dateFrom, timeFrom)}
              </LabelValue>
              <LabelValue
                direction="vertical"
                label={translate('general.until')}
                className={classNames(C.visibleMd, C.invisibleXs)}
              >
                {formatDateTimeToDisplayDate(dateUntil, timeUntil)}
              </LabelValue>
            </Visible>
          </div>
          <div className={C.icons}>
            <Visible visible={id !== PHASE_CREATE && !!onCopy}>
              <Icon.Copy onClick={_onCopy} className={focus.within} />
            </Visible>
            <Visible visible={!!onDelete}>
              <Icon.Delete level={Levels.Secondary} onClick={_onDelete} className={focus.within} />
            </Visible>
          </div>
        </div>
      }
      index={index}
      className={classNames(showError && C.error)}
      collapsed={!openPhases.includes(id)}
      onClick={onClick}
    >
      {children}
    </SgwAccordion>
  );
};
