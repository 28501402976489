import validateRequired from './validateRequired';
import { ValidateResult } from 'react-hook-form/dist/types/validator';
import { translate } from '../../translations/translate';

export const validateSSN =
  (required: boolean = true) =>
  (value: string): ValidateResult => {
    let errorMessage;

    if (required) {
      errorMessage = validateRequired(value);
    }

    if (value && !checkSSN(value)) {
      errorMessage = translate('fields.novalidssn');
    }

    return errorMessage || true;
  };

function checkSSN(value: string) {
  // RR numbers need to be 11 chars long
  if (value.length !== 11) {
    return false;
  }

  const checkDigit = parseInt(value.substr(value.length - 2, 2), 10);
  const modFunction = (nr: number) => 97 - (nr % 97);
  let nrToCheck = parseInt(value.substr(0, 9), 10);

  // first check without 2
  if (modFunction(nrToCheck) === checkDigit) {
    return true;
  }

  // then check with 2 appended for y2k+ births
  nrToCheck = parseInt('2' + value.substr(0, 9), 10);

  return modFunction(nrToCheck) === checkDigit;
}
