import { IDefaultAction, IGenericAction } from '../../../store/actions/actions';
import { defaultAction, genericAction } from '../../../store/actions';
import { IPostMessage, IRequestMessages } from '../../../types';
import * as T from '../types';

export function createRequestMessagesGetAction(payload: string): IGenericAction<string> {
  return genericAction(T.REQUEST_MESSAGES_GET, payload);
}

export function createRequestMessagesReceivedAction(payload: IRequestMessages): IGenericAction<IRequestMessages> {
  return genericAction(T.REQUEST_MESSAGES_RECEIVED, payload);
}

export function createRequestMessagesPostAction(payload: IPostMessage): IGenericAction<IPostMessage> {
  return genericAction(T.REQUEST_MESSAGES_POST, payload);
}

export function createRequestMessagesResetAction(): IDefaultAction {
  return defaultAction(T.REQUEST_MESSAGES_RESET);
}
