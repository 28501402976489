import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

interface IProps {
  title: string;
  value: any;
}

const useStyle = createUseStyles({
  row: {
    marginBottom: '0.5rem',
    display: 'flex',
  },
  title: {
    flexBasis: '33.333333%',
    maxWidth: '33.333333%',
  },
  value: {
    flexBasis: '66.666666%',
    maxWidth: '66.666666%',
  },
});

export const MyRequestsMobileCell: FC<IProps> = ({ title, value }) => {
  const C = useStyle();
  return (
    <div className={C.row}>
      <div className={C.title}>{title}</div>
      <div className={C.value}>{value}</div>
    </div>
  );
};
