import React, { FC, PropsWithChildren, useCallback } from 'react';
import { Required } from '../../common/forms/Required.component';
import { InputProperties } from 'antwerp-core-react-branding/dist/typings/lib/atoms/form/inputProperties';
import { useGenericStyles } from '../../../hooks';
import classNames from 'classnames';
import { IWithClassName, IWithComponentName } from '../../../types';
import { Children } from '../Children/Children.component';
import { Visible } from '../../common/layout/Visible.component';
import { useRadioButtonListStyles } from '../RadioButtonList/RadioButtonList.styles';
import { CheckBox } from '../CheckBox/CheckBox.component';
import { useFormContext } from 'react-hook-form';

interface IListProps extends Omit<InputProperties<string>, 'value'>, IWithClassName {
  value?: string[];
}

interface IHeaderProps extends IWithComponentName, IWithClassName, PropsWithChildren {
  id?: string;
}

interface IItemProps extends Omit<Partial<IListProps>, 'value'>, IWithClassName, IWithComponentName {
  value: string;
  onSetItem?(value: string, isChecked: boolean): void;
  initialChecked?(value: string): boolean;
}

interface IRequiredErrorProps extends IWithComponentName, PropsWithChildren {
  show?: boolean;
}

interface CheckBoxListFC extends FC<IListProps> {
  Header: FC<IHeaderProps>;
  Item: FC<IItemProps>;
  RequiredError: FC<IRequiredErrorProps>;
}

const CheckBoxList: CheckBoxListFC = ({ children, className, id, name, value, ...props }) => {
  const { flex } = useGenericStyles();
  const C = useRadioButtonListStyles();
  const { getValues, setValue } = useFormContext();

  const onSetItem = useCallback(
    (itemValue: string, isChecked: boolean) => {
      const items: string[] = getValues(name) || [];
      if (!Array.isArray(items)) {
        return;
      }
      if (isChecked) {
        setValue(name, [...items, itemValue]);
      } else {
        setValue(
          name,
          items.filter((item) => item !== itemValue),
        );
      }
    },
    [getValues, name, setValue],
  );

  const initialChecked = useCallback(
    (value: string) => {
      return (getValues(name) || []).map((value: unknown) => `${value}`).includes(`${value}`);
    },
    [getValues, name],
  );

  return (
    <div className={classNames(flex.column, C.list, className)} id={id}>
      <Children componentNames={['CheckBoxListHeader']} id={id}>
        {children}
      </Children>
      <Children name={name} onSetItem={onSetItem} initialChecked={initialChecked} componentNames={['CheckBoxListItem']}>
        {children}
      </Children>
      <Children componentNames={['CheckBoxListRequiredError']}>{children}</Children>
    </div>
  );
};

const CheckBoxListHeader: FC<IHeaderProps> = ({ children, className, id }) => {
  const { spacing } = useGenericStyles();
  return (
    <Visible visible={!!children}>
      <label htmlFor={id} className={classNames(spacing.marginBottom.XXS, className)}>
        {children}
      </label>
    </Visible>
  );
};
CheckBoxListHeader.defaultProps = {
  componentName: 'CheckBoxListHeader',
};

const CheckBoxListItem: FC<IItemProps> = ({ className, label = '', value, name, onSetItem, initialChecked }) => {
  const { flex } = useGenericStyles();
  const checked = initialChecked?.(value) || false;

  const _onChange = useCallback(
    (isChecked: boolean) => {
      onSetItem?.(value, isChecked);
    },
    [onSetItem, value],
  );

  return (
    <div className={classNames(flex.row, flex.centerVertical, className)}>
      <CheckBox checked={checked} label={label || ''} name={name || ''} onChange={_onChange} />
    </div>
  );
};

CheckBoxListItem.defaultProps = {
  componentName: 'CheckBoxListItem',
};

const CheckBoxListRequiredError: FC<IRequiredErrorProps> = ({ children, show = false }) => (
  <Required required={show} message={typeof children === 'string' ? children : undefined} />
);
CheckBoxListRequiredError.defaultProps = {
  componentName: 'CheckBoxListRequiredError',
};

CheckBoxList.Header = CheckBoxListHeader;
CheckBoxList.Item = CheckBoxListItem;
CheckBoxList.RequiredError = CheckBoxListRequiredError;
export { CheckBoxList };
