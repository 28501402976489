import { EditMap } from './EditMap.component';
import { FC, useState } from 'react';
import { LatLng, latLngBounds } from 'leaflet';
import { IEditMapPolygonProps } from '../../../types';
import { Polygon } from './features/Polygon.component';
import {
  geometryHasIntersections,
  geometryHasPointsCloseTogether,
  getLatLng3D,
  getLatLngTuple3DReversed,
} from '../../../common/utils/mapUtils';
import { translate } from '../../../translations/translate';

export const EditMapMinorConstruction: FC<IEditMapPolygonProps> = ({ conflicts, geometry, onChange, onError }) => {
  const [latLngs, setLatLngs] = useState<LatLng[][]>(getLatLng3D(geometry.coordinates));
  const onDragEnd = (coords: LatLng[][]) => {
    const coordinates = getLatLngTuple3DReversed(coords);
    if (geometryHasPointsCloseTogether(coordinates[0])) {
      onError?.(translate('locationsform.markerstooclose'));
      setLatLngs(getLatLng3D(geometry.coordinates));
    } else if (geometryHasIntersections(coordinates[0])) {
      onError?.(translate('locationsform.invalidgeometry'));
      setLatLngs(getLatLng3D(geometry.coordinates));
    } else {
      onChange?.({
        geometry: {
          ...geometry,
          // @ts-ignore
          coordinates,
        },
      });
    }
  };

  return (
    <EditMap
      bounds={latLngBounds(latLngs[0])}
      conflicts={conflicts}
      extraLayers={!!latLngs ? <Polygon draggable onDragEnd={onDragEnd} positions={latLngs} /> : null}
    />
  );
};
