import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { SgwRequestActions } from '../actions';
import { HttpStatusCodes, ICall, IRequestMessageAttachment, ISelect } from '../../types';
import { genericErrorHandler } from './errors.sagas';
import { SgwRequestApi } from '../api';
import { selectMessageAttachments } from '../selectors/sgw/messages.selectors';

function* onFetchMessages({ payload }: ReturnType<typeof SgwRequestActions.messages.fetch>): SagaIterator {
  const response: ICall<typeof SgwRequestApi.fetchMessages> = yield call(
    SgwRequestApi.fetchMessages,
    payload.requestId,
  );
  yield put(SgwRequestActions.messages.set(response!.data.data));
}

function* onCreateMessage({ payload }: ReturnType<typeof SgwRequestActions.messages.create>): SagaIterator {
  yield call(SgwRequestApi.createMessage, payload);
  yield put(SgwRequestActions.messages.setAttachment([]));
  yield put(SgwRequestActions.messages.fetch({ requestId: payload.requestId }));
}

function* onUploadMessageAttachment({
  payload,
}: ReturnType<typeof SgwRequestActions.messages.uploadAttachment>): SagaIterator {
  const attachments: ISelect<typeof selectMessageAttachments> = yield select(selectMessageAttachments);
  const tempAttachment: Partial<IRequestMessageAttachment> = { loading: true, name: payload.name };
  if (attachments) {
    yield put(SgwRequestActions.messages.setAttachment([...attachments, tempAttachment]));

    const formData = new FormData();
    formData.append('file', payload, payload.name);
    try {
      const response: ICall<typeof SgwRequestApi.saveFile> = yield call(SgwRequestApi.saveFile, formData);
      if (response?.status === HttpStatusCodes.OK) {
        const newAttachment: IRequestMessageAttachment = {
          id: response?.data.data[0].id,
          loading: false,
          name: payload.name,
          url: response?.data.data[0].url,
        };
        yield put(SgwRequestActions.messages.setAttachment([...attachments, newAttachment]));
      }
    } catch (e) {
      SgwRequestActions.messages.setAttachment(attachments);
    }
  }
}

export default function* sgwRequestsMessagesSagas(): SagaIterator {
  yield takeEvery(SgwRequestActions.messages.fetch.type, genericErrorHandler(onFetchMessages));
  yield takeEvery(SgwRequestActions.messages.create.type, genericErrorHandler(onCreateMessage));
  yield takeEvery(SgwRequestActions.messages.uploadAttachment, genericErrorHandler(onUploadMessageAttachment));
}
