import './ZoomControl.component.scss';
import React, { createRef, FC, useCallback, useEffect } from 'react';
import * as A from 'antwerp-core-react-branding';
import { useMap } from 'react-leaflet';
import { DomEvent } from 'leaflet';

export const ZoomControl: FC = () => {
  const map = useMap();
  const ref = createRef<HTMLDivElement>();
  const zoomIn = useCallback(() => map.zoomIn(1), [map]);
  const zoomOut = useCallback(() => map.zoomOut(1), [map]);
  useEffect(() => {
    if (ref.current) {
      DomEvent.disableClickPropagation(ref.current);
    }
  }, [ref]);
  return (
    <div ref={ref} className="leaflet__editmap__zoomcontrol" aria-hidden>
      {/*@ts-ignore*/}
      <A.ButtonGroup>
        {/*@ts-ignore*/}
        <A.IconButton icon="plus" onClick={zoomIn} tabIndex={-1} />
        {/*@ts-ignore*/}
        <A.IconButton icon="minus" onClick={zoomOut} tabIndex={-1} />
      </A.ButtonGroup>
    </div>
  );
};
