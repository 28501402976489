import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import rootReducer from '../reducers';
import rootSaga from '../sagas/index';
import { captureException, createReduxEnhancer } from '@sentry/react';
import { appUrls } from '../../common/constants';
import { IS_PRODUCTION } from '../../config/environment';
import { getLocalUrl } from '../../common/utils/urlUtils';

const historyListener = () => createBrowserHistory();
export const history = historyListener();

// Create the saga middleware, we need it again below
const sagaMiddleware: any = createSagaMiddleware({
  onError: (error) => {
    captureException(new Error(error.message));
    window.location.href = getLocalUrl(appUrls.error);
  },
});

//state send to sentry is currently set to null, can be adjusted later. Be careful with sensitive data!
const sentryReduxEnhancer = createReduxEnhancer({
  stateTransformer: (state) => (IS_PRODUCTION ? null : state),
});

// Build the middleware for intercepting and dispatching navigation actions
const routerMiddlewareConfig = routerMiddleware(history);

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store: Store<any> = createStore(
  rootReducer(history),
  composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddlewareConfig), sentryReduxEnhancer),
);

// Hook up the saga middleware
sagaMiddleware.run(rootSaga);
