import { FC, useEffect } from 'react';
import { Autocomplete } from '../../../molecules';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { ISgwRequest } from '../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectOptions } from '../../../../store/selectors/sgw';
import { SgwRequestActions } from '../../../../store/actions';
import intl from 'react-intl-universal';
import { createUseStyles } from 'react-jss';
import { TextArea } from '../../../common/forms/TextArea.component';
import { Fieldset } from '../../../atoms';

const useStyles = createUseStyles({
  root: {
    marginTop: '30px',
  },
});

export const DescriptionFields: FC = () => {
  const { register, setValue, formState, watch } = useFormContext<ISgwRequest>();
  const { errors, touchedFields } = formState;
  const projects = useSelector(getProjectOptions);
  const dispatch = useDispatch();
  const C = useStyles();
  const sgwProject = watch('sgwProject');

  useEffect(() => {
    dispatch(SgwRequestActions.fetchProjects());
  }, [dispatch]);

  return (
    <Fieldset legend={intl.get('sgw.workType.description.legend')} noBottomBorder>
      <TextArea
        ariaLabel={intl.get('sgw.workType.description.label')}
        label={intl.get('sgw.workType.description.label')}
        TextAreaProps={{ ...register('workDescription', { required: true }), maxLength: 1500 }}
        meta={{
          error: !!errors.workDescription,
          touched: !!touchedFields.workDescription || !!errors.workDescription,
        }}
        required
      />
      <div className={C.root}>
        <Autocomplete
          placeholder={intl.get('sgw.workType.description.projectType.placeholder')}
          options={projects}
          {...register('sgwProject')}
          value={sgwProject}
          label={intl.get('sgw.workType.description.projectType.label')}
          filterOnType
          onSelectOption={(value) => setValue('sgwProject', value)}
        />
      </div>
    </Fieldset>
  );
};
