import React, { FC } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { WorkflowTemplate } from '../../templates/WorkflowTemplate/WorkflowTemplate.component';
import { PartnerType, SgwWorkflowStep } from '../../../types';
import { translate } from '../../../translations/translate';
import { Fieldset } from '../../atoms';
import { PartnerAccordion } from '../../molecules';
import { PartnerForm } from '../../forms/sgw';
import { useGenericStyles, useSgwRequestForm } from '../../../hooks';
import { getAllPartnersAddedToRequest, getRequestPartnersId } from '../../../store/selectors/sgw';
import { Visible } from '../../common/layout/Visible.component';
import { InvalidSgwRequestStartDateAlert } from '../../organisms';

export const PartnersPage: FC = () => {
  const step = SgwWorkflowStep.Partners;
  const { request, requestId, saveReduxRequest } = useSgwRequestForm(step);
  const retributionRequired = request?.acl.sgwRetributionRequired;
  const allPartnersAdded = useSelector(getAllPartnersAddedToRequest(requestId, retributionRequired));
  const requestPartners = useSelector(getRequestPartnersId(requestId!));
  const { flex, spacing } = useGenericStyles();
  const partners = [
    PartnerType.client,
    PartnerType.signalisation,
    PartnerType.contractor,
    ...(retributionRequired ? [PartnerType.fee] : []),
  ];

  return (
    <WorkflowTemplate
      onNext={saveReduxRequest}
      title={translate('sgw.partners.title')}
      step={SgwWorkflowStep.Partners}
      nextDisabled={!allPartnersAdded}
    >
      <InvalidSgwRequestStartDateAlert />
      <Fieldset legend={translate('sgw.partners.title')} noBottomBorder>
        <div className={spacing.marginBottom.XL}>{translate('sgw.partners.description')}</div>
        <div className={classNames(flex.column, flex.gap)}>
          <Visible visible={!!request}>
            {partners.map((variant) => (
              <PartnerAccordion requestId={requestId!} variant={variant} key={variant}>
                <PartnerForm partnerId={requestPartners[variant]} variant={variant} />
              </PartnerAccordion>
            ))}
          </Visible>
        </div>
      </Fieldset>
    </WorkflowTemplate>
  );
};
