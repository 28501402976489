import * as React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FC } from 'react';
import { Visible } from '../../common/layout/Visible.component';
import { Dialog, DialogProps, IconButton } from '@mui/material';
import { useGenericStyles } from '../../../hooks';
import { COLORS } from '../../../theme';
import { translate } from '../../../translations/translate';

interface IProps extends React.PropsWithChildren {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  title: string;
  confirmButtonText?: string;
  discardButtonText?: string;
  description?: string;
  size?: DialogProps['maxWidth'];
  form?: string;
}

export const Modal: FC<IProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
  children,
  discardButtonText,
  confirmButtonText,
  size = 'md',
  form,
}) => {
  const G = useGenericStyles();

  return (
    <Dialog maxWidth={size} open={open} onClose={onClose}>
      <DialogTitle className={G.flex.spaceBetween}>
        <div>{title}</div>
        <IconButton onClick={onClose}>
          <i className="fa fa-close"></i>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Visible visible={!!description}>
          <DialogContentText className={G.spacing.marginBottom.MD}>{description}</DialogContentText>
        </Visible>
        {children}
      </DialogContent>
      <DialogActions sx={{ padding: '15px' }}>
        <Button
          variant="text"
          startIcon={<i className="fa fa-close"></i>}
          onClick={onClose}
          sx={{ color: COLORS.borderGrey }}
        >
          {discardButtonText ? discardButtonText : translate('general.cancel')}
        </Button>
        <Button
          variant="contained"
          startIcon={<i className="fa fa-check"></i>}
          onClick={onConfirm}
          type="submit"
          form={form}
        >
          {confirmButtonText ? confirmButtonText : translate('general.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
