import { put, select, takeEvery } from 'redux-saga/effects';

import { createBusyChangedAction } from '../actions/creators';
import { PROCESS_IDLE, PROCESS_STARTED } from '../actions/types';
import { SagaIterator } from 'redux-saga';
import { selectBusy, selectProcesses } from '../selectors';

/**
 * In this app we don't work with a busy state parameter containing only a boolean. Instead,
 * we work with two actions:
 * - createProcessStartedAction
 * - createProcessIdleAction
 *
 * This increments or decrements the processes property in the state. This saga then checks
 * whether a process is running and reflects it to the general busy flag in the state. As
 * such, multiple processes can occur concurrently.
 */
export function* checkBusyChanged(): SagaIterator {
  // Get the current language
  const processes = yield select(selectProcesses);
  const currentValue = yield select(selectBusy);

  const newValue = processes! > 0;

  if (currentValue !== newValue) {
    yield put(createBusyChangedAction(newValue));
  }
}

/**
 * Saga watcher
 */
export default function* busySaga(): SagaIterator {
  yield takeEvery([PROCESS_STARTED, PROCESS_IDLE], checkBusyChanged);
}
