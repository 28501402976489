import { IChangePDITypeAction, IDefaultAction, IGenericAction } from '../../../store/actions/actions';
import { defaultAction, genericAction, publicDomainIntakeAction } from '../../../store/actions';
import { IPublicDomainIntakeType, IRequest, IRequestExtension, IRequestFile } from '../../../types';
import * as T from '../types';

export function createRequestReceivedAction(payload: IRequest): IGenericAction<IRequest> {
  return genericAction(T.REQUEST_RECEIVED, payload);
}

export function createPersistRequestAction(payload: IRequest): IGenericAction<IRequest> {
  return genericAction(T.PERSIST_REQUEST_REQUESTED, payload);
}

export function createRequestCreatedAction(payload: IRequest): IGenericAction<IRequest> {
  return genericAction(T.REQUEST_CREATED, payload);
}

export function createRequestRequestedAction(payload: string): IGenericAction<string> {
  return genericAction(T.REQUEST_REQUESTED, payload);
}

export function createDeletePDIAction(payload: IRequest): IGenericAction<IRequest> {
  return genericAction(T.REQUEST_DELETE_PDI, payload);
}

export function createDraftsReceivedAction(payload: IRequest[]): IGenericAction<IRequest[]> {
  return genericAction(T.MYREQUESTS_RECEIVED, payload);
}

export function createSaveAttachmentToPDIAction(payload: FileList): IGenericAction<FileList> {
  return genericAction(T.PDI_SAVE_ATTACHMENT_REQUESTED, payload);
}

export function createAttachmentSavedToPDIAction(payload: File | IRequestFile): IGenericAction<File> {
  return genericAction(T.PDI_ATTACHMENT_SAVED, payload);
}

export function createRequestUpdatedAction(payload: IRequest): IGenericAction<IRequest> {
  return genericAction(T.REQUEST_UPDATED, payload);
}

export function createDeleteTempRequestAction(): IDefaultAction {
  return defaultAction(T.REQUEST_TEMP_DELETE);
}

export function createDeleteDraftRequestAction(payload: IRequest): IGenericAction<IRequest> {
  return genericAction(T.REQUEST_DELETE, payload);
}

export function createClearRequestAction(): IDefaultAction {
  return defaultAction(T.CLEAR_REQUEST);
}

export function createExtensionSaveRequestedAction(
  payload: Partial<IRequestExtension>,
): IGenericAction<Partial<IRequestExtension>> {
  return genericAction(T.REQUEST_EXTENSION_SAVE_REQUESTED, payload);
}

export function createNewRequestAction(): IDefaultAction {
  return defaultAction(T.REQUEST_CREATE_NEW);
}

export function createResetPublicDomainIntakeAction(
  payload: IRequest,
  index: number,
  type: IPublicDomainIntakeType,
): IChangePDITypeAction<IRequest, number, IPublicDomainIntakeType> {
  return publicDomainIntakeAction(T.REQUEST_RESET_PDI, payload, index, type);
}
