import { Capacity } from './SgwRequest.types';
import { IContactPerson, IPartnerContactPerson, IPerson } from './person.types';
import { ICompany } from './company.types';
import { IAddress, IAddressBE } from './address.types';
import { IWithId } from './generic.types';

export enum PartnerType {
  client = 'client',
  contractor = 'contractor',
  fee = 'fee',
  requester = 'requester',
  signalisation = 'signalisation',
}

export interface IPartnerHeaderProps {
  sameAsContractor: boolean;
  sameAsRequestor: boolean;
  showSameAsContractor: boolean;
}

interface IPartnerBase extends IWithId, IPerson, ICompany {
  capacity: Capacity;
}

export interface IPartner extends IPartnerBase, IAddress, IContactPerson {}

export interface IPartnerBE extends IPartnerBase, IAddressBE, IPartnerContactPerson {}

export interface IWithPartnerVariant {
  variant: PartnerType;
}

export interface ISaveRequestPartner extends IWithPartnerVariant {
  partner: IPartner;
  requestId?: string;
}
