import { Actions } from './generic.actions';
import { IGate, IRequest, IRequestFile } from '../../types';

class PublicDomainIntakeActionsClass extends Actions {
  save = this.createPayloadAction<IRequest>('save');
  saveGates = this.createPayloadAction<IGate[]>('saveGates');
  storeAttachment = this.createPayloadAction<{ index: number; file: IRequestFile }>('storeAttachment');
}

export const PublicDomainIntakeActions = new PublicDomainIntakeActionsClass('publicDomainIntake');
