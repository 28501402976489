import './StateLabel.scss';
import React, { FC } from 'react';
import classNames from 'classnames';
import { Visible } from '../../common/layout/Visible.component';

interface IProps {
  name?: string;
  small?: boolean;
  state: string;
}

export const StateLabel: FC<IProps> = ({ name, small = false, state }) => {
  const badgeClassName = classNames('state-label__badge', `state-label__badge--${state}`);
  const labelClassName = classNames('a-label', `a-label--${state}`);
  const componentClassName = classNames('state-label', `state-label--${small ? 'small' : 'large'}`);

  return (
    <Visible visible={!!state}>
      <Visible
        visible={small}
        defaultComponent={
          <span className="state-label">
            <span className={labelClassName}>{name}</span>
          </span>
        }
      >
        <div className={componentClassName}>
          <div className={badgeClassName} />
          <div className="state-label__label">{name}</div>
        </div>
      </Visible>
    </Visible>
  );
};
