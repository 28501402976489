import * as A from 'antwerp-core-react-branding';

import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createStepRequestedAction } from '../../../../common/actions/creators/workflow';

import { geoCodeIntake, getConflicts as getConflictsAPI, getCostCalculation } from '../../../../common/api';
import debug from '../../../../common/debug';
import { GeometryType, PublicDomainType } from '../../../../common/enums';
import { getSelectorTitle } from '../../../../common/utils/cardSelectorUtils';
import { debounce } from '../../../../common/utils/debounceUtils';
import { submitId } from '../../../../common/utils/formUtils';
import {
  expandLineCoordsToRectangle,
  getEmptyGeometryForIntake,
  isIntakeAddressCompleteForGeoCoding,
} from '../../../../common/utils/geometry.util';
import { calculateNumberOfParkingSpots } from '../../../../common/utils/priceUtils';

import { getCarFreeZoneByGisId } from '../../../../common/utils/requestUtils';
import { AlertsActions } from '../../../../store/actions/alerts.actions';
import { selectCarFreeZones, selectRequest } from '../../../../store/selectors';
import { translate, translateHtml } from '../../../../translations/translate';
import {
  AsignWorkflowSteps,
  ICost,
  IPublicDomainGeometry,
  IPublicDomainIntake,
  IPublicDomainIntakeType,
  IRequest,
  IRequestConflict,
  ITenant,
  WorkflowType,
} from '../../../../types';
import { Fieldset } from '../../../atoms';
import { TypeSelector } from '../../cardselector/TypeSelector.component';
import SectionRow from '../../layout/sectionRow';
import { Visible } from '../../layout/Visible.component';
import { Confirm } from '../../messaging/Confirm.component';
import IntermediatePrice from '../../price/intermediatePrice';
import Price from '../../price/price';
import InfoFiche from '../infoFiche';
import { Required } from '../Required.component';
import { TextArea } from '../TextArea.component';
import { LocationButtons } from './LocationButtons.component';
import { LocationsFormGatesFields } from './LocationsFormGatesFields.component';
import './locationsFormItem.scss';
import { LocationsFormItemMap } from './locationsFormItemMap.component';
import { LocationsFormStreetFields } from './locationsFormStreetFields.component';
import { ParkedPlates } from './ParkedPlates.component';
import { Requirements } from './Requirements.component';

export type LocationsFormItemProperties = {
  initialRequest: Partial<IRequest>;
  selectedIntake: IPublicDomainIntake;
  unselectIntake(): void;
  onIntakeFormCompleted: (intake: IPublicDomainIntake) => void;
  onIntakeDelete: (intake: IPublicDomainIntake) => void;
  tenant: ITenant;
  editMode: boolean;
};

const styles = {
  intermediatePriceContainer: {
    marginTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
  },
};

/**
 * React Component LocationsItem
 */
export const LocationsFormItem: FC<LocationsFormItemProperties> = (allProps) => {
  const { initialRequest, selectedIntake, onIntakeFormCompleted, onIntakeDelete } = allProps;

  const dispatch = useDispatch();
  const selectedRequest: Partial<IRequest> | undefined = useSelector(selectRequest);
  const carFreeZones = useSelector(selectCarFreeZones);
  const [conflicts, setConflicts] = useState<IRequestConflict[]>([]);
  const [geocoding, setGeocoding] = useState<boolean>(false);
  const [popupHasBeenShown, setPopupHasBeenShown] = useState(false);
  const [showSubmitAlert, setShowSubmitAlert] = useState(false);
  const [cost, setCost] = useState<ICost>();
  const [validGeometry, setValidGeometry] = useState(true);

  const initialValues = useMemo(() => {
    const { streetNumberFrom, streetNumberTo, streetNumberUnknown, geometry, type } = selectedIntake;
    let intake: IPublicDomainIntake = {
      ...selectedIntake,
      streetNumberFrom: streetNumberUnknown || streetNumberFrom === '0' ? '' : streetNumberFrom,
      streetNumberTo: streetNumberUnknown || streetNumberTo === '0' ? '' : streetNumberTo || streetNumberFrom,
      geometry: !geometry && type ? getEmptyGeometryForIntake(selectedIntake) : geometry,
    };

    // Fill first intake with address data from requestAddress
    if (!selectedIntake.street && initialRequest.requestAddress) {
      intake.street = initialRequest.requestAddress.street;
      intake.streetNumberFrom = initialRequest.requestAddress.streetNumber;
      intake.streetNumberTo = initialRequest.requestAddress.streetNumber;
      intake.streetFlag = true;
      intake.geometry = getEmptyGeometryForIntake(selectedIntake);
    }

    // In case intake type = CarfreeZone remove streetNumberTo
    if (intake.type?.type === PublicDomainType.CarfreeZone) {
      intake.streetNumberTo = undefined;
    }

    return intake;
  }, [initialRequest.requestAddress, selectedIntake]);

  const [latestValidStreet, setLatestValidStreet] = useState(initialValues.street);

  const formMethods = useForm<IPublicDomainIntake>({
    defaultValues: { ...initialValues },
    mode: 'onBlur',
    shouldFocusError: false,
    reValidateMode: 'onChange',
  });

  const { register, trigger, getValues, setValue, reset, formState, watch } = formMethods;
  const { errors, touchedFields, isValid } = formState;

  useEffect(() => {
    trigger();
  }, [trigger]);

  const type = watch('type');
  const geometry = watch('geometry');
  const gisId = watch('gisId');
  const street = watch('street');
  const streetNumberFrom = watch('streetNumberFrom');
  const streetNumberTo = watch('streetNumberTo');
  const streetFlag = watch('streetFlag');

  useEffect(() => {
    register('gisId', { required: type?.type === PublicDomainType.CarfreeZone });
    register('street');
    register('zipCode', { required: true });
    register('city', { required: true });
  }, [type?.type, register]);

  useEffect(() => {
    if (type?.type === PublicDomainType.CarfreeZone && street !== initialValues.street) {
      setValue('carFreeZoneId', undefined);
      setValue('carFreeZone', undefined);
      setValue('gisId', undefined);
      setValue('carFreeZoneGateEntrance', undefined);
      setValue('carFreeZoneGateExit', undefined);
    }
  }, [setValue, type?.type, street, initialValues.street]);

  const onSubmit = useCallback(
    (intake: any) => {
      if (intake.type?.type !== PublicDomainType.CarfreeZone && !showSubmitAlert) {
        setShowSubmitAlert(true);
      } else if (showSubmitAlert) {
        setShowSubmitAlert(false);
        onIntakeFormCompleted({ ...initialValues, ...intake, valid: true });
      } else {
        onIntakeFormCompleted({ ...initialValues, ...intake, valid: true });
      }
    },
    [initialValues, onIntakeFormCompleted, showSubmitAlert],
  );

  const cleanLocationValues = useCallback((): void => {
    const { geometry, street, streetNumberUnknown, ...values } = getValues();
    reset(
      {
        ...values,
        geometry: { ...geometry, coordinates: undefined },
        street,
        streetNumberUnknown,
        streetNumberFrom: '',
        streetNumberTo: '',
        zipCode: '',
      },
      { keepErrors: true },
    );
  }, [getValues, reset]);

  const getConflicts = useCallback(
    (intake: IPublicDomainIntake): void => {
      if (intake.type?.type !== PublicDomainType.CarfreeZone) {
        getConflictsAPI(selectedRequest as IRequest, getValues().geometry!)
          .then((conflicts: IRequestConflict[]) => {
            setConflicts(conflicts || []);
          })
          .catch((e) => debug(e));
      }
    },
    [getValues, selectedRequest],
  );

  const intermediateCostCalculation = useCallback(
    (intake: IPublicDomainIntake): void => {
      if (intake.type?.type !== PublicDomainType.CarfreeZone) {
        const request = { ...selectedRequest };
        intake.valid = true;
        let added = false;

        if (request.publicDomainIntakes) {
          if (intake.index !== undefined) {
            const index = request.publicDomainIntakes.findIndex((x) => x.index === intake.index);

            if (index !== -1) {
              request.publicDomainIntakes[index] = intake;
            } else {
              request.publicDomainIntakes.push(intake);
              added = true;
            }
          } else {
            request.publicDomainIntakes.push(intake);
            added = true;
          }
        } else {
          request.publicDomainIntakes = [intake];
        }

        getCostCalculation(request as IRequest, carFreeZones)
          .then((cost) => {
            let index = cost.costComponents.length - 1;

            if (intake.index !== undefined) {
              index = intake.index;
            }

            const costComponent = cost.costComponents[index];
            cost.variableCost = costComponent.amount;
            cost.numberOfParkingSpots = calculateNumberOfParkingSpots([costComponent]);

            setCost(cost);
            if (request.publicDomainIntakes && added) {
              request.publicDomainIntakes.pop();
            }
          })
          .catch((e) => debug(e));
      }
    },
    [carFreeZones, selectedRequest],
  );

  const geoCode = useCallback(
    (intakeToGeoCode: IPublicDomainIntake): void => {
      setGeocoding(true);
      geoCodeIntake(intakeToGeoCode)
        .then(({ geometry: g, intake: i }) => {
          setValue('zipCode', i.zipCode, { shouldValidate: true });
          setValue('city', i.city, { shouldValidate: true });
          const newGeometry: IPublicDomainGeometry = { ...geometry } as IPublicDomainGeometry;
          if (g!.coordinates) {
            switch (g.type) {
              case GeometryType.Point:
                newGeometry.type = g!.type;
                newGeometry.coordinates = g!.coordinates![0] as any;
                break;
              case GeometryType.Polygon:
                newGeometry.type = g!.type;
                newGeometry.coordinates = expandLineCoordsToRectangle(g!.coordinates) as any;
                break;
              case GeometryType.LineString:
              default:
                newGeometry.type = g!.type;
                newGeometry.coordinates = g.coordinates;
            }
            setValue('geometry', newGeometry, { shouldTouch: true });

            const currentValues = { ...getValues(), newGeometry, zipCode: i.zipCode, city: i.city };

            // Check for conflicts
            getConflicts(currentValues);

            if (getValues().type?.type === PublicDomainType.CarfreeZone && !!getValues('gisId')) {
              setValue('gisId', undefined);
              setValue('carFreeZoneGateEntrance', undefined);
              setValue('carFreeZoneGateExit', undefined);
            }

            // Calculate intermediate cost
            intermediateCostCalculation(currentValues);
            if (!popupHasBeenShown) {
              switch (getValues().type?.type) {
                case PublicDomainType.CarfreeZone:
                  dispatch(AlertsActions.createInfoAlertActionStep4(translate('locationsform.selectcarfreezone')));
                  break;
                case PublicDomainType.MinorConstruction:
                  dispatch(
                    AlertsActions.createWarningAlertActionStep4(
                      translateHtml('locationsform.warningminorconstructionnmap'),
                    ),
                  );
                  break;
                default:
                  dispatch(
                    AlertsActions.createWarningAlertActionStep4(translateHtml('locationsform.warningparkingbanmap')),
                  );
                  break;
              }
              setPopupHasBeenShown(true);
            }
          }

          if (type?.type === PublicDomainType.CarfreeZone) {
            setValue('carFreeZoneId', undefined);
            setValue('carFreeZone', undefined);
            setValue('gisId', undefined);
          }

          setGeocoding(false);
        })
        .catch((address) => {
          // Show an alert
          dispatch(
            AlertsActions.createWarningAlertActionStep4(
              translateHtml('locationsform.addresscouldnotbefound', { address }),
            ),
          );
          cleanLocationValues();
          setGeocoding(false);
          if (getValues().streetNumberUnknown) {
            setLatestValidStreet(undefined);
          }
        });
    },
    [
      cleanLocationValues,
      dispatch,
      geometry,
      getConflicts,
      getValues,
      intermediateCostCalculation,
      popupHasBeenShown,
      setValue,
      type?.type,
    ],
  );

  const runGeocoding = useCallback(
    () =>
      debounce(
        'geocode',
        () => {
          if (isIntakeAddressCompleteForGeoCoding(getValues(), true) && !getValues().geometry?.coordinates) {
            geoCode(getValues());
          }
        },
        1000,
      ),
    [geoCode, getValues],
  );

  const _cancel = useCallback(() => {
    if (allProps.editMode) {
      // In edit mode just unselect intake
      allProps.unselectIntake();
      return;
    }
    if (selectedIntake) {
      if ((selectedIntake?.index || 0) > 0) {
        // Intake delete
        onIntakeDelete(getValues());
      }

      // Jump to step 2 if the first step is cleared because it steers the date
      if (getValues().index === 0) {
        onIntakeDelete(selectedIntake);
        dispatch(createStepRequestedAction(AsignWorkflowSteps.Type));
      }
    }
  }, [allProps, selectedIntake, onIntakeDelete, getValues, dispatch]);

  useEffect(() => {
    runGeocoding();
  }, [geoCode, getValues, runGeocoding, street, streetNumberFrom, streetNumberTo, streetFlag]);

  runGeocoding(); // should trigger geocoding after every rerender

  const reGeocodeDeferred = () => {
    debounce(
      'regeocode',
      () =>
        geoCode({
          ...getValues(),
          zipCode: undefined,
        }),
      200,
    );
  };

  const onChange = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    // Handle the change
    const targetName = e.currentTarget.name as
      | 'type'
      | 'description'
      | 'streetNumberFrom'
      | 'streetNumberTo'
      | 'id'
      | 'streetNumberUnknown';

    if (!e.currentTarget.value) {
      setValue(targetName, '');
    } else {
      setValue(targetName, e.currentTarget.value);
    }
  };

  const onSetType = (type: IPublicDomainIntakeType): void => {
    const intake = { ...getValues() };
    intake.type = type;
    // AS-5438 when the user selects a new intake with a type other than minor construction, clear the saved location data
    const intakes = selectedRequest?.publicDomainIntakes;
    const oldIntake = intakes && intakes.length ? intakes[0] : undefined;

    // AS-5438 we should only clear the values from the previous intake in the cases that are NOT the following,
    // so we keep the previous values in these cases:
    const previousValuesShouldBeSaved =
      // eslint-disable-next-line no-mixed-operators
      ((type?.type === PublicDomainType.MinorConstruction && oldIntake?.type?.type === PublicDomainType.ParkingBan) ||
        // eslint-disable-next-line no-mixed-operators
        (type?.type === PublicDomainType.ParkingBan && oldIntake?.type?.type === PublicDomainType.MinorConstruction)) &&
      // we only need to keep them when we are adding the *second* location, so intakes has length 1
      intakes?.length === 1;

    if (!previousValuesShouldBeSaved) {
      intake.attachments = undefined;
      intake.geometry = undefined;
      intake.street = undefined;
      intake.streetFlag = undefined;
      intake.streetNumberFrom = undefined;
      intake.streetNumberTo = undefined;
      intake.streetNumberUnknown = undefined;
      intake.zipCode = undefined;
      intake.city = undefined;
    }

    // In case intake type = CarfreeZone remove streetNumberTo
    if (intake.type?.type === PublicDomainType.CarfreeZone) {
      setValue('streetNumberTo', undefined);
    }
    setValue('type', type);
  };

  const onIntakeEdited = (intake: IPublicDomainIntake): void => {
    setValue('geometry', intake.geometry);
    if (intake.gisId) {
      setValue('gisId', intake.gisId);
    }
    if (intake.carFreeZoneId) {
      setValue('carFreeZoneId', intake.carFreeZoneId);
    } else {
      setValue('carFreeZoneId', undefined);
    }
    const zone = getCarFreeZoneByGisId(carFreeZones, intake.gisId);
    if (zone) {
      setValue('carFreeZone', zone);
      setValue('carFreeZoneId', zone.id);
      setValue('zone', zone);
    } else {
      setValue('carFreeZone', undefined);
      setValue('carFreeZoneId', undefined);
      setValue('zone', undefined);
    }

    getConflicts(intake);
    intermediateCostCalculation(intake);
  };

  return (
    <Fieldset legend={translate('workflowsteps.location')}>
      <h5 className="locationsformtitle u-margin-bottom">{translate('locationsform.title')}</h5>
      <section>
        <FormProvider {...formMethods}>
          <section className="locationsformitem">
            {/* @ts-ignore */}
            <A.Spacing type={A.SpacingStyle.MarginBottom}>
              {type?.name ? (
                <A.Form
                  id={submitId(WorkflowType.ASign, AsignWorkflowSteps.Location)}
                  onSubmit={formMethods.handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-sm-4">
                      <div>{translate('locationsform.type') + ': ' + getSelectorTitle(getValues().type.name)}</div>
                      <InfoFiche type={type} />
                      <LocationsFormStreetFields
                        geocoding={geocoding}
                        cleanLocationValues={cleanLocationValues}
                        initialStreet={latestValidStreet}
                        onSelectNewStreet={(newStreet) => {
                          setLatestValidStreet(newStreet);
                          reset({
                            ...getValues(),
                            geometry: { ...getValues().geometry, coordinates: undefined },
                            city: '',
                            zipCode: '',
                          });
                        }}
                      />
                      {gisId && <LocationsFormGatesFields />}
                      <Visible visible={type?.type !== PublicDomainType.CarfreeZone}>
                        {cost ? (
                          <div style={styles.intermediatePriceContainer}>
                            <IntermediatePrice cost={cost} />
                          </div>
                        ) : (
                          <Price cost={undefined} showGdpr={false} />
                        )}
                      </Visible>
                      <ParkedPlates />
                    </div>
                    <div className="col-sm-8 leaflet__container__wrapper">
                      <div>
                        <LocationsFormItemMap
                          {...allProps}
                          busy={geocoding}
                          conflicts={conflicts}
                          onIntakeEdited={onIntakeEdited}
                          setGeometryValid={setValidGeometry}
                        />
                        <div className="locationsformitem__refresh">
                          {isIntakeAddressCompleteForGeoCoding(getValues()) && (
                            <A.IconButton
                              tabIndex={-1}
                              aria-hidden
                              icon="refresh"
                              onClick={reGeocodeDeferred}
                              text={translate('locationsform.regeocode')}
                            />
                          )}
                        </div>
                      </div>
                      <div className="u-margin-top">
                        <TextArea
                          ariaLabel={translate('locationsform.description')}
                          label={translate('locationsform.description')}
                          TextAreaProps={register('description')}
                          meta={{
                            error: !!errors.description,
                            touched: !!touchedFields.description || !!errors.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <Requirements
                    isGeocoding={geocoding}
                    onChange={onChange}
                    required={type?.type === PublicDomainType.MinorConstruction}
                  />
                </A.Form>
              ) : (
                <Visible visible={!type}>
                  <SectionRow>
                    <Controller
                      control={formMethods.control}
                      name="type"
                      render={({ field: { value }, fieldState: { error } }) => (
                        <TypeSelector
                          emergencyProcedure={initialRequest.emergencyProcedure}
                          input={{ onChange: onSetType, value }}
                          error={error}
                          errorComponent={<Required required={!!error} message={translate('typeform.typeRequired')} />}
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </SectionRow>
                </Visible>
              )}
            </A.Spacing>
            <LocationButtons
              disabled={geocoding || !validGeometry || !streetFlag || !isValid || Object.keys(errors).length > 0}
              onCancel={_cancel}
            />
          </section>
        </FormProvider>

        <Confirm
          cancelText={translate('general.no') as any}
          message={translate('locationsform.checkMarkers') as any}
          okText={translate('general.yes') as any}
          onCancel={() => setShowSubmitAlert(false)}
          onOk={() => onSubmit(getValues())}
          useTimestamp={false}
          visible={showSubmitAlert}
        />
      </section>
    </Fieldset>
  );
};
