import { FC, useCallback } from 'react';
import intl from 'react-intl-universal';
import * as A from 'antwerp-core-react-branding';
import { createErrorAlertAction } from '../../../../common/actions/creators/alert';
import { PublicDomainType } from '../../../../common/enums';
import { getEmptyGeometryForIntake, isIntakeAddressCompleteForGeoCoding } from '../../../../common/utils/geometry.util';
import { IDispatcheableProperties, IRequestConflict, IPublicDomainIntake } from '../../../../types';
import { useDispatch } from 'react-redux';
import { Visible } from '../../layout/Visible.component';
import { EditMapParkingBan } from '../../leaflet/EditMapParkingBan.component';
import { BaseMap } from '../../leaflet/BaseMap.component';
import { EditMapMinorConstruction } from '../../leaflet/EditMapMinorConstruction.component';
import { LineString, MultiPolygon, Polygon } from 'geojson';
import { EditMapCarFreeZone } from '../../leaflet/EditMapCarFreeZone.component';
import { useFormContext } from 'react-hook-form';

export type LocationsFormItemMapProperties = {
  busy?: boolean;
  conflicts: IRequestConflict[];
  onIntakeEdited?: (intake: IPublicDomainIntake) => void;
  setGeometryValid: (isValid: boolean) => void;
} & IDispatcheableProperties;

export const LocationsFormItemMap: FC<LocationsFormItemMapProperties> = ({ busy, conflicts, onIntakeEdited }) => {
  const dispatch = useDispatch();
  const { watch, register } = useFormContext<IPublicDomainIntake>();
  const intake = watch();
  const gisId = watch('gisId');

  const showMap = useCallback(
    () => isIntakeAddressCompleteForGeoCoding(intake) && intake.geometry?.coordinates,
    [intake],
  );

  const onError = (message: string) => {
    dispatch(createErrorAlertAction(message));
  };

  const onChangeIntake = ({ geometry, ...intakeChanges }: Partial<IPublicDomainIntake>) => {
    onIntakeEdited?.({
      ...intake,
      ...intakeChanges,
      geometry: geometry || intake.geometry,
    });
  };
  return (
    <section className="leaflet__container">
      <Visible
        defaultComponent={<BaseMap busy={busy} className="leaflet__editmap" {...register('geometry')} />}
        visible={!!showMap() && !busy}
      >
        <Visible visible={intake.type.type === PublicDomainType.CarfreeZone}>
          <EditMapCarFreeZone
            geometry={
              (intake.geometry?.type
                ? intake.geometry
                : { ...getEmptyGeometryForIntake(intake), coordinates: intake.geometry?.coordinates }) as unknown as
                | Polygon
                | MultiPolygon
            }
            gisId={gisId}
            onChange={onChangeIntake}
          />
        </Visible>
        <Visible visible={intake.type.type === PublicDomainType.ParkingBan}>
          <EditMapParkingBan
            conflicts={conflicts}
            geometry={intake.geometry as unknown as LineString}
            onChange={onChangeIntake}
          />
        </Visible>
        <Visible visible={intake.type.type === PublicDomainType.MinorConstruction}>
          <EditMapMinorConstruction
            conflicts={conflicts}
            geometry={intake.geometry as unknown as Polygon}
            onChange={onChangeIntake}
            onError={onError}
          />
        </Visible>
      </Visible>

      <Visible visible={conflicts.length > 0}>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginBottom}>
          <div className="m-alert m-alert--danger">
            <i className="fa fa-map-marker" /> {intl.getHTML('locationsform.conflicts')}
            <ul>
              {conflicts.map((x, i) => (
                <li key={i}>{x.feature.properties.title}</li>
              ))}
            </ul>
          </div>
        </A.Spacing>
      </Visible>
    </section>
  );
};
