import { IDefaultAction, IGenericAction } from '../../../store/actions/actions';
import { defaultAction, genericAction } from '../../../store/actions';
import { IRequest } from '../../../types';
import * as T from '../types';

export function createPreviousStepRequestedAction(payload: IRequest): IGenericAction<IRequest> {
  return genericAction(T.REQUEST_PREVIOUS_STEP, payload);
}

export function createNextStepGrantedAction(payload: IRequest): IGenericAction<IRequest> {
  return genericAction(T.NEXT_STEP_GRANTED, payload);
}

export function createPreviousStepGrantedAction(payload: IRequest): IGenericAction<IRequest> {
  return genericAction(T.PREVIOUS_STEP_GRANTED, payload);
}

export function createResetStepRequestedAction(payload: IRequest): IGenericAction<IRequest> {
  return genericAction(T.RESET_STEP_REQUESTED, payload);
}

export function createResetStepGrantedAction(): IDefaultAction {
  return defaultAction(T.RESET_STEP_GRANTED);
}

export function createStepRequestedAction(payload: number): IGenericAction<number> {
  return genericAction(T.STEP_REQUESTED, payload);
}

export function createSetStepAction(payload: number): IGenericAction<number> {
  return genericAction(T.STEP_SET, payload);
}

export function createStepGrantedAction(payload: IRequest): IGenericAction<IRequest> {
  return genericAction(T.STEP_GRANTED, payload);
}

export function createSubmitAction(payload: IRequest): IGenericAction<IRequest> {
  return genericAction(T.REQUEST_SUBMIT, payload);
}

export function createInitializeWorkflowAction(): IDefaultAction {
  return defaultAction(T.INITIALIZE_WORKFLOW);
}
