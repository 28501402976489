import React, { FC, useCallback, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch } from 'react-redux';
import * as A from 'antwerp-core-react-branding';
import { createConfirmCancelAction, createConfirmOKAction } from '../../../common/actions/creators/alert';
import { AntwerpAlert } from './AntwerpAlert.component';

export type ConfirmProperties = {
  cancelText?: JSX.Element | string;
  okText?: JSX.Element | string;
  timestamp?: number;
  useTimestamp?: boolean;
} & Partial<A.AlertProperties>;

export const Confirm: FC<ConfirmProperties> = ({
  cancelText,
  okText,
  onOk,
  onCancel,
  title,
  useTimestamp = true,
  visible = false,
  ...props
}) => {
  const defaultTitle = `${intl.formatMessage({ id: 'general.pageTitle' })} - ${intl.formatMessage({
    id: 'general.pageSubTitle',
  })}`;
  const defaultCancelText = intl.formatMessage({ id: 'general.cancel' });
  const defaultOkText = intl.formatMessage({ id: 'general.ok' });

  const dispatch = useDispatch();
  const [currentTimestamp, setCurrentTimestamp] = useState<number>();
  const [isVisible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!useTimestamp) {
      setVisible(visible);
    } else if (props.timestamp && props.timestamp !== currentTimestamp) {
      setVisible(true);
      setCurrentTimestamp(props.timestamp);
    } else {
      setVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, props.timestamp, useTimestamp]);

  const _onOk = useCallback(() => {
    onOk ? onOk() : dispatch(createConfirmOKAction());
  }, [dispatch, onOk]);

  const _onCancel = useCallback(() => {
    onCancel ? onCancel() : dispatch(createConfirmCancelAction());
  }, [dispatch, onCancel]);

  return (
    <AntwerpAlert
      {...props}
      title={title || defaultTitle}
      cancelText={cancelText || defaultCancelText}
      okText={okText || defaultOkText}
      onCancel={_onCancel}
      onOk={_onOk}
      visible={isVisible}
    />
  );
};
