import React, { FC, useState, useEffect, createRef, useRef, useCallback } from 'react';
import { FeatureGroup, Polyline as ReactLeafletPolyline, Tooltip, useMap } from 'react-leaflet';
import { Marker } from './Marker.component';
import {
  LatLngExpression,
  Marker as LeafletMarker,
  polylineDecorator,
  Polyline as IPolyline,
  PolylineDecorator,
} from 'leaflet';
import { IPolylineProps } from '../../../../types';
import 'leaflet-polylinedecorator';
import { LineString } from '@turf/turf';
import { Visible } from '../../layout/Visible.component';
import { MAP_DEFAULT_DRAWINGS_WEIGHT } from '../../../../common/constants';

export const Polyline: FC<IPolylineProps> = ({
  draggable = false,
  onDragEnd,
  positions,
  arrow,
  info,
  infoSticky,
  lineWeight,
  ...props
}) => {
  const polyRef = createRef<IPolyline<LineString>>();
  const arrowRef = useRef<PolylineDecorator>();
  const [polylinePositions, setPolylinePositions] = useState<LatLngExpression[]>(positions);
  const map = useMap();

  useEffect(() => {
    setPolylinePositions(positions);
  }, [positions]);

  const addArrow = useCallback(() => {
    if (polyRef.current && arrow) {
      arrowRef.current && map.removeLayer(arrowRef.current);
      arrowRef.current = polylineDecorator(polyRef.current, {
        patterns: arrow,
      });

      arrowRef.current.addTo(map);
    }
  }, [arrow, map, polyRef]);

  useEffect(() => {
    addArrow();

    return () => {
      arrowRef.current && map.removeLayer(arrowRef.current);
    };
  }, [addArrow, map]);

  const onDragMarker = (index: number) => (marker: LeafletMarker) => {
    const positions = [...polylinePositions];
    positions[index] = marker.getLatLng();
    setPolylinePositions(positions);
  };

  const onDragMarkerEnd = () => {
    onDragEnd?.(polylinePositions);
    addArrow();
  };

  return (
    <FeatureGroup>
      <ReactLeafletPolyline
        {...props}
        ref={polyRef}
        positions={polylinePositions}
        weight={lineWeight || MAP_DEFAULT_DRAWINGS_WEIGHT}
      >
        <Visible visible={!!info}>
          <Tooltip sticky={infoSticky}>{info}</Tooltip>
        </Visible>
      </ReactLeafletPolyline>
      <Visible visible={draggable}>
        {positions.map((coords: any, i: number) => (
          <Marker
            draggable={draggable}
            key={`marker-${i}`}
            onDrag={onDragMarker(i)}
            onDragEnd={onDragMarkerEnd}
            position={coords}
          />
        ))}
      </Visible>
    </FeatureGroup>
  );
};
