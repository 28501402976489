export * from '../../store/actions/types';

export const ALERT = 'ALERT';
export const ALERT_RESET = 'ALERT_RESET';
export const CALCULATE_COST = 'CALCULATE_COST';
export const CALCULATE_EXTENSION_COST = 'CALCULATE_EXTENSION_COST';
export const CLEAR_COST = 'CLEAR_COST';
export const CLEAR_REQUEST = 'CLEAR_REQUEST';
export const CONFIRM = 'CONFIRM';
export const CONFIRM_CANCEL = 'CONFIRM_CANCEL';
export const CONFIRM_OK = 'CONFIRM_OK';
export const CONFIRM_RESET = 'CONFIRM_RESET';
export const COST_RECEIVED = 'COST_RECEIVED';
export const COUNTRIES_RECEIVED = 'COUNTRIES_RECEIVED';
export const DATA_LOADED = 'DATA_LOADED';
export const DESELECT_PDI = 'DESELECT_PDI';
export const INITIALIZE_WORKFLOW = 'INITIALIZE_WORKFLOW';
export const MYREQUESTS_RECEIVED = 'MYREQUESTS_RECEIVED';
export const NEXT_STEP_GRANTED = 'NEXT_STEP_GRANTED';
export const PAYMENT_EXTENSION_REQUESTED = 'PAYMENT_EXTENSION_REQUESTED';
export const PAYMENT_REQUESTED = 'PAYMENT_REQUESTED';
export const PAYMENT_REQUEST_REQUESTED = 'PAYMENT_REQUEST_REQUESTED';
export const PDI_ATTACHMENT_SAVED = 'PDI_ATTACHMENT_SAVED';
export const PDI_PATCH_LICENCEPLATES_REQUESTED = 'PDI_PATCH_LICENCEPLATES_REQUESTED';
export const PDI_SAVE_ATTACHMENT_REQUESTED = 'PDI_SAVE_ATTACHMENT_REQUESTED';
export const PERSIST_NEW_REQUEST_REQUESTED = 'PERSIST_NEW_REQUEST_REQUESTED';
export const PERSIST_REQUEST_REQUESTED = 'PERSIST_REQUEST_REQUESTED';
export const PREVIOUS_STEP_GRANTED = 'PREVIOUS_STEP_GRANTED';
export const PUBLICDOMAINTYPES_RECEIVED = 'PUBLICDOMAINTYPES_RECEIVED';
export const REQUESTREASONS_RECEIVED = 'REQUESTREASONS_RECEIVED';
export const REQUEST_CREATE_NEW = 'REQUEST_CREATE_NEW';
export const REQUEST_DELETE = 'REQUEST_DELETE';
export const REQUEST_DELETE_ATTACHMENT_REQUESTED = 'REQUEST_DELETE_ATTACHMENT_REQUESTED';
export const REQUEST_DELETE_PDI = 'REQUEST_DELETE_PDI';
export const REQUEST_EXTENSION_SAVE_REQUESTED = 'REQUEST_EXTENSION_SAVE_REQUESTED';
export const REQUEST_PREVIOUS_STEP = 'REQUEST_PREVIOUS_STEP';
export const REQUEST_RECEIVED = 'REQUEST_RECEIVED';
export const REQUEST_REQUESTED = 'REQUEST_REQUESTED';
export const REQUEST_SUBMIT = 'REQUEST_SUBMIT';
export const REQUEST_TEMP_DELETE = 'REQUEST_TEMP_DELETE';
export const REQUEST_UPDATED = 'REQUEST_UPDATED';
export const REQUEST_RESET_PDI = 'REQUEST_RESET_PDI';
export const REQUEST_CREATED = 'REQUEST_CREATED';
export const REQUEST_MESSAGES_GET = 'REQUEST_MESSAGES_GET';
export const REQUEST_MESSAGES_POST = 'REQUEST_MESSAGES_POST';
export const REQUEST_MESSAGES_RECEIVED = 'REQUEST_MESSAGES_RECEIVED';
export const REQUEST_MESSAGES_RESET = 'REQUEST_MESSAGES_RESET';
export const RESET_STEP_GRANTED = 'RESET_STEP_GRANTED';
export const RESET_STEP_REQUESTED = 'RESET_STEP_REQUESTED';
export const SCROLL_POSITION = 'SCROLL_POSITION';
export const SELECT_PDI = 'SELECT_PDI';
export const STEP_GRANTED = 'STEP_GRANTED';
export const STEP_REQUESTED = 'STEP_REQUEST';
export const STEP_SET = 'STEP_SET';
export const TENANT_RECEIVED = 'TENANT_RECEIVED';
export const METADATA_RECEIVED = 'METADATA_RECEIVED';
