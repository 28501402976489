import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { COLORS, useFlexStyles } from '../../../theme';
import { IRequestMessageAttachment } from '../../../types';
import { Visible } from '../../common/layout/Visible.component';

interface IProps {
  attachments: IRequestMessageAttachment[];
}
const useStyles = createUseStyles({
  attachments: {
    backgroundColor: COLORS.borderGrey,
    padding: '8px 15px',
    width: 'fit-content',
    borderRadius: '16px',
    marginTop: '8px',
  },
});

export const MessageAttachmentsList: FC<IProps> = ({ attachments }) => {
  const C = useStyles();
  const G = useFlexStyles();
  return (
    <Visible visible={!!attachments.length}>
      <div className={G.column}>
        {attachments.map((file: IRequestMessageAttachment) => (
          <a key={file.url} className={C.attachments} href={file.url} target="_blank" rel="noreferrer">
            {file.name}
          </a>
        ))}
      </div>
    </Visible>
  );
};
