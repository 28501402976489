import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { replanModalAtom } from '../components';

import { SgwRequestActions } from '../store/actions';
import { getStandardAndRecurringPhases, selectRescheduling } from '../store/selectors/sgw';

import { TRouterWithId, IRequestRescheduling } from '../types';

export const useReschedulingForm = () => {
  const [, setReplanModal] = useAtom(replanModalAtom);

  const dispatch = useDispatch();
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const phases = useSelector(getStandardAndRecurringPhases(requestId!));
  const reschedulings = useSelector(selectRescheduling);
  const hasActiveRescheduling =
    !!reschedulings.length && reschedulings[reschedulings.length - 1].acl.updateReschedulingRequest;

  const defaultValues = useCallback(() => {
    if (hasActiveRescheduling) return reschedulings[reschedulings.length - 1];
    else
      return {
        phaseReschedulings: phases.map(({ id, dateFrom, dateUntil }) => ({
          sgwPhaseId: id,
          newDateFrom: dateFrom,
          newDateUntil: dateUntil,
        })),
      };
  }, [hasActiveRescheduling, phases, reschedulings]);

  const formMethods = useForm<IRequestRescheduling>({
    defaultValues: defaultValues(),
  });

  const onSubmit = (rescheduling: IRequestRescheduling) => {
    dispatch(
      SgwRequestActions.rescheduling.create({
        requestId: requestId!,
        rescheduling,
        requestRescheduleId: rescheduling?.id,
      }),
    );
    setReplanModal(false);
    formMethods.reset();
  };

  return { formMethods, onSubmit };
};
