import { FC } from 'react';
import * as React from 'react';
import { TextInput } from '../../atoms';
import { ICompany, SgwRequestFormFields } from '../../../types';
import { useVATValidationGenerator } from '../../../hooks';
import { useFormContext } from 'react-hook-form';
import { translate } from '../../../translations/translate';

export const CompanyFields: FC = () => {
  const { register } = useFormContext<ICompany>();
  const validateVat = useVATValidationGenerator(true, SgwRequestFormFields.vatNumber, SgwRequestFormFields.companyName);

  return (
    <div className="row">
      <TextInput
        className="col-xs-6"
        {...register('vatNumber', { required: true, validate: validateVat })}
        validate={validateVat}
        label={translate('personalform.companyid')}
        required
      />
      <TextInput
        className="col-xs-6"
        {...register('companyName', { required: true })}
        label={translate('personalform.companyname')}
        required
      />
    </div>
  );
};
