import { FC, useEffect } from 'react';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { formatDateTimeToDisplayDate } from '../../../common/utils/dateUtils';
import { DatePicker } from '../../atoms';
import { translate } from '../../../translations/translate';
import { validateDate } from '../../../common/validation/validateTime';
import { IRequestRescheduling, ISgwPhase } from '../../../types';
import { useFormContext } from 'react-hook-form';
import { useGenericStyles } from '../../../hooks';

interface IProps {
  phase: ISgwPhase;
  index: number;
}
export const RescheduleRequestPhaseFormFields: FC<IProps> = ({ phase, index }) => {
  const G = useGenericStyles();
  const { register, trigger, watch } = useFormContext<IRequestRescheduling>();
  const minDate = watch(`phaseReschedulings.${index}.newDateFrom`);
  const maxDate = watch(`phaseReschedulings.${index}.newDateUntil`);

  useEffect(() => {
    trigger(`phaseReschedulings.${index}.newDateUntil`);
  }, [index, minDate, trigger]);

  useEffect(() => {
    trigger(`phaseReschedulings.${index}.newDateFrom`);
  }, [index, maxDate, trigger]);

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="end" sx={{ marginBottom: '30px' }} key={phase.id}>
      <Grid item xs={4}>
        <div className={classNames(G.flex.column, phase.parentPhaseId && G.spacing.marginLeft.MD)}>
          <b>{phase?.phaseName}</b>
          <div>{`${formatDateTimeToDisplayDate(phase.dateFrom)} - ${formatDateTimeToDisplayDate(
            phase.dateUntil,
          )}`}</div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <DatePicker
          {...register(`phaseReschedulings.${index}.newDateFrom`)}
          label={translate('myRequests.details.rescheduleRequest.startDate')}
          validate={(value) => validateDate(translate('fields.startDate'), value, undefined, maxDate, true)}
        />
      </Grid>
      <Grid item xs={4}>
        <DatePicker
          {...register(`phaseReschedulings.${index}.newDateUntil`)}
          label={translate('myRequests.details.rescheduleRequest.endDate')}
          minDate={minDate}
          validate={(value) => validateDate(translate('fields.endDate'), value, minDate, undefined, true)}
        />
      </Grid>
    </Grid>
  );
};
