import { Actions } from './generic.actions';
import { IGeometry, TCarFreeZone } from '../../types';

class CarFreeZoneActionsClass extends Actions {
  get = this.createAction('get');
  received = this.createPayloadAction<TCarFreeZone[]>('received');
  setIntersectingZones = this.createPayloadAction<number[]>('setIntersectingZones');
  verifyIntersections = this.createPayloadAction<IGeometry[]>('verifyIntersections');
}

export const CarFreeZoneActions = new CarFreeZoneActionsClass('carFreeZone');
