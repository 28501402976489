import { call, Effect, put, select, takeEvery } from 'redux-saga/effects';

import { createErrorAlertAction, createInfoAlertAction } from '../../common/actions/creators/alert';
import { createOnlinePaymentExtensionAction } from '../../common/actions/creators/payments';
import { createRequestUpdatedAction } from '../../common/actions/creators/requests';
import { REQUEST_EXTENSION_SAVE_REQUESTED } from '../../common/actions/types';
import { getRequest, saveRequestExtension } from '../../common/api';
import debug from '../../common/debug';
import { convertStringToTimestamp } from '../../common/utils/dateUtils';
import { IGenericAction } from '../actions/actions';
import { createProcessIdleAction, createProcessStartedAction } from '../actions/creators';
import { FeatureName, ICall, IRequestExtension } from '../../types';
import { SagaIterator } from 'redux-saga';
import { historyPush } from '../../common/utils/historyUtils';
import { appUrls } from '../../common/constants';
import { isFeatureEnabled } from '../selectors';
import { convertBackendRequest } from '../../common/utils/requestUtils';

function* saveExtension({ payload }: IGenericAction<Partial<IRequestExtension>>): IterableIterator<Effect> {
  const process = 'saveExtension';

  yield put(createProcessStartedAction(process));
  try {
    if (payload.permitRequest) {
      if (convertStringToTimestamp(payload.permitRequest!.latestRequestDateForExtension) < new Date().getTime()) {
        payload.emergencyProcedure = true;
      }

      const extension: ICall<typeof saveRequestExtension> = yield call(
        saveRequestExtension,
        payload.permitRequest,
        payload,
      );
      yield put(createInfoAlertAction('De verlenging werd opgeslagen.'));

      // Reload the extension
      const request: ICall<typeof getRequest> = yield call(getRequest as any, extension!.permitRequest.id);
      yield put(createRequestUpdatedAction(convertBackendRequest(request!)));

      // Execute the payment
      const integrateDigipolisOrder = yield select(isFeatureEnabled(FeatureName.integrateDigipolisOrder));
      if (integrateDigipolisOrder) {
        yield put(createOnlinePaymentExtensionAction(convertBackendRequest(request!), extension!));
      } else {
        historyPush(appUrls.request.submit.success.replace(':id', `${extension!.permitRequest.id}`));
      }
    }
  } catch (e) {
    debug('The extension could not be saved', e);
    yield put(createErrorAlertAction(`De verlenging kon niet worden opgeslagen`));
  } finally {
    yield put(createProcessIdleAction(process));
    historyPush(appUrls.myRequests.detail.replace(':id', `${payload.permitRequest!.id}`));
  }
}

export default function* extensionSaga(): SagaIterator {
  yield takeEvery(REQUEST_EXTENSION_SAVE_REQUESTED, saveExtension);
}
