import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../theme';
import { IWithClassName } from '../../../types';

export interface IDescriptionProps extends IWithClassName {
  description?: string;
  error?: ReactNode;
  isError?: boolean;
}

const useStyles = createUseStyles({
  descriptionContainer: {
    marginTop: '0.75rem',
    '&.has-error': {
      '& small': {
        color: `${COLORS.red} !important`,
      },
    },
  },
});

export const Description: FC<IDescriptionProps> = ({ isError = false, description = '', className, error }) => {
  const C = useStyles();
  const content = !isError ? description : error;

  return (
    <div className={classNames(C.descriptionContainer, className, { 'has-error': isError })}>
      <small>{content}</small>
    </div>
  );
};
