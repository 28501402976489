import './busy.scss';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectBusy } from '../../../store/selectors';
import * as A from 'antwerp-core-react-branding';

export const BusyComponent: FC = () => (
  // @ts-ignore
  <A.Overlay visible={useSelector(selectBusy)}>
    <div className="busy">
      <div className="a-spinner" />
    </div>
  </A.Overlay>
);
