import {
  CLEAR_COST,
  CLEAR_REQUEST,
  COST_RECEIVED,
  INITIALIZE_WORKFLOW,
  REQUEST_TEMP_DELETE,
} from '../../common/actions/types';
import { IGenericAction } from '../actions/actions';
import { ICost } from '../../types/cost.types';

const INITIAL_STATE: ICost | {} = {};

export default function cost(state: ICost | {} = INITIAL_STATE, action: IGenericAction<ICost | {}>): ICost | {} {
  switch (action.type) {
    case INITIALIZE_WORKFLOW:
    case REQUEST_TEMP_DELETE:
    case CLEAR_REQUEST:
    case CLEAR_COST:
      return INITIAL_STATE;
    case COST_RECEIVED:
      return action.payload as ICost;
    default:
      return state;
  }
}
