import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { IWithPartnerVariant, PartnerType } from '../../../types';
import { Icon } from '../../atoms';
import { PartnerAccordionHeader } from '../../molecules';
import intl from 'react-intl-universal';
import { SgwAccordion } from '../SgwAccordion/SgwAccordion.component';
import { useDispatch, useSelector } from 'react-redux';
import { SgwRequestActions } from '../../../store/actions';
import { getRequestPartnersId } from '../../../store/selectors/sgw';

interface IProps extends IWithPartnerVariant {
  requestId: string;
  readOnly?: boolean;
}

export const PartnerAccordion: FC<PropsWithChildren<IProps>> = ({ children, readOnly = false, requestId, variant }) => {
  const dispatch = useDispatch();
  const requestPartners = useSelector(getRequestPartnersId(requestId));

  const requestorId = useMemo(() => requestPartners[PartnerType.requester], [requestPartners]);
  const contractorId = useMemo(() => requestPartners[PartnerType.contractor], [requestPartners]);
  const thisId = useMemo(() => requestPartners[variant], [requestPartners, variant]);
  const sameAsRequestor = useMemo(() => !!requestorId && `${thisId}` === `${requestorId}`, [requestorId, thisId]);
  const sameAsContractor = useMemo(() => !!contractorId && `${thisId}` === `${contractorId}`, [contractorId, thisId]);

  const showSameAsContractor = useMemo(
    () => variant === PartnerType.fee && `${requestorId}` !== `${contractorId}`,
    [contractorId, requestorId, variant],
  );

  const locked = useMemo(
    () => sameAsRequestor || (showSameAsContractor && sameAsContractor),
    [sameAsContractor, sameAsRequestor, showSameAsContractor],
  );

  const onClickSameAsRequestor = useCallback(() => {
    // Also save fee partner id when contractor partner is set to be same as applicant.
    const shouldSavePartnerFee = `${thisId}` !== `${requestorId}` && variant === PartnerType.contractor;
    dispatch(
      SgwRequestActions.linkPartner({
        partnerId: `${thisId}` === `${requestorId}` ? undefined : `${requestorId}`,
        requestId,
        variant,
        shouldSavePartnerFee,
      }),
    );
  }, [dispatch, requestId, requestorId, thisId, variant]);

  const onClickSameAsContractor = useCallback(() => {
    dispatch(
      SgwRequestActions.linkPartner({
        partnerId: `${thisId}` === `${contractorId}` ? undefined : `${contractorId}`,
        requestId,
        variant,
      }),
    );
  }, [contractorId, dispatch, requestId, thisId, variant]);

  return (
    <SgwAccordion
      icon={<Icon.PartnerSvgIcon variant={variant} />}
      header={
        <PartnerAccordionHeader
          readOnly={readOnly}
          sameAsRequestor={sameAsRequestor}
          sameAsContractor={sameAsContractor}
          onClickSameAsContractor={onClickSameAsContractor}
          onClickSameAsRequestor={onClickSameAsRequestor}
          showSameAsContractor={showSameAsContractor}
          title={intl.get(`sgw.partners.accordion.title.${variant}`)}
        />
      }
      index={variant}
      locked={locked}
      collapsed
    >
      {children}
    </SgwAccordion>
  );
};
