import { FC } from 'react';
import { useSelector } from 'react-redux';
import { GeometryType } from '../../../common/enums';
import { useGeoDrawingContext } from '../../../hooks';
import { getConflictGroupForCategoryAndPhase } from '../../../store/selectors/sgw';
import { ConflictDrawingPoint } from '../../molecules/ConflictDrawingPoint/ConflictDrawingPoint.component';
import { ConflictDrawingPolygon } from '../../molecules/ConflictDrawingPolygon/ConflictDrawingPolygon.component';
import { ConflictDrawingLineString } from '../../molecules/ConflictDrawingLineString/ConflictDrawingLineString.component';

interface IProps {
  conflictGroupId: number;
}

export const ConflictDrawing: FC<IProps> = ({ conflictGroupId }) => {
  const context = useGeoDrawingContext();
  const conflictGroup = useSelector(getConflictGroupForCategoryAndPhase(conflictGroupId, context.phaseId));

  return (
    <>
      {conflictGroup?.conflicts.map((conflict) => {
        switch (conflict.geometry.type) {
          case GeometryType.LineString:
            return (
              <ConflictDrawingLineString
                geometry={conflict.geometry}
                conflictCategory={conflictGroup.conflictCategory}
              />
            );
          case GeometryType.Point:
            return (
              <ConflictDrawingPoint geometry={conflict.geometry} conflictCategory={conflictGroup.conflictCategory} />
            );

          case GeometryType.Polygon:
            return (
              <ConflictDrawingPolygon geometry={conflict.geometry} conflictCategory={conflictGroup.conflictCategory} />
            );

          default:
            return null;
        }
      })}
    </>
  );
};
