import { MYREQUESTS_RECEIVED, REQUEST_CREATED, REQUEST_DELETE, REQUEST_UPDATED } from '../../common/actions/types';
import { IGenericAction } from '../actions/actions';
import { IRequest } from '../../types';

const INITIAL_STATE: IRequest[] = [];

export default function myrequests(
  state: IRequest[] = INITIAL_STATE,
  action: IGenericAction<IRequest[] | IRequest>,
): IRequest[] {
  switch (action.type) {
    case REQUEST_DELETE: {
      const request = action.payload as IRequest;
      return state.filter((x) => x.id !== request.id);
    }
    case MYREQUESTS_RECEIVED: {
      return action.payload as IRequest[];
    }
    case REQUEST_UPDATED: {
      const request = action.payload as IRequest;
      return state.map((x) => (x.id === request.id ? request : x));
    }
    case REQUEST_CREATED: {
      const request = action.payload as IRequest;
      return [request, ...state];
    }
    default: {
      return state;
    }
  }
}
