import intl from 'react-intl-universal';

import { IRequest } from '../../types';
import { PaymentType } from '../enums';
import validateRequired from './validateRequired';

export default function validateBankTransferData(value: string, values: Partial<IRequest>): string | null {
  // Returned if required if false
  const requiredMessage = validateRequired(value);
  if (requiredMessage) return requiredMessage;

  if (value === PaymentType.Banktransfer && !canPayByBankTransfer(values)) {
    return intl.get('fields.nobanktransferavailable');
  }

  return null;
}

export const canPayByBankTransfer = (request: Partial<IRequest>): boolean => {
  return !(
    request.dateFrom &&
    request.bankPaymentDay &&
    new Date(request.dateFrom).getTime() < new Date(request.bankPaymentDay).getTime()
  );
};
