import { FC, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { PublicDomainType } from '../../../common/enums';
import { getAltTextForImage, getImageIntakeType, TImageType } from '../../../common/utils/assetsUtils';
import { getSelectorRemarks, getSelectorTitle } from '../../../common/utils/cardSelectorUtils';
import { selectPublicDomainIntakeTypes } from '../../../store/selectors';
import { IPublicDomainIntakeType } from '../../../types';
import { CardSelectorItemProperties } from './CardSelectorItem.component';
import { CardSelectorTag } from './CardSelectorTag.component';
import { GenericCardSelector } from './GenericCardSelector.component';

interface IProps {
  emergencyProcedure?: boolean;
  errorComponent?: JSX.Element;
  hideRemarks?: boolean;
  error?: FieldError;
  input?: {
    onChange: (value: IPublicDomainIntakeType) => void;
    value?: IPublicDomainIntakeType;
  };
}

export const TypeSelector: FC<IProps> = ({ input, emergencyProcedure, error, errorComponent, hideRemarks }) => {
  const [value, setValue] = useState<IPublicDomainIntakeType | null>();
  const types = useSelector(selectPublicDomainIntakeTypes);
  const activeIndex = types.findIndex(({ type }) => type === value?.type);
  const items = emergencyProcedure ? types.filter((x) => x.type !== PublicDomainType.MinorConstruction) : types;
  useEffect(() => {
    setValue(input?.value);
  }, [input?.value]);

  const mapItem = (type: IPublicDomainIntakeType): CardSelectorItemProperties => ({
    image: getImageIntakeType(type.type as TImageType),
    remarks: hideRemarks ? undefined : getSelectorRemarks(type.displayName),
    title: getSelectorTitle(type.displayName),
    imageDescription: getAltTextForImage('type', type.type),
  });

  const onChange = (pdi: IPublicDomainIntakeType): void => {
    input?.onChange?.(pdi);
    setValue(pdi);
  };
  return (
    <div className="typeselector cardselector__wrapper">
      <GenericCardSelector
        activeIndex={activeIndex}
        data={items}
        errorComponent={errorComponent}
        mapItem={mapItem}
        onChange={onChange}
        error={error}
      />
      <CardSelectorTag name={value?.name} />
    </div>
  );
};
