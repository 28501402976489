export enum GeometryType {
  LineString = 'LineString',
  Point = 'Point',
  Polygon = 'Polygon',
}

export enum PublicDomainType {
  MinorConstruction = 'minorconstructionsiteintake',
  CarfreeZone = 'carfreezoneintake',
  ParkingBan = 'parkingbanintake',
}

export enum ApplicantType {
  Citizen = 'CITIZEN',
  Company = 'COMPANY',
}

export enum PaymentType {
  Online = 'online',
  Banktransfer = 'banktransfer',
}

export enum RequestReasons {
  CeremonialCars = 'ceremonial_cars',
  Container = 'container',
  LoadingUnLoadingZone = 'loading_unloading_zone',
  Move = 'move',
  MovingLift = 'moving_lift',
  Scaffold = 'scaffold',
}

export enum Levels {
  Danger = 'danger',
  None = '',
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Warning = 'warning',
}

export enum Sizes {
  Default = 'default',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}
