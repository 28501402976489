import * as React from 'react';
import intl from 'react-intl-universal';

import * as A from 'antwerp-core-react-branding';
import { FC } from 'react';
import { CountrySelector, TextInput } from '../../atoms';
import { PersonalFormFields } from '../../../common/form';
import { HOUSE_NUMBER_MAX_LENGTH } from '../../../common/constants';

type AddressInputProperties = {};

export const AddressInput: FC<AddressInputProperties> = () => (
  <div>
    <div className="row">
      <div className="col-xs-12 col-md-6">
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginBottom}>
          <TextInput name="street" label={intl.get('personalform.street')} required />
        </A.Spacing>
      </div>
      <div className="col-xs-12 col-md-3">
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginBottom}>
          <TextInput
            name="streetNumber"
            label={intl.get('personalform.streetnumber')}
            required
            maxLength={HOUSE_NUMBER_MAX_LENGTH}
          />
        </A.Spacing>
      </div>
      <div className="col-xs-12 col-md-3">
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginBottom}>
          <TextInput name={PersonalFormFields.bus} label={intl.get('personalform.bus')} />
        </A.Spacing>
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12 col-md-2">
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginBottom}>
          <TextInput name={PersonalFormFields.zipCode} label={intl.get('personalform.zipcode')} required />
        </A.Spacing>
      </div>
      <div className="col-xs-12 col-md-5">
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginBottom}>
          <TextInput name={PersonalFormFields.city} label={intl.get('personalform.city')} required />
        </A.Spacing>
      </div>
      <div className="col-xs-12 col-md-5">
        <CountrySelector name={PersonalFormFields.country} label={intl.get('personalform.country')} />
      </div>
    </div>
  </div>
);
