import { REQUESTREASONS_RECEIVED } from '../../common/actions/types';
import { IGenericAction } from '../actions/actions';
import { IRequestReason } from '../../types';

const INITIAL_STATE: IRequestReason[] = [];

export default function requestReasons(
  state: IRequestReason[] = INITIAL_STATE,
  action: IGenericAction<IRequestReason[]>,
): IRequestReason[] {
  switch (action.type) {
    case REQUESTREASONS_RECEIVED:
      return action.payload;
    default:
      return state;
  }
}
