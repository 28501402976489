import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { mapToIPartner, mapToIPartnerBE, mapToIPartners } from '../../common/utils/partner.utils';
import { PartnerActions, SgwRequestActions } from '../actions';
import { SnackBarActions } from '../actions/Snackbar.actions';
import { sgwPartnersApi } from '../api';
import { selectCountries } from '../selectors';
import { translate } from '../../translations/translate';
import { ICall, ICountry, PartnerType } from '../../types';
import { genericErrorHandler } from './errors.sagas';

export function* fetch({ payload }: ReturnType<typeof PartnerActions.fetch>): SagaIterator {
  const response: ICall<typeof sgwPartnersApi.fetch> = yield call(sgwPartnersApi.fetch, payload);
  if (response?.data.data) {
    const countries: ICountry[] = yield select(selectCountries);
    yield put(PartnerActions.set(mapToIPartner(response?.data.data, countries)));
  }
}

export function* fetchBulk({ payload }: ReturnType<typeof PartnerActions.fetchBulk>): SagaIterator {
  const response: ICall<typeof sgwPartnersApi.fetchBulk> = yield call(sgwPartnersApi.fetchBulk, payload);
  if (response?.data.data) {
    const countries: ICountry[] = yield select(selectCountries);
    yield put(PartnerActions.setList(mapToIPartners(response?.data.data, countries)));
  }
}

export function* saveRequestPartner({
  payload: { requestId, partner, variant },
}: ReturnType<typeof PartnerActions.saveRequestPartner>): SagaIterator {
  try {
    const countries: ICountry[] = yield select(selectCountries);
    const response: ICall<typeof sgwPartnersApi.save> = yield call(sgwPartnersApi.save, mapToIPartnerBE(partner));
    if (response?.data.data) {
      const partner = mapToIPartner(response?.data.data, countries);
      yield put(PartnerActions.set(partner));
      if (requestId) {
        yield put(SgwRequestActions.linkPartner({ partnerId: `${partner.id}`, requestId, variant }));
      }
      if (variant !== PartnerType.requester) {
        yield put(SnackBarActions.setSuccess(translate('sgw.partners.form.feedback.saveSuccess')));
      }
      return response?.data.data.id;
    }
  } catch (e) {
    yield put(
      SnackBarActions.setFailure({
        feedback: translate('sgw.partners.form.feedback.saveFailure'),
      }),
    );
  }
}

export default function* sgwRequestsSagas(): SagaIterator {
  yield takeEvery(PartnerActions.fetch.type, genericErrorHandler(fetch));
  yield takeEvery(PartnerActions.fetchBulk.type, genericErrorHandler(fetchBulk));
  yield takeEvery(PartnerActions.saveRequestPartner.type, genericErrorHandler(saveRequestPartner));
}
