import React, { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { Button, Icon, IconButton } from '../../atoms';
import { COLORS } from '../../../theme';
import { translate } from '../../../translations/translate';
import { ButtonVariant } from '../../../types';
import { useGenericStyles, useGeoDrawingContext } from '../../../hooks';
import { Visible } from '../../common/layout/Visible.component';
import { useSelector } from 'react-redux';
import { getCopyPhaseOptions, getPhases } from '../../../store/selectors/sgw';
import { Select } from '../../atoms/Select/Select.component';
import { Disableable } from '../../atoms';

const useStyles = createUseStyles({
  addContainer: {
    flexDirection: 'column',
    height: '150px',
    borderBottom: `1px solid ${COLORS.lightSilver}`,
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  addIcon: {
    height: '50px',
    width: '50px',
    border: `1px solid ${COLORS.lightSilver}`,
    borderRadius: '25px',
    padding: '12px',
  },
  copyContainer: {
    minHeight: '84px',
    borderBottom: `1px solid ${COLORS.lightSilver}`,
  },
  copyFlowContainer: {
    minHeight: '84px',
  },
});

interface IProps {
  phaseId: string;
}

export const MapActionButtons: FC<IProps> = ({ phaseId }) => {
  const C = useStyles();
  const { flex, spacing, focus } = useGenericStyles();
  const { coordinates, geoDrawingIndexInEdit, onAddNew, isAddNew, requestId, addNewGeoDrawings } =
    useGeoDrawingContext();
  const [isCopyFlow, setIsCopyFlow] = useState(false);
  const [selectedPhaseIdToCopy, setSelectedPhaseIdToCopy] = useState<string>();
  const phases = useSelector(getPhases(requestId));
  const options = useSelector(getCopyPhaseOptions(requestId, phaseId));
  const addInfoTranslation = translate('sgw.map.drawOnMap.phase.addInfo');

  const resetFlow = useCallback(() => {
    setIsCopyFlow(false);
    setSelectedPhaseIdToCopy(undefined);
  }, []);

  useEffect(() => {
    resetFlow();
  }, [phaseId, resetFlow]);

  useEffect(() => {
    if (!selectedPhaseIdToCopy && phases[0]?.id) {
      setSelectedPhaseIdToCopy(`${options[0]?.value}`);
    }
  }, [options, phases, selectedPhaseIdToCopy]);

  const _onStartCopyFlow = () => setIsCopyFlow(true);

  const _onChange = (e: SyntheticEvent<HTMLSelectElement>) => setSelectedPhaseIdToCopy(e.currentTarget.value);

  const _onCopy = () => {
    const selectedPhase = phases.find(({ id }) => `${id}` === selectedPhaseIdToCopy);
    const drawingsToCopy = [...(selectedPhase?.sgwGeoDrawings || [])];
    addNewGeoDrawings(drawingsToCopy);

    resetFlow();
  };

  const _onAddNew = () => onAddNew(true);

  return (
    <>
      <Visible visible={!isAddNew && !isCopyFlow}>
        <Disableable
          disabled={geoDrawingIndexInEdit !== undefined || isAddNew || !coordinates}
          className={classNames(flex.center, C.addContainer, focus.on)}
          onClick={_onAddNew}
          role="button"
          tabIndex={0}
          title={addInfoTranslation}
        >
          <Icon.Add className={classNames(C.addIcon, spacing.marginBottom.ML)} description={addInfoTranslation} />
          {addInfoTranslation}
        </Disableable>
        <Visible visible={!!options.length}>
          <div className={classNames(flex.center, C.copyContainer)}>
            <IconButton
              onClick={_onStartCopyFlow}
              icon="copy"
              size="small"
              variant={ButtonVariant.Outline}
              type="button"
            >
              {translate('sgw.map.drawOnMap.phase.copyButton')}
            </IconButton>
          </div>
        </Visible>
      </Visible>
      <Visible visible={isCopyFlow}>
        <div className={classNames(C.copyFlowContainer, spacing.margin.XS)}>
          <span>{translate('sgw.map.drawOnMap.phase.copyPhase')}</span>
          <Select
            data-testid={'PhasesSelect'}
            name="phase"
            onSelect={_onChange}
            options={options}
            label={translate('sgw.map.drawOnMap.phase.copyPhase')}
            emptyOption={false}
          />
          <div className={classNames(flex.alignRight)}>
            <Button size="small" variant={ButtonVariant.Negative} onClick={() => setIsCopyFlow(false)}>
              {translate('general.cancel')}
            </Button>
            <Button
              size="small"
              variant={ButtonVariant.Negative}
              disabled={!options?.length}
              type="button"
              onClick={_onCopy}
            >
              {translate('sgw.map.drawOnMap.phase.copyDone')}
            </Button>
          </div>
        </div>
      </Visible>
    </>
  );
};
