import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../theme';

export const usePhaseAddressStyles = createUseStyles({
  autocomplete: {
    width: '200px',
    marginRight: '15px',
    '& input': {
      font: 'inherit',
      fontFamily: 'inherit',
    },
  },
  option: {
    font: 'inherit',
    fontFamily: 'inherit',
  },
  textField: {
    marginRight: '15px !important',
    '& label, & input': {
      font: 'inherit',
      fontFamily: 'inherit',
    },
  },
  empty: {
    paddingLeft: '10px',
    paddingTop: '10px',
    fontStyle: 'italic',
  },
  table: {
    '& th, & td': {
      font: 'inherit',
      fontFamily: 'inherit',
    },
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
  },
  tableHeaders: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
    border: 'inherit',
    borderBottom: `1px solid ${COLORS.borderGrey}`,
  },
});
