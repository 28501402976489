import * as React from 'react';
import * as L from 'leaflet';
import { EditMap } from './EditMap.component';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FeatureLayer } from 'react-esri-leaflet';
import {
  MAP_CARFREEZONE_GATES_URL,
  MAP_CARFREEZONE_URL,
  MAP_DEFAULT_ZOOM,
  MAP_FEATURE_COLOR,
  MAP_FEATURE_SELECTED_COLOR,
} from '../../../common/constants';
import {
  ICarFreeZoneFeature,
  IEditMapCarFreeZoneProps,
  IGateFeature,
  IPublicDomainIntake,
  IStyleableLayer,
} from '../../../types';
import centreOfMass from '@turf/center-of-mass';
import { getCarFreeZoneByGisId } from '../../../common/utils/requestUtils';
import { MultiPolygon, Polygon } from 'geojson';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectApplicationFeatures, selectCarFreeZones } from '../../../store/selectors';
import { createUseStyles } from 'react-jss';
import dotSvg from '../../../assets/images/dot.svg';

const styles = {
  selected: {
    color: MAP_FEATURE_SELECTED_COLOR,
    strokeColor: MAP_FEATURE_SELECTED_COLOR,
  },
  unSelected: {
    color: MAP_FEATURE_COLOR,
    strokeColor: MAP_FEATURE_COLOR,
  },
};

const useStyles = createUseStyles(() => ({
  tooltip: {
    width: '400px',
    padding: '15px',
    borderRadius: '15px',
    textAlign: 'center',
    ' & > p': {
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      margin: 'auto',
    },
  },
}));

const icon = L.icon({ iconUrl: dotSvg, iconSize: [15, 15] });

export const EditMapCarFreeZone: FC<IEditMapCarFreeZoneProps> = ({ geometry, onChange, gisId }) => {
  const C = useStyles();
  const [geometryBounds, setGeometryBounds] = useState<Polygon | MultiPolygon>(geometry);
  const refFeatureLayers = useRef<{ feature: ICarFreeZoneFeature; layer: any }[]>([]);
  const { register, setValue, trigger, clearErrors } = useFormContext<IPublicDomainIntake>();
  const applicationFeatures = useSelector(selectApplicationFeatures);
  const carFreeZones = useSelector(selectCarFreeZones);

  useEffect(() => {
    register('carFreeZone', { required: true, shouldUnregister: true });
    register('carFreeZoneId', { required: true, shouldUnregister: true });
  }, [register]);

  const refreshFeature = useCallback(
    ({ feature, layer }: any) => {
      layer.setStyle(gisId === feature.properties.GISID_GEBIED ? styles.selected : styles.unSelected);
    },
    [gisId],
  );

  useEffect(() => {
    setGeometryBounds(geometry);
  }, [geometry]);

  useEffect(() => {
    refFeatureLayers.current.forEach(refreshFeature);
    // eslint-disable-next-line
  }, [gisId]);

  const onClick = (feature: ICarFreeZoneFeature) => {
    setValue('gisId', feature.properties.GISID_GEBIED, { shouldDirty: true });
    setValue('carFreeZoneId', getCarFreeZoneByGisId(carFreeZones, feature.properties.GISID_GEBIED)?.id, {
      shouldDirty: true,
    });
    trigger('gisId');
    clearErrors('carFreeZone');
    clearErrors('carFreeZoneId');
    setGeometryBounds(feature.geometry);
    saveCarFreeZone(feature);
  };

  const saveCarFreeZone = useCallback(
    (feature: ICarFreeZoneFeature) => {
      const { geometry } = centreOfMass(feature.geometry);

      onChange?.({
        carFreeZoneId: getCarFreeZoneByGisId(carFreeZones, gisId)?.id,
        // @ts-ignore
        geometry,
        gisId: feature.properties.GISID_GEBIED,
        selectedZone: feature.properties,
      });
    },
    [carFreeZones, gisId, onChange],
  );

  const onEachCarFreeZone = (feature: ICarFreeZoneFeature, layer: IStyleableLayer) => {
    const { GISID_GEBIED, HANDHAVING, FIRST_NAAM_GEBIED, VENSTERTIJD } = feature.properties;

    layer.bindTooltip(`<p>${HANDHAVING} ${FIRST_NAAM_GEBIED}<br/>${VENSTERTIJD}</p>`, {
      className: C.tooltip,
      direction: 'top',
    });

    layer.on('click', () => {
      layer.openTooltip();
      onClick(feature);
    });

    if (GISID_GEBIED === gisId) {
      layer.setStyle?.(styles.selected);
    }
    // Save layers
    refFeatureLayers.current.push({ feature, layer });
  };

  const onEachGate = (feature: IGateFeature, layer: IStyleableLayer) => {
    const { SAS } = feature.properties;
    layer.bindTooltip(`<p>${SAS}</p>`);
    layer.setIcon?.(icon);
  };

  return (
    <EditMap
      geometryBounds={geometryBounds}
      maxZoom={MAP_DEFAULT_ZOOM + 3}
      extraLayers={
        applicationFeatures?.integrateDigipolisArcGis && [
          <FeatureLayer url={MAP_CARFREEZONE_URL} onEachFeature={onEachCarFreeZone} />,
          <FeatureLayer minZoom={MAP_DEFAULT_ZOOM + 2} url={MAP_CARFREEZONE_GATES_URL} onEachFeature={onEachGate} />,
        ]
      }
    />
  );
};
