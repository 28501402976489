import { EditMap } from './EditMap.component';
import { FC, useState } from 'react';
import { Polyline } from './features/Polyline.component';
import { LatLng, latLngBounds } from 'leaflet';
import { IEditMapPolylineProps } from '../../../types';
import { getLatLng2D, getLatLngTuple2DReversed } from '../../../common/utils/mapUtils';

export const EditMapParkingBan: FC<IEditMapPolylineProps> = ({ conflicts, geometry, onChange }) => {
  const [latLngs] = useState<LatLng[]>(getLatLng2D(geometry.coordinates));

  const onDragEnd = (coordinates: LatLng[]) => {
    onChange?.({
      geometry: {
        ...geometry,
        coordinates: getLatLngTuple2DReversed(coordinates),
      },
    });
  };

  return (
    <EditMap
      bounds={latLngBounds(latLngs)}
      conflicts={conflicts}
      extraLayers={
        !!geometry?.coordinates ? (
          <Polyline draggable onDragEnd={onDragEnd} positions={getLatLng2D(geometry.coordinates)} />
        ) : null
      }
    />
  );
};
