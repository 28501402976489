import { TENANT_RECEIVED } from '../../common/actions/types';
import { IGenericAction } from '../actions/actions';
import { ITenant } from '../../types';

const INITIAL_STATE: ITenant | {} = {};

export default function tenant(
  state: ITenant | {} = INITIAL_STATE,
  action: IGenericAction<ITenant | {}>,
): ITenant | {} {
  switch (action.type) {
    case TENANT_RECEIVED:
      return action.payload;
    default:
      return state;
  }
}
