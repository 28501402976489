import { IDefaultAction } from '../actions/actions';
import { ApplicationActions } from '../actions/workflow.actions';

const INITIAL_STATE: boolean = false;

export default function authenticated(state: boolean = INITIAL_STATE, action: IDefaultAction): boolean {
  switch (action.type) {
    case ApplicationActions.authenticated.type:
      return true;
    case ApplicationActions.logout.type:
      return false;
    default:
      return state;
  }
}
