import * as React from 'react';
import intl from 'react-intl-universal';
import './overviewElements.scss';

import * as A from 'antwerp-core-react-branding';
import { onEnter } from '../../../common/utils/keyboardUtils';

import { OverviewProperties } from './overViewType';

type TextElement = string | JSX.Element;
export type OverviewSubtitleProperties = { title: TextElement; isPrimary?: boolean };
export type OverviewRowProperties = { title: TextElement; description: TextElement };

export const OverviewSubtitle = (props: OverviewSubtitleProperties) => (
  <p className={props.isPrimary ? 'u-text-primary u-text-bold' : 'u-text-secondary u-text-bold'}>{props.title}</p>
);

export const OverviewItem = (props: OverviewRowProperties) => (
  <li>
    <div className="row">
      <div className="col-xs-12 col-md-6 u-text-light">{props.title}</div>
      <div className="col-xs-12 col-md-6">{props.description}</div>
    </div>
  </li>
);

export const OverviewList = (props: any) => <ul className="a-list a-list--lined has-icon-left">{props.children}</ul>;

export const OverviewEditLink = (props: OverviewProperties) => {
  if (props.onEdit) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        className="overview__editlink focusable"
        onClick={props.onEdit}
        onKeyPress={onEnter(props.onEdit)}
        tabIndex={0}
      >
        {intl.get('general.editdata')}
      </a>
    );
  }
  return null;
};

export const OverviewSection = (props: { children: any }) => {
  return (
    //@ts-ignore
    <A.Spacing type={A.SpacingStyle.MarginBottom} className="overview">
      {props.children}
    </A.Spacing>
  );
};
