import { takeEvery } from 'redux-saga/effects';

import { STORE_OAUTH_DATA, STORE_OAUTH_TOKEN } from '../../common/constants';
import { registerStorageDeletedHandler } from '../../common/utils/storageListenerUtils';
import { SagaIterator } from 'redux-saga';
import { ApplicationActions } from '../actions/workflow.actions';

function registerDeleteHandlers() {
  registerStorageDeletedHandler(STORE_OAUTH_TOKEN);
  registerStorageDeletedHandler(STORE_OAUTH_DATA);
}

export default function* storageSaga(): SagaIterator {
  yield takeEvery(ApplicationActions.init, registerDeleteHandlers);
}
