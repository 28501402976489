import React from 'react';
import { ButtonProps } from './button.types';

export enum WorkflowButtonOrientations {
  left = 'left',
  right = 'right',
}

export enum WorkflowType {
  Sgw = 'Sgw',
  ASign = 'Asign',
}

export interface IWorkflowButtonProps extends ButtonProps {
  disabled?: boolean;
  label: string | JSX.Element;
  onClick?(e?: React.MouseEvent<HTMLElement>): void;
  orientation?: WorkflowButtonOrientations;
  visible?: boolean;
}

export const SGW_WORKFLOW_STEPS = ['start', 'type', 'partners', 'phases', 'map', 'attachments', 'overview'];

export enum SgwWorkflowStep {
  StartRequest = 1,
  Type,
  Partners,
  Phases,
  Map,
  Attachments,
  Overview,
  Success,
}

export enum AsignWorkflowSteps {
  Reason = 1,
  Type,
  Period,
  Location,
  Personal,
  Payment,
}
