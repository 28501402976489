import * as React from 'react';
import { FC, useState } from 'react';
import * as A from 'antwerp-core-react-branding';
import { getUrl } from '../../../common/utils/urlUtils';
import { Environment } from '../../../config/environment';
import { Visible } from '../layout/Visible.component';
import { useOnClickOutside } from '../../../hooks';
import { useOnEscape } from '../../../hooks';
import classNames from 'classnames';
import { appUrls } from '../../../common/constants';
import { translate } from '../../../translations/translate';

export const Toggle: FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const onClick = () => setShowMenu(false);
  const ref = useOnClickOutside(() => setShowMenu(false));
  useOnEscape(() => setShowMenu(false));

  return (
    <div ref={ref} className={classNames('m-flyout', 'm-flyout--right', 'm-flyout--sm', showMenu ? ' is-open' : '')}>
      <div className="a-switch__toggle">
        <div className="a-toggle__labels">
          <Visible visible={!showMenu}>
            {/*@ts-ignore*/}
            <A.IconButton icon="bars" className="toggle" onClick={() => setShowMenu(true)}>
              <span className="u-screen-reader-only">Open menu</span>
            </A.IconButton>
          </Visible>
          <Visible visible={showMenu}>
            {/*@ts-ignore*/}
            <A.IconButton icon="close" className="a-button--danger" onClick={() => setShowMenu(false)}>
              <span className="u-screen-reader-only">Close menu</span>
            </A.IconButton>
          </Visible>
        </div>
      </div>
      <div className="m-flyout__content has-padding">
        <ul className="m-nav-list">
          <li>
            <a onClick={onClick} href={getUrl(Environment.baseFrontEndUrl, appUrls.projectType)}>
              {translate('newrequest.title')}
            </a>
          </li>
          <li>
            <a onClick={onClick} href={getUrl(Environment.baseFrontEndUrl, appUrls.myRequests.base)}>
              {translate('myRequests.title')}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
