import './layout.scss';

import * as React from 'react';

import { FC } from 'react';
import { Header } from '../header/Header.component';
import { Hero } from './Hero.component';
import { Footer } from './Footer.component';

export const Layout: FC<React.PropsWithChildren> = ({ children }) => (
  <section className="layout">
    <Header />
    <Hero />
    <section className="content__wrapper">
      <div className="content col-lg-10">{children}</div>
    </section>
    <Footer />
  </section>
);
