import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { IWithClassName } from '../../../types';
import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../theme';
import { Spacing, SpacingStyle } from 'antwerp-core-react-branding';
import { Visible } from '../../common/layout/Visible.component';
import { Icon } from '../../atoms';
import classNames from 'classnames';
import { useGenericStyles } from '../../../hooks';
import { onSpace } from '../../../common/utils/keyboardUtils';

interface IStyleProps {
  collapsed: boolean;
  locked?: boolean;
}

export interface IAccordionProps extends IStyleProps, IWithClassName, PropsWithChildren {
  header: ReactNode;
  index: number | string;
  onClick?(index: number | string): void;
}

const useStyles = createUseStyles<string, IStyleProps>({
  content: {
    backgroundColor: COLORS.white,
    borderBottom: `1px solid ${COLORS.borderGrey}`,
    borderLeft: `1px solid ${COLORS.borderGrey}`,
    borderRight: `1px solid ${COLORS.borderGrey}`,
    display: 'block',
    maxHeight: 5000,
    overflow: 'auto',
    padding: 0,
    visibility: 'visible',
    transition: 'visibility .5s max-height .5s ease-out',
  },
  contentCollapsed: {
    borderBottom: 'none',
    maxHeight: 0,
    transition: 'visibility .5s max-height .5s ease-out',
    visibility: 'hidden',
  },
  header: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.borderGrey}`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '3rem',
    padding: '0.75rem',
    position: 'relative',
    textAlign: 'left',
    transition: 'background 250ms ease-in-out',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: COLORS.lightGrey,
    },
  },
  headerContent: {
    width: '100%',
  },
  headerIcon: {
    fontSize: '1.125rem',
    paddingLeft: '0.75rem',
    paddingRight: '0.5rem',
  },
  visibleMd: {
    '@media only screen and (min-width: 48rem)': {
      display: 'block',
      visibility: 'visible',
    },
  },
  invisibleXs: {
    display: 'none',
    visibility: 'hidden',
  },
});

export const Accordion: FC<IAccordionProps> = (props) => {
  const { children, className, collapsed, header, index, locked = false, onClick } = props;
  const C = useStyles(props);
  const { focus } = useGenericStyles();
  const [_collapsed, setCollapsed] = useState<boolean>(collapsed || locked);
  const toggleCollapse = () => {
    if (!locked) {
      onClick?.(index);
      setCollapsed((collapsed) => !collapsed);
    }
  };

  useEffect(() => {
    !locked && setCollapsed(collapsed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  useEffect(() => {
    locked && setCollapsed(locked);
  }, [locked]);

  return (
    <div className={className}>
      <div
        tabIndex={0}
        className={classNames('accordion__header', C.header, focus.on)}
        onClick={toggleCollapse}
        onKeyDown={onSpace(toggleCollapse)}
      >
        <div className={C.headerContent}>{header}</div>
        <Visible
          visible={collapsed}
          defaultComponent={<Icon.AngleUp className={classNames(C.headerIcon, C.visibleMd, C.invisibleXs)} />}
        >
          <Icon.AngleDown className={classNames(C.headerIcon, C.visibleMd, C.invisibleXs)} />
        </Visible>
      </div>
      <div className={classNames('accordion__content', C.content, _collapsed && C.contentCollapsed)}>
        {/* @ts-ignore */}
        <Spacing type={SpacingStyle.Margin}>{children}</Spacing>
      </div>
    </div>
  );
};
