import { IPublicDomainIntakeType } from '../../../types';
import * as A from '../actions';

/**
 * Create a generic action with a type and payload
 */
export function genericAction<T>(type: string, payload: T, error?: Error): A.IGenericAction<T> {
  return {
    error,
    payload,
    type,
  };
}

/**
 * Create a default action with a type
 */
export function defaultAction(type: string): A.IDefaultAction {
  return {
    type,
  };
}

/**
 * Create a generic action with a type and payload and index
 */
export function publicDomainIntakeAction<T>(
  type: string,
  payload: T,
  index: number,
  pdiType: IPublicDomainIntakeType,
  error?: Error,
): A.IChangePDITypeAction<T, number, IPublicDomainIntakeType> {
  return {
    error,
    index,
    payload,
    pdiType,
    type,
  };
}
