import { Position } from 'geojson';
import { LatLng } from 'leaflet';
import { IGeometry } from '../../types';

export const coordinatesToLatLng0Level = ([longitude, latitude]: Position): LatLng => new LatLng(latitude, longitude);
export const coordinatesToLatLng1Level = (positions: Position[]): LatLng[] => positions.map(coordinatesToLatLng0Level);
export const coordinatesToLatLng2Level = (positions: Position[][]): LatLng[] =>
  positions.flatMap(coordinatesToLatLng1Level);
export const coordinatesToLatLng3Level = (positions: Position[][][]): LatLng[] =>
  positions.flatMap(coordinatesToLatLng2Level);

export const geometryToPositions = ({ type, coordinates }: IGeometry): LatLng[] => {
  switch (type) {
    case 'Point':
      return [coordinatesToLatLng0Level(coordinates as Position)];
    case 'MultiPoint':
      return coordinatesToLatLng1Level(coordinates as Position[]);
    case 'LineString':
      return coordinatesToLatLng1Level(coordinates as Position[]);
    case 'MultiLineString':
      return coordinatesToLatLng2Level(coordinates as Position[][]);
    case 'Polygon':
      return coordinatesToLatLng2Level(coordinates as Position[][]);
    case 'MultiPolygon':
      return coordinatesToLatLng3Level(coordinates as Position[][][]);
  }
};

export const geometryCalculateCenter = (geometry: IGeometry) => {
  const positions = geometryToPositions(geometry);
  return {
    lat: positions.reduce((a, b) => a + b.lat, 0) / positions.length,
    lng: positions.reduce((a, b) => a + b.lng, 0) / positions.length,
  } as LatLng;
};
