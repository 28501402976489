import { OverviewList } from '../../atoms';
import { PartnerType, SgwWorkflowStep, TRouterWithId } from '../../../types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../../store/selectors/sgw';
import { translate, translateIgnoreTS } from '../../../translations/translate';
import { getInitialRequestorValues } from '../../../store/selectors';
import { FC, useEffect } from 'react';
import { PartnerActions } from '../../../store/actions';
import { SpinnerLoader } from '../../atoms/SpinnerLoader/SpinnerLoader.component';

interface IProps {
  disableStep?: boolean;
}

export const RequestorInfo: FC<IProps> = ({ disableStep }) => {
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const request = useSelector(getRequest(requestId));
  const dispatch = useDispatch();
  const { requestorId: id } = request || {};
  const requestor = useSelector(getInitialRequestorValues(`${id}`));

  useEffect(() => {
    id && dispatch(PartnerActions.fetch(`${id}`));
  }, [dispatch, id]);

  const {
    capacity,
    ssn,
    street,
    streetNumber,
    vatNumber,
    city,
    companyName,
    bus,
    country,
    contactFirstName,
    contactLastName,
    contactPhoneNumber,
    contactEmailAddress,
  } = requestor;

  const variantTranslation = translateIgnoreTS(`sgw.partners.form.variant.${PartnerType.requester}`);

  return (
    <SpinnerLoader loading={!requestor}>
      <OverviewList>
        <OverviewList.Title step={disableStep ? undefined : SgwWorkflowStep.StartRequest}>
          {translate('sgw.overview.requestor.title')}
        </OverviewList.Title>

        <OverviewList.Item
          title={translate('sgw.overview.requestor.type')}
          description={translateIgnoreTS(`sgw.partners.form.capacity.options.${capacity}`, {
            variant: variantTranslation,
          })}
        />
        <OverviewList.Item
          title={translate('sgw.overview.requestor.ssn')}
          description={ssn}
          visible={capacity !== 'business'}
        />
        <OverviewList.Item title={translate('sgw.overview.requestor.vatNumber')} description={vatNumber} />
        <OverviewList.Item
          title={translate('sgw.overview.requestor.companyName')}
          description={companyName}
          visible={capacity === 'business'}
        />
        <OverviewList.Item
          title={translate('sgw.overview.requestor.address')}
          description={`${street} ${streetNumber} ${bus || ''}`}
        />
        <OverviewList.Item title={translate('sgw.overview.requestor.city')} description={city} />
        <OverviewList.Item title={translate('sgw.overview.requestor.country')} description={country?.name} />
        <OverviewList.Item
          title={translate('sgw.overview.requestor.contactName')}
          description={`${contactFirstName} ${contactLastName}`}
        />
        <OverviewList.Item
          title={translate('sgw.overview.requestor.contactPhoneNumber')}
          description={contactPhoneNumber}
        />
        <OverviewList.Item title={translate('sgw.overview.requestor.contactEmail')} description={contactEmailAddress} />
      </OverviewList>
    </SpinnerLoader>
  );
};
