import { Environment } from '../../config/environment';
import { API_BACKOFFICE_URL, API_GENERAL_URL, API_OAUTH_URL, API_USER_URL } from '../constants';
import { getUrl } from './urlUtils';

export function getApiGeneralUrl(...path: string[]): string {
  return getUrl(getApiGeneralBaseUrl(), ...path);
}

function getApiGeneralBaseUrl(): string {
  return getUrl(Environment.baseApiIUrl, API_GENERAL_URL);
}

export function getApiUserUrl(...path: any[]): string {
  return getUrl(getApiUserBaseUrl(), ...path.filter((x) => x).map((x) => x.toString()));
}

function getApiUserBaseUrl(): string {
  return getUrl(Environment.baseApiIUrl, API_USER_URL);
}

export function getApiBackofficeBaseUrl(...path: string[]): string {
  return getUrl(Environment.baseApiIUrl, API_BACKOFFICE_URL, ...path);
}

export const getApiOauthUrl = (...path: string[]): string => {
  return getUrl(Environment.baseApiIUrl, API_OAUTH_URL, ...path);
};

export function addQueryString(url: string, ...params: Array<{ key: string; value: any } | null>): string {
  if (params) {
    return `${url}?${params
      .filter((x) => x && x.key)
      .map((x) => `${x!.key}=${x!.value || ''}`)
      .join('&')}`;
  }
  return url;
}

export function queryParameter(key: string, value?: any, noEncode?: boolean) {
  if (key) {
    return {
      key,
      value: value ? (noEncode ? value : encodeURIComponent(value)) : null,
    };
  }
  return null;
}
