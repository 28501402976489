import { IApiPromise, ISgwPhase } from '../../types';
import axios from 'axios';
import { getApiUserUrl } from '../../common/utils/apiUtils';

export const phaseApi = {
  delete: (requestId: string, id: string): IApiPromise<ISgwPhase> =>
    axios.delete(getApiUserUrl(`/sgw/requests/${requestId}/phases/${id}`)),
  fetch: (requestId: string, id: string): IApiPromise<ISgwPhase> =>
    axios.get(getApiUserUrl(`/sgw/requests/${requestId}/phases/${id}`)),
  fetchList: (requestId: string) => axios.get(getApiUserUrl(`/sgw/requests/${requestId}/phases`)),
  save: (requestId: string, phase: ISgwPhase): IApiPromise<ISgwPhase> =>
    phase.id
      ? axios.patch(getApiUserUrl(`/sgw/requests/${requestId}/phases/${phase.id}`), phase)
      : axios.post(getApiUserUrl(`/sgw/requests/${requestId}/phases`), phase),
};
