// In docker containers we generate a js file from the config that gets inserted into the index.html
// @ts-ignore
import { IEnvironment } from '../domain';

// @ts-ignore
const ENV = window.ENV || process.env;

const getEnvVariable = (variable: string) => ENV[variable] || ENV[`REACT_APP_${variable}`];
export const ENVIRONMENT = getEnvVariable('HOST_ENV');
export const IS_STAGING = ENVIRONMENT === 'staging';
export const IS_DEVELOPMENT = ENVIRONMENT === 'development';
export const IS_PRODUCTION = ENVIRONMENT === 'production';

export const VERSION = getEnvVariable('VERSION');
export const SENTRY_DSN = getEnvVariable('SENTRY_DSN');
const getLocalToken = () =>
  getEnvVariable('LOCAL_TOKEN') && getEnvVariable('NODE_ENV') === 'development'
    ? JSON.parse(getEnvVariable('LOCAL_TOKEN'))
    : undefined;

export const Environment: IEnvironment = {
  baseApiIUrl: getEnvVariable('BASE_API_URL'),
  baseFrontEndUrl: getEnvVariable('BASE_FRONT_URL'),
  frontEndHost: getEnvVariable('BASE_API_URL'),
  oauthParams: {
    clientId: getEnvVariable('AS_CLIENT_ID'),
    clientSecret: getEnvVariable('AS_CLIENT_SECRET'),
    endpoint: `${getEnvVariable('BASE_API_URL')}/oauth/v2/token`,
    grantType: 'http://asign.com/grants/antwerpen',
    localToken: getLocalToken(),
  },
  oauthParamsAntwerpen: {
    clientId: getEnvVariable('ANTWERP_CLIENT_ID'),
    endpoint: getEnvVariable('OAUTH_ENDPOINT'),
    endpointAuth: getEnvVariable('OAUTH_ENDPOINT') + '/v2/authorize',
    redirectUri: `${getEnvVariable('FRONT_URL')}${getEnvVariable('BASE_FRONT_URL')}`,
  },
  oauthUrl: getEnvVariable('OAUTH_ENDPOINT') + '/v2/authorize',
};
