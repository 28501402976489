import { INITIALIZE_WORKFLOW, REQUEST_MESSAGES_RECEIVED, REQUEST_MESSAGES_RESET } from '../../common/actions/types';
import { IChangePDITypeAction, IGenericAction } from '../actions/actions';
import { IPublicDomainIntakeType, IRequestMessages } from '../../types';

export const INITIAL_STATE: IRequestMessages = {
  acl: {
    messages_can_edit: false,
    messages_can_view: false,
  },
  data: [],
};

export default function requestMessages(
  state: IRequestMessages | {} = INITIAL_STATE,
  action: IGenericAction<IRequestMessages> | IChangePDITypeAction<IRequestMessages, number, IPublicDomainIntakeType>,
): IRequestMessages | {} {
  switch (action.type) {
    case INITIALIZE_WORKFLOW:
    case REQUEST_MESSAGES_RESET:
      return INITIAL_STATE;
    case REQUEST_MESSAGES_RECEIVED:
      return { ...action.payload, data: action.payload.data || [] };
    default:
      return state;
  }
}
