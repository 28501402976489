import * as React from 'react';
import { StaticInfoPage } from './static-info-page';
import { FC, useCallback } from 'react';
import { Visible } from '../layout/Visible.component';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery } from '../../../hooks';
import { STATIC_INFO } from '../../../common/constants';
import { useOnEscape } from '../../../hooks';

export interface StaticInfoSnippetProperties extends React.PropsWithChildren {
  title: string;
  url: string;
}

export const StaticInfoSnippet: FC<StaticInfoSnippetProperties> = ({ title, url, children }) => {
  const history = useNavigate();
  const query = useQuery();
  const isModalVisible = useCallback(() => query.get(STATIC_INFO) === url, [query, url]);

  const onClose = useCallback(() => {
    history({ search: '' });
    query.delete(STATIC_INFO);
  }, [history, query]);

  useOnEscape(() => onClose());

  return (
    <>
      <Link to={`${window.location.pathname}?${STATIC_INFO}=${url}`} className="static-info-link">
        {title}
      </Link>
      <Visible visible={isModalVisible()}>
        <StaticInfoPage onClose={onClose}>{children}</StaticInfoPage>
      </Visible>
    </>
  );
};
