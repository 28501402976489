const TITLE_TOKEN: string = '--';

export const getSelectorTitle = (title: string = '') =>
  title.includes(TITLE_TOKEN) ? title.split(TITLE_TOKEN)[0].trim() : title;

export function getSelectorRemarks(title: string): string | undefined {
  if (title && title.indexOf(TITLE_TOKEN) > -1) {
    return title.split(TITLE_TOKEN)[1].trim();
  }
  return undefined;
}
