import L from 'leaflet';
import { FC } from 'react';
import { getLatLng1D } from '../../../common/utils/mapUtils';
import { Marker } from '../../common/leaflet/features/Marker.component';
import markerIconSvg from '../../../../src/assets/images/conflict.svg';

import { ConflictCategory } from '../../../types/sgwConflict.types';
import { Tooltip } from 'react-leaflet';
import { translateIgnoreTS } from '../../../translations/translate';
import { Point } from '@turf/turf';

interface IProps {
  geometry: Point;
  conflictCategory: ConflictCategory;
}

const markerIcon = L.icon({
  iconAnchor: [15, 15],
  iconUrl: markerIconSvg,
  iconRetinaUrl: markerIconSvg,
  iconSize: [30, 30],
  className: 'leaflet-venue-icon',
});

export const ConflictDrawingPoint: FC<IProps> = ({ geometry, conflictCategory }) => (
  <Marker icon={markerIcon} position={getLatLng1D(geometry.coordinates)}>
    <Tooltip>{translateIgnoreTS(`sgw.map.conflictOverview.conflictCategory.${conflictCategory}`)}</Tooltip>
  </Marker>
);
