import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCountryOptions, getDefaultCountry, selectCountries } from '../../../store/selectors';
import * as A from 'antwerp-core-react-branding';
import { useController, useFormContext } from 'react-hook-form';
import intl from 'react-intl-universal';
import { IControllableForm, PersonalFormFields } from '../../../common/form';
import { Capacity, IPartner } from '../../../types';
import { translate } from '../../../translations/translate';
import { createUseStyles } from 'react-jss';
import { ICON_COLORS } from '../../../theme';

export interface CountrySelectorProperties extends IControllableForm {
  disabled?: boolean;
}

const useStyles = createUseStyles({
  error: { border: `1px solid ${ICON_COLORS.danger} !important` },
});

export const CountrySelector: FC<CountrySelectorProperties> = ({ name, validate, className, disabled, ...props }) => {
  const C = useStyles();
  const countries = useSelector(selectCountries);
  const { clearErrors, setError, setValue, control } = useFormContext();
  const { watch } = useFormContext<IPartner>();

  const {
    field,
    fieldState: { error, isTouched },
  } = useController({ name, control, rules: { required: intl.get('fields.required'), validate } });
  const defaultCountry = useSelector(getDefaultCountry);
  const capacity = watch('capacity');
  const country = watch('country');

  const countryOptions = useSelector(getCountryOptions(capacity === Capacity.NonBelgianResident));

  const _onChange = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      const country = countries.find(({ country }) => country === e.currentTarget.value);
      setValue(PersonalFormFields.country, country);
      if (!country) {
        setError(name, { type: 'required', message: translate('fields.labelRequired', { label: props.label }) });
        setValue(name, '');
      } else {
        clearErrors(name);
      }
    },
    [countries, setValue, setError, name, props.label, clearErrors],
  );

  useEffect(() => {
    disabled && setValue(PersonalFormFields.country, defaultCountry);
    if (capacity === Capacity.NonBelgianResident && (!country || country?.country === defaultCountry?.country)) {
      setValue(PersonalFormFields.country, countries[0]);
    }
    if (capacity === Capacity.BelgianResident) setValue(PersonalFormFields.country, defaultCountry);

    clearErrors(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCountry, disabled, setValue, countries, capacity]);

  return (
    <div className={className}>
      {/*@ts-ignore*/}
      <A.Select
        {...props}
        className={error?.message ? C.error : undefined}
        meta={{ error: error?.message, touched: !!error?.message || isTouched }}
        value={field.value?.country}
        options={countryOptions}
        onChange={_onChange}
        required
        disabled={disabled}
      />
    </div>
  );
};
