import { useRef } from 'react';
import {
  LEAFLET_DRAW_CONTROL,
  POLYGON_BUTTON,
  POLYLINE_BUTTON,
} from '../components/molecules/MapDrawOverlay/constants';
import { translate } from '../translations/translate';
import { GeoDrawingType } from '../types';

export const useLeafletDrawButtons = () => {
  const polygonButtonRef = useRef<HTMLElement | null>();
  const polylineButtonRef = useRef<HTMLElement | null>();
  const cancelButtonRef = useRef<HTMLElement | null>();
  const leafletContainer = useRef<HTMLElement | null>();

  const getPolygonButton = () => {
    if (!polygonButtonRef.current) {
      polygonButtonRef.current = document.querySelector(POLYGON_BUTTON) as HTMLElement;
    }
    return polygonButtonRef.current;
  };

  const getPolylineButton = () => {
    if (!polylineButtonRef.current) {
      polylineButtonRef.current = document.querySelector(POLYLINE_BUTTON) as HTMLElement;
    }
    return polylineButtonRef.current;
  };

  const getDrawContainer = () => {
    if (!leafletContainer.current) {
      leafletContainer.current = document.querySelector(LEAFLET_DRAW_CONTROL) as HTMLElement;
    }
    return leafletContainer.current;
  };

  const hideButton = (button: HTMLElement | null) => button?.style.setProperty('display', 'none');
  const showElement = (button: HTMLElement | null) => button?.style.removeProperty('display');

  const hideButtons = () => {
    hideButton(getPolygonButton());
    hideButton(getPolylineButton());
    hideButton(getDrawContainer());
  };

  const simulateClick = (el?: Element | null) =>
    el?.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
      }),
    );

  const findCancelButton = () => {
    const xpathResults = document.evaluate(`//a[contains(., '${translate('leaflet.actionsText')}')]`, document);
    const cancelButton = xpathResults.iterateNext();
    if (cancelButton) cancelButtonRef.current = cancelButton as HTMLElement;
  };

  const startDrawing = (geoDrawingType: GeoDrawingType) => {
    showElement(getDrawContainer());
    if (geoDrawingType === GeoDrawingType.constructionzone) {
      showElement(getPolygonButton());
      simulateClick(getPolygonButton());
    } else {
      showElement(getPolylineButton());
      simulateClick(getPolylineButton());
    }

    findCancelButton();
  };

  const stopDrawing = () => simulateClick(cancelButtonRef.current);

  return { hideButtons, startDrawing, stopDrawing };
};
