import * as React from 'react';
import * as A from 'antwerp-core-react-branding';
import intl from 'react-intl-universal';
import { FC } from 'react';
import { IPublicDomainIntake } from '../../../types';

interface IProps {
  intakes: IPublicDomainIntake[];
  onDelete(intake: IPublicDomainIntake): void;
  onSelect(intake: IPublicDomainIntake): void;
  header(intake: IPublicDomainIntake): string | JSX.Element;
}

export const MobileIntakesComponent: FC<IProps> = ({ intakes, onDelete, onSelect, header }) => (
  <div className="intakes-mobile">
    {intakes.map((x, i) => (
      <div key={i} className="m-card">
        <div className="m-card__body">
          <p>
            {x.valid && <span className="fa fa-check locations__intakes__intake--valid" />}
            {x.type ? x.type.name : '-'}
          </p>
          <p>{header(x)}</p>
          <div className="row">
            {x.index! >= 0 && (
              <div className="col-xs">
                {/*@ts-ignore*/}
                <A.IconButton
                  className="delete-location"
                  icon="trash"
                  level={A.Levels.Danger}
                  type={A.ButtonType.Transparent}
                  onClick={() => {
                    onDelete(x);
                  }}
                />
              </div>
            )}
            {/*@ts-ignore*/}
            <A.Button
              className="col-xs edit-location"
              size={A.Sizes.Small}
              text={intl.get('locationsform.editlocation')}
              type={A.ButtonType.Transparent}
              onClick={() => onSelect(x)}
            />
          </div>
        </div>
      </div>
    ))}
  </div>
);
