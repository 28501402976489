import { generatePath, useNavigate, useParams } from 'react-router';
import { SGW_WORKFLOW_STEPS, SgwWorkflowStep, TRouterWithId } from '../types';
import { appUrls } from '../common/constants';
import { useSelector } from 'react-redux';
import { getRequest } from '../store/selectors/sgw';
import { useCallback } from 'react';
import { Environment } from '../config/environment';

export const useWorkflowHistory = () => {
  const history = useNavigate();
  const { id } = useParams<keyof TRouterWithId>();
  const request = useSelector(getRequest(id));
  const path = `${Environment.baseFrontEndUrl}${appUrls.sgw.request.base}${
    request?.id ? appUrls.sgw.request.edit() : appUrls.sgw.request.create
  }`;

  const goToNextPage = useCallback(
    (step: number) =>
      step < SGW_WORKFLOW_STEPS.length && history(generatePath(path, { step: step + 1, id: request?.id })),
    [path, history, request?.id],
  );

  const goToPreviousPage = useCallback(
    (step: number) => step > 1 && history(generatePath(path, { step: step - 1, id: request?.id })),
    [path, history, request?.id],
  );

  const goToStep = useCallback(
    (step: SgwWorkflowStep) => history(generatePath(path, { step, id: request?.id })),
    [path, history, request?.id],
  );

  return { goToNextPage, goToPreviousPage, goToStep };
};
