import { WorkflowTemplate } from '../../templates/WorkflowTemplate/WorkflowTemplate.component';
import * as React from 'react';
import { translate } from '../../../translations/translate';
import { SgwWorkflowStep, WorkflowType } from '../../../types';
import { StartRequestForm } from '../../forms/sgw';
import { SgwAlert } from '../../molecules/SgwAlert/SgwAlert.component';

export const StartRequestPage = () => {
  return (
    <WorkflowTemplate
      title={translate('sgw.startRequest.title')}
      step={SgwWorkflowStep.StartRequest}
      type={WorkflowType.Sgw}
    >
      <SgwAlert />
      <StartRequestForm />
    </WorkflowTemplate>
  );
};
