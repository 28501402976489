import * as React from 'react';

export enum ButtonVariant {
  Default = 'default',
  Negative = 'negative',
  Outline = 'outline',
  Transparent = 'transparent',
}

export interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  level?: string;
  size?: string;
  variant?: ButtonVariant;
}
