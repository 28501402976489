import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../theme';

export const useRadioButtonListStyles = createUseStyles({
  list: {
    gap: '0.6rem',
  },
  radio: {
    marginLeft: '0.6rem',
  },
  input: {
    '&:checked': {
      boxShadow: '0 0 0 0.375rem #0064b4 inset',
    },
    backgroundColor: COLORS.white,
    borderRadius: '50%',
    boxShadow: '0 0 0 1px #b0b0b0 inset',
    content: '""',
    display: 'inline-block',
    height: '1.5rem',
    transitionDuration: '250ms',
    transitionProperty: 'background, box-shadow',
    transitionTimingFunction: 'ease-in-out',
    width: '1.5rem',
  },
});
