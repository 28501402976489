import React, { FC } from 'react';
import { Visible } from '../../common/layout/Visible.component';
import { LayersControl } from 'react-leaflet';
import { getLatLng3D } from '../../../common/utils/mapUtils';
import { LatLng } from 'leaflet';
import { Position } from 'geojson';
import { Polygon } from '../../common/leaflet/features/Polygon.component';
import { COLORS } from '../../../theme';

interface IProps {
  coords?: Position[][];
  onDragEnd?(coords: LatLng[][]): void;
  draggable?: boolean;
  info: string;
}

export const MapConstructionZoneOverlay: FC<IProps> = ({ coords, ...props }) => (
  <Visible visible={!!coords}>
    <LayersControl.Overlay checked name={props.info}>
      <Polygon {...props} positions={getLatLng3D(coords!)} color={COLORS.apple} lineWeight={5} />
    </LayersControl.Overlay>
  </Visible>
);
