import './EditMap.component.scss';
import React, { FC } from 'react';
import { BaseMap } from './BaseMap.component';
import { Conflicts } from './Conflicts.component';
import { IBaseMapProps, IConflictProps } from '../../../types';
import classnames from 'classnames';

interface IProps extends IBaseMapProps, IConflictProps {
  extraLayers?: React.ReactNode | React.ReactNode[];
}

export const EditMap: FC<IProps> = ({ className, conflicts, ...props }) => {
  return (
    <BaseMap className={classnames(className, 'leaflet__editmap')} zoomControl {...props}>
      <Conflicts conflicts={conflicts} />
    </BaseMap>
  );
};
