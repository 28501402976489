import axios from 'axios';
import { call, Effect, put, select, takeEvery } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { createErrorAlertAction } from '../../common/actions/creators/alert';
import { PublicDomainIntakeLicencePlatePatchPayload } from '../../common/actions/creators/publicDomainIntake';
import { createRequestReceivedAction, createRequestUpdatedAction } from '../../common/actions/creators/requests';
import {
  createRequestMessagesGetAction,
  createRequestMessagesResetAction,
} from '../../common/actions/creators/requestsMessages';
import {
  PDI_PATCH_LICENCEPLATES_REQUESTED,
  PERSIST_NEW_REQUEST_REQUESTED,
  PERSIST_REQUEST_REQUESTED,
  REQUEST_DELETE,
  REQUEST_REQUESTED,
  REQUEST_TEMP_DELETE,
} from '../../common/actions/types';
import { getRequest, patchCarfreezone, saveAttachment, saveRequest } from '../../common/api';
import { APP_MAX_ATTACH_SIZE, WORKFLOW_MIN_STEP, WORKFLOW_PATH_NEW } from '../../common/constants';
import debug from '../../common/debug';
import * as TempRequest from '../../common/tempRequestStorage';
import { getApiUserUrl } from '../../common/utils/apiUtils';
import { cleanRequestForApp } from '../../common/utils/requestUtils';
import { IGenericAction } from '../actions/actions';
import { createProcessIdleAction, createProcessStartedAction } from '../actions/creators';
import { ICall, IRequest, IRequestBE, IRequestFile, ISelect } from '../../types';
import { selectCarFreeZones } from '../selectors';
import IState from '../../state';
import { SagaIterator } from 'redux-saga';
import { genericErrorHandler } from './errors.sagas';
import { PublicDomainIntakeActions, RequestActions } from '../actions';
import { historyPush } from '../../common/utils/historyUtils';
import { CarFreeZoneApi } from '../api/carFreeZone.api';
import { CarFreeZoneActions } from '../actions/carFreeZone.actions';
import { PublicDomainType } from '../../common/enums';

function* requestRequest(action: IGenericAction<string>): IterableIterator<Effect> {
  const process = 'loadRequest';
  try {
    yield put(createProcessStartedAction(process));
    yield put(createRequestMessagesResetAction());

    const request: ICall<typeof getRequest> = yield call(getRequest, action.payload);

    const gates = request!.publicDomainIntakes?.flatMap((intake) => [
      intake.carFreeZoneGateEntrance,
      intake.carFreeZoneGateExit,
    ]);
    yield put(PublicDomainIntakeActions.saveGates(gates));

    const carFreeZones: ISelect<typeof selectCarFreeZones> = yield select(selectCarFreeZones);
    yield put(createRequestReceivedAction(cleanRequestForApp(request!, carFreeZones!)));

    if (request!.id) {
      yield put(createRequestMessagesGetAction(request!.id.toString()));
    }
  } catch (e) {
    debug('Request could not be loaded', e);
    yield put(
      createErrorAlertAction(`De aanvraag met id ${action.payload} kon niet geladen worden`, undefined, () =>
        historyPush(`/${WORKFLOW_PATH_NEW}/${WORKFLOW_MIN_STEP}`),
      ),
    );
  }
  yield put(createProcessIdleAction(process));
}

function* persistRequest(action: IGenericAction<IRequest>): IterableIterator<Effect> {
  try {
    const carFreeZones: ISelect<typeof selectCarFreeZones> = yield select(selectCarFreeZones);
    const response: ICall<typeof saveRequest> = yield call(saveRequest, action.payload, carFreeZones!);
    yield put(createRequestReceivedAction(response!));
  } catch (e) {
    throw e;
  }
}

function* persistNewRequest(action: IGenericAction<IRequest>): IterableIterator<Effect> {
  yield call(persistRequest, action);
}

function* updateLicencePlates(
  action: IGenericAction<PublicDomainIntakeLicencePlatePatchPayload>,
): IterableIterator<Effect> {
  const payload = action.payload;
  if (payload.intake && payload.intake.permittedPlates) {
    // Patch the licence plates
    const intake: ICall<typeof patchCarfreezone> = yield call(patchCarfreezone, payload.request, payload.intake);

    // Now get the specific request
    const originalRequest: IRequestBE = (yield select(
      (state: IState) => (state.myrequests || []).filter((x) => x.id === intake!.permitRequestId)[0],
    ))!;

    // Avoid mutability problems
    const request = { ...originalRequest };
    if (request) {
      // @ts-ignore
      request.publicDomainIntakes = request.publicDomainIntakes.map((x) => (x.id === intake!.id ? intake : x));
      const carFreeZones: ISelect<typeof selectCarFreeZones> = yield select(selectCarFreeZones);
      yield put(createRequestUpdatedAction(cleanRequestForApp(request, carFreeZones!)));

      yield put(createRequestReceivedAction(cleanRequestForApp(request, carFreeZones!)));
    }
  }
}

export function* saveTempAttachment(file: File) {
  const process = 'saveTempAttachments';

  yield put(createProcessStartedAction(process));
  try {
    const fileResponse: ICall<typeof saveAttachment> = yield call(saveAttachment, file);
    return {
      // @ts-ignore
      file: fileResponse![0].id,
      fileName: file.name,
      name: file.name,
    } as IRequestFile;
  } catch (e) {
    debug('The attachment could not be saved', e);
    yield put(createErrorAlertAction(intl.get('locationsform.maxSize', { maxSize: APP_MAX_ATTACH_SIZE })));
  } finally {
    yield put(createProcessIdleAction(process));
  }
}

export function* saveFirstTempAttachment(files: FileList) {
  const file = files?.item?.(0);
  if (!!file) {
    return (yield call(saveTempAttachment, file)) as IRequestFile;
  }
}

export function* deleteDraftRequest(action: IGenericAction<IRequest>) {
  const request = action.payload;
  if (request) {
    yield call(axios.delete, getApiUserUrl('requests', request.id));
  }
}

export function* deleteTempRequest() {
  if (TempRequest.isTempRequest()) {
    yield call(TempRequest.removeTempRequest);
  }
}

export function* savePublicDomainIntake({
  payload: { publicDomainIntakes = [] },
}: ReturnType<typeof PublicDomainIntakeActions.save>): SagaIterator {
  for (let pbi of publicDomainIntakes) {
    if (!!pbi.attachments?.[0] && !pbi.attachments?.[0]?.fileName) {
      const file: ICall<typeof saveTempAttachment> = yield call(saveTempAttachment, pbi.attachments[0]);
      yield put(PublicDomainIntakeActions.storeAttachment({ index: pbi.index || 0, file: file! }));
    }
  }

  const geometries = publicDomainIntakes
    .filter(({ geometry, type }) => !!geometry && type.type !== PublicDomainType.CarfreeZone)
    .map(({ geometry }) => geometry!);

  if (geometries.length) {
    const response: ICall<typeof CarFreeZoneApi.intersects> = yield call(CarFreeZoneApi.intersects, geometries);
    yield put(CarFreeZoneActions.setIntersectingZones(response!.data.data.map(({ id }) => id)));
  }
}

export function* saveRequestAttachment({ payload }: ReturnType<typeof RequestActions.saveAttachment>): SagaIterator {
  const file: ICall<typeof saveTempAttachment> = yield call(saveTempAttachment, payload);
  if (file) {
    yield put(RequestActions.storeAttachment([file]));
  }
}

export default function* requestsSaga(): SagaIterator {
  yield takeEvery(PDI_PATCH_LICENCEPLATES_REQUESTED, genericErrorHandler(updateLicencePlates));
  yield takeEvery(REQUEST_DELETE, genericErrorHandler(deleteDraftRequest));
  yield takeEvery(REQUEST_REQUESTED, genericErrorHandler(requestRequest));
  yield takeEvery(REQUEST_TEMP_DELETE, genericErrorHandler(deleteTempRequest));
  yield takeEvery([PERSIST_NEW_REQUEST_REQUESTED], genericErrorHandler(persistNewRequest));
  yield takeEvery([PERSIST_REQUEST_REQUESTED], genericErrorHandler(persistRequest));
  yield takeEvery(RequestActions.saveAttachment.type, genericErrorHandler(saveRequestAttachment));
  yield takeEvery(PublicDomainIntakeActions.save.type, genericErrorHandler(savePublicDomainIntake));
}
