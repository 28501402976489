import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SgwRequestActions } from '../store/actions';
import { useParams } from 'react-router';
import { IAttachment, SgwWorkflowStep, TRouterWithId } from '../types';
import {
  getEachPhaseHasAtLeastOneAttachmentWithSignalisationMap,
  getRequestIsNotUrgentOrHasUrgentAttachment,
  selectRequestAttachments,
} from '../store/selectors/sgw';
import { PhaseActions } from '../store/actions/phase.actions';
import { useWorkflowHistory } from './useWorkflowHistory.hook';
import { translate, translateHtml } from '../translations/translate';

export const useAttachmentsForm = () => {
  const { id } = useParams<keyof TRouterWithId>();
  const attachments = useSelector(selectRequestAttachments);
  const eachPhaseHasAtLeastOneAttachmentWithSignalisationMap = useSelector(
    getEachPhaseHasAtLeastOneAttachmentWithSignalisationMap(id!),
  );
  const requestIsNotUrgentOrHasUrgentAttachment = useSelector(getRequestIsNotUrgentOrHasUrgentAttachment(id!));

  const { goToNextPage } = useWorkflowHistory();
  const formMethods = useForm<{ attachments: IAttachment[] }>({
    defaultValues: { attachments },
  });

  const { reset, setError, clearErrors } = formMethods;

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(SgwRequestActions.fetch(id));
      dispatch(PhaseActions.fetchList(id));
      dispatch(SgwRequestActions.attachments.fetch(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    reset({ attachments });

    if (eachPhaseHasAtLeastOneAttachmentWithSignalisationMap && requestIsNotUrgentOrHasUrgentAttachment) {
      clearErrors('attachments');
    } else {
      const message = !requestIsNotUrgentOrHasUrgentAttachment
        ? translate('sgw.attachments.urgentRequestRequired')
        : translateHtml('sgw.attachments.signalisationMapRequired');

      setError('attachments', { type: 'required', message });
    }
  }, [
    attachments,
    clearErrors,
    eachPhaseHasAtLeastOneAttachmentWithSignalisationMap,
    requestIsNotUrgentOrHasUrgentAttachment,
    reset,
    setError,
  ]);

  const submitAttachments = formMethods.handleSubmit(() => {
    if (eachPhaseHasAtLeastOneAttachmentWithSignalisationMap && requestIsNotUrgentOrHasUrgentAttachment) {
      goToNextPage(SgwWorkflowStep.Attachments);
    }
  });

  return { formMethods, submitAttachments };
};
