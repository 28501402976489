import React from 'react';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store/state/store';
import { AppRoutes } from './components/routes/App.routes';
import { App } from './components/App.container';
import { GenericSnackBar } from './components/molecules/GenericSnackbar/GenericSnackbar.component';
import { getLocalUrl } from './common/utils/urlUtils';
import { appUrls } from './common/constants';
import { Route } from 'react-router';
import { DriverPrivacyPolicyPage } from './components';
import { IS_PRODUCTION } from './config/environment';
import { Error } from './components/pages/ErrorPage/Error.page';
import { AlternativeLoginPage } from './components/pages/AlternativeLoginPage/AlternativeLogin.page';
import intl from 'react-intl-universal';
import nl from './translations/messages-nl.json';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { setupInterceptors } from './common/api';
import { history } from './store/state/store';
import { ApplicationActions } from './store/actions/workflow.actions';
import { SentryBoundary } from './components/SentryBoundary.component';

intl.init({
  currentLocale: 'nl-BE',
  locales: {
    'nl-BE': nl,
  },
});
const container = document.getElementById('root');

const root = createRoot(container!);
setupInterceptors(history, store);
store.dispatch(ApplicationActions.init());

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path={getLocalUrl(appUrls.driverPrivacyPolicy)} element={<DriverPrivacyPolicyPage />} />
      {!IS_PRODUCTION && <Route path={getLocalUrl('token')} element={<AlternativeLoginPage />} />}
      <Route path={getLocalUrl(appUrls.error)} element={<Error />} />

      <Route
        path={appUrls.front}
        element={
          <>
            <GenericSnackBar />
            <App>
              <AppRoutes />
            </App>
          </>
        }
      />
    </Route>,
  ),
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SentryBoundary>
        <RouterProvider router={router} />
      </SentryBoundary>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
