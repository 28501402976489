import React, { FC } from 'react';
import { Sizes } from 'antwerp-core-react-branding';
import { createUseStyles } from 'react-jss';
import { ButtonVariant } from '../../../types/button.types';
import { IconButton } from '../IconButton/IconButton.component';
import { Visible } from '../../common/layout/Visible.component';
import intl from 'react-intl-universal';
import { COLORS } from '../../../theme';

export interface IFileListProps {
  visible?: boolean;
  onFileDelete?: (index: number) => void;
  onDeleteAll?: () => void;
  files?: File[];
}

const useStyles = createUseStyles({
  fileList: {
    lineHeight: '1',
    listStyle: 'none',
    margin: '1.5rem 0 0',
    padding: '0',
    '& > li': {
      backgroundColor: COLORS.lightGrey,
      lineHeight: '1.3',
      minHeight: '2.25rem',
      padding: '0.5rem 3rem 0.5rem 3rem',
      position: 'relative',
      '& > .fa': {
        left: '1.125rem',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
    '& > li:not(:last-child)': {
      marginBottom: '0.75rem',
    },
  },
  fileListItemName: {
    lineHeight: '1.3',
  },
  fileListItemClear: {
    position: 'absolute!important',
    right: 0,
    top: 0,
  },
  clearButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
});

export const FilesList: FC<IFileListProps> = ({ visible = true, files, onFileDelete, onDeleteAll }) => {
  const C = useStyles();

  if (!visible || !files?.length) {
    return null;
  }

  return (
    <>
      <ul className={C.fileList}>
        {files.map((x, i) => (
          <li key={`uploaded-${i}`}>
            <span className="fa fa-file-o"></span>
            <span className={C.fileListItemName}>{x.name}</span>
            <Visible visible={!!onFileDelete}>
              <IconButton
                className={C.fileListItemClear}
                icon="close"
                onClick={() => onFileDelete?.(i)}
                size={Sizes.Small}
                variant={ButtonVariant.Transparent}
              />
            </Visible>
          </li>
        ))}
      </ul>
      <Visible visible={!!files?.length && !!onDeleteAll}>
        <div className={C.clearButtonContainer}>
          <IconButton icon="close" onClick={onDeleteAll} variant={ButtonVariant.Negative} size={Sizes.Small}>
            {intl.get('attachments.clearAll')}
          </IconButton>
        </div>
      </Visible>
    </>
  );
};
