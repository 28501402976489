import * as React from 'react';

import { Locations } from 'antwerp-core-react-branding';
import { FC } from 'react';
import classNames from 'classnames';
import { Button } from '../Button/Button.component';
import { ButtonProps } from '../../../types';

export interface IconButtonProperties extends ButtonProps {
  location?: Locations;
  icon: string;
}

export const IconButton: FC<IconButtonProperties> = ({
  location = Locations.Left,
  icon,
  form,
  className,
  children,
  ...buttonProps
}) => {
  return (
    <Button
      {...buttonProps}
      className={classNames(className, children ? `has-icon-${location}` : 'has-icon')}
      form={form}
    >
      <i className={`fa fa-${icon}`} aria-label={icon} />
      {children}
    </Button>
  );
};
