import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import { CardSelector } from './CardSelector.component';
import { CardSelectorItem, CardSelectorItemProperties } from './CardSelectorItem.component';

interface GenericCardSelectorProperties<T> {
  activeIndex?: number;
  className?: string;
  data: T[];
  errorComponent?: JSX.Element;
  mapItem: (child: T) => CardSelectorItemProperties;
  onChange: (selected: T) => void;
  error?: FieldError;
}

export function GenericCardSelector<T>({
  className,
  activeIndex,
  errorComponent,
  error,
  data,
  mapItem,
  onChange,
}: GenericCardSelectorProperties<T>) {
  const items = (data || []).map((x) => mapItem(x)).map((x, i) => <CardSelectorItem key={i} {...x} />);

  const _onChange = (index: number) => {
    onChange?.(data[index]);
  };

  return (
    <CardSelector
      className={classNames(className)}
      activeIndex={activeIndex}
      errorComponent={errorComponent}
      onChange={_onChange}
      error={error}
      items={items}
    />
  );
}
