import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { appUrls } from '../../../common/constants';
import { historyPush } from '../../../common/utils/historyUtils';
import { useGenericStyles } from '../../../hooks';
import { COLORS } from '../../../theme';
import { translate } from '../../../translations/translate';
import { ButtonVariant } from '../../../types';
import { IconButton } from '../../atoms';

const useStyles = createUseStyles({
  info: {
    border: `2px solid ${COLORS.yellow}`,
    background: `${COLORS.yellow}25`,
    padding: '10px',
    fontWeight: 'bold',
    marginTop: '40px',
    marginBottom: '40px',
  },
});

export const NotAuthorizedPage: FC = () => {
  const C = useStyles();
  const G = useGenericStyles();
  return (
    <>
      <h1>U heeft geen toegang tot deze pagina</h1>
      <p className={C.info}>
        Mogelijks is er een storing of is het ingegeven website-adres niet geheel correct. Ingeval van een storing, neem
        dan contat op met onze dienst Tijdelijke Signalisatie (Mobiliteit en Parkeren Antwerpen) via onderstaande
        coördinaten.
      </p>
      <div className={G.flex.row}>
        <div className={G.flex.half}>
          Voor vragen rond een <b>parkeerverbod</b> en een <b> werk met beperkte signalisatie</b> neemt u contact op met
          de dienst Tijdelijke verkeerssignalisatie.
          <ul>
            <li>
              Mail:
              <a href="mailto:tijdelijke.verkeerssignalisatie@antwerpen.be">
                tijdelijke.verkeerssignalisatie@antwerpen.be
              </a>
            </li>
            <li> Telefonisch: 03 221 13 33, bereikbaar op:</li>
            <ul>
              <li>Maandag van 9.00 uur tot 13.00 uur</li>
              <li>Dinsdag van 12.30 uur tot 16.30 uur</li>
              <li>Woensdag van 9.00 uur tot 13.00 uur</li>
              <li>Donderdag van 12.30 uur tot 16.30 uur</li>
              <li>Vrijdag van 9.00 uur tot 13.00 uur</li>
            </ul>
          </ul>
        </div>
        <div className={G.flex.half}>
          <p>
            Voor vragen rond <b>grote werken</b> neem je contact op met de desbetreffende cluster op basis van de
            postcode waar jouw werf gelegen is. Onze dienst is bereikbaar van maandag tot vrijdag
          </p>

          <ul>
            <li> Cluster Noord (Postcodes: 2030 / 2040 / 2060 / 2100 / 2180 / 2170)</li>
            <ul>
              <li>
                Mail:<a href="mailto:werfsignalisatie_noord@antwerpen.be">werfsignalisatie_noord@antwerpen.be</a>{' '}
              </li>
              <li>Telefonisch (van 9.00 tot 13.00): 03 338 65 06</li>
            </ul>
            <li> Cluster Zuid (Postcodes: 2020 / 2050 / 2600 / 2660 / 2610 / 2140)</li>
            <ul>
              <li>
                {' '}
                Mail: <a href="mailto:werfsignalisatie_zuid@antwerpen.be ">werfsignalisatie_zuid@antwerpen.be </a>{' '}
              </li>
              <li> Telefonisch (van 9.00 tot 13.00): 03 338 27 73</li>
            </ul>
            <li>Cluster Midden (Postcodes: 2000 / 2018)</li>
            <ul>
              <li>
                Mail: <a href="mailto:werfsignalisatie_midden@antwerpen.be">werfsignalisatie_midden@antwerpen.be</a>{' '}
              </li>
              <li>Telefonisch (van 9.00 tot 13.00): 03 338 27 72</li>
            </ul>
          </ul>
        </div>
      </div>
      <IconButton
        type={'button'}
        variant={ButtonVariant.Default}
        icon={'chevron-left'}
        onClick={(e) => historyPush(appUrls.projectType)}
        className={G.spacing.marginBottom.LG}
      >
        {translate('general.goback')}
      </IconButton>
    </>
  );
};
