import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getPhaseById } from '../../../store/selectors/sgw';
import { useFlexStyles } from '../../../theme';
import { formatDateTimeToDisplayDate } from '../../../common/utils/dateUtils';
import { translate } from '../../../translations/translate';
interface IProps {
  phaseId: number;
  requestId: number;
  index: number;
}

export const PhaseNameDatesComponent: FC<IProps> = ({ phaseId, requestId, index }) => {
  const phase = useSelector(getPhaseById(`${requestId}`, phaseId));
  const G = useFlexStyles();
  return phase ? (
    <div className={G.row} key={phase.id}>
      <b>{phase.phaseName || translate('sgw.overview.phases.subTitle', { index })}</b>
      {`: ${formatDateTimeToDisplayDate(phase.dateFrom)} - ${formatDateTimeToDisplayDate(phase.dateUntil)}`}
    </div>
  ) : null;
};
