import { translate } from '../../../../translations/translate';
import { CheckBox, NumberInput } from '../../../atoms';
import { PublicDomainType } from '../../../../common/enums';
import { debounce } from '../../../../common/utils/debounceUtils';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { IPublicDomainIntake } from '../../../../types';
import { FC, useEffect } from 'react';
import { validateAddress } from '../../../../common/validation/validateAddress';
import { StreetField } from '../../../organisms';
import { useSpacingStyles } from '../../../../theme';
import { Required } from '../Required.component';

interface IProps {
  geocoding: boolean;
  cleanLocationValues(): void;
  initialStreet?: string;
  onSelectNewStreet(option: string): void;
}

export const LocationsFormStreetFields: FC<IProps> = ({
  geocoding,
  cleanLocationValues,
  initialStreet,
  onSelectNewStreet,
}) => {
  const {
    trigger,
    setValue,
    watch,
    register,
    formState: { errors },
  } = useFormContext<IPublicDomainIntake>();

  const type = watch('type');
  const street = watch('street');
  const streetNumberFrom = watch('streetNumberFrom');
  const streetNumberTo = watch('streetNumberTo');
  const streetNumberUnknown = watch('streetNumberUnknown');
  const { marginBottom, marginTop } = useSpacingStyles();

  useEffect(() => {
    register('streetFlag');
  }, [register]);

  useEffect(() => {
    setValue('streetFlag', street === initialStreet);
    if (street?.length && street.length >= 3) {
      debounce(
        'streetTouched',
        async () => {
          await trigger('street');
        },
        1000,
      );
    }
  }, [cleanLocationValues, initialStreet, setValue, street, trigger]);

  useEffect(() => {
    trigger();
  }, [streetNumberUnknown, street, trigger]);

  const onSelectOption = (option: string): void => {
    if (option !== initialStreet) {
      setValue('streetFlag', true);
      onSelectNewStreet(option);
    }
  };

  const onChangeStreetNumberUnknown = (value: boolean): void => {
    // AS-4689 We need to populate StreetNumber with '0' to disable validation for intake without house Numbers
    // and set it back to empty string to enable validation

    setValue('streetNumberUnknown', value);

    if (!streetNumberUnknown) {
      setValue('streetNumberFrom', '0');
      setValue('streetNumberTo', '0');
    } else {
      setValue('streetNumberFrom', '');
      setValue('streetNumberTo', '');
    }
  };

  const addressValidation = async (value?: string) =>
    (streetNumberUnknown && (await validateAddress(street))) || (await validateAddress(street, value));

  const streetValidation = async (value?: string) => {
    const validatedAddress = await validateAddress(value, undefined, translate('locationsform.noStreets'));
    if (street !== initialStreet) {
      return translate('locationsform.noStreets');
    }
    return validatedAddress;
  };

  return (
    <>
      <StreetField
        name="street"
        onSelectOption={onSelectOption}
        initialValue={initialStreet}
        onClear={cleanLocationValues}
        required
        validationRules={{
          required: true,
          minLength: {
            value: 3,
            message: translate('locationsform.noStreets'),
          },
          validate: streetValidation,
        }}
      />
      <Required required={!street} message={translate('locationsform.streetRequiredShort')} />
      {!streetNumberUnknown && (
        <NumberInput
          disabled={geocoding}
          label={
            type.type === PublicDomainType.CarfreeZone
              ? translate('locationsform.streetNumber')
              : translate('locationsform.streetNumberFrom')
          }
          {...register('streetNumberFrom', { validate: addressValidation, shouldUnregister: true })}
          value={streetNumberFrom}
          onChange={(e) => setValue('streetNumberFrom', e.currentTarget.value)}
          onBlur={(e) => setValue('streetNumberFrom', e.currentTarget.value)}
          meta={{ error: !!errors.streetNumberFrom, touched: !!errors.streetNumberFrom }}
          min={1}
          required={!streetNumberUnknown}
          key={`${errors.streetNumberFrom}`}
          validate={addressValidation}
          className={marginTop.XS}
        />
      )}

      {type.type !== PublicDomainType.CarfreeZone && (
        <>
          {!streetNumberUnknown && (
            <NumberInput
              disabled={geocoding}
              label={translate('locationsform.streetNumberTo', { shouldUnregister: true })}
              {...register('streetNumberTo')}
              value={streetNumberTo}
              onChange={(e) => setValue('streetNumberTo', e.currentTarget.value)}
              onBlur={(e) => setValue('streetNumberTo', e.currentTarget.value)}
              min={1}
              required={!streetNumberUnknown}
              meta={{ error: !!errors.streetNumberTo, touched: !!errors.streetNumberTo }}
              validate={addressValidation}
              className={marginBottom.SM}
            />
          )}
        </>
      )}
      <CheckBox
        {...register('streetNumberUnknown')}
        checked={streetNumberUnknown || false}
        label={translate('workflowsteps.specifyhousenumbercheckbox')}
        onChange={onChangeStreetNumberUnknown}
        className={marginBottom.SM}
      />
    </>
  );
};
