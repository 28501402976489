import React, { FC } from 'react';
import { Visible } from '../../common/layout/Visible.component';
import { LayersControl } from 'react-leaflet';
import { getLatLng2D } from '../../../common/utils/mapUtils';
import { LatLng } from 'leaflet';
import { Position } from 'geojson';
import { Polyline } from '../../common/leaflet/features/Polyline.component';

interface IProps {
  coords?: Position[];
  onDragEnd?(coords: LatLng[]): void;
  draggable?: boolean;
  info: string;
}

export const MapParkingBanOverlay: FC<IProps> = ({ coords, ...props }) => (
  <Visible visible={!!coords}>
    <LayersControl.Overlay checked name={props.info}>
      <Polyline {...props} positions={getLatLng2D(coords!)} lineWeight={5} />
    </LayersControl.Overlay>
  </Visible>
);
