import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { appUrls } from '../../../common/constants';
import { getLocalUrl } from '../../../common/utils/urlUtils';
import { SgwRequestActions } from '../../../store/actions';
import { getLatestSgwDraftRequest } from '../../../store/selectors/sgw';
import { translate } from '../../../translations/translate';
import { Alert } from '../Alert/Alert.component';
import { historyPush } from '../../../common/utils/historyUtils';

export const SgwAlert: FC = () => {
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const request = useSelector(getLatestSgwDraftRequest);
  const location = useLocation();

  useEffect(() => {
    dispatch(SgwRequestActions.list.fetch());
  }, [dispatch]);

  useEffect(() => {
    location.pathname === getLocalUrl(appUrls.sgw.request.base, appUrls.sgw.request.create) &&
      !!request &&
      setShowAlert(true);
  }, [request, location.pathname]);

  const goToOverviewPage = () => {
    setShowAlert(false);
    historyPush(appUrls.myRequests.base);
  };

  const startNewRequest = () => {
    setShowAlert(false);
  };

  return (
    <Alert
      visible={showAlert}
      title={translate('sgw.startRequest.alert.title')}
      message={translate('sgw.startRequest.alert.description')}
      confirmButtonText={translate('sgw.startRequest.alert.confirm')}
      discardButtonText={translate('sgw.startRequest.alert.discard')}
      onClose={startNewRequest}
      onConfirm={goToOverviewPage}
    />
  );
};
