import * as A from 'antwerp-core-react-branding';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { isValid, submitId } from '../../../../common/utils/formUtils';
import { validateAttachment } from '../../../../common/validation/validateRequiredAttachment';
import { SgwRequestActions } from '../../../../store/actions';
import { getInitialSgwValues, getRequest } from '../../../../store/selectors/sgw';
import { useGenericStyles, usePartnerForm, useSgwRequestForm } from '../../../../hooks';
import { translate, translateHtml } from '../../../../translations/translate';
import { IFileStorage, PartnerType, SgwWorkflowStep, TRouterWithId, WorkflowType } from '../../../../types';
import { Fieldset } from '../../../atoms';
import { CheckboxWithLabelComponent } from '../../../common/forms/CheckboxWithLabel.component';
import { Required } from '../../../common/forms/Required.component';
import { Visible } from '../../../common/layout/Visible.component';
import { PartnerFields, PeriodFormFields } from '../../../organisms';
import { FileSelect } from '../../../molecules';
import { MAX_FILE_SIZE } from '../../../molecules/FileSelect/constants';
import { useEffect, useState } from 'react';

export const StartRequestForm = () => {
  const step = SgwWorkflowStep.StartRequest;
  const dispatch = useDispatch();
  const { spacing } = useGenericStyles();
  const { id } = useParams<keyof TRouterWithId>();
  const defaultValues = useSelector(getInitialSgwValues(id));
  const { formMethods: sgwRequestFormMethods, submitRequest, requestId } = useSgwRequestForm(step);
  const request = useSelector(getRequest(requestId));
  const [submittingPartner, setSubmittingPartner] = useState(false);
  const {
    formMethods: partnerFormMethods,
    submitPartner,
    isFormReady,
  } = usePartnerForm(PartnerType.requester, request?.requestorId, true);

  const {
    watch,
    setValue,
    register,
    clearErrors,
    setError,
    formState: { errors: sgwRequestErrors },
    trigger,
  } = sgwRequestFormMethods;

  const {
    formState: { errors: partnerErrors },
  } = partnerFormMethods;

  const isUrgentRequest = watch('isUrgentRequest');
  const initialFile = watch('initialFile');

  const onFileSelect = (file?: IFileStorage) => {
    const error = _validateAttachment(file);
    if (isValid(error) && file) {
      setValue('initialFile', file);
    }
  };

  const _validateAttachment = (file?: File) => {
    const validateResult = validateAttachment(file, !!isUrgentRequest, MAX_FILE_SIZE);
    if (isValid(validateResult) && file) {
      clearErrors('initialFile');
    } else {
      setError('initialFile', { type: 'manual', message: validateResult as string });
    }
    return validateResult;
  };

  const onClearAttachments = () => {
    setValue('initialFile', undefined);
  };

  useEffect(() => {
    if (submittingPartner) submitRequest();
    setSubmittingPartner(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const saveRequest = async () => {
    if (requestId) {
      // Update the partner first and if it's ok - update the request
      setSubmittingPartner(true);
      submitPartner();
    } else if (!Object.keys(sgwRequestErrors).length && !Object.keys(partnerErrors).length) {
      // Create new request and its partner
      dispatch(
        SgwRequestActions.create({
          request: { ...defaultValues, ...sgwRequestFormMethods.getValues() },
          partner: { ...partnerFormMethods.getValues() },
        }),
      );
    }
    return false;
  };

  const onChangeUrgency = () => {
    const newIsUrgentRequest = !isUrgentRequest;
    if (newIsUrgentRequest) {
      setValue('initialFile', undefined);
    }
    setValue('isUrgentRequest', newIsUrgentRequest);
  };

  useEffect(() => {
    if (!isUrgentRequest) {
      // SGW-811 [FE] Request flow step 1 - Checking / unchecking urgent procedure doesn't update the error message
      trigger('initialFile');
    }
    trigger('dateFrom');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUrgentRequest]);

  return (
    <div data-testid={'CapacityAndPartnerForm'}>
      <Visible visible={isFormReady}>
        {/* @ts-ignore */}
        <A.Paragraph spacing>{translateHtml('form.fieldsMarkedWithStarAreRequired')}</A.Paragraph>
        <FormProvider {...partnerFormMethods}>
          <form id="requestPartnerForm">
            <PartnerFields variant={PartnerType.requester} />
          </form>
        </FormProvider>
        <FormProvider {...sgwRequestFormMethods}>
          <form
            id={submitId(WorkflowType.Sgw, step)}
            onSubmit={sgwRequestFormMethods.handleSubmit((data, event) => {
              partnerFormMethods.handleSubmit(saveRequest)(event);
            })}
            noValidate
          >
            <Fieldset legend={translate('sgw.startRequest.period.legend')} className={spacing.marginTop.ML}>
              <PeriodFormFields />
              <div className="row">
                <div className="col-xs-12 col-md-12">
                  <div className={spacing.marginTop.ML}>
                    <CheckboxWithLabelComponent
                      label={translate('sgw.startRequest.period.urgentRequest')}
                      {...register('isUrgentRequest', { onChange: onChangeUrgency })}
                    />
                    <p>{translate('sgw.startRequest.period.urgentRequestInfo')}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Visible visible={!!isUrgentRequest}>
                    <FileSelect
                      {...register('initialFile', {
                        validate: _validateAttachment,
                      })}
                      description={translate('sgw.startRequest.period.attachmentDescription', {
                        maxsize: MAX_FILE_SIZE,
                      })}
                      className={spacing.marginTop.ML}
                      uploadedFiles={initialFile ? [initialFile] : []}
                      onFileSelect={onFileSelect}
                      onDeleteAll={onClearAttachments}
                      required={isUrgentRequest}
                    />
                  </Visible>
                  <Required
                    // @ts-ignore
                    required={!!sgwRequestErrors?.initialFile?.message}
                    // @ts-ignore
                    message={sgwRequestErrors?.initialFile?.message}
                  />
                </div>
              </div>
            </Fieldset>
          </form>
        </FormProvider>
      </Visible>
    </div>
  );
};
