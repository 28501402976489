import { SgwAccordion } from '../SgwAccordion/SgwAccordion.component';
import { Button, Icon } from '../../atoms';
import { translate, translateIgnoreTS } from '../../../translations/translate';
import { Visible } from '../../common/layout/Visible.component';
import { ButtonVariant } from '../../../types';
import { Grid } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useFlexStyles } from '../../../theme';
import { createUseStyles } from 'react-jss';
import { PhaseNameDatesComponent } from '../PhaseNameDates/PhaseNameDates.component';
import { ConflictCategory, IConflictGroup } from '../../../types/sgwConflict.types';
import { useAtom } from 'jotai';
import { ConflictsShownOnMapAtom } from '../../pages/MapPage/Map.page';

const useStyles = createUseStyles({
  swgAccordion: {
    marginBottom: '10px',
  },
  accordionHeader: {
    fontSize: 'larger',
  },
  accordionShowOnMapButton: {
    borderRadius: '5px',
  },
});
interface IProps {
  conflictGroup: IConflictgroupWithPhaseIds;
  index: number;
}
interface IConflictgroupWithPhaseIds extends IConflictGroup {
  phaseIds: number[];
}

export const SgwConflictAccordion: FC<IProps> = ({ conflictGroup, index }) => {
  const G = useFlexStyles();
  const C = useStyles();
  const [toggleButton, setToggleButton] = useState(false);
  const [selectedConflicts, setSelectedConflicts] = useAtom(ConflictsShownOnMapAtom);

  const toggleShowOnMapButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectedConflicts((prevState) =>
      prevState.includes(conflictGroup.id)
        ? prevState.filter((prevId) => prevId !== conflictGroup.id)
        : [...prevState, conflictGroup.id],
    );
    setToggleButton((prevState) => !prevState);
  };
  useEffect(() => {
    setToggleButton(selectedConflicts.includes(conflictGroup.id!));
  }, [conflictGroup.id, selectedConflicts]);

  return (
    <SgwAccordion
      icon={<Icon.ConlictSvgIcon variant={conflictGroup.conflictCategory} />}
      header={
        <div className={G.spaceBetween}>
          <div className={G.centerVertical}>
            <div className={C.accordionHeader}>
              {translateIgnoreTS(
                `sgw.map.conflictOverview.conflictCategoryDisplayName.${conflictGroup.conflictCategory}`,
              )}
            </div>
          </div>

          <Visible
            visible={conflictGroup.conflictCategory !== ConflictCategory.crane && !!conflictGroup.conflicts.length}
          >
            <Button
              variant={toggleButton ? ButtonVariant.Default : ButtonVariant.Outline}
              size="small"
              className={C.accordionShowOnMapButton}
              onClick={toggleShowOnMapButton}
            >
              {toggleButton
                ? translate('sgw.map.conflictOverview.hideOnMapButton')
                : translate('sgw.map.conflictOverview.showOnMapButton')}
            </Button>
          </Visible>
        </div>
      }
      index={index}
      collapsed
      className={C.swgAccordion}
    >
      <div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {translate('sgw.map.conflictOverview.conflictInformation.category')}
          </Grid>
          <Grid item xs={8}>
            {translateIgnoreTS(`sgw.map.conflictOverview.conflictCategory.${conflictGroup.conflictCategory}`)}
          </Grid>
          <Grid item xs={4}>
            {translate('sgw.map.conflictOverview.conflictInformation.detectedInPhase')}
          </Grid>
          <Grid item xs={8}>
            {!!conflictGroup.phaseIds.length ? (
              conflictGroup.phaseIds.map((phaseId: number) => (
                <PhaseNameDatesComponent
                  phaseId={phaseId}
                  requestId={conflictGroup.sgwRequestId}
                  key={phaseId}
                  index={index + 1}
                />
              ))
            ) : (
              <span>{translate('sgw.map.conflictOverview.doesNotApply')}</span>
            )}
          </Grid>
          <Visible visible={!!conflictGroup.conflicts.length}>
            <Grid item xs={4}>
              {translate('sgw.map.conflictOverview.conflictInformation.amountDetected')}
            </Grid>
            <Grid item xs={8}>
              {conflictGroup.conflicts.length}
            </Grid>
          </Visible>
          <Grid item xs={4}>
            {translate('sgw.map.conflictOverview.conflictInformation.info')}
          </Grid>
          <Grid item xs={8}>
            {translateIgnoreTS(
              `sgw.map.conflictOverview.conflictCategoryInformation.${conflictGroup.conflictCategory}`,
            )}
          </Grid>
          <Grid item xs={4}>
            {translate('sgw.map.conflictOverview.conflictInformation.advice')}
          </Grid>
          <Grid item xs={8}>
            {conflictGroup.remarks ? (
              <td dangerouslySetInnerHTML={{ __html: conflictGroup.remarks }} />
            ) : (
              <span>{translate('sgw.map.conflictOverview.conflictInformation.noAdvice')}</span>
            )}
          </Grid>
        </Grid>
      </div>
    </SgwAccordion>
  );
};
