import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { extractIdsFromArray, IById, IWithId } from '../../utils/normalized.util';
import { IByIdReducerProps, ICreateTableReducerProps } from '../../types';

export const normalizeArray = <T>(data: Array<IWithId<T>>): IById<IWithId<T>> =>
  data.reduce(
    (prev, g) => ({
      ...prev,
      [`${g.id}`]: g,
    }),
    {},
  );

const createByIdReducer = <Entity>({
  addEntityActions = [],
  addEntitiesActions = [],
  removeEntityActions = [],
}: IByIdReducerProps) => {
  const actionsMap = {};

  addEntitiesActions.forEach(
    (action) =>
      // @ts-ignore
      (actionsMap[action] = (state: IById<Entity>, { payload }: PayloadAction<Entity[]>): IById<Entity> => ({
        ...state,
        ...normalizeArray<Entity>(payload as IWithId<Entity>[]),
      })),
  );

  addEntityActions?.forEach(
    (action) =>
      // @ts-ignore
      (actionsMap[action] = (state: IById<Entity>, { payload }: PayloadAction<IWithId<Entity>>): IById<Entity> => ({
        ...state,
        [payload.id]: payload,
      })),
  );

  removeEntityActions.forEach((removeAction) => {
    // @ts-ignore
    actionsMap[removeAction] = (state: IById<Entity>, { payload }: PayloadAction<string>): IById<Entity> => {
      const { [payload]: removedId, ...newState } = state;
      return newState;
    };
  });

  // @ts-ignore
  return createReducer<IById<Entity>>({}, actionsMap);
};

const createAllIdsReducer = <Entity>(setEntitiesAction: string, removeEntityActions: string[] = []) => {
  const actionsMap = {
    [setEntitiesAction]: (state: string[], { payload }: PayloadAction<Entity[]>): string[] =>
      extractIdsFromArray(payload),
  };

  removeEntityActions.forEach((removeAction) => {
    // @ts-ignore
    actionsMap[removeAction] = (state: string[], { payload }: PayloadAction<string>): string[] =>
      state.filter((id) => id !== payload);
  });

  return createReducer<string[]>([], actionsMap);
};

const createSetBooleanReducer = (setTrueTypes: string[], setFalseTypes: string[] = [], payloadType?: string) =>
  createReducer<boolean>(false, {
    ...setTrueTypes.reduce(
      (accumulator, type) => ({
        ...accumulator,
        [type]: () => true,
      }),
      {},
    ),
    ...setFalseTypes.reduce(
      (accumulator, type) => ({
        ...accumulator,
        [type]: () => false,
      }),
      {},
    ),
    ...(!!payloadType && {
      payloadType: (state: boolean, { payload }: PayloadAction<boolean>) => payload,
    }),
  });

export const createNormalizedReducer = <Entity>({
  setAllIdsAction,
  removeEntityActions,
  addEntitiesActions,
  addEntityActions,
  fetchAction,
  setLoadingAction,
}: ICreateTableReducerProps) =>
  combineReducers({
    allIds: createAllIdsReducer<Entity>(setAllIdsAction, removeEntityActions),
    byId: createByIdReducer<Entity>({ addEntitiesActions, addEntityActions, removeEntityActions }),
    loading: createSetBooleanReducer(
      fetchAction ? [fetchAction] : [],
      [setAllIdsAction, ...(addEntitiesActions || [])],
      setLoadingAction,
    ),
  });
