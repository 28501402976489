import { CarFreeZoneType, IPublicDomainIntake, TCarFreeZone } from '../../../../types';
import { PublicDomainType } from '../../../../common/enums';
import * as A from 'antwerp-core-react-branding';
import { LicensePlateAddWarning } from './LicensePlateAddWarning.component';
import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectCarFreeZones, selectCountries, selectRequest } from '../../../../store/selectors';
import { Visible } from '../../layout/Visible.component';
import { Controller, useFormContext } from 'react-hook-form';
import { translate, translateIgnoreTS } from '../../../../translations/translate';
import { LicencePlateField } from '../../../organisms';

export const ParkedPlates: FC = () => {
  const { watch, control } = useFormContext<IPublicDomainIntake>();
  const type = watch('type');
  const gisId = watch('gisId');
  const permittedPlates = watch('permittedPlates');
  const carFreeZones = useSelector(selectCarFreeZones);
  const countries = useSelector(selectCountries);
  const request = useSelector(selectRequest);
  const zone = carFreeZones.find((z: TCarFreeZone) => z.gisId === gisId);
  return (
    <Visible visible={!!(type && type.type === PublicDomainType.CarfreeZone && gisId)}>
      {/*@ts-ignore*/}
      <A.Spacing type={A.SpacingStyle.MarginBottom}>
        <h6>{translate('locationsform.allowedparkingplates')}</h6>
      </A.Spacing>

      <div className="col-sm-12">
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginBottom}>
          {/*@ts-ignore*/}
          <A.Paragraph type={A.ParagraphStyle.Alert}>
            {zone && (zone.type === CarFreeZoneType.anpr || zone.type === CarFreeZoneType.krautli)
              ? translateIgnoreTS(`myRequests.details.licensePlateWarning.${zone.type}`)
              : translate('myRequests.details.licensePlateWarning.general')}
          </A.Paragraph>
        </A.Spacing>
      </div>
      <LicensePlateAddWarning permittedPlates={permittedPlates} zone={zone} />
      <Controller
        name="permittedPlates"
        shouldUnregister
        control={control}
        render={({ field }) => (
          <LicencePlateField
            countries={countries}
            label={translate('locationsform.parkedplates')}
            onChangeLicensePlates={(e) => field.onChange(e)}
            startDate={request?.dateFrom!}
            requestLicensePlates={field.value || []}
          />
        )}
      />
    </Visible>
  );
};
