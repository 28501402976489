import { SgwWorkflowStep, TRouterWithId } from '../../../types';
import { Alert } from '../../molecules';
import React, { FC } from 'react';
import { useVerifySgwRequestStartDate, useWorkflowHistory } from '../../../hooks';
import { translate } from '../../../translations/translate';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { getStandardPhases } from '../../../store/selectors/sgw';
import { useParams } from 'react-router';

const useStyles = createUseStyles({
  message: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
});

export const InvalidSgwRequestStartDateAlert: FC = () => {
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const { goToStep } = useWorkflowHistory();
  const isValid = useVerifySgwRequestStartDate();
  const phases = useSelector(getStandardPhases(requestId!));
  const C = useStyles();

  return (
    <Alert
      visible={!isValid}
      title={translate('sgw.invalidStartDate.title')}
      message={
        <div className={C.message}>
          <div>{translate('sgw.invalidStartDate.message')}</div>
          {!!phases?.length && <div>{translate('sgw.invalidStartDate.hasPhases')}</div>}
        </div>
      }
      confirmButtonText={translate('sgw.invalidStartDate.edit')}
      onConfirm={() => goToStep(SgwWorkflowStep.StartRequest)}
    />
  );
};
