import { WorkflowTemplate } from '../../templates/WorkflowTemplate/WorkflowTemplate.component';
import { translate } from '../../../translations/translate';
import { SgwWorkflowStep, WorkflowType } from '../../../types';
import React from 'react';
import { AttachmentsForm } from '../../forms/sgw/AttachmentsForm/AttachmentsForm.component';
import { InvalidSgwRequestStartDateAlert } from '../../organisms';

export const AttachmentsPage = () => {
  return (
    <WorkflowTemplate
      title={translate('sgw.attachments.title')}
      step={SgwWorkflowStep.Attachments}
      type={WorkflowType.Sgw}
    >
      <InvalidSgwRequestStartDateAlert />
      <AttachmentsForm />
    </WorkflowTemplate>
  );
};
