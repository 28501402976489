import * as A from 'antwerp-core-react-branding';

import * as React from 'react';
import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import intl from 'react-intl-universal';
import { ITypeForm } from '../../../common/form';
import { isValid as isValidError, submitId } from '../../../common/utils/formUtils';
import { validateAttachmentStep2 } from '../../../common/validation/validateRequiredAttachment';
import { translateHtml } from '../../../translations/translate';
import { IPublicDomainIntakeType, IRequest, IRequestFile, WorkflowType } from '../../../types';
import { Fieldset } from '../../atoms';
import { TypeSelector } from '../../common/cardselector/TypeSelector.component';
import InfoFiche from '../../common/forms/infoFiche';
import { Required } from '../../common/forms/Required.component';
import { WorkflowButtons } from '../../molecules';
import './2-typeform.scss';
import { Requirements } from './Requirements.component';

export type TypeFormProperties = {
  initialValues?: IRequest;
  onPrevious: (value: IRequest) => void;
  onSubmit: (values: IRequest) => void;
  request?: IRequest;
  step: number;
};

export const TypeForm = (props: TypeFormProperties) => {
  const { initialValues, onSubmit, onPrevious, step, request } = props;
  const formMethods = useForm<ITypeForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      attachments: initialValues?.publicDomainIntakes?.[0]?.attachments,
      publicDomainIntakes: initialValues?.publicDomainIntakes,
      conditions: initialValues?.publicDomainIntakes?.[0]?.conditions,
    },
  });

  const {
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    control,
    watch,
    register,
    unregister,
    trigger,
    formState: { isValid, errors },
  } = formMethods;

  const watchType = watch('publicDomainIntakes.0.type');
  const watchAttachments: IRequestFile[] | undefined = watch('attachments');
  const [typeChanged, setTypeChanged] = useState(false);

  const changeType = (type: IPublicDomainIntakeType) => {
    if (request) {
      request.publicDomainIntakes = [];
    }
    setValue('publicDomainIntakes.0.type', type);
    setTypeChanged(true);
    clearErrors('publicDomainIntakes.0.type');
  };

  const _onSubmit = (values: IRequest) => {
    isValid &&
      onSubmit({
        ...values,
        publicDomainIntakes: typeChanged ? [{ type: watchType }] : values.publicDomainIntakes,
      });
  };

  const onChangeAttachment = (attachment?: File | null): void => {
    if (!!attachment) {
      setValue('attachments', [attachment]);
      const error = validateAttachmentStep2([attachment], watchType);
      if (isValidError(error)) {
        clearErrors('attachments');
      } else {
        setError('attachments', { type: 'manual', message: error as string });
      }
    } else {
      setValue('attachments', []);
      if (request?.publicDomainIntakes?.[0]?.attachments) {
        request.publicDomainIntakes[0].attachments = [];
      }
    }
    trigger();
  };

  return (
    <section>
      <FormProvider {...formMethods}>
        {/*@ts-ignore*/}
        <A.Form onSubmit={handleSubmit(_onSubmit)} id={submitId(WorkflowType.ASign, step)}>
          <Fieldset legend={intl.get('typeform.typetitle')}>
            <div className="a-input is-required">
              <label className="a-input__label">{translateHtml('typeform.typeofpdi')}</label>
              <Controller
                control={control}
                name={'publicDomainIntakes.0.type'}
                render={({ field: { value }, fieldState: { error, isTouched } }) => (
                  <TypeSelector
                    input={{ onChange: changeType, value }}
                    error={error}
                    {...props}
                    errorComponent={<Required required={!!error} message={intl.get('typeform.typeRequired')} />}
                  />
                )}
                rules={{ required: true }}
              />
            </div>
            <InfoFiche type={watchType} />
            <Requirements
              type={watchType}
              attachments={watchAttachments}
              register={register}
              unregister={unregister}
              control={control}
              // @ts-ignore
              errorAttachment={errors?.['attachments']?.message}
              onChangeAttachment={onChangeAttachment}
            />
          </Fieldset>
          <WorkflowButtons step={step} onPrevious={onPrevious} type={WorkflowType.ASign} />
        </A.Form>
      </FormProvider>
    </section>
  );
};
