import carFreeZoneIntake from '../../assets/images/publicdomainintaketype.carfreezoneintake.svg';
import minorConstructionSiteIntake from '../../assets/images/publicdomainintaketype.minorconstructionsiteintake.svg';
import parkingBanIntake from '../../assets/images/publicdomainintaketype.parkingbanintake.svg';
import ceremonialCars from '../../assets/images/reason.ceremonial_cars.svg';
import container from '../../assets/images/reason.container.svg';
import loadingUnloadingZone from '../../assets/images/reason.loading_unloading_zone.svg';
import move from '../../assets/images/reason.move.svg';
import movingLift from '../../assets/images/reason.moving_lift.svg';
import scaffold from '../../assets/images/reason.scaffold.svg';
import intl from 'react-intl-universal';
import smallWork from '../../assets/images/projectType.smallWork.svg';
import largeWork from '../../assets/images/projectType.largeWork.svg';
import { ProjectType } from '../../components/routes/projectType/ProjectType.component';

const IMAGES = {
  reason: {
    ceremonial_cars: ceremonialCars,
    container,
    loading_unloading_zone: loadingUnloadingZone,
    move,
    moving_lift: movingLift,
    scaffold,
  },
  intakeType: {
    carfreezoneintake: carFreeZoneIntake,
    minorconstructionsiteintake: minorConstructionSiteIntake,
    parkingbanintake: parkingBanIntake,
  },
  projectType: {
    smallWork,
    largeWork,
  },
};

export type TImageType = keyof typeof IMAGES.intakeType;
export type TImageReason = keyof typeof IMAGES.reason;

export const getImageReason = (reason: TImageReason): string => IMAGES.reason[reason];
export const getImageIntakeType = (type: TImageType): string => IMAGES.intakeType[type];
export const getImageProjectType = (type: ProjectType): string => IMAGES.projectType[type];

export const getAltTextForImage = (step: string, value: string) => intl.get(`assets.images.${step}.${value}`);
