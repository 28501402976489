import { Effect, select, takeLatest } from 'redux-saga/effects';
import { IGenericAction } from '../actions/actions';
import { SET_PAGE_TITLE } from '../actions/types';
import { selectTitle } from '../selectors';
import { ISelect } from '../../types';
import { SagaIterator } from 'redux-saga';

/**
 * Report an error back to the server
 */
function* setWindowTitle(action: IGenericAction<string>): IterableIterator<Effect | any> {
  // Report error to the server
  if (action.payload) {
    if (document) {
      const appTitle: ISelect<typeof selectTitle> = yield select(selectTitle);
      document.title = appTitle ? `${appTitle} - ${action.payload}` : action.payload;
    }
  }
}

function* titleSaga(): SagaIterator {
  yield takeLatest(SET_PAGE_TITLE, setWindowTitle);
}

export default titleSaga;
