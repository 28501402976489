import { PublicDomainType } from '../../../common/enums';
import intl from 'react-intl-universal';
import * as A from 'antwerp-core-react-branding';
import {
  APP_ALLOWED_FILE_FORMATS,
  APP_LINK_INFOFICHE_MINORCONSTRUCTION,
  APP_LINK_ONDERNEMEN,
  APP_LINK_REQUIREMENTS,
} from '../../../common/constants';
import { urlDisplayName } from '../../../common/utils/urlUtils';
import { validateAttachmentStep2 } from '../../../common/validation/validateRequiredAttachment';
import * as React from 'react';
import { IPublicDomainIntakeType, IRequestFile } from '../../../types';
import { Control, UseFormRegister, UseFormUnregister } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { ITypeForm } from '../../../common/form';
import { CheckboxWithLabelComponent } from '../../common/forms/CheckboxWithLabel.component';
import { Required } from '../../common/forms/Required.component';
import { RequiredStar } from '../../common/forms/RequiredStar.component';
import { Fieldset } from '../../atoms';
import { FileSelect } from '../../molecules';
import { translate } from '../../../translations/translate';

interface IProps {
  attachments?: IRequestFile[];
  type?: IPublicDomainIntakeType;
  register: UseFormRegister<ITypeForm>;
  unregister: UseFormUnregister<ITypeForm>;
  control: Control<ITypeForm>;
  errorAttachment?: string;
  onChangeAttachment(attachment?: File | null): void;
}

export const Requirements = ({
  attachments,
  errorAttachment,
  onChangeAttachment,
  register,
  type,
  unregister,
}: IProps): JSX.Element | null => {
  useEffect(() => {
    type?.type !== PublicDomainType.MinorConstruction && unregister('attachments');
  }, [type, unregister]);

  const validate = useCallback((value: any) => validateAttachmentStep2(value, type), [type]);

  const labelAttachmentLabel = intl.get('typeform.attachmentlabel');
  const labelAttachmentDescription = intl.get('typeform.attachmentdescription');

  return type?.type === PublicDomainType.MinorConstruction ? (
    <section className="typeform__requirements">
      {/*@ts-ignore*/}
      <A.Spacing type={A.SpacingStyle.MarginTop}>
        <h4>{intl.get('typeform.requirements')}</h4>
        {/*@ts-ignore*/}
        <A.Paragraph spacing>
          {intl.getHTML('typeform.requirementstext')}
          {intl.getHTML('typeform.requestconforms', {
            linkondernemen: APP_LINK_ONDERNEMEN,
            linkvoorwaarden: APP_LINK_INFOFICHE_MINORCONSTRUCTION,
            textondernemen: urlDisplayName(APP_LINK_ONDERNEMEN),
          })}
        </A.Paragraph>
      </A.Spacing>
      {/*@ts-ignore*/}
      <A.Spacing type={A.SpacingStyle.MarginTop}>
        <CheckboxWithLabelComponent
          {...register('conditions', { validate: (checked) => !!checked })}
          label={
            <>
              {intl.getHTML('typeform.requirementslink', { link: APP_LINK_REQUIREMENTS })}
              <RequiredStar />
            </>
          }
          required={translate('locationsform.conditionsRequired')}
        />
      </A.Spacing>
      {/*@ts-ignore*/}
      <A.Spacing type={A.SpacingStyle.MarginTop}>
        <Fieldset legend={labelAttachmentLabel} noBottomBorder>
          <FileSelect
            uploadedFiles={attachments}
            accept={APP_ALLOWED_FILE_FORMATS}
            description={labelAttachmentDescription}
            {...register('attachments', { validate })}
            onFileSelect={onChangeAttachment}
            onDeleteAll={() => onChangeAttachment(null)}
            required
          />
          <Required required={!!errorAttachment} message={errorAttachment} />
        </Fieldset>
      </A.Spacing>
    </section>
  ) : null;
};
