import './price.scss';

import * as React from 'react';
import intl from 'react-intl-universal';

import * as A from 'antwerp-core-react-branding';

import { APP_CONTACT_TIJDELIJKEVERKEERSSIGNAL, INFO_GDPR } from '../../../common/constants';
import { calculateNumberOfParkingSpots, formatPrice } from '../../../common/utils/priceUtils';
import { ICost } from '../../../types/cost.types';
import { GdprContent } from '../staticinfo/gdpr-content';
import { StaticInfoSnippet } from '../staticinfo/static-info-snippet';

export type PriceProp = {
  cost?: ICost;
  showGdpr: boolean;
};

/**
 * React Component Price
 */
export default class Price extends React.Component<PriceProp, {}> {
  public render() {
    return (
      <div className="animated FadeIn">
        <table className="a-table">
          <thead>
            <tr>
              <th>{intl.get('general.price')}</th>
              <th></th>
            </tr>
          </thead>
          {this.renderCost()}
        </table>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginTopXs} />
        <div className="row center-xs align__left">
          <div className="col-xs-10 col-md-10 u-margin-bottom">
            <small className="u-text-left">
              {intl.getHTML('price.contact', { email: APP_CONTACT_TIJDELIJKEVERKEERSSIGNAL })}
              {this.props.showGdpr && (
                <>
                  <br />
                  <br />
                  <StaticInfoSnippet title={intl.getHTML('general.staticinfo', {})} url={INFO_GDPR}>
                    <GdprContent />
                  </StaticInfoSnippet>
                </>
              )}
            </small>
          </div>
        </div>
      </div>
    );
  }

  private renderCost(): JSX.Element {
    if (this.hasCost()) {
      const cost = this.props.cost!;

      return (
        <tbody>
          {calculateNumberOfParkingSpots(cost.costComponents) > 0 && (
            <tr>
              <td>{intl.get('general.parkinglots')}</td>
              <td>{calculateNumberOfParkingSpots(cost.costComponents)}</td>
            </tr>
          )}
          <tr>
            <td>{intl.get('general.days')}</td>
            <td>{cost.numberOfDays}</td>
          </tr>
          <tr>
            <td>{intl.get('price.administrativecost')}</td>
            <td>{this.renderPrice(cost.fixedAdministrationCost)}</td>
          </tr>
          <tr>
            <td>{intl.get('price.emergencycost')}</td>
            <td>{this.renderPrice(cost.fixedEmergencyCost)}</td>
          </tr>
          <tr>
            <td>{intl.get('price.variablecost')}</td>
            <td>{this.renderPrice(cost.variableCost)}</td>
          </tr>
          {this.renderCreditLeft()}
          <tr className="total-price">
            <td>
              <b>{intl.get('general.total')}</b>
            </td>
            <td>
              <b>{this.renderPrice(cost.totalToPay)}</b>
            </td>
          </tr>
          {cost.vat > 0 && (
            <tr className="total-vat">
              <td>{intl.get('general.vat')}</td>
              <td>
                <b>{this.renderPrice(cost.vat)}</b>
              </td>
            </tr>
          )}
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={2}>{intl.get('price.couldnotbecalculated')}</td>
          </tr>
        </tbody>
      );
    }
  }

  private renderCreditLeft(): JSX.Element {
    const cost = this.props.cost!;
    if (cost.creditLeft) {
      return (
        <tr>
          <td>{intl.get('price.creditleft')}</td>
          <td>{this.renderPrice(cost.creditLeft)}</td>
        </tr>
      );
    }
    return null!;
  }

  private renderPrice(price: number) {
    return <span>{formatPrice(price)}</span>;
  }

  private hasCost(): boolean {
    return this.props.cost !== undefined && Object.keys(this.props.cost).length > 0;
  }
}
