import React, { FC, SyntheticEvent, FocusEvent, MouseEvent, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import {
  DEFAULT_ALLOWED_FILE_FORMATS,
  UNLIMITED_NR_OF_FILE_SELECT,
  DEFAULT_NR_OF_FILE_SELECT,
  DEFAULT_ALLOWED_FILE_FORMAT_REGEX,
} from './constants';
import { FileSelectInfo } from '../../atoms';
import { Description } from '../../atoms/Description/Description.component';
import { FilesList } from '../../atoms/FilesList/FilesList.component';
import { FieldMetaProperties, IWithClassName } from '../../../types';
import { COLORS } from '../../../theme';
import { translate } from '../../../translations/translate';

export interface IFileSelectProps extends IWithClassName {
  name: string;
  onFileSelect(file?: File | null): void;
  onFileDelete?(file?: File | null): void;
  onDeleteAll?(): void;
  onBlur?(e: FocusEvent<HTMLInputElement>): void;
  maxAllowedFiles?: number;
  accept?: string;
  info?: JSX.Element | string;
  description?: string;
  uploadedFiles?: File[];
  errorComponent?: JSX.Element;
  disabled?: boolean;
  meta?: FieldMetaProperties;
  required?: boolean;
}

const useStyles = createUseStyles({
  descriptionContainer: {
    marginTop: '0.75rem',
  },
  input: {
    height: '100%',
    width: '100%',
    opacity: 0,
    position: 'absolute',
    cursor: 'pointer',
  },
  dropzone: {
    alignItems: 'center',
    backgroundColor: 'white',
    border: `2px dashed ${COLORS.blue}`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '7.5rem',
    padding: '2.25rem 1.5rem',
    position: 'relative',
    textAlign: 'center',
    transition: 'background 250ms ease-in-out',
    width: '100%',
  },
});

export const FileSelect: FC<IFileSelectProps> = ({
  accept = DEFAULT_ALLOWED_FILE_FORMATS,
  maxAllowedFiles = 1,
  info,
  description,
  name,
  errorComponent,
  meta,
  required = false,
  uploadedFiles,
  onFileSelect,
  onFileDelete,
  onDeleteAll,
  disabled,
  className,
}) => {
  const C = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const isMulti = maxAllowedFiles === UNLIMITED_NR_OF_FILE_SELECT || maxAllowedFiles > DEFAULT_NR_OF_FILE_SELECT;
  const [fileInValid, setFileInValid] = React.useState<boolean>(false);
  const _onFileSelect = (e: SyntheticEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.item(0);
    const isValid = file && DEFAULT_ALLOWED_FILE_FORMAT_REGEX.test(file.name);
    setFileInValid(!isValid);
    if (isValid) onFileSelect(file);
  };

  const _onFileDelete = (index: number) => {
    onFileDelete?.(uploadedFiles?.[index]);
  };

  // needed to reset the input or it won't reselect the same file
  const _onClick = (e: MouseEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    element.value = '';
  };

  const _openInputFile = () => {
    inputRef.current?.click();
  };

  return (
    <div className={className}>
      <div className={C.dropzone}>
        <input
          ref={inputRef}
          className={C.input}
          onChange={_onFileSelect}
          type="file"
          accept={accept}
          required={required}
          name={name}
          disabled={disabled}
          onClick={_onClick}
        />
        <FileSelectInfo
          isMulti={isMulti}
          file={uploadedFiles?.[0]}
          info={info}
          onDeleteAll={onDeleteAll}
          openInputFile={_openInputFile}
          required={required}
        />
      </div>
      <Description
        description={description}
        error={errorComponent || meta?.error}
        isError={!!meta?.touched && !!meta?.error}
      />
      <Description error={translate('attachments.validation.invalidType')} isError={fileInValid} />
      <FilesList visible={isMulti} files={uploadedFiles} onFileDelete={_onFileDelete} onDeleteAll={onDeleteAll} />
    </div>
  );
};
