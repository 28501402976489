import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Capacity, IPartner, PartnerType } from '../../../types';
import { Fieldset } from 'antwerp-core-react-branding';
import { Visible } from '../../common/layout/Visible.component';
import { PersonalFormFields } from '../PersonalFormFields/PersonalFormFields.component';
import { CompanyFields } from '../CompanyFields/CompanyFields.component';
import { AddressFields } from '../AddressFields/AddressFields.component';
import { ContactPersonFields } from '../ContactPersonFields/ContactPersonFields.component';
import { translate, translateHtml, translateIgnoreTS } from '../../../translations/translate';
import { RadioButtonList } from '../../atoms';
import { useGenericStyles } from '../../../hooks';

interface IProps {
  variant: PartnerType;
}

export const PartnerFields: FC<IProps> = ({ variant }) => {
  const { spacing } = useGenericStyles();
  const {
    formState: { errors },
    register,
    watch,
    setValue,
  } = useFormContext<IPartner>();
  const capacity = watch('capacity');

  const variantTranslation = translateIgnoreTS(`sgw.partners.form.variant.${variant}`);

  return (
    <>
      <Fieldset legend={translate('sgw.partners.form.capacity.legend', { variant: variantTranslation })}>
        <RadioButtonList
          {...register('capacity', { required: true })}
          id={`${variant}-capacity`}
          initialValue={capacity}
          value={capacity}
          onChange={(e: any) => {
            setValue('capacity', e.target.value, { shouldValidate: true });
          }}
        >
          <RadioButtonList.Header>
            {translateHtml('sgw.partners.form.capacity.info', { variant: variantTranslation })}
          </RadioButtonList.Header>
          <RadioButtonList.Item value={Capacity.Business}>
            {translate('sgw.partners.form.capacity.options.business', { variant: variantTranslation })}
          </RadioButtonList.Item>
          <RadioButtonList.Item value={Capacity.BelgianResident}>
            {translate('sgw.partners.form.capacity.options.belgian_resident', { variant: variantTranslation })}
          </RadioButtonList.Item>
          <RadioButtonList.Item value={Capacity.NonBelgianResident}>
            {translate('sgw.partners.form.capacity.options.non_belgian_resident', { variant: variantTranslation })}
          </RadioButtonList.Item>
          <RadioButtonList.RequiredError show={!!errors['capacity']}>
            {translate('fields.labelRequired', { label: translate('sgw.partners.form.capacity.label') })}
          </RadioButtonList.RequiredError>
        </RadioButtonList>
        <Visible visible={!!capacity}>
          <div className={spacing.marginTop.LG}>
            <Visible visible={[Capacity.BelgianResident, Capacity.NonBelgianResident].includes(capacity)}>
              <PersonalFormFields showSsn={capacity === Capacity.BelgianResident} />
            </Visible>
            <Visible visible={capacity === Capacity.Business}>
              <CompanyFields />
            </Visible>
            <AddressFields />
          </div>
        </Visible>
      </Fieldset>
      <ContactPersonFields />
    </>
  );
};
