import { useForm } from 'react-hook-form';
import { Capacity, IPartner, PartnerType, TRouterWithId } from '../types';
import { useCallback, useEffect, useState } from 'react';
import { PartnerActions } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getInitialRequestorValues } from '../store/selectors';

export const usePartnerForm = (variant: PartnerType, id?: number, prefillInEmptyState: boolean = false) => {
  const dispatch = useDispatch();
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const defaultValues = useSelector(getInitialRequestorValues(`${id}`));
  const [isFormReady, setIsFormReady] = useState<boolean>(!id || !!defaultValues?.id);
  const loadDefaultValues = !!id || prefillInEmptyState;

  const formMethods = useForm<IPartner>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    // have to make a copy of the defaultValues, because useForm will update the provided object
    defaultValues: loadDefaultValues ? { ...defaultValues } : {},
    shouldUnregister: true,
    shouldFocusError: false,
  });

  const { getValues, watch, setValue, reset, handleSubmit } = formMethods;

  const capacity = watch('capacity');

  useEffect(() => {
    if (loadDefaultValues) {
      reset({ ...defaultValues });
    } else {
      reset({ contactFirstName: '', contactEmailAddress: '', contactLastName: '', contactPhoneNumber: '' });
    }
    setIsFormReady(!id || !!defaultValues?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, id, loadDefaultValues]);

  useEffect(() => {
    id && dispatch(PartnerActions.fetch(`${id}`));
  }, [dispatch, id]);

  useEffect(() => {
    if (loadDefaultValues) {
      switch (capacity) {
        case Capacity.Business:
          setValue('companyName', defaultValues?.companyName || '');
          setValue('vatNumber', defaultValues?.vatNumber || '');
          break;
        case Capacity.NonBelgianResident:
          setValue('firstName', defaultValues?.firstName || '');
          setValue('lastName', defaultValues?.lastName || '');
          setValue('ssn', '');
          break;
        case Capacity.BelgianResident:
          setValue('firstName', defaultValues?.firstName || '');
          setValue('lastName', defaultValues?.lastName || '');
          setValue('ssn', defaultValues?.ssn || '');
          break;
        default:
          break;
      }
      if (!getValues('contactFirstName')) setValue('contactFirstName', defaultValues?.contactFirstName);
      if (!getValues('contactLastName')) setValue('contactLastName', defaultValues?.contactLastName);
      if (!getValues('contactPhoneNumber')) setValue('contactPhoneNumber', defaultValues?.contactPhoneNumber);
      if (!getValues('contactEmailAddress')) setValue('contactEmailAddress', defaultValues?.contactEmailAddress);
      if (!getValues('street')) setValue('street', defaultValues?.street);
      if (!getValues('streetNumber')) setValue('streetNumber', defaultValues?.streetNumber);
      if (!getValues('bus')) setValue('bus', defaultValues?.bus);
      if (!getValues('zipCode')) setValue('zipCode', defaultValues?.zipCode);
      if (!getValues('city')) setValue('city', defaultValues?.city);
      if (!getValues('country')) setValue('country', defaultValues?.country);
    }
  }, [capacity, defaultValues, setValue, loadDefaultValues, getValues]);

  const _onSubmit = useCallback(
    (values: IPartner) => {
      dispatch(PartnerActions.saveRequestPartner({ variant, partner: { ...values, id }, requestId }));
    },
    [dispatch, id, requestId, variant],
  );

  const submitPartner = handleSubmit(_onSubmit);

  return { formMethods, isFormReady, defaultPartner: { ...defaultValues }, submitPartner };
};
