import { IRequest } from '../types';
import { LOCAL_STOR_RESTORE_ALERT, LOCAL_STOR_TEMP_REQUEST } from './constants';
import * as Utils from './utils/localStorageUtils';

export const storeTempRequest = (request: Partial<IRequest>) => {
  // Only store a temp request if it does not exist yet (aka it doesn't have an id yet)
  if (!request.id) {
    Utils.storeLocalStorage<Partial<IRequest>>(LOCAL_STOR_TEMP_REQUEST, request);
    if (getTempRequestTimeStamp() === 0) {
      setTempRequestTimeStamp();
    }
  }
};

export function isTempRequest(): boolean {
  return Utils.isLocalStorage(LOCAL_STOR_TEMP_REQUEST);
}

export function getTempRequest(): IRequest | undefined {
  if (Utils.isLocalStorage(LOCAL_STOR_TEMP_REQUEST)) {
    return Utils.getLocalStorage(LOCAL_STOR_TEMP_REQUEST);
  }
}

export function getTempRequestExceptSaved(): IRequest | undefined {
  if (Utils.isLocalStorage(LOCAL_STOR_TEMP_REQUEST)) {
    const request = Utils.getLocalStorage<IRequest>(LOCAL_STOR_TEMP_REQUEST);
    if (request && !request.id) return request;
  }
}

export function removeTempRequest(): void {
  Utils.removeLocalStorage(LOCAL_STOR_TEMP_REQUEST);
  Utils.removeLocalStorage(LOCAL_STOR_RESTORE_ALERT);
}

export function getTempRequestTimeStamp(): number {
  const key: string = LOCAL_STOR_RESTORE_ALERT;
  if (Utils.isLocalStorage(key)) {
    return parseInt(Utils.getLocalStorage<string>(key)!, 10);
  }
  return 0;
}

export function setTempRequestTimeStamp(): void {
  Utils.storeLocalStorage(LOCAL_STOR_RESTORE_ALERT, new Date().getTime());
}
