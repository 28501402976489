import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { CountrySelector, TextInput } from '../../atoms';
import { Capacity, IAddress, IPartner } from '../../../types';
import { translate } from '../../../translations/translate';

export const AddressFields: FC = () => {
  const { register } = useFormContext<IAddress>();
  const { watch } = useFormContext<IPartner>();

  return (
    <>
      <div className="row">
        <TextInput
          className="col-xs-8"
          {...register('street', { required: true })}
          label={translate('personalform.street')}
          required
        />
        <TextInput
          className="col-xs-2"
          {...register('streetNumber', { required: true })}
          label={translate('personalform.streetnumber')}
          required
        />
        <TextInput className="col-xs-2" {...register('bus')} label={translate('personalform.bus')} />
      </div>
      <div className="row">
        <TextInput
          className="col-xs-2"
          {...register('zipCode', { required: true })}
          label={translate('personalform.zipcode')}
          required
        />
        <TextInput
          className="col-xs-5"
          {...register('city', { required: true })}
          label={translate('personalform.city')}
          required
        />
        <CountrySelector
          disabled={watch('capacity') === Capacity.BelgianResident}
          className="col-xs-5"
          {...register('country', { required: true })}
          label={translate('personalform.country')}
        />
      </div>
    </>
  );
};
