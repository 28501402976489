import {
  IPhaseCostQuarter,
  IRequestMessageAttachment,
  IRequestMessages,
  IRequestRescheduling,
  ISgwRequest,
} from '../../../types';
import { SgwRequestActions } from '../../actions';
import { combineReducers } from 'redux';
import { createNormalizedReducer } from '../pagedTable.reducer';
import { requestAttachmentReducer } from './attachment.reducer';
import { generatePayloadReducer } from '../generic.reducers';
import { IConflictGroup } from '../../../types/conflict.types';

export const requestReducer = combineReducers({
  list: createNormalizedReducer<ISgwRequest>({
    addEntityActions: [SgwRequestActions.set.type],
    fetchAction: SgwRequestActions.list.fetch.type,
    addEntitiesActions: [SgwRequestActions.list.set.type],
    setAllIdsAction: SgwRequestActions.list.set.type,
  }),
  attachments: requestAttachmentReducer,
  conflictGroups: generatePayloadReducer<IConflictGroup[]>([SgwRequestActions.conflicts.setList.type], []),
  messagelist: generatePayloadReducer<IRequestMessages>([SgwRequestActions.messages.set.type], {
    acl: { messages_can_edit: false, messages_can_view: false },
    data: [],
  }),
  messageAttachments: generatePayloadReducer<IRequestMessageAttachment[]>(
    [SgwRequestActions.messages.setAttachment.type],
    [],
  ),
  reschedulings: generatePayloadReducer<IRequestRescheduling[]>([SgwRequestActions.rescheduling.set.type], []),
  phaseCostQuarters: generatePayloadReducer<IPhaseCostQuarter[]>([SgwRequestActions.phaseCostQuarters.set.type], []),
});
