import * as React from 'react';
import intl from 'react-intl-universal';

import * as A from 'antwerp-core-react-branding';
import { formatToDisplayDate } from '../../../common/utils/dateUtils';
import { IRequestState } from '../../../types';
import { OverviewItem, OverviewList, OverviewSection, OverviewSubtitle } from './overviewElements';
import { StateLabel } from '../../atoms';

export type ReasonInfoProperties = {
  title: string | JSX.Element;
  dateUntil: string;
  state: IRequestState;
};

/**
 * React Component ExtensionInfo
 */
export default class StateInfo extends React.Component<ReasonInfoProperties, {}> {
  public render(): any {
    return (
      <OverviewSection>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginTop}>
          <OverviewSubtitle title={this.props.title} />
          <OverviewList>
            <OverviewItem title={intl.get('general.to')} description={formatToDisplayDate(this.props.dateUntil)} />
            <OverviewItem title={intl.get('general.state')} description={<StateLabel {...this.props.state!} small />} />
          </OverviewList>
        </A.Spacing>
      </OverviewSection>
    );
  }
}
