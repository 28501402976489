import { WorkflowTemplate } from '../../templates/WorkflowTemplate/WorkflowTemplate.component';
import React, { useEffect } from 'react';
import { SgwWorkflowStep, WorkflowType, TRouterWithId } from '../../../types';
import { translate } from '../../../translations/translate';
import { useDispatch, useSelector } from 'react-redux';
import { selectConflictGroups } from '../../../store/selectors/sgw';
import { GeoDrawingsForm, phaseIdAtom } from '../../forms/sgw';
import { Visible } from '../../common/layout/Visible.component';
import { useParams } from 'react-router';
import { PhaseActions, SgwRequestActions } from '../../../store/actions';
import { ConflictOverview } from '../../organisms/ConflictOverview/ConflictOverview.component';
import { atomWithHash } from 'jotai/utils';
import { useAtom } from 'jotai';
import { InvalidSgwRequestStartDateAlert } from '../../organisms';

export const ConflictsShownOnMapAtom = atomWithHash<number[]>('conflicts', [], { replaceState: true });

export const MapPage = () => {
  const step = SgwWorkflowStep.Map;
  const dispatch = useDispatch();
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const conflictGroups = useSelector(selectConflictGroups);
  const [, setPhaseId] = useAtom(phaseIdAtom);

  useEffect(() => {
    dispatch(SgwRequestActions.fetch(requestId!));
    dispatch(PhaseActions.fetchList(requestId!));
    dispatch(SgwRequestActions.conflicts.fetchList(requestId!));
  }, [dispatch, requestId]);

  return (
    <WorkflowTemplate
      title={translate('sgw.map.title')}
      step={step}
      type={WorkflowType.Sgw}
      onPrevious={() => setPhaseId('')}
    >
      <InvalidSgwRequestStartDateAlert />
      <Visible visible={!!requestId}>
        <GeoDrawingsForm requestId={requestId!} />
        <Visible visible={!!conflictGroups.length}>
          <ConflictOverview />
        </Visible>
      </Visible>
    </WorkflowTemplate>
  );
};
