import { FC, useCallback, useEffect, useState } from 'react';
import { FormLabel } from 'antwerp-core-react-branding';
import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Icon } from '../../atoms';
import classNames from 'classnames';
import { COLORS } from '../../../theme';
import { IWithClassName } from '../../../types';
import { useGenericStyles } from '../../../hooks';

interface IProps extends IWithClassName {
  checked: boolean;
  disabled?: boolean;
  label: string | JSX.Element;
  name?: string;
  onChange(value: boolean): void;
  stopPropagation?: boolean;
  preventDefault?: boolean;
}

const useStyles = createUseStyles({
  checkbox: {
    '&:focus': {
      zIndex: 1,
    },
  },
  icon: {
    color: `${COLORS.white} !important`,
    cursor: 'pointer !important',
    height: '1.5rem',
    width: '1.5rem',
    marginRight: '0.75rem',
    transitionDuration: 250,
    transitionProperty: 'background, box-shadow',
    transitionTimingFunction: 'ease-in-out',
  },
  iconChecked: {
    backgroundColor: COLORS.blue,
  },
  iconUnchecked: {
    backgroundColor: COLORS.white,
    boxShadow: '0 0 0 1px #b0b0b0 inset',
  },
});

export const CheckBox: FC<IProps> = ({
  checked,
  className,
  disabled = false,
  label,
  name,
  onChange,
  stopPropagation = true,
  preventDefault = true,
}) => {
  const { flex, focus } = useGenericStyles();
  const C = useStyles();
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const toggleCheckbox = useCallback(
    (e: React.MouseEvent) => {
      stopPropagation && e.stopPropagation();
      preventDefault && e.preventDefault();

      setIsChecked((isChecked) => !isChecked);
      onChange(!isChecked);
    },
    [isChecked, onChange, stopPropagation, preventDefault],
  );

  return (
    <div
      className={classNames(flex.centerVertical, C.checkbox, className)}
      onClick={disabled ? undefined : toggleCheckbox}
    >
      <Icon.Check
        className={classNames(flex.center, C.icon, isChecked ? C.iconChecked : C.iconUnchecked, focus.within)}
        disabled={disabled}
      >
        <input
          aria-checked={isChecked ? 'true' : 'false'}
          defaultChecked={isChecked}
          disabled={disabled}
          id={name}
          name={name}
          type="checkbox"
          onKeyDown={(event) => {
            // AS-6736 prevent the form from submitting on pressing Enter
            event.key === 'Enter' && event.preventDefault();
          }}
        />
      </Icon.Check>
      <FormLabel noClass for={name} label={label} />
    </div>
  );
};
