import React, { FC } from 'react';
import { PartnerType, TRouterWithId } from '../../../types';
import { PartnerAccordion } from '../../molecules';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getRequest, getRequestPartnersId } from '../../../store/selectors/sgw';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { useGenericStyles } from '../../../hooks';
import { PartnerRequestInfoContent } from '../../molecules/PartnerRequestInfoContent/PartnerRequestInfoContent.component';
import { translate } from '../../../translations/translate';

const useStyles = createUseStyles({
  root: {
    marginBottom: '20px',
    padding: '0 10px',
  },
  title: {
    marginBottom: '10px',
  },
});

export const PartnerRequestInfo: FC = () => {
  const C = useStyles();
  const { flex } = useGenericStyles();
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const request = useSelector(getRequest(requestId));
  const retributionRequired = request?.acl?.sgwRetributionRequired;

  const partners = [
    PartnerType.client,
    PartnerType.signalisation,
    PartnerType.contractor,
    ...(retributionRequired ? [PartnerType.fee] : []),
  ];
  const requestPartners = useSelector(getRequestPartnersId(requestId!));

  return (
    <div className={C.root}>
      <h5 className={C.title}>{translate('myRequests.details.partners')}</h5>
      <div className={classNames(flex.column, flex.gap)}>
        {partners.map((variant) => (
          <PartnerAccordion readOnly requestId={requestId!} variant={variant} key={variant}>
            <PartnerRequestInfoContent id={requestPartners[variant]} />
          </PartnerAccordion>
        ))}
      </div>
    </div>
  );
};
