import { FC, useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { selectRequestMainLocation } from '../../../store/selectors/sgw';
import { latLngBounds, LatLngTuple } from 'leaflet';
import { findValidAddressToGeoCode } from '../../../common/api';
import { BaseMap } from '../../common/leaflet/BaseMap.component';
import { GeoDrawingOverlays } from '../GeoDrawingOverlays/GeoDrawingOverlays.component';
import { SnackBarActions } from '../../../store/actions/Snackbar.actions';
import { translate } from '../../../translations/translate';
import { useGeoDrawingContext, useLeafletDrawButtons } from '../../../hooks';
import { IGeometry } from '../../../types';
import { Position } from '@turf/turf';
import { getLatLng2D, getLatLng3D } from '../../../common/utils/mapUtils';
import { ConflictDrawingOverlay } from '../ConflictDrawingOverlay/ConflictDrawingOverlay.component';
import { Environment } from '../../../config/environment';
import { generatePath } from 'react-router';
import { appUrls } from '../../../common/constants';

interface IProps {
  readOnly?: boolean;
}
const useStyles = createUseStyles({
  baseMapContainer: {
    '& > div': {
      height: '600px!important',
    },
  },
});

export const GeoDrawingMap: FC<IProps> = ({ readOnly = false }) => {
  const C = useStyles();
  const dispatch = useDispatch();
  const {
    coordinates,
    setCoordinates,
    requestId,
    inDrawingMode,
    setInDrawingMode,
    calculateSurfaceArea,
    isConstructionZoneType,
  } = useGeoDrawingContext();
  const mainLocation = useSelector(selectRequestMainLocation(requestId));
  const { hideButtons } = useLeafletDrawButtons();
  const [geometry, setGeometry] = useState<IGeometry | null>();

  useEffect(() => {
    !inDrawingMode && hideButtons();
  }, [inDrawingMode, hideButtons]);

  useEffect(() => {
    const _geoCodeAddress = async () => {
      if (!mainLocation) return;

      const coord = await findValidAddressToGeoCode(mainLocation);

      if (coord) {
        setCoordinates(coord as unknown as LatLngTuple);
      } else {
        dispatch(
          SnackBarActions.setFailure({
            feedback: translate('sgw.map.addressNotFound'),
            visitLink: generatePath(
              `${Environment.baseFrontEndUrl}${appUrls.sgw.request.base}${appUrls.sgw.request.edit()}`,
              {
                step: 4,
                id: requestId,
              },
            ),
            visitLinkText: translate('sgw.map.updateAddressHere'),
          }),
        );
      }
    };

    _geoCodeAddress();
  }, [dispatch, mainLocation, requestId, setCoordinates]);

  useEffect(() => {
    if (geometry) {
      calculateSurfaceArea(
        isConstructionZoneType
          ? getLatLng3D(geometry.coordinates as Position[][])
          : getLatLng2D(geometry.coordinates as Position[]),
      );
      setGeometry(null);
    }
  }, [geometry, isConstructionZoneType, calculateSurfaceArea]);

  const onDrawingCreated = (geometry: IGeometry) => {
    setGeometry(geometry);
    hideButtons();
  };

  const onStartDrawing = () => setInDrawingMode(true);

  return (
    <BaseMap
      busy={!coordinates}
      bounds={coordinates ? latLngBounds([coordinates]) : undefined}
      zoomControl
      className={C.baseMapContainer}
      extraLayers={[<GeoDrawingOverlays />, <ConflictDrawingOverlay />]}
      enableDrawing={!readOnly}
      onDrawingCreated={onDrawingCreated}
      onStartDrawing={onStartDrawing}
      showLayersControl
    />
  );
};
