import { ICountry, IPartner, IPartnerBE } from '../../types';
import { APP_DEFAULT_COUNTRY } from '../constants';
import { mapToCountryObject } from './country.utils';

export const mapToIPartnerBE = ({ country, ...partner }: IPartner): IPartnerBE => ({
  ...partner,
  country: country?.country || APP_DEFAULT_COUNTRY,
});

export const mapToIPartner = ({ country, ...partner }: IPartnerBE, countries: ICountry[]): IPartner => ({
  ...partner,
  country: mapToCountryObject(country, countries),
});

export const mapToIPartners = (partners: IPartnerBE[], countries: ICountry[]): IPartner[] =>
  partners.map((partner) => mapToIPartner(partner, countries));
