import { MAP_ARC_GIS_URL, MAP_BASE_CITY_LAYER_URL } from '../common/constants';
import { IApplicationFeatures, IMapLayer, MapLayerTypes, TimeBasedTypes } from '../types';

export const getBaseMapLayers = (applicationFeatures: IApplicationFeatures | null): IMapLayer[] => [
  { id: 1, name: 'base', type: MapLayerTypes.BASE },
  { id: 2, name: 'baseCity', url: MAP_BASE_CITY_LAYER_URL, type: MapLayerTypes.TILED },
  {
    id: 3,
    name: 'arcGisTopographic',
    type: MapLayerTypes.BASE_MAP,
    baseMap: 'Topographic',
    enabled: applicationFeatures?.['integrateDigipolisArcGis'],
  },
  { id: 4, name: 'arcGisGray', type: MapLayerTypes.BASE_MAP, baseMap: 'Gray' },
  { id: 5, name: 'satellite', type: MapLayerTypes.SATELLITE },
];

export const BASE_MAP_OVERLAY_LAYERS: IMapLayer[] = [
  {
    id: 6,
    name: 'constructionZone',
    url: MAP_ARC_GIS_URL,
    layer: 48,
    type: MapLayerTypes.DYNAMIC,
    timeBasedType: TimeBasedTypes.PHASE,
  },
  { id: 7, name: 'parkingBanLine', url: MAP_ARC_GIS_URL, layer: 20, type: MapLayerTypes.DYNAMIC },
  { id: 8, name: 'parkingBanZone', url: MAP_ARC_GIS_URL, layer: 21, type: MapLayerTypes.DYNAMIC },
  { id: 9, name: 'eventsLine', url: MAP_ARC_GIS_URL, layer: 23, type: MapLayerTypes.DYNAMIC },
  { id: 10, name: 'eventsZone', url: MAP_ARC_GIS_URL, layer: 22, type: MapLayerTypes.DYNAMIC },
  { id: 11, name: 'busLine', url: MAP_ARC_GIS_URL, layer: 5, type: MapLayerTypes.DYNAMIC },
  { id: 12, name: 'busStop', url: MAP_ARC_GIS_URL, layer: 3, type: MapLayerTypes.DYNAMIC },
  { id: 13, name: 'tramLine', url: MAP_ARC_GIS_URL, layer: 6, type: MapLayerTypes.DYNAMIC },
  { id: 14, name: 'tramStop', url: MAP_ARC_GIS_URL, layer: 4, type: MapLayerTypes.DYNAMIC },
  { id: 15, name: 'portArea', url: MAP_ARC_GIS_URL, layer: 24, type: MapLayerTypes.DYNAMIC },
  { id: 16, name: 'tree', url: MAP_ARC_GIS_URL, layer: 25, type: MapLayerTypes.DYNAMIC },
  { id: 17, name: 'park', url: MAP_ARC_GIS_URL, layer: 26, type: MapLayerTypes.DYNAMIC },
  { id: 18, name: 'wasteDisposalAboveGround', url: MAP_ARC_GIS_URL, layer: 27, type: MapLayerTypes.DYNAMIC },
  { id: 19, name: 'wasteDisposalUnderGround', url: MAP_ARC_GIS_URL, layer: 28, type: MapLayerTypes.DYNAMIC },
  { id: 20, name: 'glassContainer', url: MAP_ARC_GIS_URL, layer: 29, type: MapLayerTypes.DYNAMIC },
  { id: 21, name: 'antiParkingPost', url: MAP_ARC_GIS_URL, layer: 30, type: MapLayerTypes.DYNAMIC },
  { id: 22, name: 'bench', url: MAP_ARC_GIS_URL, layer: 31, type: MapLayerTypes.DYNAMIC },
  { id: 23, name: 'veloStation', url: MAP_ARC_GIS_URL, layer: 32, type: MapLayerTypes.DYNAMIC },
  { id: 24, name: 'bicycleParkingArea', url: MAP_ARC_GIS_URL, layer: 33, type: MapLayerTypes.DYNAMIC },
  { id: 25, name: 'bicycleShed', url: MAP_ARC_GIS_URL, layer: 34, type: MapLayerTypes.DYNAMIC },
  { id: 26, name: 'neighbourhoodBicycleShed', url: MAP_ARC_GIS_URL, layer: 36, type: MapLayerTypes.DYNAMIC },
  { id: 27, name: 'disabledParking', url: MAP_ARC_GIS_URL, layer: 14, type: MapLayerTypes.DYNAMIC },
  { id: 28, name: 'carSharing', url: MAP_ARC_GIS_URL, layer: 37, type: MapLayerTypes.DYNAMIC },
  { id: 29, name: 'cambio', url: MAP_ARC_GIS_URL, layer: 38, type: MapLayerTypes.DYNAMIC },
  { id: 30, name: 'chargingSpot', url: MAP_ARC_GIS_URL, layer: 12, type: MapLayerTypes.DYNAMIC },
  { id: 31, name: 'fixedLoadingZone', url: MAP_ARC_GIS_URL, layer: 39, type: MapLayerTypes.DYNAMIC },
  { id: 32, name: 'timedLoadingZone', url: MAP_ARC_GIS_URL, layer: 40, type: MapLayerTypes.DYNAMIC },
  { id: 33, name: 'taxiSpot', url: MAP_ARC_GIS_URL, layer: 13, type: MapLayerTypes.DYNAMIC },
  { id: 34, name: 'trafficLightCity', url: MAP_ARC_GIS_URL, layer: 41, type: MapLayerTypes.DYNAMIC },
  { id: 35, name: 'railway', url: MAP_ARC_GIS_URL, layer: 43, type: MapLayerTypes.DYNAMIC },
  { id: 36, name: 'levelCrossing', url: MAP_ARC_GIS_URL, layer: 44, type: MapLayerTypes.DYNAMIC },
  { id: 37, name: 'monument', url: MAP_ARC_GIS_URL, layer: 45, type: MapLayerTypes.DYNAMIC },
  { id: 38, name: 'lowEmissionZone', url: MAP_ARC_GIS_URL, layer: 42, type: MapLayerTypes.DYNAMIC },
  { id: 39, name: 'trafficSign', url: MAP_ARC_GIS_URL, layer: 46, type: MapLayerTypes.DYNAMIC },
  {
    id: 40,
    name: 'detour',
    url: MAP_ARC_GIS_URL,
    layer: 47,
    type: MapLayerTypes.DYNAMIC,
    timeBasedType: TimeBasedTypes.PHASE,
  },
];

export const BaseMapDefaultLayers = {
  baseLayer: 1,
  overlayLayers: [],
};
