import * as React from 'react';
import * as A from 'antwerp-core-react-branding';
import { createWarningAlertAction } from '../../../common/actions/creators/alert';
import {
  WORKFLOW_STATE_CANCELED,
  WORKFLOW_STATE_CLOSED,
  WORKFLOW_STATE_IN_EFFECT,
  WORKFLOW_STATE_REJECTED,
  WORKFLOW_STATE_SUBMITTED,
  WORKFLOW_STATE_WAITING_FOR_PAYMENT,
} from '../../../common/constants';
import { PublicDomainType } from '../../../common/enums';
import { getCarFreeZoneByGisId } from '../../../common/utils/requestUtils';
import {
  CarFreeZoneType,
  ICountry,
  IIntlProperties,
  IPublicDomainIntake,
  IPublicDomainIntakeType,
  IRequest,
} from '../../../types';
import { OverviewSection } from './overviewElements';
import { FC, useCallback, useEffect, useState } from 'react';
import { LocationItem } from './LocationItem.component';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '../../../translations/translate';
import { selectCarFreeZones } from '../../../store/selectors';

export type LicencePlateFormProperties = {
  countries: ICountry[];
  publicDomainIntakeTypes: IPublicDomainIntakeType[];
  request?: IRequest;
} & IIntlProperties;

export const LocationInfoAndForm: FC<LicencePlateFormProperties> = ({
  request,
  publicDomainIntakeTypes,
  countries,
}) => {
  const dispatch = useDispatch();
  const carFreeZones = useSelector(selectCarFreeZones);
  const [licensePlateWarningShown, setLicensePlateWarningShown] = useState(false);

  const isLicensePlateEnabled = useCallback(
    (intake: IPublicDomainIntake, request: IRequest): boolean => {
      if (intake.type.type !== PublicDomainType.CarfreeZone) {
        return false;
      }
      const zone = getCarFreeZoneByGisId(carFreeZones, intake.gisId);
      if (!zone) {
        return false;
      }
      const { state } = request.state!;

      switch (zone.type) {
        case CarFreeZoneType.anpr:
          return true;
        case CarFreeZoneType.krautli:
          return ![
            WORKFLOW_STATE_IN_EFFECT,
            WORKFLOW_STATE_CLOSED,
            WORKFLOW_STATE_CANCELED,
            WORKFLOW_STATE_REJECTED,
          ].includes(state);
        default:
          return state === WORKFLOW_STATE_SUBMITTED || state === WORKFLOW_STATE_WAITING_FOR_PAYMENT;
      }
    },
    [carFreeZones],
  );

  const hasZonesOfType = useCallback(
    (type: CarFreeZoneType): boolean => {
      return (request?.publicDomainIntakes || []).some((intake) => {
        const zone = getCarFreeZoneByGisId(carFreeZones, intake.gisId);
        return intake.type.type === PublicDomainType.CarfreeZone && zone?.type === type;
      });
    },
    [carFreeZones, request?.publicDomainIntakes],
  );

  const hasLicencePlatesRequired = useCallback((): boolean => {
    return (request?.publicDomainIntakes || []).some((pdi) => isLicensePlateEnabled(pdi, request!));
  }, [request, isLicensePlateEnabled]);

  useEffect(() => {
    if (hasLicencePlatesRequired() && !licensePlateWarningShown) {
      if (hasZonesOfType(CarFreeZoneType.krautli)) {
        dispatch(createWarningAlertAction(translate('myRequests.details.licensePlateWarning.krautli')));
        setLicensePlateWarningShown(true);
      } else if (hasZonesOfType(CarFreeZoneType.anpr)) {
        dispatch(createWarningAlertAction(translate('myRequests.details.licensePlateWarning.anpr')));
        setLicensePlateWarningShown(true);
      } else {
        dispatch(createWarningAlertAction(translate('myRequests.details.licensePlateWarning.general')));
        setLicensePlateWarningShown(true);
      }
    }
  }, [
    dispatch,
    carFreeZones,
    isLicensePlateEnabled,
    request,
    hasLicencePlatesRequired,
    hasZonesOfType,
    licensePlateWarningShown,
  ]);

  return (
    <OverviewSection>
      {/*@ts-ignore*/}
      <A.Spacing type={A.SpacingStyle.NoMargin} className="row between-md">
        <h6>{translate('paymentform.locations')}</h6>
      </A.Spacing>
      {request?.publicDomainIntakes.map((intake, i) => (
        <LocationItem
          publicDomainIntake={intake}
          index={i}
          publicDomainIntakeTypes={publicDomainIntakeTypes}
          request={request}
          countries={countries}
          key={intake.id}
          isLicensePlateEnabled={isLicensePlateEnabled(intake, request)}
        />
      ))}
    </OverviewSection>
  );
};
