import * as React from 'react';

import { AntwerpCheckboxes } from './AntwerpCheckboxes.component';
import { CheckboxOption, InputProperties } from 'antwerp-core-react-branding';

export interface ConfirmProperties extends InputProperties<boolean> {
  for?: string;
}

/**
 * Atoms: Form element confirm
 */
export class AntwerpConfirm extends React.Component<ConfirmProperties, {}> {
  public render(): any {
    return (
      <AntwerpCheckboxes
        options={this.getOptions()}
        {...this.props}
        // @ts-ignore
        label={null}
        value={[this.parseValueToString(this.props.value)]}
        for={this.props.for}
      />
    );
  }

  private getOptions(): CheckboxOption[] {
    return [
      {
        // @ts-ignore
        label: this.props.label,
        value: this.parseValueToString(true),
      },
    ];
  }

  private parseValueToString(value: any): string {
    if (value && (value === true || value === 'true')) {
      return 'true';
    }
    return 'false';
  }
}
