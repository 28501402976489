import { DATA_LOADED } from '../../common/actions/types';
import { IDefaultAction } from '../actions/actions';

const INITIAL_STATE: boolean = false;

export default function initialized(state: boolean = INITIAL_STATE, action: IDefaultAction): boolean {
  switch (action.type) {
    case DATA_LOADED:
      return true;
    default:
      return state;
  }
}
