import moment from 'moment/moment';
import * as React from 'react';
import intl from 'react-intl-universal';

import * as A from 'antwerp-core-react-branding';

import { APP_DEFAULT_COUNTRY, APP_DEFAULT_DATE_FORMAT } from '../../../common/constants';
import { ApplicantType } from '../../../common/enums';
import validateBirthday from '../../../common/validation/validateBirthday';
import { validateEmail } from '../../../common/validation/validateEmail';
import validateIban from '../../../common/validation/validateIban';
import { validateSSN } from '../../../common/validation/validateSSN';
import validateTelephone from '../../../common/validation/validateTelephone';
import { IRequest, ICost } from '../../../types';
import { DatePicker, TextInput } from '../../atoms';
import { FC, useCallback } from 'react';

type PersonalInputProperties = {
  cost: ICost;
  currentValues?: Partial<IRequest>;
};

export const PersonalInput: FC<PersonalInputProperties> = ({ cost, currentValues }) => {
  const isCitizen = useCallback(
    () => currentValues?.applicantType === ApplicantType.Citizen,
    [currentValues?.applicantType],
  );
  const isNonBelgianCitizen = useCallback(
    () => isCitizen() && currentValues?.country?.country !== APP_DEFAULT_COUNTRY,
    [currentValues?.country?.country, isCitizen],
  );

  const normalizeInput = useCallback((e: React.SyntheticEvent<HTMLInputElement, Event>) => {
    const { value } = e.currentTarget;
    if (value) {
      e.currentTarget.value = value.trim().toLowerCase();
    }
    return e;
  }, []);

  const onChangeRegExp = useCallback(
    (regExp: RegExp, replaceValue: string) => (e: any) => {
      const { value } = e.target;
      if (value) {
        e.target.value = value.replace(regExp, replaceValue);
      }
    },
    [],
  );

  return (
    <div>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          {/*@ts-ignore*/}
          <A.Spacing type={A.SpacingStyle.MarginBottom}>
            <TextInput name="firstName" label={intl.get('personalform.firstname')} required />
          </A.Spacing>
        </div>
        <div className="col-xs-12 col-md-6">
          {/*@ts-ignore*/}
          <A.Spacing type={A.SpacingStyle.MarginBottom}>
            <TextInput name="lastName" label={intl.get('personalform.lastname')} required />
          </A.Spacing>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          {/*@ts-ignore*/}
          <A.Spacing type={A.SpacingStyle.MarginBottom}>
            <TextInput
              name="emailAddress"
              label={intl.get('personalform.emailaddress')}
              required
              validate={validateEmail}
              onChange={normalizeInput}
            />
          </A.Spacing>
        </div>
        <div className="col-xs-12 col-md-6">
          {/* @ts-ignore */}
          <A.Spacing type={A.SpacingStyle.MarginBottom}>
            <TextInput
              type="tel"
              name="phoneNumber"
              label={intl.get('personalform.phonenumber')}
              required
              validate={validateTelephone}
              onChange={onChangeRegExp(/[/,.-]/g, ' ')}
            />
          </A.Spacing>
        </div>
      </div>
      <div className="row">
        {cost.totalCost > 0 && (
          <div className="col-xs-12 col-md-6">
            {/*@ts-ignore*/}
            <A.Spacing type={A.SpacingStyle.MarginBottom}>
              <TextInput
                name="ibanNumber"
                label={intl.get('personalform.ibannumber')}
                description={
                  <>
                    {intl.get('fields.withoutspaces')}
                    <br />
                    {intl.get('personalform.ibannumber.description')}
                  </>
                }
                required
                validate={validateIban}
                onChange={onChangeRegExp(/ /i, '')}
              />
            </A.Spacing>
          </div>
        )}
        <div className="col-xs-12 col-md-6" style={{ zIndex: 1 }}>
          {isCitizen() && !isNonBelgianCitizen() && cost.totalCost > 0 && (
            //@ts-ignore
            <A.Spacing type={A.SpacingStyle.MarginBottom} className="animated fadeIn">
              <TextInput
                name="ssn"
                label={intl.get('personalform.ssn')}
                description={intl.get('fields.withoutspaces')}
                required
                validate={validateSSN()}
                onChange={onChangeRegExp(/[ \-,.]/, '')}
              />
            </A.Spacing>
          )}
          {isNonBelgianCitizen() && (
            //@ts-ignore
            <A.Spacing type={A.SpacingStyle.MarginBottom} className="animated fadeIn">
              <DatePicker
                name="birthday"
                label={intl.get('personalform.birthday')}
                description={intl.get('fields.withoutspaces')}
                required
                validate={(value) => !!value && validateBirthday(value, moment().format(APP_DEFAULT_DATE_FORMAT))}
                maxDate={moment().format(APP_DEFAULT_DATE_FORMAT)}
                onChange={onChangeRegExp(/ /i, '')}
              />
            </A.Spacing>
          )}
        </div>
      </div>
    </div>
  );
};
