import { generatePath } from 'react-router';
import { SagaIterator } from 'redux-saga';
import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import { appUrls, EXCLUDED_ERROR_STATUSES } from '../../common/constants';
import { historyPush } from '../../common/utils/historyUtils';
import { mapToISgwRequest, mapToISgwRequestBE } from '../../common/utils/sgwRequestUtils';
import { translate } from '../../translations/translate';
import {
  IAttachment,
  ICall,
  ICountry,
  IRequestFile,
  ISgwRequest,
  ISgwRequestBE,
  PartnerType,
  SgwWorkflowStep,
} from '../../types';
import { SgwRequestActions } from '../actions';
import { createBusyChangedAction } from '../actions/creators';
import { SnackBarActions } from '../actions/Snackbar.actions';
import { SgwRequestApi } from '../api';
import { selectCountries } from '../selectors';
import { getRequest } from '../selectors/sgw';
import { genericErrorHandler } from './errors.sagas';
import { saveRequestPartner } from './partner.sagas';
import { saveTempAttachment } from './requests.sagas';
import { IS_DEVELOPMENT } from '../../config/environment';
import axios from 'axios';
import { COUNTRIES_RECEIVED } from '../../common/actions/types';

function* setRequestInReduxStore(request?: ISgwRequestBE): SagaIterator {
  if (request) {
    const countries: ICountry[] = yield select(selectCountries);
    yield put(SgwRequestActions.set(mapToISgwRequest(request, countries)));
  }
}

export function* createRequest({ payload }: ReturnType<typeof SgwRequestActions.create>): SagaIterator {
  const partnerId = yield call(saveRequestPartner, {
    payload: { partner: payload.partner, variant: PartnerType.requester },
    type: '',
  });
  return yield call(saveRequest, {
    payload: { step: SgwWorkflowStep.StartRequest, request: { ...payload.request, requestorId: partnerId } },
    type: '',
  });
}

export function* copyRequest({ payload }: ReturnType<typeof SgwRequestActions.copy>): SagaIterator {
  try {
    yield put(createBusyChangedAction(true));
    const { request } = payload;
    if (request.id) {
      const response: ICall<typeof SgwRequestApi.copy> = yield call(SgwRequestApi.copy, request.id);
      if (response?.data.data.id) {
        const url =
          appUrls.sgw.request.base +
          appUrls.sgw.request.edit(`${SgwWorkflowStep.StartRequest}`, `${response.data.data.id}`);
        historyPush(url);
      }
    }
  } finally {
    yield put(createBusyChangedAction(false));
  }
}

export function* deleteRequest({ payload }: ReturnType<typeof SgwRequestActions.copy>): SagaIterator {
  try {
    yield put(createBusyChangedAction(true));
    const { request } = payload;
    if (request.id) {
      yield call(SgwRequestApi.delete, request.id);
      yield put(SgwRequestActions.list.fetch());
    }
  } finally {
    yield put(createBusyChangedAction(false));
  }
}

export function* saveRequest({ payload }: ReturnType<typeof SgwRequestActions.save>): SagaIterator {
  try {
    const { step, request, id } = payload;
    const currentRequest: ISgwRequest = yield select(getRequest(id));

    let urgentRequestAttachmentFile = undefined;
    if (request.initialFile && request.isUrgentRequest && !request.initialFile?.id) {
      const file = yield call(saveTempAttachment, request.initialFile);
      if (!file?.file) {
        return;
      }
      urgentRequestAttachmentFile = `${file.file}`;
    }

    const response: ICall<typeof SgwRequestApi.save> = yield call(
      SgwRequestApi.save,
      mapToISgwRequestBE({
        ...currentRequest,
        ...request,
        urgentRequestAttachmentFile,
        id: Number(id),
      }),
    );

    yield call(setRequestInReduxStore, response?.data.data);

    const nextStep = step + 1;
    let path = generatePath(`${appUrls.sgw.request.base}${appUrls.sgw.request.edit()}`, {
      step: nextStep,
      id: response?.data.data.id,
    });

    if (nextStep === SgwWorkflowStep.Success) {
      path = generatePath(`${appUrls.sgw.request.base}${appUrls.sgw.request.submitted}`, {
        id: response?.data.data.id,
      });
    }
    historyPush(path);
  } catch (e) {
    yield put(
      SnackBarActions.setFailure({
        feedback: translate('sgw.form.saveRequestError'),
      }),
    );
    if (IS_DEVELOPMENT) {
      throw e;
    }
  }
}

export function* fetch({ payload }: ReturnType<typeof SgwRequestActions.fetch>): SagaIterator {
  try {
    const response: ICall<typeof SgwRequestApi.fetch> = yield call(SgwRequestApi.fetch, payload);
    yield call(setRequestInReduxStore, response?.data.data);
  } catch (error) {
    if (axios.isAxiosError(error) && EXCLUDED_ERROR_STATUSES.includes(error.response?.status!)) {
      historyPush(appUrls.notAuthorized);
    }
  }
}

export function* fetchAttachments({ payload }: ReturnType<typeof SgwRequestActions.attachments.fetch>): SagaIterator {
  const response: ICall<typeof SgwRequestApi.fetchAttachments> = yield call(SgwRequestApi.fetchAttachments, payload);
  yield put(SgwRequestActions.attachments.set(response!.data.data));
}

export function* fetchProjects(): SagaIterator {
  const response: ICall<typeof SgwRequestApi.fetchProjects> = yield call(SgwRequestApi.fetchProjects);
  yield put(SgwRequestActions.setProjects(response!.data.data));
}

export function* linkPartner({
  payload: { partnerId, requestId, variant, shouldSavePartnerFee },
}: ReturnType<typeof SgwRequestActions.linkPartner>): SagaIterator {
  // Object need to be cloned so it can be altered below
  const { dateFrom, dateUntil, ...request } = yield select(getRequest(requestId));

  if (variant === PartnerType.client) request.principalId = partnerId;
  else if (variant === PartnerType.contractor) {
    request.mainContractorId = partnerId;
    if (shouldSavePartnerFee) request.feeResponsibleId = partnerId;
  } else if (variant === PartnerType.fee) request.feeResponsibleId = partnerId;
  else if (variant === PartnerType.requester) request.requestorId = partnerId;
  else if (variant === PartnerType.signalisation) request.signallingResponsibleId = partnerId;

  yield call(
    SgwRequestApi.save,
    mapToISgwRequestBE({
      ...request,
    }),
  );

  yield put(SgwRequestActions.set(request));
}

export function* uploadAttachment({ payload }: ReturnType<typeof SgwRequestActions.attachments.upload>): SagaIterator {
  const file: IRequestFile = yield call(saveTempAttachment, payload.file);
  if (!file?.file) {
    return;
  }

  const initialFile: IAttachment = {
    // @ts-ignore
    fileName: file.file,
    sgwPhases: [],
    category: payload.category || '',
  };

  if (payload.requestId) {
    yield put(SgwRequestActions.attachments.link({ requestId: payload.requestId, attachment: initialFile }));
    yield put(SgwRequestActions.attachments.fetch(`${payload.requestId}`));
  }
}

export function* deleteAttachment({ payload }: ReturnType<typeof SgwRequestActions.attachments.remove>): SagaIterator {
  yield call(SgwRequestApi.deleteAttachment, `${payload.requestId}`, `${payload.attachmentId}`);
  yield put(SgwRequestActions.attachments.fetch(`${payload.requestId}`));
}

export function* linkRequestToAttachment({
  payload,
}: ReturnType<typeof SgwRequestActions.attachments.link>): SagaIterator {
  // attach it to sgw request

  const response: ICall<typeof SgwRequestApi.saveAttachmentToRequest> = yield call(
    SgwRequestApi.saveAttachmentToRequest,
    `${payload.requestId}`,
    payload.attachment,
  );

  yield put(SgwRequestActions.attachments.add(response!.data.data));
}

export function* fetchList(): SagaIterator {
  let countries: ICountry[] = yield select(selectCountries);
  if (!countries.length) {
    yield take(COUNTRIES_RECEIVED);
    countries = yield select(selectCountries);
  }
  if (!!countries.length) {
    const response: ICall<typeof SgwRequestApi.fetchList> = yield call(SgwRequestApi.fetchList);
    yield put(SgwRequestActions.list.set(response!.data.data.map((request) => mapToISgwRequest(request, countries))));
  }
}

export function* fetchPhaseCostQuarters({
  payload,
}: ReturnType<typeof SgwRequestActions.phaseCostQuarters.fetch>): SagaIterator {
  const response: ICall<typeof SgwRequestApi.fetchPhaseCostQuarters> = yield call(
    SgwRequestApi.fetchPhaseCostQuarters,
    payload,
  );
  yield put(SgwRequestActions.phaseCostQuarters.set(response!.data.data));
}

export default function* sgwRequestsSagas(): SagaIterator {
  yield takeEvery(SgwRequestActions.create.type, genericErrorHandler(createRequest));
  yield takeEvery(SgwRequestActions.copy.type, genericErrorHandler(copyRequest));
  yield takeEvery(SgwRequestActions.delete.type, genericErrorHandler(deleteRequest));
  yield takeEvery(SgwRequestActions.fetch.type, genericErrorHandler(fetch));
  yield takeEvery(SgwRequestActions.attachments.upload.type, genericErrorHandler(uploadAttachment));
  yield takeEvery(SgwRequestActions.attachments.remove.type, genericErrorHandler(deleteAttachment));
  yield takeEvery(SgwRequestActions.attachments.fetch.type, genericErrorHandler(fetchAttachments));
  yield takeEvery(SgwRequestActions.attachments.link.type, genericErrorHandler(linkRequestToAttachment));
  yield takeEvery(SgwRequestActions.fetchProjects.type, genericErrorHandler(fetchProjects));
  yield takeEvery(SgwRequestActions.linkPartner.type, genericErrorHandler(linkPartner));
  yield takeEvery(SgwRequestActions.save.type, genericErrorHandler(saveRequest));
  yield takeEvery(SgwRequestActions.list.fetch.type, genericErrorHandler(fetchList));
  yield takeEvery(SgwRequestActions.phaseCostQuarters.fetch.type, genericErrorHandler(fetchPhaseCostQuarters));
}
