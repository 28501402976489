import { Polygon, LineString } from 'geojson';
import { LatLng, LatLngTuple } from 'leaflet';
import { FC, PropsWithChildren, createContext, useState, SyntheticEvent } from 'react';
import { GeometryType } from '../common/enums';
import { getLatLngTuple2DReversed, getLatLngTuple3DReversed } from '../common/utils/mapUtils';
import { GeoDrawingType, ISgwGeoDrawing, ISgwPhase } from '../types';
import { getArea, getDistance } from '../common/utils/geometry.util';
import { initialFunction } from '../utils';
import { useLeafletDrawButtons } from '../hooks';
import { useFormContext } from 'react-hook-form';
import { createErrorAlertAction } from '../common/actions/creators/alert';
import { translate } from '../translations/translate';
import { useDispatch } from 'react-redux';

interface IGeoDrawingContext {
  addNewGeoDrawings(geoDrawings: ISgwGeoDrawing[]): void;
  calculateSurfaceArea(coordinates: LatLng[][] | LatLng[]): void;
  cancelEditGeoDrawing(): void;
  coordinates?: LatLngTuple;
  geoDrawingIndexInEdit?: number;
  geoDrawings: ISgwGeoDrawing[];
  inDrawingMode: boolean;
  isAddNew: boolean;
  isConstructionZoneType: boolean;
  newGeoDrawing?: ISgwGeoDrawing;
  onAddNew(isAddNew: boolean): void;
  onChangeLineDirection(): void;
  onEditDrawing(index: number): void;
  phaseId: string;
  removeGeoDrawing(index: number): void;
  requestId: string;
  savePhase(hideSnackBar: boolean): (e: SyntheticEvent) => void;
  setCoordinates(coordinates: LatLngTuple): void;
  setInDrawingMode(inDrawingMode: boolean): void;
  setShouldSave(shouldSave: boolean): void;
  shouldSave: boolean;
  updateGeoDrawing(geoDrawing: ISgwGeoDrawing): void;
  workZones: ISgwGeoDrawing[];
}

interface IProps {
  requestId: string;
  phaseId: string;
  geoDrawings?: ISgwGeoDrawing[];
  savePhase(hideSnackBar: boolean): (e: SyntheticEvent) => void;
}

export const initialGeoDrawingContextValues: IGeoDrawingContext = {
  addNewGeoDrawings: initialFunction,
  calculateSurfaceArea: initialFunction,
  cancelEditGeoDrawing: initialFunction,
  geoDrawings: [],
  inDrawingMode: false,
  isAddNew: false,
  isConstructionZoneType: false,
  onAddNew: initialFunction,
  onChangeLineDirection: initialFunction,
  onEditDrawing: initialFunction,
  phaseId: '',
  removeGeoDrawing: initialFunction,
  requestId: '',
  savePhase: () => initialFunction,
  setCoordinates: initialFunction,
  setInDrawingMode: initialFunction,
  setShouldSave: initialFunction,
  shouldSave: false,
  updateGeoDrawing: initialFunction,
  workZones: [],
};

export const GeoDrawingContext = createContext<IGeoDrawingContext>(initialGeoDrawingContextValues);

export const GeoDrawingContextProvider: FC<PropsWithChildren<IProps>> = ({
  children,
  requestId,
  geoDrawings = [],
  savePhase,
  phaseId,
}) => {
  const dispatch = useDispatch();
  const { stopDrawing } = useLeafletDrawButtons();
  const { setValue } = useFormContext<ISgwPhase>();
  const [isAddNew, setIsAddNew] = useState<boolean>(false);
  const [newGeoDrawing, setNewGeoDrawing] = useState<ISgwGeoDrawing>();
  const [shouldSave, setShouldSave] = useState<boolean>(false);
  const [inDrawingMode, setInDrawingMode] = useState<boolean>(false);
  const [geoDrawingIndexInEdit, setGeoDrawingIndexInEdit] = useState<number | undefined>();
  const [coordinates, setCoordinates] = useState<LatLngTuple>();
  const workZones = geoDrawings.filter((d) => d.geoDrawingType === GeoDrawingType.constructionzone);
  const isConstructionZoneType = newGeoDrawing?.geoDrawingType === GeoDrawingType.constructionzone;

  const createNewGeoDrawingObject = ({ id, sgwPhaseId, ...props }: ISgwGeoDrawing): ISgwGeoDrawing => ({ ...props });

  const addNewGeoDrawings = (data: ISgwGeoDrawing[]) => {
    setValue('sgwGeoDrawings', geoDrawings.concat(data.map(createNewGeoDrawingObject)));
    resetFields();
  };

  const updateGeoDrawing = (data: ISgwGeoDrawing) => {
    if (geoDrawingIndexInEdit !== undefined) {
      const _geoDrawings = [...geoDrawings];
      _geoDrawings.splice(geoDrawingIndexInEdit, 1, data);
      setValue('sgwGeoDrawings', _geoDrawings);
    }
    resetFields();
  };

  const removeGeoDrawing = (index: number) => {
    if (workZones.length === 1 && !isAddNew && geoDrawings[index].geoDrawingType === GeoDrawingType.constructionzone) {
      dispatch(
        createErrorAlertAction(
          translate('sgw.map.drawOnMap.phase.oneConstructionZoneMandatory'),
          translate('sgw.map.drawOnMap.phase.removeWorkzone'),
        ),
      );
    } else if (index !== undefined && !isAddNew) {
      geoDrawings.splice(index, 1);
      setValue('sgwGeoDrawings', geoDrawings);
    }
    resetFields();
  };

  const resetFields = () => {
    setIsAddNew(false);
    setNewGeoDrawing(undefined);
    setShouldSave(true);
    setGeoDrawingIndexInEdit(undefined);
    setInDrawingMode(false);
    stopDrawing();
  };

  const cancelEditGeoDrawing = () => {
    setIsAddNew(false);
    setNewGeoDrawing(undefined);
    setGeoDrawingIndexInEdit(undefined);
    setInDrawingMode(false);
    stopDrawing();
  };

  const calculateSurfaceArea = async (coords: LatLng[][] | LatLng[]) => {
    const currDrawing = geoDrawingIndexInEdit !== undefined ? { ...geoDrawings[geoDrawingIndexInEdit] } : newGeoDrawing;
    if (!currDrawing?.geoDrawingType) {
      return;
    }

    if (currDrawing.geoDrawingType === GeoDrawingType.constructionzone) {
      const coordinates = getLatLngTuple3DReversed(coords as LatLng[][]);
      currDrawing.geometry = { type: GeometryType.Polygon, coordinates } as Polygon;
      currDrawing.surfaceArea = getArea(currDrawing.geometry);
    } else {
      const coordinates = getLatLngTuple2DReversed(coords as LatLng[]);
      currDrawing.geometry = { type: GeometryType.LineString, coordinates } as LineString;
      currDrawing.surfaceArea = getDistance(currDrawing.geometry);
    }

    if (geoDrawingIndexInEdit !== undefined) {
      geoDrawings.splice(geoDrawingIndexInEdit, 1, currDrawing);
      setValue('sgwGeoDrawings', geoDrawings);
    } else {
      setNewGeoDrawing({ ...currDrawing });
    }
  };

  const onEditDrawing = (index: number) => setGeoDrawingIndexInEdit(index);

  const onAddNew = (addNew: boolean) => {
    setShouldSave(false);
    addNew && setNewGeoDrawing({});
    setIsAddNew(addNew);
  };

  const onChangeLineDirection = () => {
    const currDrawing = geoDrawingIndexInEdit !== undefined ? { ...geoDrawings[geoDrawingIndexInEdit] } : newGeoDrawing;
    if (currDrawing?.geometry) {
      const { geometry } = currDrawing;
      const reversedCoords = [...geometry.coordinates].reverse();

      const updatedGeodrawing: ISgwGeoDrawing = {
        ...currDrawing,
        geometry: {
          ...geometry,
          coordinates: reversedCoords,
        } as LineString,
      };

      if (geoDrawingIndexInEdit !== undefined) {
        geoDrawings.splice(geoDrawingIndexInEdit, 1, updatedGeodrawing);
        setValue('sgwGeoDrawings', geoDrawings);
      } else {
        setNewGeoDrawing(updatedGeodrawing);
      }
    }
  };

  const value = {
    addNewGeoDrawings,
    calculateSurfaceArea,
    cancelEditGeoDrawing,
    coordinates,
    geoDrawingIndexInEdit,
    geoDrawings: geoDrawings || [],
    inDrawingMode,
    isAddNew,
    isConstructionZoneType,
    newGeoDrawing,
    onAddNew,
    onChangeLineDirection,
    onEditDrawing,
    phaseId,
    removeGeoDrawing,
    requestId,
    savePhase,
    setCoordinates,
    setInDrawingMode,
    setShouldSave,
    shouldSave,
    updateGeoDrawing,
    workZones,
  };

  return <GeoDrawingContext.Provider value={value}>{children}</GeoDrawingContext.Provider>;
};
