import * as React from 'react';

import * as A from 'antwerp-core-react-branding';

import { APP_MAX_HOUR, APP_MIN_HOUR } from '../../../common/constants';
import { formatHour } from '../../../common/utils/hourUtils';
import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import intl from 'react-intl-universal';
import { IControllableForm } from '../../../common/form';

export type TimePickerProperties = Partial<A.SelectProperties> &
  IControllableForm & {
    end?: boolean;
    start?: boolean;
  };

/**
 * React Component TimePicker
 */
export const TimePicker: FC<TimePickerProperties> = ({ start, end, name, validate, ...props }) => {
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error, isTouched },
  } = useController({ name, control, rules: { required: intl.get('fields.required'), validate } });

  const getOptions = (): A.SelectOption[] => {
    const startValue: number = end ? APP_MIN_HOUR + 1 : APP_MIN_HOUR;
    const endValue: number = start ? APP_MAX_HOUR - 1 : APP_MAX_HOUR;
    const options: A.SelectOption[] = [];

    for (let i = startValue; i <= endValue; i++) {
      options.push({
        label: formatHour(i, true),
        value: formatHour(i, true),
      });
    }

    return options;
  };
  return (
    <A.Select
      {...props}
      {...field}
      meta={{ error: error?.message, touched: isTouched || !!error }}
      options={getOptions()}
      errorComponent={
        <div role="alert" aria-live="polite">
          {error?.message}
        </div>
      }
      onKeyDown={(event) => {
        // AS-6736 prevent the form from submitting on pressing Enter
        event.key === 'Enter' && event.preventDefault();
      }}
    />
  );
};
