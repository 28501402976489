import React, { FC, useCallback } from 'react';
import * as Creators from '../../../common/actions/creators/workflow';
import { WORKFLOW_MAX_STEP } from '../../../common/constants';
import { TypeForm } from '../../forms/2-typeform/2-typeform.component';
import { PeriodForm } from '../../forms/3-periodform';
import { LocationsForm } from '../../forms/4-locations/4-locations';
import { PersonalForm } from '../../forms/5-personalform/5-personalform';
import { PaymentForm } from '../../forms/6-paymentform';
import { StepNotFound } from './stepNotFound';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReasonsFrontOffice,
  selectCost,
  selectMetadata,
  selectRequest,
  selectSelectedPublicDomainIntakeTypes,
  selectStep,
  selectTenant,
} from '../../../store/selectors';
import { Visible } from '../../common/layout/Visible.component';
import { ReasonForm } from '../../forms/1-reasonform';
import { ApplicationActions } from '../../../store/actions/workflow.actions';
import { AsignWorkflowSteps } from '../../../types';

export const WorkflowForm: FC = () => {
  const dispatch = useDispatch();
  const cost = useSelector(selectCost);
  const initialValues = useSelector(selectRequest);
  const metadata = useSelector(selectMetadata);
  const request = useSelector(selectRequest);
  const requestReasons = useSelector(getReasonsFrontOffice);
  const selectedPublicDomainIntake = useSelector(selectSelectedPublicDomainIntakeTypes);
  const step = useSelector(selectStep);
  const tenant = useSelector(selectTenant);

  const onSubmit = useCallback(
    (data: any) => {
      if (step === WORKFLOW_MAX_STEP) {
        dispatch(Creators.createSubmitAction(data));
      } else {
        dispatch(ApplicationActions.requestNextStep(data));
      }
    },
    [step, dispatch],
  );

  const onPrevious = useCallback(
    (data: any): void => {
      dispatch(Creators.createPreviousStepRequestedAction(data));
    },
    [dispatch],
  );

  const getBaseProps = useCallback(
    () => ({
      dispatch,
      initialValues,
      onPrevious,
      onSubmit,
      request,
      step,
    }),
    [dispatch, initialValues, onPrevious, onSubmit, request, step],
  );

  return (
    <>
      <Visible visible={step === AsignWorkflowSteps.Reason}>
        <ReasonForm {...getBaseProps()} requestReasons={requestReasons} />
      </Visible>
      <Visible visible={step === AsignWorkflowSteps.Type}>
        <TypeForm {...getBaseProps()} />
      </Visible>
      <Visible visible={step === AsignWorkflowSteps.Period}>
        <PeriodForm {...getBaseProps()} metadata={metadata!} tenant={tenant!} />
      </Visible>
      <Visible visible={step === AsignWorkflowSteps.Location}>
        <LocationsForm
          {...getBaseProps()}
          initialValues={getBaseProps().initialValues!}
          cost={cost}
          selectedPublicDomainIntake={selectedPublicDomainIntake}
          tenant={tenant}
        />
      </Visible>
      <Visible visible={step === AsignWorkflowSteps.Personal}>
        <PersonalForm {...getBaseProps()} cost={cost!} />
      </Visible>
      <Visible visible={step === AsignWorkflowSteps.Payment}>
        <PaymentForm {...getBaseProps()} cost={cost!} />
      </Visible>
      <Visible
        visible={
          ![
            AsignWorkflowSteps.Period,
            AsignWorkflowSteps.Personal,
            AsignWorkflowSteps.Payment,
            AsignWorkflowSteps.Reason,
            AsignWorkflowSteps.Type,
            AsignWorkflowSteps.Location,
          ].includes(step)
        }
      >
        <StepNotFound />
      </Visible>
    </>
  );
};
