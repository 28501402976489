import React, { FC, useCallback, useState } from 'react';
import { useGenericStyles, useStreetSuggestions } from '../../../hooks';
import { Visible } from '../../common/layout/Visible.component';
import { CheckBox, Icon, IconButton } from '../../atoms';
import { Levels } from 'antwerp-core-react-branding';
import { Autocomplete, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { translate, translateHtml } from '../../../translations/translate';
import { IPhaseAddress, ISgwPhase } from '../../../types';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { usePhaseAddressStyles } from './PhaseAddressFields.styles';
import { Required } from '../../common/forms/Required.component';

export const PhaseAddressFields: FC = () => {
  const { focus, spacing, flex } = useGenericStyles();
  const C = usePhaseAddressStyles();

  const [streetName, setStreetName] = useState<string>('');
  const [streetNumberFrom, setStreetNumberFrom] = useState<string>('');
  const [streetNumberTo, setStreetNumberTo] = useState<string>('');
  const [streetNumberUnknown, setStreetNumberUnknown] = useState<boolean>(false);
  const { setValue, watch, formState, register, setError, clearErrors } = useFormContext<ISgwPhase>();

  const addresses: IPhaseAddress[] | undefined = (watch('addresses') || []).filter(
    (address, index, self) => self.findIndex((a) => a.streetName === address.streetName) === index,
  );

  const { streetSuggest, streetSuggestions } = useStreetSuggestions('addresses');

  const addPhaseAddress = useCallback(() => {
    const previousAddresses = addresses || [];
    if (streetName) {
      const newAddress = streetNumberUnknown ? { streetName } : { streetName, streetNumberFrom, streetNumberTo };
      const newAddresses: IPhaseAddress[] = [...previousAddresses, newAddress];
      setValue('addresses', newAddresses);

      newAddresses.length && clearErrors('addresses');
      setStreetName('');
      setStreetNumberFrom('');
      setStreetNumberTo('');
      setStreetNumberUnknown(false);
    }
  }, [addresses, streetName, streetNumberUnknown, streetNumberFrom, streetNumberTo, setValue, clearErrors]);

  const onDelete = useCallback(
    (addressToDelete: IPhaseAddress) => {
      const newAddresses = addresses.filter((address) => addressToDelete !== address);
      setValue('addresses', newAddresses);
      !newAddresses.length && setError('addresses', { type: 'required' });
    },
    [addresses, setValue, setError],
  );

  return (
    <div
      className={spacing.marginBottom.LG}
      {...register('addresses', {
        required: !addresses?.length && translate('sgw.phases.addresses.required'),
        validate: (value) => !!value?.length,
      })}
    >
      <h6 className={spacing.marginBottom.MD}>{translate('sgw.phases.addresses.title')}</h6>
      <div className={spacing.marginBottom.SM}>{translateHtml('sgw.phases.addresses.description')}</div>
      <Table className={C.table}>
        <TableHead className={C.tableHeaders}>
          <TableCell>{translate('sgw.phases.addresses.street')}</TableCell>
          <TableCell>{translate('sgw.phases.addresses.streetNumberFrom')}</TableCell>
          <TableCell>{translate('sgw.phases.addresses.streetNumberTo')}</TableCell>
          <TableCell />
        </TableHead>
        <TableBody>
          <Visible
            visible={!!addresses?.length}
            defaultComponent={<div className={C.empty}>{translate('sgw.phases.addresses.notFound')}</div>}
          >
            {!!addresses &&
              addresses.map((address) => (
                <TableRow className={C.tableRow}>
                  <TableCell>{address.streetName}</TableCell>
                  <TableCell>{address.streetNumberFrom}</TableCell>
                  <TableCell>{address.streetNumberTo}</TableCell>
                  <TableCell>
                    <Icon.Delete level={Levels.Secondary} onClick={() => onDelete(address)} className={focus.within} />
                  </TableCell>
                </TableRow>
              ))}
          </Visible>
        </TableBody>
      </Table>
      <div className={classNames(flex.row, spacing.marginTop.MD, spacing.marginBottom.MD)}>
        <Autocomplete
          className={C.autocomplete}
          onChange={(event, value) => value && setStreetName(value)}
          options={streetSuggestions}
          noOptionsText={translate('sgw.phases.addresses.noOptions')}
          value={streetName}
          renderInput={(params) => (
            <TextField
              className={C.textField}
              {...params}
              label={translate('sgw.phases.addresses.street')}
              variant="standard"
              onChange={(event) => streetSuggest(event.target.value)}
              value={streetName}
            />
          )}
        />
        <TextField
          onChange={(event) => setStreetNumberFrom(event.target.value)}
          className={C.textField}
          variant="standard"
          label={translate('sgw.phases.addresses.streetNumberFrom')}
          value={streetNumberUnknown ? '' : streetNumberFrom}
          disabled={streetNumberUnknown}
        />
        <TextField
          onChange={(event) => setStreetNumberTo(event.target.value)}
          className={C.textField}
          variant="standard"
          label={translate('sgw.phases.addresses.streetNumberTo')}
          value={streetNumberUnknown ? '' : streetNumberTo}
          disabled={streetNumberUnknown}
        />
        <CheckBox
          checked={streetNumberUnknown}
          label={translate('sgw.phases.addresses.streetNumberUnknown')}
          onChange={setStreetNumberUnknown}
        />
      </div>
      <div className={classNames(flex.row, spacing.marginBottom.SM)}>
        <IconButton type="button" icon="plus" onClick={addPhaseAddress}>
          {translate('sgw.phases.addresses.addAddress')}
        </IconButton>
      </div>
      <Required required={!!formState.errors.addresses} message={translate('sgw.phases.addresses.required')} />
    </div>
  );
};
