import { OverviewList } from '../../atoms';
import { PartnerType, SgwWorkflowStep, TRouterWithId } from '../../../types';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getRequest, getRequestPartnersId } from '../../../store/selectors/sgw';
import { FC } from 'react';
import { translate } from '../../../translations/translate';
import { PartnerInfo } from '../PartnerInfo/PartnerInfo.component';
import { SpinnerLoader } from '../../atoms/SpinnerLoader/SpinnerLoader.component';

export const PartnersInfo: FC = () => {
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const partnerIds = useSelector(getRequestPartnersId(requestId!));
  const request = useSelector(getRequest(requestId));

  return (
    <SpinnerLoader loading={!partnerIds}>
      <OverviewList>
        <OverviewList.Title step={SgwWorkflowStep.Partners}>
          {translate('sgw.overview.partners.title')}
        </OverviewList.Title>
        <PartnerInfo id={partnerIds['client']} variant={PartnerType.client} />
        <PartnerInfo id={partnerIds['signalisation']} variant={PartnerType.signalisation} />
        <PartnerInfo id={partnerIds['contractor']} variant={PartnerType.contractor} />
        {request?.acl.sgwRetributionRequired && <PartnerInfo id={partnerIds['fee']} variant={PartnerType.fee} />}
      </OverviewList>
    </SpinnerLoader>
  );
};
