import { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    margin: 20,
  },
  h1: {
    marginBottom: 30,
    fontSize: 'larger',
  },
  h2: {
    marginBottom: 20,
    fontSize: 'large',
  },
  h3: {
    marginBottom: 10,
    fontSize: 'medium',
  },
  p: {
    marginBottom: 10,
  },
});

export const DriverPrivacyPolicyPage: FC = () => {
  const C = useStyles();

  return (
    <div className={C.root}>
      <h1 className={C.h1}>Privacy policy for A-Sign mobile applications</h1>

      <h2 className={C.h2}>What kinds of information do we collect?</h2>
      <p className={C.p}>
        Depending on which services you use, we collect different kinds of information from or about you, this is only
        used to track the sign locations and when workorder items were completed.
      </p>

      <h3 className={C.h3}>Things you do and information you provide.</h3>
      <p className={C.p}>
        We collect the content and other information you provide when you use our services, including when you sned
        messages or communicate with others. This can include information in or about the content you provide. We also
        collect information about how you use our services, such as the types of content you view or engage with or the
        frequency and duration of your activities.
      </p>

      <h3 className={C.h3}>Device information</h3>
      <p className={C.p}>
        We collect information from or about the smartphones, tablets or other devices where you install or access our
        services, depending on the permissions you’ve granted. We may associate the information we collect from your
        different devices, which helps us provide consistent services across your devices. Here are some examples of the
        device information we collect: Attributes such as the operating system, hardware version, device settings, file
        and software names and types, battery and signal strength, and device identifiers. Device locations, including
        specific geographic locations, such as through GPS, Bluetooth, or WiFi signals. Connection information such as
        the name of your mobile operator or ISP, browser type, language and time zone, mobile phone number and IP
        address.
      </p>

      <h2 className={C.h2}>How do we use this information?</h2>
      <p className={C.p}>
        We are passionate about creating engaging and customized experiences for people. We use all of the information
        we have to help us provide and support our services. Here’s how:{' '}
      </p>

      <h3 className={C.h3}>Provide, improve and develop Services.</h3>
      <p className={C.p}>
        We are able to deliver our services, personalize content, and make suggestions for you by using this information
        to understand how you use and interact with our Services and the people or things you’re connected to and
        interested in on and off our Services. We conduct surveys and research, test features in development, and
        analyze the information we have to evaluate and improve products and services, develop new products or features,
        and conduct audits and troubleshooting activities.
      </p>

      <h3 className={C.h3}>Communicate with you.</h3>
      <p className={C.p}>We use your information to respond to you when you contact us.</p>

      <h3 className={C.h3}>Promote safety and security.</h3>
      <p className={C.p}>
        We use the information we have to help verify accounts and activity, and to promote safety and security on and
        off of our Services, such as by investigating suspicious activity or violations of our terms or policies. We
        work hard to protect your account using teams of engineers, automated systems, and advanced technology such as
        encryption and machine learning.
      </p>
    </div>
  );
};
