import { Visible } from '../layout/Visible.component';
import intl from 'react-intl-universal';
import * as React from 'react';

interface IProps {
  required: boolean;
  message?: string;
}

export const Required = ({ required, message }: IProps) => (
  <Visible visible={required}>
    <small className="has-error" role="alert" aria-live="polite">
      {message || intl.get('fields.required')}
    </small>
  </Visible>
);
