import { CarFreeZoneType, IRequestLicencePlate, TCarFreeZone } from '../../../../types';
import * as A from 'antwerp-core-react-branding';
import * as React from 'react';
import { FC } from 'react';
import { Visible } from '../../layout/Visible.component';
import { translate } from '../../../../translations/translate';

interface IProps {
  permittedPlates?: IRequestLicencePlate[];
  zone?: TCarFreeZone;
}

export const LicensePlateAddWarning: FC<IProps> = ({ permittedPlates = [], zone }) => {
  return (
    <Visible visible={!permittedPlates?.length && zone?.type === CarFreeZoneType.anpr}>
      <div className="col-sm-12">
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginBottom}>
          {/*@ts-ignore*/}
          <A.Paragraph type={A.ParagraphStyle.Alert} level={A.Levels.Danger}>
            {translate('myRequests.details.licensePlateWarning.add')}
          </A.Paragraph>
        </A.Spacing>
      </div>
    </Visible>
  );
};
