import { useGenericStyles } from '../../../hooks';
import { InfoText } from '../InfoText/InfoText.component';
import * as React from 'react';

export const PaymentDisabled = () => {
  const { spacing } = useGenericStyles();
  return (
    <InfoText className={spacing.marginBottom.MD}>
      Door de cyberaanval waar de stad Antwerpen mee geconfronteerd werd, is het momenteel onmogelijk om uw
      parkeerverbodsaanvraag vooraf online of per overschrijving te betalen. Een factuur wordt opgemaakt voor het totale
      bedrag van uw aanvraag, dit wordt berekend op basis van het goedgekeurde{' '}
      {
        <a
          target="_blank"
          href="https://assets.antwerpen.be/srv/assets/api/download/361da531-19d0-464d-942c-e456a46084b5/Retributiereglement_innameparkeerplaatsen_2023-2025.pdf"
          rel="noreferrer"
        >
          retributiereglement
        </a>
      }
      . De factuur zal op een later tijdstip uitgestuurd worden.
    </InfoText>
  );
};
