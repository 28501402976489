import { PaymentType } from './enums';
import { ValidateResult } from 'react-hook-form/dist/types/validator';
import { ApplicantType } from './enums';
import { ICountry, IProfile, IPublicDomainIntake, IRequestFile, IRequestReason, IWithClassName } from '../types';

export interface IReasonForm {
  reason?: IRequestReason | null;
  comment?: string;
  requestAddress?: {
    street?: string;
    streetNumber?: string;
  };
}

export interface ITypeForm {
  publicDomainIntakes?: IPublicDomainIntake[];
  attachments?: IRequestFile[];
  conditions?: boolean;
}

export interface IPeriodForm {
  dateFrom?: string;
  dateUntil?: string;
  onlyOnWeekdays?: boolean;
  timeFrom?: string;
  timeUntil?: string;
  entireDay?: boolean;
  publicDomainIntakes?: IPublicDomainIntake[]; // periodForm is capable of editing pdis
}

export enum PeriodFormFields {
  dateFrom = 'dateFrom',
  dateUntil = 'dateUntil',
  timeFrom = 'timeFrom',
  timeUntil = 'timeUntil',
  entireDay = 'entireDay',
  onlyOnWeekdays = 'onlyOnWeekdays',
}

export interface IPersonalForm extends Partial<IProfile> {
  applicantType?: ApplicantType;
  attachments?: IRequestFile[];
  emailAddress?: string;
  country?: ICountry;
  companyId?: string;
  companyName?: string;
}

export enum PersonalFormFields {
  applicantType = 'applicantType',
  attachments = 'attachments',
  emailAddress = 'emailAddress',
  country = 'country',
  bus = 'bus',
  zipCode = 'zipCode',
  city = 'city',
  companyId = 'companyId',
  companyName = 'companyName',
}

export interface IPaymentForm {
  paymentType?: PaymentType;
}

export enum PaymentFormFields {
  paymentType = 'paymentType',
}

export interface IControllableForm extends IWithClassName {
  label?: string | JSX.Element;
  name: string;
  validate?(value: string): ValidateResult | Promise<ValidateResult>;
}
