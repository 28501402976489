import * as React from 'react';

import classNames from 'classnames';
import { FC } from 'react';
import { ButtonProps, ButtonVariant } from '../../../types/button.types';

export const Button: FC<ButtonProps> = ({ level, className, size, variant, children, ...buttonProps }) => {
  const _className = () =>
    classNames(
      'a-button',
      variant && variant !== ButtonVariant.Default ? `a-button-${variant}` : '',
      level ? `a-button--${level}` : '',
      size ? `a-button--${size}` : '',
      className,
    );

  return (
    <button className={_className()} {...buttonProps}>
      {children}
    </button>
  );
};
