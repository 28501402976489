import React, { FC, useEffect } from 'react';
import { createInitializeWorkflowAction } from '../../../common/actions/creators/workflow';
import Price from '../../common/price/price';
import WorkflowStepper from './workflowStepper';
import { useDispatch, useSelector } from 'react-redux';
import { selectCost, selectRequest, selectStep } from '../../../store/selectors';
import { Visible } from '../../common/layout/Visible.component';
import { WorkflowForm } from './WorkflowForm.component';
import { AsignWorkflowSteps } from '../../../types';
import { CarFreeZoneActions } from '../../../store/actions/carFreeZone.actions';

export const Workflow: FC = () => {
  const dispatch = useDispatch();
  const cost = useSelector(selectCost);
  const request = useSelector(selectRequest);
  const step = useSelector(selectStep);

  useEffect(() => {
    dispatch(createInitializeWorkflowAction());
    dispatch(CarFreeZoneActions.get());
  }, [dispatch]);

  return (
    <Visible visible={!!request}>
      <section className="workflow animated fadeIn">
        <WorkflowStepper activeStep={step} />
        <div className="row">
          <div className={step !== AsignWorkflowSteps.Location ? 'col-xs-12 col-md-9' : 'col-xs-12 col-md-12'}>
            <WorkflowForm />
          </div>
          <Visible visible={step !== AsignWorkflowSteps.Location}>
            <div className="col-xs-12 col-md-3">
              <Price cost={cost} showGdpr={step === 5} />
            </div>
          </Visible>
        </div>
      </section>
    </Visible>
  );
};
