import { Button } from '@mui/material';
import classNames from 'classnames';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../theme';
import { translate } from '../../../translations/translate';
import { useGenericStyles } from '../../../hooks';
import { useAtom } from 'jotai';
import { replanModalAtom } from '../../pages';
import { useDispatch, useSelector } from 'react-redux';
import { SgwRequestActions } from '../../../store/actions';
import { useParams } from 'react-router';
import { selectRescheduling } from '../../../store/selectors/sgw';
import { TRouterWithId } from '../../../types';

const useStyles = createUseStyles({
  rescheduling: {
    backgroundColor: COLORS.lightGrey,
    padding: '10px',
  },
  reschedulingInfo: {
    padding: '5px',
    margin: '10px 0px',
    textAlign: 'justify',
  },
});

export const RescheduleInfo: FC = () => {
  const dispatch = useDispatch();
  const C = useStyles();
  const G = useGenericStyles();
  const [, setReplanModal] = useAtom(replanModalAtom);
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const reschedulings = useSelector(selectRescheduling);
  const onDelete = () => {
    dispatch(
      SgwRequestActions.rescheduling.remove({
        requestId: requestId!,
        requestRescheduleId: `${reschedulings[reschedulings.length - 1]?.id}`,
      }),
    );
  };
  return (
    <div className={C.rescheduling}>
      <div className={G.flex.centerVertical}>
        <i className={classNames('fa fa-info-circle fa-2x', G.spacing.marginRight.SM)} />
        <span>{translate('myRequests.details.rescheduleRequest.rescheduleButton')}</span>
      </div>
      <p className={C.reschedulingInfo}>{translate('myRequests.details.rescheduleRequest.rescheduleInfo')}</p>

      <div>
        <Button
          startIcon={<i className="fa fa-close" />}
          variant="text"
          size="small"
          className={G.spacing.marginRight.SM}
          onClick={onDelete}
        >
          {translate('myRequests.details.rescheduleRequest.discardRequest')}
        </Button>
        <Button
          startIcon={<i className="fa fa-pencil" />}
          variant="contained"
          size="small"
          onClick={() => setReplanModal(true)}
        >
          {translate('myRequests.details.rescheduleRequest.editRequest')}
        </Button>
      </div>
    </div>
  );
};
