import { OverviewList } from '../../atoms';
import { Category, SgwWorkflowStep, TRouterWithId } from '../../../types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FC, useEffect } from 'react';
import { translate } from '../../../translations/translate';
import { SgwRequestActions } from '../../../store/actions';
import { AttachmentInfo } from '../AttachmentInfo/AttachmentInfo.component';
import { getPhaseOverviewInfo } from '../../../store/selectors/sgw';
import { PhaseActions } from '../../../store/actions/phase.actions';

interface IProps {
  disableStep?: boolean;
}

export const AttachmentsInfo: FC<IProps> = ({ disableStep }) => {
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const overviewInfo = useSelector(getPhaseOverviewInfo(requestId!));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SgwRequestActions.attachments.fetch(requestId!));
    dispatch(PhaseActions.fetchList(requestId!));
  }, [dispatch, requestId]);

  return (
    <OverviewList>
      <OverviewList.Title step={disableStep ? undefined : SgwWorkflowStep.Attachments}>
        {translate('sgw.attachments.title')}
      </OverviewList.Title>

      {overviewInfo.map(({ name, url, category, phaseNames }, index) => (
        <AttachmentInfo
          fileName={name}
          url={url}
          category={category as Category}
          phaseNames={phaseNames}
          key={name + index}
        />
      ))}
    </OverviewList>
  );
};
