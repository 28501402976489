import './price.scss';

import * as React from 'react';
import intl from 'react-intl-universal';

import { calculateNumberOfParkingSpots, formatPrice } from '../../../common/utils/priceUtils';
import { ICost } from '../../../types/cost.types';

export type PriceProp = {
  cost: ICost;
};

/**
 * React Component Price
 */
export default class IntermediatePrice extends React.Component<PriceProp, {}> {
  public render() {
    return (
      <table className="a-table">
        <thead>
          <tr>
            <th>{intl.get('general.price')}</th>
            <th></th>
          </tr>
        </thead>
        {this.renderCost()}
      </table>
    );
  }

  private renderCost(): JSX.Element {
    if (this.hasCost()) {
      const cost = this.props.cost;

      return (
        <tbody>
          {calculateNumberOfParkingSpots(this.props.cost.costComponents) > 0 && (
            <tr>
              <td>{intl.get('general.parkinglots')}</td>
              <td>{cost.numberOfParkingSpots}</td>
            </tr>
          )}
          <tr>
            <td>{intl.get('general.days')}</td>
            <td>{cost.numberOfDays}</td>
          </tr>
          <tr>
            <td>{intl.get('price.currentlocationcost')}</td>
            <td>
              <span>{formatPrice(cost.variableCost)}</span>
            </td>
          </tr>
          <tr className="total-price">
            <td>
              <b>{intl.get('price.totalcostrequest')}</b>
            </td>
            <td>
              <b>{formatPrice(cost.totalCost)}</b>
            </td>
          </tr>
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={2}>{intl.get('price.couldnotbecalculated')}</td>
          </tr>
        </tbody>
      );
    }
  }

  private hasCost(): boolean {
    if (this.props.cost && Object.keys(this.props.cost).length > 0) {
      return true;
    }
    return false;
  }
}
