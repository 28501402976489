import { FC, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { validateAddress } from '../../../../common/validation/validateAddress';
import { ISgwRequest } from '../../../../types';
import { useGenericStyles } from '../../../../hooks';
import * as A from 'antwerp-core-react-branding';
import { Fieldset, TextInput } from '../../../atoms';
import { translate } from '../../../../translations/translate';
import { createUseStyles } from 'react-jss';
import { CheckboxWithLabelComponent } from '../../../common/forms/CheckboxWithLabel.component';
import classNames from 'classnames';
import { StreetField } from '../../../organisms';
import { Visible } from '../../../common/layout/Visible.component';
import { geoCodeAddress } from '../../../../common/api';
import {
  TENANT_NAME,
  STREET_NUMBER_VALIDATION,
  HOUSE_NUMBER_MAX_LENGTH,
  POSTAL_CODE_REGEX,
} from '../../../../common/constants';

const useStyles = createUseStyles({
  street: {
    '& > div:first-of-type': {
      width: '100%',
    },
  },
});

export const MainLocationFields: FC = () => {
  const C = useStyles();
  const { spacing } = useGenericStyles();
  const { trigger, register, watch, setValue, resetField } = useFormContext<ISgwRequest>();

  const rowClassWithTopSpacing = classNames('row', spacing.marginTop.MD);

  const streetNumberUnknown = watch('mainLocation.streetNumberUnknown');
  const street = watch('mainLocation.street');

  const resetZipAndDistrict = useCallback(() => {
    setValue('mainLocation.zipCode', '');
    setValue('mainLocation.city', '');
  }, [setValue]);

  useEffect(() => {
    const getCityAndZipCode = async (controller: AbortController) => {
      if (street) {
        const response = await geoCodeAddress({ street, city: TENANT_NAME }, controller.signal);
        const address = response?.data.data?.address;
        if (street.replace(POSTAL_CODE_REGEX, '') === address?.streetName) {
          address?.postalCode && setValue('mainLocation.zipCode', address.postalCode);
          address?.city && setValue('mainLocation.city', address.city);
          trigger('mainLocation.zipCode');
        } else resetZipAndDistrict();
      } else resetZipAndDistrict();
    };

    const controller = new AbortController();

    getCityAndZipCode(controller);

    return () => controller.abort();
  }, [resetZipAndDistrict, setValue, street, trigger]);

  useEffect(() => {
    if (streetNumberUnknown === false) {
      resetField('mainLocation.streetNumberFrom');
      resetField('mainLocation.streetNumberTo');
    }
  }, [resetField, streetNumberUnknown]);

  const onChangeStreetNumberUnknown = (): void => {
    // AS-4689 We need to populate StreetNumber with '0' to disable validation for intake without house Numbers
    // and set it back to empty string to enable validation
    if (!streetNumberUnknown) {
      setValue('mainLocation.streetNumberFrom', '0');
      setValue('mainLocation.streetNumberTo', '0');
    } else {
      setValue('mainLocation.streetNumberFrom', '');
      setValue('mainLocation.streetNumberTo', '');
    }
  };

  const streetValidation = async (value?: string) =>
    await validateAddress(value, undefined, translate('locationsform.noStreets'));

  const zipCodeValidation = async (value?: string) =>
    await validateAddress(street, undefined, translate('locationsform.invalidZipCode'), value);

  return (
    <Fieldset legend={translate('sgw.phases.mainLocation.legend')} data-testid={'mainLocationFieldset'}>
      {/*@ts-ignore*/}
      <A.Paragraph spacing>{translate('sgw.phases.mainLocation.info')}</A.Paragraph>
      <div className={rowClassWithTopSpacing}>
        <div className="col-md-7 col-xs-12">
          <StreetField
            className={C.street}
            {...register('mainLocation.street', { required: true, validate: streetValidation })}
            required
          />
        </div>
      </div>
      <div className={rowClassWithTopSpacing}>
        <TextInput
          className="col-xs-3"
          {...register('mainLocation.zipCode', { required: true, validate: zipCodeValidation })}
          label={translate('sgw.phases.mainLocation.zipcode')}
          required
        />
        <TextInput
          className="col-xs-3"
          {...register('mainLocation.city', { required: true })}
          label={translate('sgw.phases.mainLocation.city')}
          required
        />
        <TextInput
          className="col-xs-2"
          {...register('mainLocation.bus')}
          label={translate('sgw.phases.mainLocation.bus')}
        />
      </div>
      <Visible visible={!streetNumberUnknown}>
        <div className={rowClassWithTopSpacing}>
          <TextInput
            className="col-xs-3"
            {...register('mainLocation.streetNumberFrom', {
              required: !streetNumberUnknown,
              minLength: 1,
              pattern: {
                value: STREET_NUMBER_VALIDATION,
                message: translate('locationsform.streetNumberFromRequired'),
              },
            })}
            label={translate('sgw.phases.mainLocation.streetNumberFrom')}
            required={!streetNumberUnknown}
            maxLength={HOUSE_NUMBER_MAX_LENGTH}
          />
          <TextInput
            className="col-xs-3"
            {...register('mainLocation.streetNumberTo', {
              required: !streetNumberUnknown,
              minLength: 1,
              pattern: { value: STREET_NUMBER_VALIDATION, message: translate('locationsform.streetNumberToRequired') },
            })}
            label={translate('sgw.phases.mainLocation.streetNumberTo')}
            required={!streetNumberUnknown}
            maxLength={HOUSE_NUMBER_MAX_LENGTH}
          />
        </div>
      </Visible>
      <div className={rowClassWithTopSpacing}>
        <div className="col-xs-12 col-md-6">
          <CheckboxWithLabelComponent
            label={translate('sgw.phases.mainLocation.specifyStreetNumberCheckbox')}
            {...register('mainLocation.streetNumberUnknown', { onChange: onChangeStreetNumberUnknown })}
          />
        </div>
      </div>
    </Fieldset>
  );
};
