import { createUseStyles } from 'react-jss';

export const usePhaseOverviewStyles = createUseStyles({
  accordion: {
    marginTop: '30px',
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
});
