import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';
import { Levels, Sizes } from '../../../common/enums';
import { onEnter } from '../../../common/utils/keyboardUtils';
import { COLORS } from '../../../theme';
import { translate } from '../../../translations/translate';
import { IColumn } from '../../../types';
import { Icon } from '../../atoms';

interface IProps<T> {
  records: T[];
  onClick?: (record: T) => void;
  onDelete?: (record: T) => void;
  columns: IColumn<T>[];
}

const useStyle = createUseStyles({
  deleteButton: { textAlign: 'right !important' },
  removeLeftMargin: {
    marginLeft: 0,
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${COLORS.rowHover}!important`,
    },
  },
  delete: {
    textAlign: 'right',
  },
  table: {
    display: 'table',
    borderSpacing: 0,
    border: `1px solid ${COLORS.borderGrey}`,
    borderCollapse: 'collapse',
    width: '100%',

    '& tr': {
      height: '2.25rem',
      borderBottom: `1px solid  ${COLORS.borderGrey}`,
      transition: 'background-color .25s ease-in-out',
    },

    '& thead': {
      background: COLORS.lightGrey,
      '& th': {
        padding: '0.75rem',
        textAlign: 'left',
      },
    },

    '& tbody': {
      background: COLORS.lightGrey,
      '& td': {
        padding: '0.75rem',
        textAlign: 'left',
      },
    },
  },
  whiteBackground: {
    background: COLORS.white,
  },
});

export const MyRequestsDesktopTable = <T,>({ onClick, onDelete, records, columns }: PropsWithChildren<IProps<T>>) => {
  const C = useStyle();

  const onRowClick = (record: T) => () => onClick?.(record);
  const onKeyPress = (record: T) => () => onEnter(onRowClick(record));

  const renderHeaders = () => (
    <thead>
      <tr>
        {columns
          .filter(({ hideInDesktop, visible = true }) => !hideInDesktop && visible)
          .map(({ name, label, className }) => (
            // @ts-ignore
            <th key={`${name}`} className={className}>
              {label}
            </th>
          ))}
        {onDelete && <th />}
      </tr>
    </thead>
  );

  const renderRows = () =>
    !records?.length ? null : (
      <tbody>
        {records.map((record, i) => (
          <tr
            className={classNames(C.row, 'focusable', i % 2 === 0 && C.whiteBackground)}
            //@ts-ignore
            key={`${i}-${record?.['id']}`}
            onKeyPress={onKeyPress(record)}
            onClick={onRowClick(record)}
            tabIndex={0}
          >
            {columns
              .filter(({ hideInDesktop, visible = true }) => !hideInDesktop && visible)
              .map(({ renderer, name }, index) => (
                // @ts-ignore
                <td key={`${index}-${name}`}>{renderer ? renderer(record[name], record) : record[name]}</td>
              ))}
            {onDelete && (
              <td onKeyPress={(event) => event.stopPropagation()} className={C.deleteButton}>
                <Icon.Delete
                  level={Levels.Secondary}
                  size={Sizes.Small}
                  name={translate('general.clear')}
                  onClick={() => onDelete(record)}
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    );

  return (
    <table className={C.table}>
      {renderHeaders()}
      {renderRows()}
    </table>
  );
};
