import { FC } from 'react';
import * as React from 'react';
import { FormProvider } from 'react-hook-form';
import { submitId } from '../../../../common/utils/formUtils';
import { SgwWorkflowStep, WorkflowType } from '../../../../types';
import { useSgwRequestForm } from '../../../../hooks';
import { DescriptionFields } from '../../../organisms/TypeOfWork';
import { TypeFields } from '../../../organisms/TypeOfWork';
import { WorkCraneFields } from '../../../organisms/TypeOfWork';
import { Visible } from '../../../common/layout/Visible.component';

export const TypeOfWorkForm: FC = () => {
  const step = SgwWorkflowStep.Type;
  const { isFormReady, formMethods, submitRequest } = useSgwRequestForm(step);

  return (
    <Visible visible={isFormReady}>
      <FormProvider {...formMethods}>
        <form id={submitId(WorkflowType.Sgw, step)} onSubmit={submitRequest}>
          <TypeFields />
          <DescriptionFields />
          <WorkCraneFields />
        </form>
      </FormProvider>
    </Visible>
  );
};
