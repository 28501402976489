import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useReschedulingForm } from '../../../hooks/useReschedulingForm.hook';
import { getStandardAndRecurringPhases } from '../../../store/selectors/sgw';
import { translate } from '../../../translations/translate';
import { TRouterWithId } from '../../../types';
import { TextArea } from '../../common/forms/TextArea.component';
import { TEXT_MAX_LENGTH } from '../../../common/constants';
import { RescheduleRequestPhaseFormFields } from '../../molecules';

export const ReschedulingRequestForm: FC = () => {
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const phases = useSelector(getStandardAndRecurringPhases(requestId!));

  const form = 'rescheduleRequest';
  const { formMethods, onSubmit } = useReschedulingForm();
  const { register, handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form id={form} onSubmit={handleSubmit(onSubmit)}>
        {phases.map((phase, index) => (
          <RescheduleRequestPhaseFormFields phase={phase} index={index} />
        ))}

        <TextArea
          TextAreaProps={{
            ...register('description'),
            maxLength: TEXT_MAX_LENGTH,
          }}
          label={translate('myRequests.details.rescheduleRequest.extraField')}
          ariaLabel={translate('myRequests.details.rescheduleRequest.extraField')}
        ></TextArea>
      </form>
    </FormProvider>
  );
};
