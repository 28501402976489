import classNames from 'classnames';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Levels, Sizes } from '../../../common/enums';
import { translate } from '../../../translations/translate';
import { IWithClassName, MessageState } from '../../../types';
import { Icon } from '..';

interface IProps extends IWithClassName {
  messageState?: MessageState;
  emergencyProcedure?: boolean;
}

const useStyle = createUseStyles({
  spacing: {
    margin: '0 5px',
    '& i': {
      cursor: 'pointer',
    },
  },
});

export const MyRequestsStateIcons: FC<IProps> = ({ messageState, emergencyProcedure = false, className }) => {
  const C = useStyle();
  return (
    <>
      {messageState === MessageState.AwaitingFeedback && (
        <span className={classNames(C.spacing, className)}>
          <Icon.AwaitingFeedback level={Levels.Warning} size={Sizes.Medium}>
            <span className="sr-only">{translate('myRequests.messageState.awaiting_feedback')}</span>
          </Icon.AwaitingFeedback>
        </span>
      )}
      {emergencyProcedure && (
        <span className={classNames(C.spacing, className)}>
          <Icon.EmergencyRequest level={Levels.Danger} size={Sizes.Medium}>
            <span className="sr-only">{translate('general.emergencyRequest')}</span>
          </Icon.EmergencyRequest>
        </span>
      )}
    </>
  );
};
