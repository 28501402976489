import * as React from 'react';
import * as A from 'antwerp-core-react-branding';
import { APP_DEFAULT_COUNTRY } from '../../../common/constants';
import { ICountry, IRequestLicencePlate, FieldMetaProperties, KeyEvent as KeyEventBase } from '../../../types';
import { translate } from '../../../translations/translate';
import { FC, SyntheticEvent, useState } from 'react';
import {
  formatLicensePlateString,
  isLicensePlateValid,
  validateLicensePlate,
} from '../../../common/utils/licensePlateUtils';
import { LicensePlateTagLists } from '../../molecules';
import { toUniqueArray } from '../../../common/utils/array.utils';

type KeyEvent = KeyEventBase<HTMLInputElement>;

interface IProps extends FieldMetaProperties {
  countries: ICountry[];
  disabled?: boolean;
  label: JSX.Element | string;
  onChangeLicensePlates?: (value: IRequestLicencePlate[]) => void;
  startDate: string;
  requestLicensePlates: IRequestLicencePlate[];
  className?: string;
}

export const LicencePlateField: FC<IProps> = ({
  countries,
  disabled,
  onChangeLicensePlates,
  requestLicensePlates,
  startDate,
}) => {
  const [licensePlateText, setLicensePlateText] = useState<string>();
  const [countryCode, setCountryCode] = useState<string>(APP_DEFAULT_COUNTRY);

  if (disabled && requestLicensePlates.length === 0) {
    return null;
  }

  const licensePlateAlreadyAdded = requestLicensePlates?.some(
    ({ licensePlate }) => licensePlate === validateLicensePlate(licensePlateText),
  );

  const addLicencePlate = (): void => {
    const validatedLicensePlate = validateLicensePlate(licensePlateText);
    if (validatedLicensePlate && !licensePlateAlreadyAdded) {
      const newLicensePlates = toUniqueArray([
        ...requestLicensePlates,
        { licensePlate: validatedLicensePlate, countryCode, startDate },
      ]);
      onChangeLicensePlates?.(newLicensePlates);
    }
    setLicensePlateText(undefined);
    setCountryCode(APP_DEFAULT_COUNTRY);
  };

  return (
    <div className="input-field">
      <div className="row">
        <div className="col-xs-12">
          {/*@ts-ignore*/}
          <A.Spacing type={A.SpacingStyle.MarginBottom}>
            {translate(disabled ? 'locationsform.parkedplatesDisabled' : 'locationsform.parkedplates')}
          </A.Spacing>
        </div>
      </div>
      <LicensePlateTagLists
        disabled={!!disabled}
        requestLicensePlates={requestLicensePlates}
        onChangeLicensePlates={(plates) => {
          const updatedPlates = requestLicensePlates.filter((requestLicensePlate) =>
            plates.includes(formatLicensePlateString(requestLicensePlate)),
          );
          onChangeLicensePlates?.(updatedPlates);
        }}
      />
      {!disabled && (
        <div className="row">
          <div className="col-md-6">
            {/*@ts-ignore*/}
            <A.TextInput
              maxLength={10}
              minLength={5}
              name="licencePlate"
              onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                setLicensePlateText(e?.currentTarget?.value);
              }}
              onKeyPress={(e: KeyEvent) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  addLicencePlate();
                }
              }}
              value={licensePlateText || ''}
            />
          </div>
          <div className="col-md-6">
            {/*@ts-ignore*/}
            <A.Select
              name="countryCode"
              onChange={(event: SyntheticEvent<HTMLInputElement>) => setCountryCode(event?.currentTarget?.value)}
              options={countries.map(({ name, country }) => ({ label: name, value: country }))}
              value={countryCode}
            />
          </div>
          <div className="col-md-12" style={{ textAlign: 'right' }}>
            {/*@ts-ignore*/}
            <A.Spacing type={A.SpacingStyle.MarginBottomXs}>
              {/*@ts-ignore*/}
              <A.IconButton
                disabled={
                  !countryCode ||
                  !licensePlateText ||
                  !isLicensePlateValid(licensePlateText) ||
                  licensePlateAlreadyAdded
                }
                icon="plus"
                text={translate('locationsform.addlicenseplate')}
                level={A.Levels.Primary}
                type={A.ButtonType.Default}
                onClick={addLicencePlate}
              />
            </A.Spacing>
          </div>
        </div>
      )}
    </div>
  );
};
