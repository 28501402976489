import { translate } from '../../translations/translate';
import moment from 'moment/moment';

import { APP_DATE_FIELD_FORMAT } from '../constants';
import { convertStringToTimestamp } from '../utils/dateUtils';
import { ValidateResult } from 'react-hook-form/dist/types/validator';

export default function validateBirthday(value: string, maxDate?: string): ValidateResult {
  const format = /[0-9]{4}-[0-9]{2}-[0-9]{2}/i;
  if (!format.test(value)) {
    return translate('fields.noValidDate');
  }

  const timestamp = convertStringToTimestamp(value);

  if (maxDate) {
    const maxTimestamp = convertStringToTimestamp(maxDate);
    if (timestamp > maxTimestamp) {
      return translate('fields.dateHigherThanMaxDate', {
        maxDate: moment(maxTimestamp).format(APP_DATE_FIELD_FORMAT),
      });
    }
  }

  return true;
}
