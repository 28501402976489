import { getCarFreeZoneByGisId } from '../../common/utils/requestUtils';
import IState from '../../state';
import { createSelector } from '@reduxjs/toolkit';
import { CarFreeZoneType, GateType } from '../../types';

export const selectCarFreeZones = (state: IState) => state.carFreeZones || [];
export const selectCarFreeZoneByGisId = (gisId: string | undefined) => (state: IState) =>
  getCarFreeZoneByGisId(state.carFreeZones || [], gisId);

export const getIsAnprZone = (gisId: string | undefined) =>
  createSelector([selectCarFreeZoneByGisId(gisId)], (carFreeZone) => carFreeZone?.type === CarFreeZoneType.anpr);

export const getGatesByGisId = (gisId: string, gateType: GateType) =>
  createSelector(
    [selectCarFreeZoneByGisId(gisId)],
    (carFreeZone) =>
      carFreeZone?.gates.filter(({ active, sas }) => active && [gateType, GateType.both].includes(sas)) || [],
  );
