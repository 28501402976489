import { IGenericAction } from '../actions/actions';
import { IApplicationFeatures } from '../../types';
import { ApplicationActions } from '../actions/workflow.actions';

const INITIAL_STATE = null;

export default function applicationFeatures(
  state: IApplicationFeatures | null = INITIAL_STATE,
  action: IGenericAction<IApplicationFeatures>,
): IApplicationFeatures | null {
  switch (action.type) {
    case ApplicationActions.applicationFeatures.type:
      return action.payload;
    default:
      return state;
  }
}
