import { IGenericAction } from '../actions/actions';
import { BUSYSTATE_CHANGED } from '../actions/types';

const INITIAL_STATE: boolean = false;

export default function busy(state: boolean = INITIAL_STATE, action: IGenericAction<boolean>): boolean {
  switch (action.type) {
    case BUSYSTATE_CHANGED:
      return action.payload;
    default:
      return state;
  }
}
