import { Sizes } from 'antwerp-core-react-branding';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { ButtonVariant } from '../../../types';
import { IconButton } from '../IconButton/IconButton.component';
import intl from 'react-intl-universal';
import { Visible } from '../../common/layout/Visible.component';
import { RequiredStar } from '../../common/forms/RequiredStar.component';

export interface IFileSelectInfoProps {
  isMulti: boolean;
  openInputFile(): void;
  onDeleteAll?(): void;
  file?: File;
  info?: JSX.Element | string;
  required?: boolean;
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    maxWidth: '22.5rem',
    zIndex: '1',
  },
  fileName: {
    fontWeight: 700,
  },
  info: {
    marginBottom: '0.75rem',
  },
  closeButton: {
    bottom: '30px',
    right: '11rem',
    position: 'absolute!important',
    zIndex: '2',
  },
});

export const FileSelectInfo: FC<IFileSelectInfoProps> = ({
  isMulti,
  file,
  info,
  onDeleteAll,
  openInputFile,
  required,
}) => {
  const C = useStyles();
  const fileName = file?.name;
  const showFileName = !isMulti && !!fileName;

  return (
    <>
      <Visible visible={showFileName && !!onDeleteAll}>
        <IconButton
          className={C.closeButton}
          icon="close"
          onClick={onDeleteAll}
          variant={ButtonVariant.Transparent}
          size={Sizes.Small}
          type="button"
        />
      </Visible>
      <div className={C.container} onClick={openInputFile}>
        <p className={C.info}>
          {info || intl.get('fields.attachment')} {required ? <RequiredStar /> : null}
        </p>
        <Visible visible={showFileName}>
          <p className={C.fileName}>{fileName}</p>
        </Visible>
      </div>
    </>
  );
};
