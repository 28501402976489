import './ZoomControl.component.scss';
import React, { FC } from 'react';
import { FeatureGroup, GeoJSON } from 'react-leaflet';
import { MAP_FEATURE_CONFLICT_COLOR } from '../../../common/constants';
import { IConflictProps } from '../../../types/map.types';

export const Conflicts: FC<IConflictProps> = ({ conflicts }) =>
  !!conflicts ? (
    <FeatureGroup>
      {conflicts!.map((x, i) => (
        <GeoJSON
          key={`conflict-${i}`}
          data={x.feature.geometry as any}
          style={() => ({ color: MAP_FEATURE_CONFLICT_COLOR })}
        />
      ))}
    </FeatureGroup>
  ) : null;
