import { FC, useCallback, useState } from 'react';
import intl from 'react-intl-universal';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router';
import { appUrls } from '../../../common/constants';
import { getImageProjectType } from '../../../common/utils/assetsUtils';
import { getWorkflowInitialPath } from '../../../common/utils/requestUtils';
import { getLocalUrl } from '../../../common/utils/urlUtils';
import { WorkflowType } from '../../../types';
import { Fieldset } from '../../atoms';
import { CardSelectorItemProperties } from '../../common/cardselector/CardSelectorItem.component';
import { GenericCardSelector } from '../../common/cardselector/GenericCardSelector.component';
import { Required } from '../../common/forms/Required.component';
import { WorkflowButtons } from '../../molecules';

export enum ProjectType {
  smallWork = 'smallWork',
  largeWork = 'largeWork',
}

const useStyles = createUseStyles({
  cardsContainer: {
    width: '100%',
    display: 'flex',
  },
  cards: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
});

export const ProjectTypeComponent: FC = () => {
  const C = useStyles();
  const [selectedType, setSelectedType] = useState<ProjectType | null>(null);
  const [required, setRequired] = useState<boolean>(false);
  const navigate = useNavigate();

  const mapItem = (type: ProjectType): CardSelectorItemProperties => ({
    title: intl.get(`ProjectType.Card.Title.${type}`),
    image: getImageProjectType(type),
    imageDescription: intl.get(`ProjectType.Card.Description.${type}`),
    remarks: intl.getHTML(`ProjectType.Card.Remarks.${type}`),
  });

  const onNext = useCallback(() => {
    switch (selectedType) {
      case ProjectType.smallWork:
        navigate(getLocalUrl(getWorkflowInitialPath()));
        break;
      case ProjectType.largeWork:
        navigate(getLocalUrl(appUrls.sgw.request.base, appUrls.sgw.request.create));
        break;
      default:
        setRequired(true);
    }
  }, [selectedType, navigate]);

  const onChange = useCallback((selected: ProjectType) => {
    setRequired(false);
    setSelectedType(selected);
  }, []);

  return (
    <Fieldset legend={intl.get('ProjectType.Title')}>
      <p>{intl.getHTML('ProjectType.Description')}</p>
      <GenericCardSelector
        className={C.cards}
        data={[ProjectType.smallWork, ProjectType.largeWork]}
        mapItem={mapItem}
        onChange={onChange}
        errorComponent={<Required required={required} message={intl.get('ProjectType.Required')} />}
        error={required ? { type: 'manual' } : undefined}
      />
      <WorkflowButtons onNext={onNext} type={WorkflowType.ASign} />
    </Fieldset>
  );
};
