import { Position } from '@turf/turf';
import { getAreaSizeByGeometry } from '../common/api';
import { calculateDistanceBetweenTwoPoints } from '../common/utils/geometry.util';
import { IGeometry } from '../types';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import { LatLng, LatLngTuple } from 'leaflet';

export const getAreaSize = async (geometry: IGeometry): Promise<number> => {
  const area = await getAreaSizeByGeometry(geometry);
  return Number(area || 0);
};

export const getDistance = async (coordinates: Position[]): Promise<number> => {
  const distance = calculateDistanceBetweenTwoPoints(coordinates as LatLngTuple[]);
  return Math.round(distance);
};

export const getDistanceBetweenLatLng = (pointA?: LatLng, pointB?: LatLng) =>
  pointA && pointB
    ? distance(point([pointA.lng, pointA.lat]), point([pointB.lng, pointB.lat]), {
        units: 'meters',
      })
    : 0;
