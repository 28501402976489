import intl from 'react-intl-universal';

export default function validateRequired(
  value: string | {},
  allValues?: any[],
  props?: any,
  name?: string,
): string | null {
  const message = intl.get('fields.required');
  if (!value || (typeof value === 'object' && Object.keys(value).length === 0)) {
    return message;
  } else {
    if (typeof value === 'string') {
      if (value === '') {
        return message;
      }
    }
  }
  return null;
}
