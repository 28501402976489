class DebounceManager {
  public static Instance(): DebounceManager {
    if (!DebounceManager.instance) {
      DebounceManager.instance = new DebounceManager();
    }
    return DebounceManager.instance;
  }
  private static instance: DebounceManager;
  private keys: { [key: string]: number } = {};

  public debounce(key: string, func: () => void, delay: number) {
    this.tryClear(key);
    this.keys[key] = setTimeout(() => {
      this.clearKey(key);
      func();
    }, delay) as any;
  }

  private clearKey(key: string) {
    this.keys[key] = -1;
  }

  private tryClear(key: string) {
    if (this.keys[key] > 0) {
      clearInterval(this.keys[key]);
      this.clearKey(key);
    }
  }
}

export function debounce(key: string, func: () => void, delay: number): void {
  DebounceManager.Instance().debounce(key, func, delay);
}
