import { useCallback, useEffect } from 'react';

export const useOnEscape = (handler: () => void) => {
  const listener = useCallback((e: { key: string }) => e.key === 'Escape' && handler(), [handler]);

  useEffect(() => {
    window.addEventListener('keydown', listener);
    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [listener]);
};
