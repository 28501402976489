import classNames from 'classnames';
import * as React from 'react';
import { FC } from 'react';

interface IProps {
  step: string;
  index: number;
  activeStep?: number;
}

export const Step: FC<IProps> = ({ step, index, activeStep = -1 }) => (
  <li
    aria-label={`stap ${index}: ${step}${index === activeStep ? ' (huidige stap)' : ''}`}
    id={`step-${index}`}
    key={`step-${index}`}
    className={classNames({ 'is-active': index === activeStep, 'is-completed': index < activeStep })}
    tabIndex={-1}
  >
    <span aria-hidden className="m-step-indicator__label">
      {step}
    </span>
  </li>
);
