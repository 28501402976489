import { FC } from 'react';
import * as React from 'react';
import { IRequestLicencePlate } from '../../../types';
import { formatLicensePlateString } from '../../../common/utils/licensePlateUtils';
import { LicensePlateTagList } from '../../atoms';
import { toUniqueArray } from '../../../common/utils/array.utils';

interface IProps {
  disabled: boolean;
  requestLicensePlates: IRequestLicencePlate[];
  onChangeLicensePlates?(licensePlates: string[]): void;
}

export const LicensePlateTagLists: FC<IProps> = ({ disabled, requestLicensePlates, onChangeLicensePlates }) => (
  <>
    {toUniqueArray(requestLicensePlates.map(({ startDate }) => startDate)).map((startDate) => (
      <LicensePlateTagList
        key={`taglist-${startDate}`}
        startDate={startDate}
        disabled={disabled}
        licensePlates={requestLicensePlates
          .filter((licensePlate) => licensePlate.startDate === startDate)
          .map(formatLicensePlateString)}
        onChangeLicensePlates={onChangeLicensePlates}
      />
    ))}
  </>
);
