import { createSelector } from '@reduxjs/toolkit';
import { calculateDaysInterval, sortByDate } from '../../../common/utils/dateUtils';
import IState from '../../../state';
import { IByPartnerType, createAllEntitiesSelector } from '../../../utils';
import { Category, PartnerType, SgwRequestState } from '../../../types';
import { getInitialProfileValues } from '../state.selectors';
import { getPhases } from './phase.selectors';
import { uniqueNumericValues } from '../../../common/utils/filterUtils';
import { selectSgwMinDateFrom } from '../metadata.selectors';
import moment from 'moment/moment';
import { APP_DEFAULT_DATE_FORMAT } from '../../../common/constants';

export const selectRequestAllIds = (state: IState) => state.sgw?.request?.list.allIds;
export const selectRequestById = (state: IState) => state.sgw?.request?.list.byId;
export const selectRequestAttachments = (state: IState) => state.sgw?.request?.attachments || [];
export const selectConflictGroups = (state: IState) => state.sgw?.request?.conflictGroups || [];
export const selectRescheduling = (state: IState) => state.sgw?.request?.reschedulings || [];
export const selectPhaseCostQuarters = (state: IState) => state.sgw?.request?.phaseCostQuarters || [];

export const getRequest = (id?: string) =>
  createSelector([selectRequestById], (requestById) => (id ? requestById?.[id] : undefined));
export const selectRequestMainLocation = (id?: string) =>
  createSelector([getRequest(id)], (request) => request?.mainLocation);
export const getRequestReferenceId = (id?: string) =>
  createSelector([getRequest(id)], (request) => request?.referenceId);
export const getRequests = createAllEntitiesSelector(selectRequestAllIds, selectRequestById);

export const getRequestDateFrom = (id?: string) => createSelector([getRequest(id)], (request) => request?.dateFrom);
export const getRequestDateUntil = (id?: string) => createSelector([getRequest(id)], (request) => request?.dateUntil);

export const getSgwDraftRequests = createSelector([getRequests], (requests) =>
  requests
    .filter(({ state }) => state === SgwRequestState.draft)
    .sort(({ submittedDate: a }, { submittedDate: b }) => (a && b ? sortByDate(b, a) : -1)),
);

export const getLatestSgwDraftRequest = createSelector(
  [getRequests],
  (requests) => requests.filter(({ state }) => state === SgwRequestState.draft).sort((a, b) => b.id! - a.id!)[0],
);

export const getSgwSubmittedRequests = createSelector([getRequests], (requests) =>
  requests
    .filter(({ state }) => state !== SgwRequestState.draft)
    .sort(({ submittedDate: a }, { submittedDate: b }) => (a && b ? sortByDate(b, a) : -1)),
);

export const getConflictGroupsWithPhaseIds = (requestId: string) =>
  createSelector([selectConflictGroups, getPhases(requestId)], (conflictGroups, phases) =>
    conflictGroups.map((conflictGroup) => ({
      ...conflictGroup,
      phaseIds: conflictGroup.conflicts.map((conflict) => conflict.sgwPhaseId).filter(uniqueNumericValues),
    })),
  );
export const getConflictGroupForCategoryAndPhase = (conflictGroupId: number, phaseId: string) =>
  createSelector(selectConflictGroups, (conflictgroups) =>
    conflictgroups.find((conflictgroup) => {
      return (
        conflictgroup.id === conflictGroupId &&
        conflictgroup.conflicts.some((conflict) => conflict.sgwPhaseId === parseInt(phaseId))
      );
    }),
  );

export const getFirstUrgentAttachment = createSelector([selectRequestAttachments], (attachments) => {
  const urgentRequestAttachments = attachments.filter(({ category }) => category === Category.urgentRequest);
  return urgentRequestAttachments.length >= 1 ? urgentRequestAttachments[0] : undefined;
});

export const getInitialSgwValues = (id?: string) =>
  createSelector(
    [getRequest(id), getInitialProfileValues, getFirstUrgentAttachment],
    (request, profileValues, firstSavedAttachment) => {
      const { companyId, sourceId, ...valuesForRequest } = profileValues;

      return {
        ...valuesForRequest,
        ...request,
        contactFirstName: request?.contactFirstName || profileValues.firstName,
        contactLastName: request?.contactLastName || profileValues.lastName,
        country: request?.country || profileValues.country,
        vatNumber: request?.vatNumber || profileValues?.companyId,
        initialFile: firstSavedAttachment?.fileStorage,
      };
    },
  );

export const getRequestPartnersId = (id: string) =>
  createSelector(
    [getRequest(id)],
    (request): IByPartnerType<number | undefined> => ({
      [PartnerType.client]: request?.principalId,
      [PartnerType.contractor]: request?.mainContractorId,
      [PartnerType.fee]: request?.feeResponsibleId,
      [PartnerType.requester]: request?.requestorId,
      [PartnerType.signalisation]: request?.signallingResponsibleId,
    }),
  );

export const getRequestAddress = (id?: string) =>
  createSelector([getRequest(id)], (request) => ({
    street: request?.street,
    streetNumber: request?.streetNumber,
  }));

export const getAllPartnersAddedToRequest = (id?: string, retributionRequired?: boolean) =>
  createSelector(
    [getRequest(id)],
    (request) =>
      !!request?.requestorId &&
      !!request?.principalId &&
      !!request?.signallingResponsibleId &&
      (retributionRequired ? !!request?.feeResponsibleId : true) &&
      !!request?.mainContractorId,
  );

export const getEachPhaseHasAtLeastOneAttachmentWithSignalisationMap = (id: string) =>
  createSelector([getPhases(id), selectRequestAttachments], (phases, attachments) =>
    phases.every((phase) =>
      attachments.some(
        ({ sgwPhases, category }) => phase.id && sgwPhases.includes(phase.id) && category === Category.signalisationMap,
      ),
    ),
  );

export const getRequestIsNotUrgentOrHasUrgentAttachment = (id: string) =>
  createSelector([getRequest(id), selectRequestAttachments], (request, attachments) => {
    return request?.isUrgentRequest ? attachments.some(({ category }) => category === Category.urgentRequest) : true;
  });

export const getAmountDaysInRequest = (id: string) =>
  createSelector(
    [getRequest(id)],
    (request) => request?.dateFrom && request.dateUntil && calculateDaysInterval(request?.dateFrom, request?.dateUntil),
  );

export const getIsStartDateValid = (id: string) =>
  createSelector([getRequest(id), selectSgwMinDateFrom], (request, minDateFrom) => {
    if (!request?.dateFrom || request.isUrgentRequest || !minDateFrom) return true;

    const current = moment(request?.dateFrom as string, APP_DEFAULT_DATE_FORMAT);
    const minimum = moment(minDateFrom, APP_DEFAULT_DATE_FORMAT);

    return current.isSameOrAfter(minimum, 'day');
  });

export const getPhaseCostQuarterTableValues = createSelector([selectPhaseCostQuarters], (phaseCostQuarters) =>
  phaseCostQuarters.map(({ quarterYear: year, ...quarter }) => {
    let quarterRange = '';
    switch (quarter.quarterIndex) {
      case 1:
        quarterRange = `01/01/${year} - 31/03/${year}`;
        break;
      case 2:
        quarterRange = `01/04/${year} - 30/06/${year}`;
        break;
      case 3:
        quarterRange = `01/07/${year} - 30/09/${year}`;
        break;
      case 4:
        quarterRange = `01/10/${year} - 31/12/${year}`;
        break;
    }

    return {
      quarterRange,
      ...quarter,
    };
  }),
);
