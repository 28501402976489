import { Environment } from '../../config/environment';
import history from '../../utils/history/history';

import { joinUrl } from './urlUtils';

export function historyPush(url: string) {
  if (url) {
    history.navigate(joinUrl(Environment.baseFrontEndUrl, url));
  }
}
