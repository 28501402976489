import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Error } from './pages/ErrorPage/Error.page';
import { isFeatureEnabled } from '../store/selectors';
import { FeatureName } from '../types';

export const SentryBoundary: FC<PropsWithChildren> = ({ children }) => {
  const isSentryEnabled = useSelector(isFeatureEnabled(FeatureName.sentryLoggingFrontoffice));

  return isSentryEnabled ? (
    <Sentry.ErrorBoundary fallback={<Error />}>{children}</Sentry.ErrorBoundary>
  ) : (
    <>{children}</>
  );
};
