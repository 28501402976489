import { IGenericAction } from '../actions/actions';
import { CLEAR_ERROR, ERROR_OCCURRED, SET_ERROR } from '../actions/types';

const INITIAL_STATE: Error | any = {};

/**
 * Reduce the busy state of the application
 */
export default function lastError(
  state: Error | any = INITIAL_STATE,
  action: IGenericAction<Error | any>,
): Error | any {
  switch (action.type) {
    case ERROR_OCCURRED:
    case SET_ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return {};
    default:
      return state;
  }
}
