import { History } from 'history';
import { combineReducers } from 'redux';

import applicationFeatures from './applicationFeatures';
import busy from './busy';
import lastError from './lastError';
import processes from './processes';
import title from './title';
import alert from './alert';
import authenticated from './authenticated';
import confirm from './confirm';
import cost from './cost';
import countries from './countries';
import initialized from './initialized';
import metadata from './metadata';
import myrequests from './myrequests';
import partner from './partner.reducer';
import profile from './profile';
import publicDomainIntakeTypes from './publicDomainIntakeTypes';
import request from './request.reducer';
import requestMessages from './requestMessages';
import requestReasons from './requestReasons';
import selectedPublicDomainIntake from './selectedPublicDomainIntake';
import step from './step';
import tenant from './tenant';
import sgw from './sgw';
import { generatePayloadReducer } from './generic.reducers';
import { SnackBarActions } from '../actions/Snackbar.actions';
import { PublicDomainIntakeActions } from '../actions';
import { CarFreeZoneActions } from '../actions/carFreeZone.actions';

const rootReducer = (history: History) =>
  combineReducers({
    alert,
    applicationFeatures,
    authenticated,
    busy,
    carFreeZones: generatePayloadReducer([CarFreeZoneActions.received.type], []),
    confirm,
    cost,
    countries,
    gates: generatePayloadReducer([PublicDomainIntakeActions.saveGates.type], []),
    initialized,
    intersectingZones: generatePayloadReducer([CarFreeZoneActions.setIntersectingZones.type], []),
    lastError,
    metadata,
    myrequests,
    partner,
    processes,
    profile,
    publicDomainIntakeTypes,
    request,
    requestMessages,
    requestReasons,
    selectedPublicDomainIntake,
    snackBar: generatePayloadReducer([SnackBarActions.setFeedback.type], null),
    sgw,
    step,
    tenant,
    title,
  });

export default rootReducer;
