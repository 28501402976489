import { call, put, takeEvery } from 'redux-saga/effects';
import debug from '../../common/debug';
import { createProcessIdleAction, createProcessStartedAction } from '../actions/creators';
import { ICall } from '../../types';
import { SagaIterator } from 'redux-saga';
import { CarFreeZoneActions } from '../actions/carFreeZone.actions';
import { CarFreeZoneApi } from '../api/carFreeZone.api';

function* getCarFreeZones(): SagaIterator {
  const process = 'getCarFreeZones';
  try {
    yield put(createProcessStartedAction(process));
    const response: ICall<typeof CarFreeZoneApi.fetchAllActive> = yield call(CarFreeZoneApi.fetchAllActive);

    yield put(CarFreeZoneActions.received(response!.data!.data));
  } catch (e) {
    debug('Car free zones could not be loaded', e);
  }
  yield put(createProcessIdleAction(process));
}

function* verifyIntersections({ payload }: ReturnType<typeof CarFreeZoneActions.verifyIntersections>): SagaIterator {}

export default function* carFreeZonesSaga(): SagaIterator {
  yield takeEvery(CarFreeZoneActions.get.type, getCarFreeZones);
  yield takeEvery(CarFreeZoneActions.verifyIntersections.type, verifyIntersections);
}
