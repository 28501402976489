import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { createStepRequestedAction } from '../../common/actions/creators/workflow';
import { IPaymentForm, PaymentFormFields } from '../../common/form';
import { PaymentDisabled } from '../atoms/PaymentDisabled/PaymentDisabled.component';
import { PaymentTypeSelector } from '../common/forms/PaymentTypeSelector.component';
import { Visible } from '../common/layout/Visible.component';
import AddressInfo from '../common/overview/addressInfo';
import { LocationInfo } from '../common/overview/locationInfo';
import PersonalInfo from '../common/overview/personalInfo';
import { ReasonInfo } from '../common/overview/ReasonInfo.component';
import './forms.scss';
import { WorkflowButtons } from '../molecules';
import { Feature, Fieldset } from '../atoms';
import { submitId } from '../../common/utils/formUtils';
import {
  AsignWorkflowSteps,
  IDispatcheableProperties,
  ICost,
  IIntlProperties,
  IRequest,
  IWaitableProperties,
  WorkflowType,
} from '../../types';
import { translate } from '../../translations/translate';

export type PaymentFormProperties = {
  cost: ICost;
  onPrevious: (value: IRequest) => void;
  onSubmit: (values: IRequest) => void;
  request?: IRequest;
  step: number;
} & IDispatcheableProperties &
  IWaitableProperties &
  IIntlProperties;

/**
 * React Component PaymentForm
 */
export const PaymentForm = ({ onSubmit, request, ...props }: PaymentFormProperties) => {
  const formMethods = useForm<IPaymentForm>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      paymentType: undefined,
    },
  });

  const { handleSubmit } = formMethods;

  const _onSubmit = (values: IPaymentForm) => {
    request && onSubmit({ ...request, ...values });
  };

  const onEdit = (step: number) => {
    props.dispatch!(createStepRequestedAction(step));
  };

  return (
    <Visible visible={!!request}>
      <section>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(_onSubmit)} id={submitId(WorkflowType.ASign, props.step)}>
            <Feature.IntegrateDigipolisOrder fallbackComponent={<PaymentDisabled />}>
              {props.cost.totalCost > 0 && (
                <Fieldset legend={translate('paymentform.paymenttitle')}>
                  <PaymentTypeSelector
                    name={PaymentFormFields.paymentType}
                    label={translate('paymentform.paymentlabel')}
                    request={request}
                    required
                  />
                </Fieldset>
              )}
            </Feature.IntegrateDigipolisOrder>
            <Fieldset legend={translate('paymentform.overview')}>
              <ReasonInfo request={request!} onEdit={() => onEdit(AsignWorkflowSteps.Reason)} />
              <LocationInfo request={request!} onEdit={() => onEdit(AsignWorkflowSteps.Location)} />
              <PersonalInfo request={request!} onEdit={() => onEdit(AsignWorkflowSteps.Personal)} />
              <AddressInfo request={request!} onEdit={() => onEdit(AsignWorkflowSteps.Personal)} />
            </Fieldset>
            <WorkflowButtons
              step={props.step}
              onPrevious={props.onPrevious}
              type={WorkflowType.ASign}
              onNextLabel={translate('form.submit')}
            />
          </form>
        </FormProvider>
      </section>
    </Visible>
  );
};
