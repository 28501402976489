import * as A from 'antwerp-core-react-branding';
import React, { FC, useCallback } from 'react';
import intl from 'react-intl-universal';

import { createStepRequestedAction } from '../../../common/actions/creators/workflow';
import { WORKFLOW_MIN_STEP } from '../../../common/constants';
import { useDispatch } from 'react-redux';

export const StepNotFound: FC = () => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(createStepRequestedAction(WORKFLOW_MIN_STEP));
  }, [dispatch]);

  return (
    //@ts-ignore
    <A.Spacing type={A.SpacingStyle.MarginTop}>
      {/*@ts-ignore*/}
      <A.Paragraph type={A.ParagraphStyle.Alert}>
        {intl.get('general.stepnotfound')}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={onClick}>{intl.get('general.gotostepone')}</a>
      </A.Paragraph>
    </A.Spacing>
  );
};
