import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';

import { NEXT_STEP_GRANTED, PREVIOUS_STEP_GRANTED, RESET_STEP_GRANTED, STEP_SET } from '../../common/actions/types';
import { WORKFLOW_MIN_STEP } from '../../common/constants';
import { getWorkFlowStepFromPath, isWorkFlowPath } from '../../common/utils/pathUtils';
import { IGenericAction } from '../actions/actions';

const INITIAL_STATE: number = WORKFLOW_MIN_STEP;

export default function step(
  state: number = INITIAL_STATE,
  action: IGenericAction<number> | LocationChangeAction,
): number {
  switch (action.type) {
    case NEXT_STEP_GRANTED:
      return state + 1;
    case PREVIOUS_STEP_GRANTED:
      return state - 1;
    case STEP_SET:
      return (action as IGenericAction<number>).payload;
    case RESET_STEP_GRANTED:
      return INITIAL_STATE;
    case LOCATION_CHANGE:
      // @ts-ignore
      const pathname = (action as LocationChangeAction).payload.pathname;
      if (isWorkFlowPath(pathname)) {
        const pathstep = getWorkFlowStepFromPath(pathname);
        if (pathstep < state) {
          return pathstep;
        }
      }
      return state;
    default:
      return state;
  }
}
