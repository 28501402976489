import axios from 'axios';

import { ITokenResponse } from '../../types';
import { STORE_OAUTH_DATA, STORE_OAUTH_TOKEN, SESSION_STOR_ORIG_URL, STORE_OAUTH_REFRESH } from '../constants';
import { getLocalStorage, removeLocalStorage, storeLocalStorage } from './localStorageUtils';

const AccessTokenHashRegex = /access_token=([a-zA-Z0-9]+)&/;
const CancelHashRegex = /cancelled/;

export function hasCancelToken(): boolean {
  if (window && window.location.hash) {
    const matches = window.location.hash.match(CancelHashRegex);
    if (matches && matches.length > 0) {
      return true;
    }
  }
  return false;
}

export function hasHashToken(): boolean {
  if (window && window.location.hash) {
    const matches = window.location.hash.match(AccessTokenHashRegex);
    if (matches && matches.length > 0) {
      return true;
    }
  }
  return false;
}

export function hashToken(): string {
  if (window && window.location.hash) {
    const hash: string = window.location.hash;
    const matches = hash.match(AccessTokenHashRegex);
    if (matches && matches.length > 0) {
      return matches[1];
    }
  }
  return '';
}

export function cleanHash(): void {
  if (window && window.location.href) {
    window.location.hash = '';
  }
}

export function storeTokenResponse(response: ITokenResponse): void {
  storeLocalStorage(STORE_OAUTH_DATA, JSON.stringify({ ...response, received: new Date().getTime() }));
  storeLocalStorage(STORE_OAUTH_TOKEN, response.access_token);
  storeLocalStorage(STORE_OAUTH_REFRESH, response.refresh_token);
  setupAxiosAuthorization();
}

export function getTokenResponse(): ITokenResponse | null {
  const data: string | undefined = getLocalStorage(STORE_OAUTH_DATA);
  return data ? JSON.parse(data) : null;
}

export function getAccessToken(): string | null {
  return getLocalStorage<string>(STORE_OAUTH_TOKEN) || null;
}

export function restoreTokenResponse(): void {
  setupAxiosAuthorization();
}

export function removeTokenData(): void {
  removeLocalStorage(STORE_OAUTH_DATA);
  removeLocalStorage(STORE_OAUTH_TOKEN);
  removeLocalStorage(STORE_OAUTH_REFRESH);
}

export function storeOriginalPath(path: string | null) {
  if (!sessionStorage) {
    return;
  }
  if (path && path !== '/') {
    sessionStorage.setItem(SESSION_STOR_ORIG_URL, path);
  } else if (window && window.location.hash) {
    sessionStorage.setItem(SESSION_STOR_ORIG_URL, window.location.hash.replace('#', ''));
  }
}

export function getOriginalPathAndRemove() {
  if (sessionStorage && window) {
    const url = sessionStorage.getItem(SESSION_STOR_ORIG_URL);
    sessionStorage.removeItem(SESSION_STOR_ORIG_URL);
    return url;
  }
  return '';
}

export function setupAxiosAuthorization(): void {
  const token: ITokenResponse = JSON.parse(getLocalStorage(STORE_OAUTH_DATA) || '');
  const type = token.token_type.slice(0, 1).toUpperCase() + token.token_type.slice(1);
  axios.defaults.headers.common.Authorization = `${type} ${token.access_token}`;
}
