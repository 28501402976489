import { FC } from 'react';
import * as A from 'antwerp-core-react-branding';
import { translateHtml } from '../../../translations/translate';
import moment from 'moment';
import * as React from 'react';

interface IProps {
  startDate: string;
  disabled: boolean;
  licensePlates: string[];
  onChangeLicensePlates?(licensePlates: string[]): void;
}

export const LicensePlateTagList: FC<IProps> = ({ startDate, disabled, licensePlates, onChangeLicensePlates }) => (
  <div className="row">
    <div className="col-xs-12">
      {/*@ts-ignore*/}
      <A.TagList
        disabled={disabled}
        label={translateHtml('myRequests.details.validFrom', {
          startDate: moment(startDate).format(A.DatePickerInput.DefaultDisplayDateFormat),
        })}
        name="permittedPlates"
        noinput
        // @ts-ignore
        onChange={disabled ? undefined : (newValues: string[]) => onChangeLicensePlates?.(newValues)}
        value={licensePlates}
      />
    </div>
  </div>
);
