import * as React from 'react';
import * as A from 'antwerp-core-react-branding';
import { getSelectorTitle } from '../../../common/utils/cardSelectorUtils';
import { formatToDisplayDate } from '../../../common/utils/dateUtils';
import { historyPush } from '../../../common/utils/historyUtils';
import { calculateUntilDate, getRequestExtensionPath, requestHasExtensions } from '../../../common/utils/requestUtils';
import { IRequest, IRoutableProperties } from '../../../types';
import { OverviewEditLink, OverviewItem, OverviewList, OverviewSection } from './overviewElements';
import { OverviewProperties } from './overViewType';
import { translate, translateHtml } from '../../../translations/translate';
import { Visible } from '../layout/Visible.component';
import { FC } from 'react';

export type ReasonInfoProperties = {
  showExtension?: boolean;
} & OverviewProperties &
  Partial<IRoutableProperties>;

const Permit: FC<IRequest> = ({ permit }) => {
  if (permit) {
    const description = permit.downloadUrl
      ? translateHtml('myRequests.details.downloadPermit', { downloadUrl: permit.downloadUrl })
      : translate('general.pending');

    return <OverviewItem title={translate('general.permit')} description={description} />;
  }
  return null!;
};

// This button is shown when there are no extensions and the state is approved
const ExtendRequest: FC<ReasonInfoProperties> = ({ request, showExtension }) =>
  showExtension && request.acl?.can_add_extensions ? (
    <div style={{ marginTop: '8px' }}>
      {/*@ts-ignore*/}
      <A.Button
        text={translate('general.extendrequest')}
        size={A.Sizes.Small}
        type={A.ButtonType.Outline}
        onClick={() => historyPush(getRequestExtensionPath(request))}
      />
    </div>
  ) : null;

export const ReasonInfo: FC<ReasonInfoProperties> = (props) => {
  const { request } = props;
  return (
    <OverviewSection>
      {/*@ts-ignore*/}
      <A.Spacing type={A.SpacingStyle.NoMargin} className="row between-md">
        <h6>{translate('paymentform.reasontitle')}</h6>
        <OverviewEditLink {...props} />
      </A.Spacing>
      {/*@ts-ignore*/}
      <A.Spacing type={A.SpacingStyle.MarginTop}>
        <OverviewList>
          <OverviewItem
            description={getSelectorTitle(request!.reason.name)}
            title={translate('paymentform.description')}
          />
          <OverviewItem description={formatToDisplayDate(request.dateFrom)} title={translate('general.from')} />
          <OverviewItem
            description={
              <span>
                {calculateUntilDate(request)} {requestHasExtensions(request) && <A.Badge value="verlengd" />}
              </span>
            }
            title={translate('general.to')}
          />
          <OverviewItem
            description={translate(request.emergencyProcedure ? 'general.yes' : 'general.no')}
            title={translate('general.emergencyRequest')}
          />
          <OverviewItem
            description={translate(request.onlyOnWeekdays ? 'general.yes' : 'general.no')}
            title={translate('general.onlyonweekdays')}
          />
          <Visible visible={!!request.sgwRequestId}>
            <OverviewItem description={`${request.sgwRequestId}`} title={translate('general.sgwDossier')} />
          </Visible>
          {request.reasonForRejection && (
            <OverviewItem description={request.reasonForRejection} title={translate('general.reasonForRejection')} />
          )}
          <Permit {...request} />
          <ExtendRequest {...props} />
        </OverviewList>
      </A.Spacing>
    </OverviewSection>
  );
};
