import { GateType, IPublicDomainIntake } from '../../../../types';
import { useFormContext } from 'react-hook-form';
import { Autocomplete } from '../../../molecules';
import { useSelector } from 'react-redux';
import { FC, useEffect, useState } from 'react';
import { getGatesByGisId } from '../../../../store/selectors';
import { translate, translateHtml } from '../../../../translations/translate';
import { useSpacingStyles } from '../../../../theme';

export const LocationsFormGatesFields: FC = () => {
  const { marginTop } = useSpacingStyles();
  const {
    clearErrors,
    register,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext<IPublicDomainIntake>();
  const gisId = watch('gisId');
  const [initialGisId] = useState(gisId);
  const gatesIn = useSelector(getGatesByGisId(gisId!, GateType.in));
  const gatesOut = useSelector(getGatesByGisId(gisId!, GateType.out));
  const carFreeZoneGateEntrance = watch('carFreeZoneGateEntrance');
  const carFreeZoneGateExit = watch('carFreeZoneGateExit');
  const [initialEntrance] = useState(gatesIn?.length === 1 ? gatesIn[0].id : carFreeZoneGateEntrance);
  const [initialExit] = useState(gatesOut?.length === 1 ? gatesOut[0].id : carFreeZoneGateExit);

  useEffect(() => {
    if (initialGisId === gisId) {
      setValue('carFreeZoneGateEntrance', initialEntrance || null);
      setValue('carFreeZoneGateExit', initialExit || null);
    } else {
      setValue('carFreeZoneGateEntrance', gatesIn?.length === 1 ? gatesIn[0].id : null);
      setValue('carFreeZoneGateExit', gatesOut?.length === 1 ? gatesOut[0].id : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialGisId, gisId, setValue]);

  useEffect(() => {
    if (carFreeZoneGateEntrance && carFreeZoneGateExit) {
      trigger();
    }
  }, [carFreeZoneGateEntrance, carFreeZoneGateExit, trigger]);

  useEffect(() => {
    clearErrors(['carFreeZoneGateEntrance', 'carFreeZoneGateExit']);
  }, [clearErrors, gisId]);

  return (
    <>
      {gatesIn?.length > 0 && (
        <Autocomplete
          filterOnType
          label={translateHtml('locationsform.gates.selectEntrance')}
          {...register('carFreeZoneGateEntrance', {
            required: true,
            shouldUnregister: true,
            validate: (value) =>
              gatesIn.some(({ id }) => value === id) || translate('locationsform.gates.selectFromList'),
          })}
          value={`${carFreeZoneGateEntrance || ''}`}
          onSelectOption={(value) => {
            setValue('carFreeZoneGateEntrance', parseInt(value));
            trigger('carFreeZoneGateEntrance');
          }}
          options={gatesIn.map(({ id, name }) => ({ label: name, value: `${id}` }))}
          className={marginTop.XS}
          meta={{ error: errors.carFreeZoneGateEntrance?.message, touched: !!errors.carFreeZoneGateEntrance }}
          updateSearchTextWhenInputOrOptionsChange
        />
      )}
      {gatesOut?.length > 0 && (
        <Autocomplete
          filterOnType
          label={translateHtml('locationsform.gates.selectExit')}
          {...register('carFreeZoneGateExit', {
            required: true,
            shouldUnregister: true,
            validate: (value) =>
              gatesOut.some(({ id }) => value === id) || translate('locationsform.gates.selectFromList'),
          })}
          value={`${carFreeZoneGateExit || ''}`}
          onSelectOption={(value) => {
            setValue('carFreeZoneGateExit', parseInt(value));
            trigger('carFreeZoneGateExit');
          }}
          options={gatesOut.map(({ id, name }) => ({ label: name, value: `${id}` }))}
          className={marginTop.XS}
          meta={{ error: errors.carFreeZoneGateExit?.message, touched: !!errors.carFreeZoneGateExit }}
          updateSearchTextWhenInputOrOptionsChange
        />
      )}
    </>
  );
};
