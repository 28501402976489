import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { formatToDisplayDate } from '../../../common/utils/dateUtils';
import { selectRescheduling } from '../../../store/selectors/sgw';
import { translate } from '../../../translations/translate';
import { Visible } from '../../common/layout/Visible.component';

const useStyles = createUseStyles({
  table: {
    width: '100%',
    marginTop: 20,
    '& th, & td': {
      font: 'inherit',
      fontFamily: 'inherit',
      borderBottom: '1px solid #b0b0b0',
      borderTop: '1px solid #b0b0b0',
      paddingLeft: '10px',
    },
    '& table': {
      marginTop: '10px',
      marginBottom: '10px',
    },
    '& th': {
      fontWeight: 'bold',
    },
  },
  container: {
    padding: '0',
    marginTop: '20px',
    marginBottom: '30px',
  },
  tableWithoutBorder: {
    '& th, & td': {
      border: '0px',
      paddingLeft: 0,
      paddingRight: 20,
    },
  },
  notFound: {
    fontStyle: 'italic',
    color: '#7d7d7d',
  },
});

export const RescheduleInfoTable: FC = () => {
  const C = useStyles();
  const reschedulings = useSelector(selectRescheduling);

  return (
    <div className={C.container}>
      <h5>{translate('sgw.overview.rescheduling.title')}</h5>
      <div className={C.table}>
        <Table>
          <TableBody>
            <Visible
              visible={reschedulings?.length > 0}
              defaultComponent={<div className={C.notFound}>{translate('sgw.overview.rescheduling.notFound')}</div>}
            >
              {reschedulings.map((reschedule, index) => (
                <TableRow>
                  <TableCell width="50%" className="u-text-light">
                    {translate('sgw.overview.rescheduling.subtitle')} {index + 1}
                  </TableCell>
                  <TableCell>
                    <table className={C.tableWithoutBorder}>
                      <td>
                        <Visible visible={!!reschedule.createdAt}>
                          <tr>
                            <td>{translate('sgw.overview.rescheduling.requestedAt')}</td>
                            <td>{formatToDisplayDate(`${reschedule.createdAt}`)}</td>
                          </tr>
                        </Visible>
                        <Visible visible={!!reschedule.consideredAt}>
                          <tr>
                            <td>{translate('sgw.overview.rescheduling.consideredAt')}</td>
                            <td>{formatToDisplayDate(`${reschedule.consideredAt}`)}</td>
                          </tr>
                        </Visible>
                        <Visible visible={!!reschedule.appliedAt}>
                          <tr>
                            <td>{translate('sgw.overview.rescheduling.appliedAt')}</td>
                            <td>{formatToDisplayDate(`${reschedule.appliedAt}`)}</td>
                          </tr>
                        </Visible>
                        <Visible visible={!!reschedule.rejectedAt}>
                          <tr>
                            <td>{translate('sgw.overview.rescheduling.rejectedAt')}</td>
                            <td>{formatToDisplayDate(`${reschedule.rejectedAt}`)}</td>
                          </tr>
                        </Visible>
                      </td>
                    </table>
                  </TableCell>
                </TableRow>
              ))}
            </Visible>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
