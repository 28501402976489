import { TRouterWithId } from '../types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsStartDateValid } from '../store/selectors/sgw';
import { useParams } from 'react-router';

export const useVerifySgwRequestStartDate = () => {
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const isStartDateValid = useSelector(getIsStartDateValid(requestId!));

  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (!isStartDateValid) {
      setIsValid(false);
    }
  }, [isStartDateValid]);

  return isValid;
};
