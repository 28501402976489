import { useCallback, useEffect, useState } from 'react';
import { debounce } from '../common/utils/debounceUtils';
import { suggestStreet } from '../common/api';
import { translate } from '../translations/translate';
import { useFormContext } from 'react-hook-form';

export const useStreetSuggestions = (name: string, initialValue?: string) => {
  const [streetSuggestions, setStreetSuggestions] = useState<string[]>([]);
  const { setError } = useFormContext();

  const streetSuggest = useCallback(
    (street: string) => {
      const trimStreet = street.trim();
      if (trimStreet && trimStreet.length >= 3) {
        debounce(
          'suggestStreet',
          async () => {
            const suggestions = await suggestStreet(trimStreet);
            if (suggestions?.length > 0) {
              setStreetSuggestions(suggestions);
            } else {
              setError(name, { type: 'validate', message: translate('locationsform.noStreets') });
            }
          },
          500,
        );
      }
    },
    [name, setError],
  );

  useEffect(() => {
    if (initialValue && !streetSuggestions?.length) {
      setStreetSuggestions([initialValue]);
    }
    if (initialValue === '') {
      setStreetSuggestions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return { streetSuggestions, streetSuggest };
};
