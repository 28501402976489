import { FC } from 'react';
import { ButtonVariant, GeoDrawingType } from '../../../types';
import { IconButton } from '../../atoms';
import { translate } from '../../../translations/translate';
import { useGenericStyles, useGeoDrawingContext } from '../../../hooks';
import classNames from 'classnames';
import { Visible } from '../../common/layout/Visible.component';
import { useGeoDrawingListItemStyles } from './GeoDrawingListItem.styles';
import { useDispatch } from 'react-redux';
import { createConfirmAction } from '../../../common/actions/creators/alert';

interface IProps {
  index?: number;
  isEditMode: boolean;
  disableControls?: boolean;
  geoDrawingType?: GeoDrawingType;
  onCancel(): void;
}

export const ActionButtons: FC<IProps> = ({
  index = -1,
  isEditMode,
  disableControls = false,
  geoDrawingType,
  onCancel,
}) => {
  const C = useGeoDrawingListItemStyles();
  const { flex } = useGenericStyles();
  const dispatch = useDispatch();
  const { geoDrawingIndexInEdit, removeGeoDrawing, onEditDrawing, onChangeLineDirection } = useGeoDrawingContext();

  const onRemoveDrawing = () => {
    dispatch(
      createConfirmAction(
        translate('sgw.map.drawOnMap.phase.removeDrawingConfirmation'),
        translate('sgw.map.drawOnMap.phase.removeDrawing'),
        () => removeGeoDrawing(index),
      ),
    );
  };

  const _onEditDrawing = () => {
    index === geoDrawingIndexInEdit ? onCancel() : onEditDrawing(index);
  };

  return (
    <div className={classNames(flex.row)}>
      <Visible visible={geoDrawingType === GeoDrawingType.detour}>
        <IconButton
          icon="exchange"
          onClick={onChangeLineDirection}
          variant={ButtonVariant.Transparent}
          size="small"
          type="button"
          title={translate('sgw.map.drawOnMap.phase.changeDirection')}
          disabled={!isEditMode}
          className={C.button}
        />
      </Visible>
      <IconButton
        icon="pencil"
        onClick={_onEditDrawing}
        variant={ButtonVariant.Transparent}
        size="small"
        type="button"
        title={translate('sgw.map.drawOnMap.phase.removeDrawing')}
        disabled={disableControls}
        className={C.button}
      />
      <IconButton
        icon="trash"
        onClick={onRemoveDrawing}
        variant={ButtonVariant.Transparent}
        size="small"
        type="button"
        title={translate('sgw.map.drawOnMap.phase.removeDrawing')}
        disabled={disableControls}
        className={C.button}
      />
    </div>
  );
};
