import React, { FC, PropsWithChildren } from 'react';

import { Layout } from './common/layout/Layout.component';
import { AppTitle } from './common/layout/AppTitle.component';
import { Alert } from './common/messaging/Alert.component';
import { GlobalConfirm } from './common/messaging/GlobalConfirm.component';
import { BusyComponent } from './common/busy/Busy.component';
import { History } from '../utils/history/History.component';

export const App: FC<PropsWithChildren> = ({ children }) => (
  <>
    <Layout>
      <Alert />
      <GlobalConfirm />
      <BusyComponent />
      <History />
      {children}
    </Layout>
    <AppTitle />
  </>
);
