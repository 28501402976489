import * as A from 'antwerp-core-react-branding';
import classNames from 'classnames';
import { FC } from 'react';
import { Visible } from '../layout/Visible.component';

export type CardSelectorItemProperties = {
  image?: string;
  imageDescription?: string;
  onClick?(): void;
  remarks?: string;
  selected?: boolean;
  title: string;
};

/**
 * React Component CardSelectorItem
 */
export const CardSelectorItem: FC<CardSelectorItemProperties> = ({
  image,
  imageDescription,
  onClick,
  remarks,
  selected,
  title,
}) => (
  <button
    className={classNames('cardselector__item', 'm-card', { 'm-card--selected': selected })}
    onClick={onClick}
    role="radio"
    aria-checked={selected}
    type="button"
  >
    <div className="m-card__body ">
      <Visible visible={!!image}>
        <div className="cardselector__image">
          <img src={image} alt={imageDescription} />
        </div>
      </Visible>
      {/*@ts-ignore*/}
      <A.Paragraph type={A.ParagraphStyle.Center} className="cardselector__title">
        {title}
      </A.Paragraph>
      <Visible visible={!!remarks}>
        {/*@ts-ignore*/}
        <A.Paragraph type={A.ParagraphStyle.Center} className="cardselector__remarks">
          <small>{remarks}</small>
        </A.Paragraph>
      </Visible>
    </div>
  </button>
);
