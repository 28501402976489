import { WorkflowTemplate } from '../../templates/WorkflowTemplate/WorkflowTemplate.component';
import * as React from 'react';
import { SgwWorkflowStep, WorkflowType } from '../../../types';
import { TypeOfWorkForm } from '../../forms/sgw';
import { translate } from '../../../translations/translate';
import { InvalidSgwRequestStartDateAlert } from '../../organisms';

export const TypeOfWorkPage = () => {
  return (
    <WorkflowTemplate title={translate('sgw.workType.title')} step={SgwWorkflowStep.Type} type={WorkflowType.Sgw}>
      <InvalidSgwRequestStartDateAlert />
      <TypeOfWorkForm />
    </WorkflowTemplate>
  );
};
