import { FC, PropsWithChildren } from 'react';
import { IWithClassName } from '../../../types';
import { Visible } from '../../common/layout/Visible.component';
import { createUseStyles } from 'react-jss';
import { useGenericStyles } from '../../../hooks';
import classNames from 'classnames';

interface IProps extends IWithClassName, PropsWithChildren {
  direction?: 'horizontal' | 'vertical';
  label: string;
  visible?: boolean;
}

const useStyles = createUseStyles({
  label: {
    fontWeight: 'bold',
  },
});

export const LabelValue: FC<IProps> = ({ direction = 'horizontal', visible = true, className, label, children }) => {
  const C = useStyles();
  const { flex, spacing } = useGenericStyles();

  return (
    <Visible visible={visible}>
      {direction === 'vertical' ? (
        <div className={classNames(flex.column, className)}>
          <div className={C.label}>{label}</div>
          {children || ''}
        </div>
      ) : (
        <div className={classNames(flex.row, spacing.marginBottom.ML, className)}>
          <div className={classNames(flex.half, C.label)}>{label}</div>
          <div className={flex.half}>{children || ''}</div>
        </div>
      )}
    </Visible>
  );
};
