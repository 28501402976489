import React, { PropsWithChildren, ReactElement } from 'react';

type IProps<P> = P & { componentNames: string[] };

/**
 * This component is to filter out certain children, it makes it easy to show them in the correct place
 * @param children The children components
 * @param componentNames The allowed component names
 * @param props The added props that will be passed
 * @constructor
 */
export const Children = <T,>({ children, componentNames, ...props }: PropsWithChildren<IProps<T>>) =>
  React.Children.toArray(children)
    .filter((child) => React.isValidElement(child) && componentNames.includes(child.props.componentName))
    // @ts-ignore
    .map((child) => React.cloneElement(child, props)) as unknown as ReactElement;
