import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { SgwRequestActions } from '../actions';
import { ConflictApi } from '../api/conflict.api';
import { ICall } from '../../types';

export function* fetchList({ payload }: ReturnType<typeof SgwRequestActions.conflicts.fetchList>): SagaIterator {
  const response: ICall<typeof ConflictApi.fetchConflicts> = yield call(ConflictApi.fetchConflicts, payload);
  yield put(SgwRequestActions.conflicts.setList(response!.data.data));
}

export default function* conflictSaga(): SagaIterator {
  yield takeLatest(SgwRequestActions.conflicts.fetchList, fetchList);
}
