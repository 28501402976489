import { createNormalizedReducer } from './pagedTable.reducer';
import { IPartner } from '../../types';
import { PartnerActions } from '../actions';

export default createNormalizedReducer<IPartner>({
  addEntityActions: [PartnerActions.set.type],
  addEntitiesActions: [PartnerActions.setList.type],
  fetchAction: PartnerActions.fetchBulk.type,
  setAllIdsAction: PartnerActions.setList.type,
});
