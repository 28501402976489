import React, { FC } from 'react';
import { Visible } from '../../common/layout/Visible.component';
import { LayersControl } from 'react-leaflet';
import { getLatLng2D } from '../../../common/utils/mapUtils';
import { LatLng, Symbol } from 'leaflet';
import { Position } from 'geojson';
import { Polyline } from '../../common/leaflet/features/Polyline.component';
import { COLORS } from '../../../theme';

interface IProps {
  coords?: Position[];
  onDragEnd?(coords: LatLng[]): void;
  draggable?: boolean;
  info: string;
}

export const MapDetourOverlay: FC<IProps> = ({ coords, ...props }) => {
  const arrow = [
    {
      offset: '55%',
      repeat: '0',
      symbol: Symbol.arrowHead({
        pixelSize: 25,
        polygon: true,
        pathOptions: { fillOpacity: 1, weight: 5, color: COLORS.beer },
      }),
    },
  ];

  return (
    <Visible visible={!!coords}>
      <LayersControl.Overlay checked name={props.info}>
        <Polyline {...props} positions={getLatLng2D(coords!)} arrow={arrow} color={COLORS.beer} lineWeight={5} />
      </LayersControl.Overlay>
    </Visible>
  );
};
