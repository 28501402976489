import { IconButton } from '../../atoms';
import React, { FC } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Sizes } from '../../../common/enums';
import { translate } from '../../../translations/translate';
import { IMessageAcl, IRequestMessageAttachment, TRouterWithId } from '../../../types';
import { Locations } from 'antwerp-core-react-branding';
import { createUseStyles } from 'react-jss';
import { Visible } from '../../common/layout/Visible.component';
import { useDispatch, useSelector } from 'react-redux';
import { SgwRequestActions } from '../../../store/actions';
import { useParams } from 'react-router';
import { atom, useAtom } from 'jotai';
import { FileSelect } from '../../molecules';
import { useGenericStyles } from '../../../hooks';
import classNames from 'classnames';
import { selectMessageAttachments } from '../../../store/selectors/sgw/messages.selectors';
import { COLORS } from '../../../theme';
import { SpinnerLoader } from '../../atoms/SpinnerLoader/SpinnerLoader.component';

const useStyles = createUseStyles({
  button: {
    width: '180px',
  },
  textArea: {
    padding: '15px',
    width: '100%',
    resize: 'vertical',
    borderColor: COLORS.borderGrey,
    marginTop: '15px',
  },
  uploadedFiles: {
    backgroundColor: COLORS.lightGrey,
    marginTop: '15px',
    width: 'fit-content',
    padding: '8px 20px',
    borderRadius: '20px',
    fontWeight: 'bold',
    color: COLORS.blue,
  },
  fileTitle: {
    height: 'fit-content',
    margin: 'auto',
    marginRight: '10px',
  },
});

interface IProps {
  acl: IMessageAcl;
}

const messageAtom = atom<string>('');

export const MessagesForm: FC<IProps> = ({ acl }) => {
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const dispatch = useDispatch();
  const [message, setMessage] = useAtom(messageAtom);
  const C = useStyles();
  const G = useGenericStyles();
  const uploadedFiles = useSelector(selectMessageAttachments);

  const onMessageChange = (e: React.InputHTMLAttributes<HTMLInputElement>) => {
    //@ts-ignore
    setMessage(e.target.value);
  };

  const sendMessage = () => {
    if (uploadedFiles)
      dispatch(
        SgwRequestActions.messages.create({
          requestId: requestId!,
          message: {
            content: message,
            attachments: uploadedFiles,
          },
        }),
      );
    setMessage('');
  };

  const uploadFileHandler = (file: File) => {
    if (file) dispatch(SgwRequestActions.messages.uploadAttachment(file));
  };

  const removeAttachmentHandler = (e: React.MouseEvent, file: IRequestMessageAttachment) => {
    const newAttachments = uploadedFiles?.filter((uploadedFiles) => uploadedFiles.id !== file.id);
    if (newAttachments) dispatch(SgwRequestActions.messages.setAttachment(newAttachments));
  };

  return (
    <Visible visible={acl.messages_can_edit}>
      <TextareaAutosize
        className={C.textArea}
        placeholder={translate('sgw.overview.messages.placeholder')}
        minRows={5}
        value={message}
        onChange={onMessageChange}
      />

      {uploadedFiles?.map((file, index) => (
        <div className={classNames(C.uploadedFiles, G.flex.row)} key={file.name + index}>
          <span className={C.fileTitle}>{file.name}</span>
          <Visible
            visible={file.loading}
            defaultComponent={
              <i
                className={`fa fa-remove messages-attachments-delete`}
                color={'error'}
                onClick={(e) => removeAttachmentHandler(e, file)}
              />
            }
          >
            <SpinnerLoader loading small />
          </Visible>
        </div>
      ))}

      <div className={classNames(G.flex.column, G.spacing.marginTop.SM)}>
        <FileSelect name="messageAttachments" onFileSelect={uploadFileHandler} />
        <IconButton
          location={Locations.Right}
          icon="chevron-right"
          disabled={!!!message?.length || uploadedFiles?.some((file) => file.loading)}
          size={Sizes.Small}
          onClick={sendMessage}
          className={C.button}
        >
          {translate('sgw.overview.messages.sendMessage')}
        </IconButton>
      </div>
    </Visible>
  );
};
