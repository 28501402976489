import * as React from 'react';
import intl from 'react-intl-universal';

import * as A from 'antwerp-core-react-branding';
import { WORKFLOW_STATE_APPROVED, WORKFLOW_STATE_DRAFT, WORKFLOW_STATE_REJECTED } from '../../../common/constants';
import { convertStringToTimestamp } from '../../../common/utils/dateUtils';
import { historyPush } from '../../../common/utils/historyUtils';
import { getRequestExtensionPath } from '../../../common/utils/requestUtils';
import { IRequest, IRequestExtension } from '../../../types';
import { OverviewSection } from './overviewElements';
import StateInfo from './stateInfo';

export type ReasonInfoProperties = {
  request: IRequest;
  extension: IRequestExtension;
  index: number;
};

/**
 * React Component ExtensionInfo
 */
export default class ExtensionInfo extends React.Component<ReasonInfoProperties, {}> {
  public render(): any {
    const { extension } = this.props;
    if (extension) {
      return (
        <OverviewSection>
          <StateInfo
            title={intl.get('general.extension') + ` ${this.props.index + 1}: ${extension.state!.name}`}
            dateUntil={extension.dateUntil}
            state={extension.state!}
          />
          {this.renderExtendRequestButton(extension)}
        </OverviewSection>
      );
    }
    return null!;
  }

  private renderExtendRequestButton(extension: IRequestExtension): JSX.Element {
    const lastExtension = this.props.request.extensions!.slice(-1)[0];

    if (
      this.props.request &&
      this.props.request.acl?.can_add_extensions &&
      extension === lastExtension &&
      extension.state
    ) {
      const state = extension.state!.state;
      let btnText = '';
      switch (state) {
        case WORKFLOW_STATE_APPROVED:
        case WORKFLOW_STATE_REJECTED:
          if (
            convertStringToTimestamp(this.props.request.latestRequestDateTimeForEmergencyExtension) >
            new Date().getTime()
          ) {
            btnText = 'general.extendrequest';
          }
          break;
        case WORKFLOW_STATE_DRAFT:
          btnText = 'general.updateextension';
      }

      if (btnText) {
        return (
          <div style={{ marginTop: '8px' }}>
            {/*@ts-ignore*/}
            <A.Button
              text={intl.get(btnText)}
              size={A.Sizes.Small}
              type={A.ButtonType.Outline}
              onClick={() => historyPush(getRequestExtensionPath(this.props.request!))}
            />
          </div>
        );
      }
    }
    return null!;
  }
}
