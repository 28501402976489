import { SagaIterator } from 'redux-saga';
import { Action } from 'redux';
import { call, put } from 'redux-saga/effects';
import { createErrorAlertAction } from '../../common/actions/creators/alert';
import intl from 'react-intl-universal';
import { ping } from '../../common/api';
import { captureException } from '@sentry/react';

export function* errorHandler(err: Error): SagaIterator {
  captureException(new Error(err.message));

  if (err.message === 'Network Error' || err.message === 'Failed to fetch') {
    yield put(createErrorAlertAction(intl.get('error.no_connection.message'), intl.get('error.no_connection.title')));
  } else {
    console.error(err.message);
  }
}

export const genericErrorHandler = (saga: (...args: any[]) => SagaIterator, ...args: any[]) =>
  function* handleApp(action: Action): SagaIterator {
    try {
      yield call(saga, action, args);
    } catch (err) {
      yield call(errorHandler, err as Error);
    }
  };

export const requireConnectionErrorHandler = (saga: (...args: any[]) => SagaIterator, ...args: any[]) =>
  function* handleApp(action: Action): SagaIterator {
    try {
      yield call(ping); // AS-6273: test internet connection by calling ping, before trying to execute the provided saga
      yield call(saga, action, args);
    } catch (err) {
      yield call(errorHandler, err as Error);
    }
  };
