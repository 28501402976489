import { IWithId } from './generic.types';
import { IAddress, ICountry, IPhaseAddress } from './address.types';
import { IContactPerson } from './person.types';
import { IPartner, IWithPartnerVariant } from './partner.types';
import { IGeometry } from './map.types';
import { IFileStorage, MessageState } from '.';

export interface ISgwRequestVirtualFields {
  initialFile?: IFileStorage;
  workCraneNeeded?: string;
  urgentRequestAttachmentFile?: string;
  actions?: string;
}

export interface ISgwProject extends IWithId {
  name: string;
  deleted: boolean;
}

export interface ISgwRequestPartnerIds {
  feeResponsibleId?: number;
  mainContractorId?: number;
  principalId?: number;
  requestorId?: number;
  signallingResponsibleId?: number;
}
export interface ISgwCraneInfo {
  isUsed?: string;
  moreThan25Tons?: string;
  type?: string | null;
  maxHeight?: string | null;
}

export interface ISgwCraneInfoBE {
  isUsed?: boolean;
  moreThan25Tons?: boolean;
  type?: string | null;
  maxHeight?: string | null;
}

export interface ISgwRequestBase extends IWithId, IAddress, IContactPerson, IPartner, ISgwRequestPartnerIds {
  bonuNumber?: string | null;
  utilityCompanyNameResponsible?: string | null;
  utilityCompanyPhoneResponsible?: string | null;
  utilityCompanyEmailResponsible?: string | null;
  utilityCompanyName?: string | null;
  gipodId?: string | null;
  phases?: string[];
  ssn: string;
  sgwProject?: string;
  workType: WorkType;
  workDescription: string;
  isUrgentRequest: boolean;
  urgentRequestAttachmentFile?: string;
  dateFrom: string;
  dateUntil: string;
  referenceId?: string;
  mainLocation: ISgwPhaseMainLocation;
  subscribeMailNotifications?: boolean;
  readTermsAndConditions?: boolean;
  state: SgwRequestState;
  submittedDate?: string;
  messageState?: MessageState;
  acl: ISgwRequestAcl;
  permitDownloadUrl?: string;
}
export interface ISgwRequestAcl {
  createReschedulingRequest: boolean;
  sgwRetributionRequired: boolean;
}

export interface ISgwRequest extends ISgwRequestBase, ISgwRequestVirtualFields {
  country: ICountry;
  crane: ISgwCraneInfo;
}

export interface ISgwRequestBE extends Omit<ISgwRequestBase, 'country' | 'sgwProject' | 'isUrgentRequest'> {
  country: string;
  sgwProject?: string | ISgwProject;
  crane: ISgwCraneInfoBE;
  urgentRequest: boolean;
}

export interface ISgwPhaseMainLocation extends Omit<IAddress, 'country' | 'streetNumber'> {
  street: string;
  zipCode: string;
  city: string;
  bus?: string;
  streetNumberFrom?: string;
  streetNumberTo?: string;
  streetNumberUnknown?: boolean;
}

export interface ISgwPhase extends IWithId {
  addresses: IPhaseAddress[];
  phaseName?: string;
  phaseStatus: string;
  dateFrom: string;
  dateUntil: string;
  timeFrom: string;
  timeUntil: string;
  workingHoursFrom: string;
  workingHoursUntil: string;
  locationDescription: string;
  trafficMeasureClarification: string;
  workingHoursNotStandard: boolean;
  sgwNatureOfWorks: string[];
  sgwGeoDrawings: ISgwGeoDrawing[];
  isRecurringPhaseTemplate?: boolean;
  permitRequestId?: number;
  parentPhaseId?: number;
  exemptedSurfaceArea: number;
}

export interface ISgwPhaseBE extends Omit<Omit<ISgwPhase, 'sgwNatureOfWorks'>, 'sgwTrafficMeasures'> {
  sgwNatureOfWorks: number[];
  sgwTrafficMeasures: number[];
}

export interface ISgwGeoDrawing extends IWithId {
  sgwTrafficMeasures?: number[];
  geoDrawingType?: GeoDrawingType;
  customName?: string;
  geometry?: IGeometry;
  surfaceArea?: number;
  description?: string;
  sgwPhaseId?: number;
}

export enum GeoDrawingType {
  constructionzone = 'constructionzone',
  parkingban = 'parkingban',
  detour = 'detour',
}

export enum Capacity {
  Business = 'business',
  BelgianResident = 'belgian_resident',
  NonBelgianResident = 'non_belgian_resident',
}

export enum WorkType {
  ConstructionPrivate = 'construction_private',
  ConstructionOnToPublicDomain = 'construction_on_to_public_domain',
  GroundWorksToPublicDomain = 'ground_works_to_public_domain',
  RelocationDelivery = 'relocation_delivery',
}

export enum SgwRequestFormFields {
  capacity = 'capacity',
  vatNumber = 'vatNumber',
  streetNumber = 'streetNumber',
  bus = 'bus',
  street = 'street',
  zipCode = 'zipCode',
  city = 'city',
  country = 'country',
  phoneNumber = 'phoneNumber',
  emailAddress = 'emailAddress',
  firstName = 'firstName',
  lastName = 'lastName',
  contactFirstName = 'contactFirstName',
  contactLastName = 'contactLastName',
  ssn = 'ssn',
  workType = 'workType',
  workDescription = 'workDescription',
  workCrane = 'workCrane',
  urgentRequest = 'urgentRequest',
  companyName = 'companyName',
  dateFrom = 'dateFrom',
  dateUntil = 'dateUntil',
  attachments = 'attachments',
}

export interface ILinkRequestPartner extends IWithPartnerVariant {
  requestId: string;
  partnerId?: string;
  shouldSavePartnerFee?: boolean;
}

export enum SgwRequestState {
  draft = 'draft',
  submitted = 'submitted',
  reviewing = 'reviewing',
  canceled = 'canceled',
  permit_granted = 'permit_granted',
  rejected = 'rejected',
  in_effect = 'in_effect',
  permit_ended = 'permit_ended',
  closed = 'closed',
}

export interface IRequestRescheduling {
  id: number;
  createdAt?: string;
  appliedAt?: string;
  consideredAt?: string;
  rejectedAt?: string;
  description?: string;
  phaseReschedulings: IPhaseRescheduling[];
  acl: {
    applyReschedulingRequest: boolean;
    updateReschedulingRequest: boolean;
    considerReschedulingRequest: boolean;
    rejectReschedulingRequest: boolean;
  };
}

export interface IPhaseRescheduling {
  sgwPhaseId?: number;
  originalDateFrom?: string;
  originalDateUntil?: string;
  newDateFrom: string;
  newDateUntil: string;
}

export interface IPhaseCostQuarter {
  quarterCost: number;
  quarterCorectionCost?: number;
  quarterIndex: number;
  quarterYear: number;
}
