import { translate } from '../translations/translate';
import { drawLocal } from 'leaflet';

export const setLeafletDrawTranslations = (currentDistance?: number) => {
  const distance = translate('leaflet.drawDistance', {
    currentDistance: currentDistance?.toFixed(0),
  });

  drawLocal.draw.handlers.polyline.tooltip.start = distance + translate('leaflet.drawLineStart');
  drawLocal.draw.handlers.polyline.tooltip.cont = distance + translate('leaflet.drawLineCont');
  drawLocal.draw.handlers.polyline.tooltip.end = distance + translate('leaflet.drawLineEnd');
  drawLocal.draw.handlers.polygon.tooltip.start = distance + translate('leaflet.drawPolygonStart');
  drawLocal.draw.handlers.polygon.tooltip.cont = distance + translate('leaflet.drawPolygonCont');
  drawLocal.draw.handlers.polygon.tooltip.end = distance + translate('leaflet.drawPolygonEnd');
  drawLocal.draw.toolbar.actions.text = translate('leaflet.actionsText');
  drawLocal.draw.toolbar.actions.title = translate('leaflet.actionsTitle');
  drawLocal.draw.toolbar.buttons.polygon = translate('leaflet.drawPolygon');
  drawLocal.draw.toolbar.buttons.polyline = translate('leaflet.drawLine');
  drawLocal.draw.toolbar.finish.text = translate('leaflet.finishText');
  drawLocal.draw.toolbar.finish.title = translate('leaflet.finishTitle');
  drawLocal.draw.toolbar.undo.text = translate('leaflet.undoText');
  drawLocal.draw.toolbar.undo.title = translate('leaflet.undoTitle');
};
