import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../theme';

export const usePhaseAccordionStyles = createUseStyles({
  phase: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '46px',
  },
  headerData: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: '10%',
  },
  name: {
    fontSize: 'large',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  number: {
    fontSize: 'x-large',
    fontWeight: 'bold',
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  dateFrom: {
    marginLeft: 'auto',
    marginRight: '15ch',
  },
  icons: {
    margin: '10px',
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    '& i': {
      marginLeft: '40px',
    },
  },
  error: {
    borderColor: `${COLORS.red}`,
  },
  visibleMd: {
    '@media only screen and (min-width: 48rem)': {
      display: 'block',
      visibility: 'visible',
    },
  },
  invisibleXs: {
    '@media only screen and (max-width: 48rem)': {
      display: 'none',
      visibility: 'hidden',
    },
  },
});
