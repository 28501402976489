import { LineString } from '@turf/turf';
import { FC } from 'react';
import { getLatLng2D } from '../../../common/utils/mapUtils';
import { COLORS } from '../../../theme';
import { Polyline } from '../../common/leaflet/features/Polyline.component';
import { ConflictCategory } from '../../../types/sgwConflict.types';
import { translateIgnoreTS } from '../../../translations/translate';
import { Marker } from '../../common/leaflet/features/Marker.component';
import markerIconSvg from '../../../../src/assets/images/conflict.svg';
import L from 'leaflet';
import { geometryCalculateCenter } from '../../../common/utils/geojson.util';
import { Visible } from '../../common/layout/Visible.component';

interface IProps {
  geometry: LineString;
  conflictCategory: ConflictCategory;
}
const markerIcon = L.icon({
  iconAnchor: [15, 15],
  iconUrl: markerIconSvg,
  iconRetinaUrl: markerIconSvg,
  iconSize: [30, 30],
  className: 'leaflet-venue-icon',
});

export const ConflictDrawingLineString: FC<IProps> = ({ geometry, conflictCategory }) => {
  return (
    <>
      <Polyline
        positions={getLatLng2D(geometry.coordinates)}
        color={COLORS.red}
        lineWeight={5}
        info={translateIgnoreTS(`sgw.map.conflictOverview.conflictCategory.${conflictCategory}`)}
        infoSticky
      />
      <Visible visible={conflictCategory !== ConflictCategory.publicTransport}>
        <Marker icon={markerIcon} position={geometryCalculateCenter(geometry)} />
      </Visible>
    </>
  );
};
