import { Actions } from './generic.actions';
import { ISnackBarFeedback } from '../../types';

class SnackBarActionsClass extends Actions {
  setFailure = this.createPayloadAction<{ feedback: string; visitLink?: string; visitLinkText?: string }>('setFailure');
  setFeedback = this.createPayloadAction<ISnackBarFeedback | null>('setFeedback');
  setSuccess = this.createPayloadAction<string>('setSuccess');
}

export const SnackBarActions = new SnackBarActionsClass('snackBar');
