import { IGenericAction } from '../../../store/actions/actions';
import { genericAction } from '../../../store/actions';
import { IRequest, IRequestExtension, IRequestPayment } from '../../../types';
import { PAYMENT_EXTENSION_REQUESTED, PAYMENT_REQUEST_REQUESTED, PAYMENT_REQUESTED } from '../types';

export type PaymentRequestPayload = { request: IRequest };
export type PaymentExtensionPayload = { extension: IRequestExtension } & PaymentRequestPayload;

export function createPaymentRequestAction(request: IRequest): IGenericAction<PaymentRequestPayload> {
  return genericAction(PAYMENT_REQUEST_REQUESTED, { request });
}

export function createOnlinePaymentExtensionAction(
  request: IRequest,
  extension: IRequestExtension,
): IGenericAction<PaymentExtensionPayload> {
  return genericAction(PAYMENT_EXTENSION_REQUESTED, { request, extension });
}

export function createOnlinePaymentAction(payload: IRequestPayment): IGenericAction<IRequestPayment> {
  return genericAction(PAYMENT_REQUESTED, payload);
}
