import { KeyEvent } from '../../types';

export const onEnter = (callback: any) => (event: KeyEvent<HTMLElement>) => {
  if (event.key === 'Enter') {
    callback();
  }
};

export const onSpace = (callback: any) => (event: KeyEvent<HTMLElement>) => {
  if (event.key === ' ') {
    callback();
  }
};
