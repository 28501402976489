import React, { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Required } from './Required.component';
import { IControllableForm } from '../../../common/form';
import { AntwerpConfirm } from '../../antwerpCore/form/AntwerpConfirm.component';
import { translate } from '../../../translations/translate';

interface IProps
  extends Omit<
      React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
      'name' | 'required'
    >,
    IControllableForm {
  required?: string | boolean;
  id?: string;
}

export const CheckboxWithLabelComponent: FC<IProps> = ({ label, name, required = false, id }) => {
  const { control, trigger } = useFormContext();
  const requiredErrorMessage = (typeof required === 'string' && required) || translate('fields.mustbetrue');
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    defaultValue: false,
    control,
    rules: { required: !!required && requiredErrorMessage },
  });

  return (
    <>
      <AntwerpConfirm
        label={label}
        {...field}
        for={id}
        onChange={(e: any) => {
          field.onChange?.(e);
          trigger(name);
        }}
      />
      <Required required={!!error} message={error?.message} />
    </>
  );
};
