import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Fieldset, IconButton } from '../../atoms';
import { translate } from '../../../translations/translate';
import * as A from 'antwerp-core-react-branding';
import { usePhaseOverviewStyles } from './PhaseOverview.styles';
import { PhaseForm } from '../../forms/sgw/PhaseForm/PhaseForm.component';
import { useDispatch, useSelector } from 'react-redux';
import { PhaseActions } from '../../../store/actions';
import { useFlexStyles } from '../../../theme';
import { PHASE_CREATE } from '../../../common/constants';
import {
  phasesHaveDateOverlap,
  getStandardPhaseIds,
  getRecurringPhaseTemplateIds,
  selectPhaseFormFieldsChanges,
} from '../../../store/selectors/sgw';
import { Tooltip } from '@mui/material';
import { selectSnackbarFeedback } from '../../../store/selectors';
import { SnackBarVariant } from '../../../types';
import { Required } from '../../common/forms/Required.component';
import { useAtom } from 'jotai';
import { openPhasesAtom } from '../../pages/PhasesPage/Phases.page';

interface IProps {
  requestId: string;
}

export const PhaseOverview: FC<IProps> = ({ requestId }) => {
  const C = usePhaseOverviewStyles();
  const flex = useFlexStyles();
  const [createNew, setCreateNew] = useState(false);
  const [createNewRecurring, setCreateNewRecurring] = useState(false);
  const [copyId, setCopyId] = useState<string>();
  const dispatch = useDispatch();
  const phases = useSelector(getStandardPhaseIds(requestId));
  const recurringPhaseTemplates = useSelector(getRecurringPhaseTemplateIds(requestId));
  const snackbarFeedback = useSelector(selectSnackbarFeedback);
  const haveDateOverlap = useSelector(phasesHaveDateOverlap(requestId));
  const isFormFieldsChanges = useSelector(selectPhaseFormFieldsChanges);
  const [, setOpenPhases] = useAtom(openPhasesAtom);
  const createRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(PhaseActions.fetchList(requestId));
  }, [dispatch, requestId]);

  useEffect(() => {
    if (snackbarFeedback?.variant === SnackBarVariant.success) {
      setCreateNew(false);
      setCreateNewRecurring(false);
      setCopyId(undefined);
      setOpenPhases([]);
    }
  }, [setOpenPhases, snackbarFeedback?.variant]);

  const onCopyPhase = useCallback(
    (recurringPhase?: boolean) => (id: string) => {
      setPhase(recurringPhase, true);
      setCopyId(id);
    },
    [],
  );

  const onCreatePhase = useCallback(
    (recurringPhase?: boolean) => () => {
      setPhase(recurringPhase, true);
      setCopyId(undefined);
      setOpenPhases([PHASE_CREATE]);
      if (createRef && createRef.current) {
        createRef.current.scrollIntoView();
      }
    },
    [setOpenPhases],
  );

  const onDeletePhase = useCallback(
    () => (id: string, index: number) => {
      dispatch(PhaseActions.delete({ requestId, id, index }));
    },
    [dispatch, requestId],
  );

  const hideCreateForm = useCallback(
    (recurringPhase?: boolean) => () => {
      setPhase(recurringPhase, false);
    },
    [],
  );

  const setPhase = (recurringPhase?: boolean, value: boolean = false) => {
    recurringPhase ? setCreateNewRecurring(value) : setCreateNew(value);
  };

  return (
    <>
      <Fieldset legend={translate('sgw.phases.overview.legend')} noBottomBorder>
        {/*@ts-ignore*/}
        <A.Paragraph spacing>{translate('sgw.phases.overview.info')}</A.Paragraph>
        <div className={C.accordion} ref={createRef}>
          {phases.map((id, index) => (
            <PhaseForm id={id} key={id} index={index} onCopy={onCopyPhase()} onDelete={onDeletePhase()} />
          ))}
          {createNew && (
            <PhaseForm
              id={PHASE_CREATE}
              copyId={copyId}
              onDelete={hideCreateForm()}
              onCreate={hideCreateForm()}
              index={phases.length}
            />
          )}
        </div>
        <Required required={!!!phases.length} message={translate('sgw.phases.overview.noPhase')} />
        <Required required={haveDateOverlap} message={translate('sgw.phases.overview.dateOverlap')} />
        <div className={flex.alignRight}>
          <Tooltip title={createNew ? translate('sgw.phases.overview.unsavedPhase') : ''}>
            <span>
              <IconButton disabled={createNew || phases.length >= 20} icon="plus" onClick={onCreatePhase()}>
                {translate('sgw.phases.new')}
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </Fieldset>
      <Fieldset legend={translate('sgw.phases.overview.recurringPhasesLegend')} noBottomBorder>
        {/*@ts-ignore*/}
        <A.Paragraph spacing>{translate('sgw.phases.overview.recurringPhasesInfo')}</A.Paragraph>
        <div className={C.accordion}>
          {recurringPhaseTemplates.map((id, index) => (
            <PhaseForm
              id={id}
              key={id}
              index={index}
              recurringPhaseTemplate
              onCopy={onCopyPhase(true)}
              onDelete={onDeletePhase()}
            />
          ))}
          {createNewRecurring && (
            <PhaseForm
              id={PHASE_CREATE}
              copyId={copyId}
              onDelete={hideCreateForm(true)}
              onCreate={hideCreateForm(true)}
              index={recurringPhaseTemplates.length}
              recurringPhaseTemplate
            />
          )}
        </div>
        <div className={flex.alignRight}>
          <IconButton icon="plus" onClick={onCreatePhase(true)} disabled={createNewRecurring}>
            {translate('sgw.phases.newRecurringPhase')}
          </IconButton>
        </div>
      </Fieldset>
      <div className={flex.alignRight}>
        <Required required={!!isFormFieldsChanges} message={translate('sgw.phases.general.unsavedPhases')} />
      </div>
    </>
  );
};
