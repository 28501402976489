import { useFormContext } from 'react-hook-form';
import { FC, useEffect } from 'react';
import { useGenericStyles } from '../../../hooks';
import { useSelector } from 'react-redux';
import { selectNatureOfWorks } from '../../../store/selectors';
import { ISgwPhase } from '../../../types';
import { CheckBoxList } from '../../atoms/CheckBoxList/CheckBoxList.component';
import { translate, translateHtml } from '../../../translations/translate';

interface IProps {
  id: string;
}

export const NatureOfWorkFields: FC<IProps> = ({ id }) => {
  const { spacing } = useGenericStyles();
  const { register, formState, watch, clearErrors } = useFormContext<ISgwPhase>();
  const { errors } = formState;
  const options = useSelector(selectNatureOfWorks);

  const sgwNatureOfWorks = watch('sgwNatureOfWorks');

  useEffect(() => {
    if (sgwNatureOfWorks) {
      clearErrors('sgwNatureOfWorks');
    }
  }, [clearErrors, sgwNatureOfWorks]);

  return (
    <div className={spacing.marginBottom.LG}>
      <h6 className={spacing.marginBottom.MD}>{translate('sgw.phases.natureOfWorks.title')}</h6>
      <CheckBoxList id={id} {...register('sgwNatureOfWorks', { required: true })}>
        <CheckBoxList.Header>{translateHtml('sgw.phases.natureOfWorks.description')}</CheckBoxList.Header>
        {options?.map((option) => <CheckBoxList.Item key={option.id} value={`${option.id}`} label={option.name} />)}
        <CheckBoxList.RequiredError show={!!errors.sgwNatureOfWorks}>
          {translate('sgw.phases.natureOfWorks.required')}
        </CheckBoxList.RequiredError>
      </CheckBoxList>
    </div>
  );
};
