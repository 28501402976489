import { FC } from 'react';
import { translate } from '../../../translations/translate';
import { useSelector } from 'react-redux';
import { getStandardPhaseIdsWithRecurringPhases } from '../../../store/selectors/sgw';
import { createUseStyles } from 'react-jss';
import { PhaseDetail } from './PhaseDetail.component';
import { useGenericStyles } from '../../../hooks';
import classNames from 'classnames';

interface IProps {
  requestId: string;
}

const useStyles = createUseStyles({
  root: {
    marginBottom: '20px',
    padding: '0 10px',
  },
  title: {
    marginBottom: '10px',
  },
});

export const PhasesDetail: FC<IProps> = ({ requestId }) => {
  const C = useStyles();
  const { flex } = useGenericStyles();
  const phases = useSelector(getStandardPhaseIdsWithRecurringPhases(requestId));

  return (
    <div className={C.root}>
      <h5 className={C.title}>{translate('myRequests.details.phases')}</h5>
      <div className={classNames(flex.column, flex.gap)}>
        {phases.map(({ id, recurringPhases }, index) => (
          <>
            <PhaseDetail id={id} index={index} key={id} />
            {recurringPhases.map((id, index) => (
              <PhaseDetail id={id} index={index} recurringPhase />
            ))}
          </>
        ))}
      </div>
    </div>
  );
};
