import { validateTime, validateDate, validateDateTimeInterval } from '../../../common/validation/validateTime';
import { DatePicker, TextInput } from '../../atoms';
import { createUseStyles } from 'react-jss';
import { useFlexStyles } from '../../../theme';
import classNames from 'classnames';
import { TimePicker } from '../../common/forms/TimePicker';
import { useFormContext } from 'react-hook-form';
import { ISgwPhase } from '../../../types';
import { CheckboxWithLabelComponent } from '../../common/forms/CheckboxWithLabel.component';
import { TextArea } from '../../common/forms/TextArea.component';
import { FC, useEffect } from 'react';
import { latestDateComparedToToday } from '../../../common/utils/dateUtils';
import { translate, translateHtml } from '../../../translations/translate';
import { useGenericStyles } from '../../../hooks';
import { Visible } from '../../common/layout/Visible.component';

interface IProps {
  id: string;
  maxDateUntil?: string;
  minDateFrom?: string;
  recurringPhaseTemplate?: boolean;
  index: number;
}

const useStyles = createUseStyles({
  dateTimes: {
    gap: '30px',
  },
  phaseName: {
    width: '60%',
  },
});

export const PhaseGeneralFields: FC<IProps> = ({ id, maxDateUntil, minDateFrom, recurringPhaseTemplate, index }) => {
  const C = useStyles();
  const { spacing } = useGenericStyles();
  const flex = useFlexStyles();
  const { register, watch, formState, getValues, trigger } = useFormContext<ISgwPhase>();
  const { errors, touchedFields } = formState;
  const dateFrom = watch('dateFrom');
  const dateUntil = watch('dateUntil');
  const timeFrom = watch('timeFrom');
  const timeUntil = watch('timeUntil');

  useEffect(() => {
    trigger('dateFrom');
    trigger('dateUntil');
    trigger('timeFrom');
    trigger('timeUntil');
  }, [trigger, dateUntil, dateFrom, timeFrom, timeUntil, minDateFrom, index]);

  return (
    <div className={spacing.marginBottom.LG}>
      <h6 className={spacing.marginBottom.MD}>{translate('sgw.phases.general.title')}</h6>
      <div className={classNames(flex.column, flex.gap)}>
        <TextInput className={C.phaseName} {...register('phaseName')} label={translate('sgw.phases.general.name')} />
        <Visible visible={!recurringPhaseTemplate}>
          <div className={classNames(flex.row, flex.topVertical, C.dateTimes)}>
            <DatePicker
              required
              className={spacing.marginRight.LG}
              {...register('dateFrom', { required: true })}
              label={translate('sgw.phases.general.dateFrom')}
              minDate={minDateFrom}
              maxDate={maxDateUntil}
              disabled={index === 0 && minDateFrom === dateFrom}
              validate={(value) => validateDate(translate('sgw.phases.general.dateUntil'), value, minDateFrom)}
              validateOnInit
            />
            <TimePicker
              required
              {...register('timeFrom', { required: true })}
              label={translate('sgw.phases.general.timeFrom')}
              validate={(value) => validateDateTimeInterval(dateFrom, dateUntil, value, timeUntil)}
              start
            />
            <DatePicker
              required
              className={spacing.marginRight.LG}
              {...register('dateUntil', { required: true })}
              label={translate('sgw.phases.general.dateUntil')}
              minDate={latestDateComparedToToday([dateFrom])}
              maxDate={maxDateUntil}
              validate={(value) =>
                validateDate(translate('sgw.phases.general.dateUntil'), value, getValues('dateFrom'))
              }
              defaultValue={dateFrom}
            />
            <TimePicker
              required
              {...register('timeUntil', { required: true })}
              label={translate('sgw.phases.general.timeUntil')}
              validate={(value) => validateDateTimeInterval(dateFrom, dateUntil, timeFrom, value)}
              end
            />
          </div>
          <div>
            <label className={classNames('a-input__label')}>
              {translateHtml('sgw.phases.general.normalWorkingHours')}
            </label>
            <div className={classNames(flex.row, flex.topVertical, C.dateTimes)}>
              <TimePicker
                required
                {...register('workingHoursFrom', { required: true })}
                start
                validate={validateTime}
              />
              <TimePicker required {...register('workingHoursUntil', { required: true })} validate={validateTime} end />
            </div>
            <CheckboxWithLabelComponent
              id={id}
              className={spacing.marginTop.XS}
              {...register('workingHoursNotStandard')}
              label={translate('sgw.phases.general.workingHoursNotStandard')}
            />
          </div>
        </Visible>
        <TextArea
          ariaLabel={translate('sgw.phases.general.locationDescription')}
          label={translate('sgw.phases.general.locationDescriptionLabel')}
          TextAreaProps={register('locationDescription')}
          meta={{
            error: !!errors.locationDescription,
            touched: !!touchedFields.locationDescription || !!errors.locationDescription,
          }}
        />
      </div>
    </div>
  );
};
