import { FC } from 'react';
import { translate } from '../../../translations/translate';
import { useSelector } from 'react-redux';
import { COLORS } from '../../../theme';
import { createUseStyles } from 'react-jss';
import { PhaseAccordion } from '../../molecules';
import { LabelValue } from '../../atoms';
import { useGenericStyles } from '../../../hooks';
import { formatDateTimeToDisplayDate } from '../../../common/utils/dateUtils';
import { getDistinctTrafficMeasuresForPhase, getNatureOfWorksByIds } from '../../../store/selectors';
import { SoftDeletableFields } from './SoftDeletableFields.component';
import { getCompletePhase } from '../../../store/selectors/sgw';
import { Visible } from '../../common/layout/Visible.component';
import { List } from '../../atoms/List/List.component';

interface IProps {
  id: string;
  index: number;
  recurringPhase?: boolean;
  recurringPhaseTemplate?: boolean;
}

const useStyles = createUseStyles({
  streetNames: {
    marginBottom: '1rem',
    '& > :first-child': {
      paddingBottom: '10px',
      marginBottom: '10px',
      borderBottom: `1px solid ${COLORS.borderGrey}`,
    },
  },
  labelValue: {
    marginBottom: '1rem',
    '& > :first-child': {
      paddingBottom: '10px',
      marginBottom: '10px',
    },
  },
  description: {
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
});

export const PhaseDetail: FC<IProps> = ({ id, index, recurringPhase, recurringPhaseTemplate }) => {
  const C = useStyles();
  const G = useGenericStyles();

  const phase = useSelector(getCompletePhase(id));
  const natureOfWorks = useSelector(getNatureOfWorksByIds(phase?.sgwNatureOfWorks || []));
  const trafficMeasures = useSelector(getDistinctTrafficMeasuresForPhase(id));

  return (
    <PhaseAccordion
      {...phase}
      id={id}
      key={id}
      index={index}
      recurringPhase={recurringPhase}
      recurringPhaseTemplate={recurringPhaseTemplate}
    >
      <div className="row">
        <div className="col-md-6 col-xs-12" data-testid="leftColumn">
          <LabelValue label={translate('sgw.phases.detail.phaseName')}>{phase.phaseName}</LabelValue>
          <Visible visible={!recurringPhaseTemplate}>
            <LabelValue label={translate('sgw.phases.detail.startDate')}>
              {formatDateTimeToDisplayDate(phase.dateFrom!, phase.timeFrom)}
            </LabelValue>
            <LabelValue label={translate('sgw.phases.detail.endDate')}>
              {formatDateTimeToDisplayDate(phase.dateUntil!, phase.timeUntil)}
            </LabelValue>
            <LabelValue label={translate('sgw.phases.detail.workHours')}>
              {`${phase.workingHoursFrom} - ${phase.workingHoursUntil}`}
            </LabelValue>
          </Visible>
          <Visible visible={!!phase.permitRequestId}>
            <LabelValue label={translate('sgw.phases.detail.asignDossier')}>{phase.permitRequestId}</LabelValue>
          </Visible>

          <LabelValue label={translate('sgw.phases.detail.streetName')} direction="vertical" className={C.streetNames}>
            {phase.addresses?.map(({ id, streetName, streetNumberFrom, streetNumberTo }) => (
              <div key={id} className={G.spacing.marginBottom.SM}>
                {streetName} {streetNumberFrom} {streetNumberTo}{' '}
              </div>
            ))}
          </LabelValue>

          <LabelValue label={translate('sgw.phases.detail.description')} direction="vertical" className={C.labelValue}>
            <div className={C.description}>{phase.locationDescription}</div>
          </LabelValue>
        </div>
        <div className="col-md-6 col-xs-12" data-testid="rightColumn">
          <LabelValue
            label={translate('sgw.phases.detail.natureOfWorks')}
            direction="vertical"
            className={C.labelValue}
          >
            <SoftDeletableFields fields={natureOfWorks} />
          </LabelValue>
          <LabelValue
            label={translate('sgw.phases.detail.trafficMeasures')}
            direction="vertical"
            className={C.labelValue}
          >
            <Visible
              visible={!!trafficMeasures?.length}
              defaultComponent={<div>{translate('sgw.overview.phases.noTrafficMeasures')}</div>}
            >
              <List items={trafficMeasures} prop="name" />
            </Visible>
          </LabelValue>
          <LabelValue
            label={translate('sgw.phases.detail.clarifyingMeasures')}
            direction="vertical"
            className={C.labelValue}
          >
            <div className={C.description}>{phase.trafficMeasureClarification}</div>
          </LabelValue>
        </div>
      </div>
    </PhaseAccordion>
  );
};
