import { useEffect, FC, useRef } from 'react';
import { useMap } from 'react-leaflet';
import { LatLngBoundsExpression } from 'leaflet';
import { MAP_DEFAULT_ZOOM } from '../../../common/constants';
import { boundsAreLatLngBoundsAndAreEqual, getLatLng1D, getLatLngBounds } from '../../../common/utils/mapUtils';
import { IGeometry } from '../../../types/map.types';

interface IProps {
  bounds?: LatLngBoundsExpression;
  geometryBounds?: IGeometry;
  maxZoom?: number;
}

export const FlyToBounds: FC<IProps> = ({ bounds, geometryBounds, maxZoom = 19, ...props }) => {
  const map = useMap();
  const boundsRef = useRef<LatLngBoundsExpression>();

  useEffect(() => {
    if (bounds && !boundsAreLatLngBoundsAndAreEqual(bounds, boundsRef.current)) {
      map.flyToBounds(bounds, { maxZoom });
    }
    boundsRef.current = bounds;
  }, [bounds, map, maxZoom]);

  useEffect(() => {
    if (geometryBounds?.coordinates[0]) {
      if (geometryBounds.type === 'Point') {
        map.flyTo(getLatLng1D(geometryBounds.coordinates), MAP_DEFAULT_ZOOM + 3);
      } else {
        map.flyToBounds(getLatLngBounds(geometryBounds), { maxZoom });
      }
    }
  }, [geometryBounds, map, maxZoom]);

  return null;
};
