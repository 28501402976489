import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { submitId } from '../../../../common/utils/formUtils';
import { SgwWorkflowStep, WorkflowType } from '../../../../types';
import { AttachmentFields } from '../../../organisms/AttachmentFields/AttachmentFields.component';
import { useAttachmentsForm } from '../../../../hooks';

export const AttachmentsForm: FC = () => {
  const { formMethods, submitAttachments } = useAttachmentsForm();

  return (
    <div data-testid="AttachmentsForm">
      <FormProvider {...formMethods}>
        <form id={submitId(WorkflowType.Sgw, SgwWorkflowStep.Attachments)} onSubmit={submitAttachments}>
          <AttachmentFields />
        </form>
      </FormProvider>
    </div>
  );
};
