import { IGenericAction } from '../actions/actions';
import { IProfile } from '../../types';
import { ApplicationActions } from '../actions/workflow.actions';

const INITIAL_STATE: IProfile | {} = {};

export default function profile(state: IProfile | {} = INITIAL_STATE, action: IGenericAction<IProfile>): IProfile | {} {
  switch (action.type) {
    case ApplicationActions.profileReceived.type:
      return action.payload;
    default:
      return state;
  }
}
