import { ValidateResult } from 'react-hook-form/dist/types/validator';
import { geoCodeAddress } from '../api';
import { POSTAL_CODE_REGEX, TENANT_NAME } from '../constants';
import { translate } from '../../translations/translate';

export const validateAddress = async (
  street?: string,
  streetNumber?: string,
  errorMessage?: string,
  postalCode?: string,
): Promise<ValidateResult> => {
  if (!street) {
    return true;
  }

  const response = await geoCodeAddress({ street, streetNumber: streetNumber, city: TENANT_NAME });

  const postalCodeExistsAndIsInvalid = postalCode ? response?.data?.data?.address?.postalCode !== postalCode : false;
  if (
    !response?.data ||
    !(response?.data?.data?.address?.streetName === street.replace(POSTAL_CODE_REGEX, '')) ||
    postalCodeExistsAndIsInvalid
  ) {
    return errorMessage || translate('locationsform.invalidAddress');
  }

  return true;
};
