import { FC } from 'react';
import { translate } from '../../../translations/translate';
import { useSelector } from 'react-redux';
import { getRecurringPhaseTemplateIds } from '../../../store/selectors/sgw';
import { createUseStyles } from 'react-jss';
import { useGenericStyles } from '../../../hooks';
import classNames from 'classnames';
import { PhaseDetail } from '../PhasesDetail/PhaseDetail.component';

interface IProps {
  requestId: string;
}

const useStyles = createUseStyles({
  root: {
    marginBottom: '20px',
    padding: '0 10px',
  },
  title: {
    marginBottom: '10px',
  },
});

export const RecurringPhaseTemplateDetail: FC<IProps> = ({ requestId }) => {
  const C = useStyles();
  const { flex } = useGenericStyles();
  const recurringPhases = useSelector(getRecurringPhaseTemplateIds(requestId));

  return (
    <div className={C.root}>
      <h5 className={C.title}>{translate('myRequests.details.recurringPhaseTemplates')}</h5>
      <div className={classNames(flex.column, flex.gap)}>
        {recurringPhases.map((id, index) => (
          <PhaseDetail id={`${id}`} index={index} key={id} recurringPhaseTemplate />
        ))}
      </div>
    </div>
  );
};
