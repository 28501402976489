import React, { ReactNode } from 'react';

interface IProps<T> {
  items: Array<T>;
  prop: string;
}

export const List = <T,>({ items, prop }: IProps<T>) => {
  return (
    <ul style={{ listStyle: 'inside' }}>
      {items.map((item, index) => (
        <li key={`${item?.[prop as keyof T]} ${index}`}>{item?.[prop as keyof T] as ReactNode}</li>
      ))}
    </ul>
  );
};
