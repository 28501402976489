import classNames from 'classnames';
import * as React from 'react';
import { Required } from './Required.component';
import intl from 'react-intl-universal';
import { UseFormRegisterReturn } from 'react-hook-form';

interface IProps {
  ariaLabel: string;
  label: JSX.Element | string;
  meta?: {
    error?: boolean;
    touched?: boolean;
  };
  TextAreaProps: UseFormRegisterReturn;
  required?: boolean;
}

export const TextArea = ({ label, ariaLabel, required = false, meta, TextAreaProps }: IProps) => (
  <>
    <div
      className={classNames(
        'a-input',
        required && 'is-required',
        meta?.touched && (meta?.error ? 'has-error' : 'has-success'),
      )}
    >
      <label className="a-input__label">{label}</label>
      <div className="a-input__wrapper">
        <textarea
          {...TextAreaProps}
          aria-invalid={!!meta?.error && meta?.touched}
          aria-label={required ? intl.get('fields.labelRequiredBrackets', { label: ariaLabel }) : ariaLabel}
        />
        <Required required={!!meta?.touched && !!meta?.error} message={intl.get('reasonform.requiredDescription')} />
      </div>
    </div>
  </>
);
