import { FC, useEffect } from 'react';
import { PartnerActions } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getPartner } from '../../../store/selectors';
import { useGenericStyles } from '../../../hooks';
import { LabelValue } from '../../atoms';
import { translate, translateIgnoreTS } from '../../../translations/translate';
import classNames from 'classnames';
import { Visible } from '../../common/layout/Visible.component';

interface IProps {
  id?: number;
}

export const PartnerRequestInfoContent: FC<IProps> = ({ id }) => {
  const dispatch = useDispatch();
  const partner = useSelector(getPartner(`${id}`));
  const { flex, spacing } = useGenericStyles();

  useEffect(() => {
    id && dispatch(PartnerActions.fetch(`${id}`));
  }, [dispatch, id]);

  if (!partner) return null;

  const {
    contactFirstName,
    contactLastName,
    contactPhoneNumber,
    contactEmailAddress,
    capacity,
    street,
    streetNumber,
    bus,
    ssn,
    vatNumber,
    companyName,
    firstName,
    lastName,
    country,
    city,
    zipCode,
  } = partner;

  return (
    <div className={flex.row}>
      <div className={classNames(flex.half, spacing.marginRight.MD)}>
        <LabelValue direction="horizontal" label={'Partner'}>
          {translateIgnoreTS(`sgw.startRequest.capacity.options.${capacity}`)}
        </LabelValue>
        <LabelValue direction="horizontal" visible={!!vatNumber} label={translate('sgw.overview.requestor.vatNumber')}>
          {vatNumber}
        </LabelValue>
        <LabelValue direction="horizontal" visible={!!ssn} label={translate('sgw.overview.requestor.ssn')}>
          {ssn}
        </LabelValue>
        <LabelValue
          direction="horizontal"
          visible={!!companyName}
          label={translate('sgw.overview.requestor.companyName')}
        >
          {companyName}
        </LabelValue>
        <Visible visible={!partner.companyName}>
          <LabelValue
            direction="horizontal"
            label={translate('personalform.name')}
          >{`${firstName} ${lastName}`}</LabelValue>
        </Visible>
        <LabelValue
          direction="horizontal"
          label={translate('sgw.overview.requestor.address')}
        >{`${street} ${streetNumber} ${bus || ''}`}</LabelValue>
        <LabelValue direction="horizontal" label={translate('personalform.city')}>{`${city} ${zipCode}`}</LabelValue>
        <LabelValue
          visible={!!country}
          direction="horizontal"
          label={translate('personalform.country')}
        >{`${country?.name}`}</LabelValue>
      </div>
      <div className={flex.half}>
        <LabelValue
          direction="horizontal"
          label={translate('sgw.overview.requestor.contactName')}
        >{`${contactFirstName} ${contactLastName}`}</LabelValue>
        <LabelValue direction="horizontal" label={translate('sgw.overview.requestor.contactPhoneNumber')}>
          {contactPhoneNumber}
        </LabelValue>
        <LabelValue direction="horizontal" label={translate('sgw.overview.requestor.contactEmail')}>
          {contactEmailAddress}
        </LabelValue>
      </div>
    </div>
  );
};
