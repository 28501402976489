import * as A from 'antwerp-core-react-branding';
import React, { FC, useCallback } from 'react';
import { historyPush } from '../../../common/utils/historyUtils';
import './Fail.component.scss';
import { appUrls } from '../../../common/constants';
import { translate } from '../../../translations/translate';

export const Fail: FC = () => {
  const onClickFactory = useCallback((url: string) => () => historyPush(url), []);

  return (
    <div className="submit-page submit-fail">
      <div className="row submit-page__icon animated bounceIn">
        <i className="fa fa-remove" aria-hidden="true" title={translate('submit.fail.title')} />
      </div>
      <h1>{translate('submit.fail.title')}</h1>
      <div className="submit-page__text">{translate('submit.fail.message')}</div>
      <div className="row submit-page__buttons">
        <div className="col-xs-12">
          {/*@ts-ignore*/}
          <A.Button onClick={onClickFactory(appUrls.myRequests.base)} text={translate('myRequests.title')} />
        </div>
      </div>
    </div>
  );
};
