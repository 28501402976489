import { createUseStyles } from 'react-jss';

const spacer = 1.5;

enum spacerSize {
  XX = 4,
  XL = 2.5,
  LG = 2,
  ML = 1,
  MD = 1.5,
  SM = 0.75,
  XS = 0.5,
  XXS = 0.375,
}

const createSpaceValue = (size: spacerSize) => `${size * spacer}rem !important`;

const createSpace = (spaceType: string) => ({
  XX: { [spaceType]: createSpaceValue(spacerSize.XX) },
  XL: { [spaceType]: createSpaceValue(spacerSize.XL) },
  LG: { [spaceType]: createSpaceValue(spacerSize.LG) },
  ML: { [spaceType]: createSpaceValue(spacerSize.ML) },
  MD: { [spaceType]: createSpaceValue(spacerSize.MD) },
  SM: { [spaceType]: createSpaceValue(spacerSize.SM) },
  XS: { [spaceType]: createSpaceValue(spacerSize.XS) },
  XXS: { [spaceType]: createSpaceValue(spacerSize.XXS) },
});

export const useSpacingStyles = () => ({
  marginBottom: createUseStyles(createSpace('marginBottom'))(),
  marginLeft: createUseStyles(createSpace('marginLeft'))(),
  marginRight: createUseStyles(createSpace('marginRight'))(),
  marginTop: createUseStyles(createSpace('marginTop'))(),
  margin: createUseStyles(createSpace('margin'))(),
  paddingTop: createUseStyles(createSpace('paddingTop'))(),
  paddingBottom: createUseStyles(createSpace('paddingBottom'))(),
  paddingLeft: createUseStyles(createSpace('paddingLeft'))(),
  paddingRight: createUseStyles(createSpace('paddingRight'))(),
  padding: createUseStyles(createSpace('padding'))(),
});
