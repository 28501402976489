import { FC, useEffect } from 'react';
import { IWithComponentName } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { OverviewList } from '../../atoms';
import { getDistinctTrafficMeasuresForPhase, getNatureOfWorks } from '../../../store/selectors';
import { PhaseActions } from '../../../store/actions/phase.actions';
import { getPhase } from '../../../store/selectors/sgw/phase.selectors';
import { formatToDisplayDate } from '../../../common/utils/dateUtils';
import { translate } from '../../../translations/translate';
import { useGenericStyles } from '../../../hooks';
import { Visible } from '../../common/layout/Visible.component';
import { List } from '../../atoms/List/List.component';

interface IProps extends IWithComponentName {
  index: number;
  id: string;
}

const PhaseInfoComponent: FC<IProps> = ({ id, index }) => {
  const G = useGenericStyles();
  const dispatch = useDispatch();
  const phase = useSelector(getPhase(`${id}`));
  const allNatureOfWorks = useSelector(getNatureOfWorks);
  const trafficMeasures = useSelector(getDistinctTrafficMeasuresForPhase(id));

  useEffect(() => {
    id && dispatch(PhaseActions.fetch({ requestId: '' + phase.permitRequestId, id: `${id}` }));
  }, [dispatch, id, phase.permitRequestId]);

  if (!phase) return null;
  const { phaseName, dateFrom, dateUntil, sgwNatureOfWorks } = phase;

  const natureOfWorks = allNatureOfWorks
    .filter((item) => sgwNatureOfWorks?.includes(`${item.id}`))
    .map((item) => item.name)
    .join(', ');

  return (
    <OverviewList.Sublist
      title={
        phase.isRecurringPhaseTemplate
          ? translate('sgw.overview.phases.subTitleTemplate', { index })
          : translate('sgw.overview.phases.subTitle', { index })
      }
      description={phaseName || ' '}
    >
      <Visible visible={!phase.isRecurringPhaseTemplate}>
        <OverviewList.Item
          title={translate('sgw.overview.type.startEndDate')}
          description={dateFrom && dateUntil && `${formatToDisplayDate(dateFrom)} - ${formatToDisplayDate(dateUntil)}`}
        />
      </Visible>
      <OverviewList.Item title={translate('sgw.overview.phases.natureofWorks')} description={natureOfWorks} />
      <OverviewList.CustomItem
        title={translate('sgw.overview.phases.trafficMeasures')}
        titleClassName="col-xs-12 col-md-6"
        childrenClassName="col-xs-12 col-md-6"
        wrapperClassName={G.flex.spaceBetween}
      >
        <Visible
          visible={!!trafficMeasures?.length}
          defaultComponent={<div>{translate('sgw.overview.phases.noTrafficMeasures')}</div>}
        >
          <List items={trafficMeasures} prop="name" />
        </Visible>
      </OverviewList.CustomItem>
    </OverviewList.Sublist>
  );
};

PhaseInfoComponent.defaultProps = { componentName: 'OverviewSublist' };

export { PhaseInfoComponent as PhaseInfo };
