import { FC } from 'react';
import { Category, IWithComponentName } from '../../../types';
import { OverviewList } from '../../atoms';
import { translate } from '../../../translations/translate';

interface IProps extends IWithComponentName {
  fileName: string;
  url?: string;
  category: Category;
  phaseNames: string[];
}

const AttachmentInfoComponent: FC<IProps> = ({ fileName, url, category, phaseNames }) => {
  return (
    <OverviewList.Sublist
      title={translate('sgw.overview.attachments.fileName')}
      description={
        url ? (
          <a href={url} target={'_blank'} rel="noreferrer">
            {fileName}
          </a>
        ) : (
          fileName
        )
      }
    >
      <OverviewList.Item title={translate('sgw.overview.attachments.category')} description={category} />
      <OverviewList.Item title={translate('sgw.overview.attachments.phases')} description={phaseNames.join(', ')} />
    </OverviewList.Sublist>
  );
};

AttachmentInfoComponent.defaultProps = { componentName: 'OverviewSublist' };

export { AttachmentInfoComponent as AttachmentInfo };
