import React, { FC, useMemo, useRef } from 'react';
import { Marker as ReactLeafletMarker, MarkerProps } from 'react-leaflet';
import L from 'leaflet';
import markerIconSvg from '../../../../assets/images/location.svg';
import { ILeafletMarkerEvents } from '../../../../types/map.types';

const markerIcon = L.icon({
  iconAnchor: [19, 34],
  iconUrl: markerIconSvg,
  iconRetinaUrl: markerIconSvg,
  iconSize: [35, 35],
  className: 'leaflet-venue-icon',
});

interface IProps extends MarkerProps, ILeafletMarkerEvents {}

export const Marker: FC<IProps> = ({ onDragStart, onDrag, onDragEnd, ...props }) => {
  const markerRef = useRef<L.Marker>(null);

  const eventHandlers = useMemo(
    () => ({
      drag: () => onDrag?.(markerRef.current),
      dragend: () => onDragEnd?.(markerRef.current),
      dragstart: () => onDragStart?.(markerRef.current),
    }),
    [onDrag, onDragEnd, onDragStart],
  );

  return <ReactLeafletMarker eventHandlers={eventHandlers} icon={markerIcon} ref={markerRef} {...props} />;
};
