import React, { FC } from 'react';
import { IRequestPayment } from '../../../types';
import { Visible } from '../layout/Visible.component';
import { PaymentDisabled } from '../../atoms/PaymentDisabled/PaymentDisabled.component';
import * as A from 'antwerp-core-react-branding';
import intl from 'react-intl-universal';
import { Feature } from '../../atoms';
import { useSelector } from 'react-redux';
import { selectRequest } from '../../../store/selectors';
import { WORKFLOW_STATE_CANCELED, WORKFLOW_STATE_REJECTED } from '../../../common/constants';

interface IProps {
  payment: IRequestPayment;
  onPay?(payment: IRequestPayment): void;
}

export const PaymentButton: FC<IProps> = ({ onPay, payment }) => {
  const request = useSelector(selectRequest);

  return (
    <Visible
      visible={
        payment.canPay &&
        !!onPay &&
        request?.state?.state !== WORKFLOW_STATE_CANCELED &&
        request?.state?.state !== WORKFLOW_STATE_REJECTED
      }
    >
      <Feature.IntegrateDigipolisOrder
        fallbackComponent={
          <>
            <PaymentDisabled />
            <A.Button disabled size={A.Sizes.Small} text={intl.get('general.pay')} type={A.ButtonType.Outline} />
          </>
        }
      >
        <div style={{ marginTop: '8px' }} data-testid="PaymentButton">
          <A.Button
            onClick={() => onPay!(payment)}
            size={A.Sizes.Small}
            text={intl.get('general.pay')}
            type={A.ButtonType.Outline}
          />
        </div>
      </Feature.IntegrateDigipolisOrder>
    </Visible>
  );
};
