import React, { forwardRef, useCallback, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ValidateResult } from 'react-hook-form/dist/types/validator';
import intl from 'react-intl-universal';
import { IControllableForm } from '../../../common/form';
import { isValid } from '../../../common/utils/formUtils';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { IRequest } from '../../../types';
import {
  AntwerpDatePickerInput,
  DatePickerInputProperties,
} from '../../antwerpCore/form/AntwerpDatePickerInput.component';

interface IProps extends Omit<Partial<DatePickerInputProperties>, 'name'>, Omit<IControllableForm, 'validate'> {
  validationLabel?: string | JSX.Element;
  validateOnInit?: boolean;

  validate?(
    value: string,
    allValues?: Partial<IRequest>,
    props?: any,
    name?: string,
    maxDate?: string,
  ): ValidateResult | Promise<ValidateResult>;
}

const useStyles = createUseStyles({
  container: {
    '&.is-disabled span': {
      visibility: 'hidden!important',
    },
  },
});

export const DatePicker = forwardRef(
  ({ label, name, validate, validationLabel = label, validateOnInit = true, ...props }: IProps, ref) => {
    const C = useStyles();
    const { control, setValue, setError, clearErrors } = useFormContext();
    const {
      field,
      fieldState: { error, isTouched },
    } = useController({
      name,
      control,
      rules: { required: intl.get('fields.labelRequired', { label: validationLabel }), validate },
    });

    const checkValue = useCallback(
      (value: string) => {
        const checkLocalValidation = validate?.(value, undefined, undefined, undefined, props.maxDate);
        checkLocalValidation && !isValid(checkLocalValidation)
          ? // @ts-ignore - isValid checks whether 'validate' is not boolean true
            setError(name, { message: checkLocalValidation })
          : clearErrors(name);
        return isValid(checkLocalValidation);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setError, name, clearErrors],
    );

    const _onBlur = useCallback(
      (e: string) => {
        checkValue(e);
        setValue(name, e);
      },
      [checkValue, name, setValue],
    );

    useEffect(() => {
      if (validateOnInit) {
        checkValue(field.value);
      }
    }, [field.value, checkValue, validateOnInit]);

    return (
      <div className={classNames(props.className, C.container, props.disabled && 'is-disabled')}>
        <AntwerpDatePickerInput
          {...props}
          // @ts-ignore
          ref={ref}
          {...field}
          value={field.value || props.value}
          meta={{ error: error?.message, touched: isTouched || !!error }}
          errorComponent={<div role="alert">{error?.message}</div>}
          label={`${label} (${intl.get('fields.dateformat')})`}
          placeholder={intl.get('fields.dateformat')}
          // @ts-ignore
          onBlur={_onBlur}
          onKeyDown={(event) => {
            // AS-6736 prevent the form from submitting on pressing Enter
            event.key === 'Enter' && event.preventDefault();
          }}
          data-testid={`datePicker-${name}`}
        />
      </div>
    );
  },
);
