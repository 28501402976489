import { call, Effect, put, takeEvery } from 'redux-saga/effects';
import { createRequestRequestedAction } from '../../common/actions/creators/requests';
import { createRequestMessagesReceivedAction } from '../../common/actions/creators/requestsMessages';
import { REQUEST_MESSAGES_GET, REQUEST_MESSAGES_POST } from '../../common/actions/types';
import { getRequestMessages, postRequestMessage } from '../../common/api';
import debug from '../../common/debug';
import { IGenericAction } from '../actions/actions';
import { createProcessIdleAction, createProcessStartedAction } from '../actions/creators';
import { ICall, IPostMessage } from '../../types';
import { SagaIterator } from 'redux-saga';

function* requestGetMessages(action: IGenericAction<string>): IterableIterator<Effect> {
  const process = 'loadRequestMessages';
  try {
    yield put(createProcessStartedAction(process));
    const requestMessages: ICall<typeof getRequestMessages> = yield call(getRequestMessages, action.payload);

    yield put(createRequestMessagesReceivedAction(requestMessages!));
  } catch (e) {
    debug('Request messages could not be loaded', e);
  }
  yield put(createProcessIdleAction(process));
}

function* createNewMessage(action: IGenericAction<IPostMessage>): IterableIterator<Effect> {
  try {
    const response: ICall<typeof postRequestMessage> = yield call(postRequestMessage, action.payload);
    yield put(createRequestMessagesReceivedAction(response!));
  } catch (e) {
    debug('New Request message could not be created', e);
  } finally {
    yield put(createRequestRequestedAction(action.payload.requestId));
  }
}

export default function* requestMessagesSaga(): SagaIterator {
  yield takeEvery(REQUEST_MESSAGES_GET, requestGetMessages);
  yield takeEvery(REQUEST_MESSAGES_POST, createNewMessage);
}
