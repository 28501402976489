import { WORKFLOW_MIN_STEP, WORKFLOW_PATH_NEW, WORKFLOW_REGEXP } from '../constants';
import { getLocalUrl } from './urlUtils';

export function getWorkflowPath(id: number | string, step: number | string): string {
  return getLocalUrl(`/${id}/${step}`);
}

export function getInitialNewPath(): string {
  return getWorkflowPath(WORKFLOW_PATH_NEW, WORKFLOW_MIN_STEP);
}

export function getInitialRequestPath(id: string): string {
  return getWorkflowPath(id, WORKFLOW_MIN_STEP);
}

export function isWorkFlowPath(pathname: string): boolean {
  return WORKFLOW_REGEXP.test(pathname);
}

export function getWorkFlowStepFromPath(pathname: string): number {
  if (pathname && isWorkFlowPath(pathname)) {
    const parts = splitWorkFlowPath(pathname);
    return parseInt(parts[3], 10);
  }
  return -1;
}

export function getWorkFlowTypeFromPath(pathname: string): string | undefined {
  if (pathname && isWorkFlowPath(pathname)) {
    const parts = splitWorkFlowPath(pathname);
    return parts[2];
  }
}

function splitWorkFlowPath(pathname: string): string[] {
  return WORKFLOW_REGEXP.exec(pathname) || [];
}
