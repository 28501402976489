import { createSelector } from '@reduxjs/toolkit';
import IState from '../state';
import { PartnerType } from '../types';

export interface IById<T> {
  [key: string]: T;
}

export type IByPartnerType<T> = {
  [key in PartnerType]: T;
};

export interface INormalizedStateById<T> {
  byId: IById<T>;
}

export interface INormalizedState<T> extends INormalizedStateById<T> {
  allIds: string[];
}

export type IWithId<T> = T & {
  id: string | number;
};

export const normalizeArray = <T>(data: Array<IWithId<T>>): IById<IWithId<T>> =>
  data.reduce(
    (prev, g) => ({
      ...prev,
      [`${g.id}`]: g,
    }),
    {},
  );

export const extractIdsFromArray = (data: Array<IWithId<any>>): string[] => data.map((item) => `${item.id}`);

export function toNormalizedState<T>(data: Array<IWithId<T>>): INormalizedState<T> {
  return {
    allIds: extractIdsFromArray(data),
    byId: normalizeArray<T>(data),
  };
}

export const mapIdsToEntities = <T>(ids: string[], byId: IById<T>): T[] => ids.map((id) => byId[id]);

export const createAllEntitiesSelector = <T>(
  idsSelector: (state: IState) => string[] | undefined,
  byIdSelector: (state: IState) => IById<T> | undefined,
) => createSelector([idsSelector, byIdSelector], (ids, byId) => (ids && byId ? mapIdsToEntities(ids, byId) : []));
