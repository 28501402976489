import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { SgwRequestActions } from '../actions';
import { ICall } from '../../types';
import { genericErrorHandler } from './errors.sagas';
import { SgwRequestApi } from '../api';

import { SnackBarActions } from '../actions/Snackbar.actions';
import { translate } from '../../translations/translate';

function* onFetchRequestRescheduling({
  payload,
}: ReturnType<typeof SgwRequestActions.rescheduling.fetch>): SagaIterator {
  const response: ICall<typeof SgwRequestApi.fetchRequestReschedulings> = yield call(
    SgwRequestApi.fetchRequestReschedulings,
    payload.requestId,
  );
  yield put(SgwRequestActions.rescheduling.set(response!.data.data));
}

function* onCreateRequestRescheduling({
  payload,
}: ReturnType<typeof SgwRequestActions.rescheduling.create>): SagaIterator {
  try {
    yield call(
      SgwRequestApi.createRequestReschedulings,
      payload.requestId,
      payload.rescheduling,
      payload.requestRescheduleId,
    );
    yield put(SgwRequestActions.fetch(payload.requestId));
    yield put(SgwRequestActions.rescheduling.fetch({ requestId: payload.requestId }));
    yield put(SnackBarActions.setSuccess(translate('myRequests.details.rescheduleRequest.submitSuccess')));
  } catch (error) {
    yield put(
      SnackBarActions.setFailure({
        feedback: translate('myRequests.details.rescheduleRequest.submitFail'),
      }),
    );
    throw error;
  }
}

function* onDeleteRequestRescheduling({
  payload,
}: ReturnType<typeof SgwRequestActions.rescheduling.remove>): SagaIterator {
  try {
    yield call(SgwRequestApi.deleteRequestReschedulings, payload.requestId, payload.requestRescheduleId);
  } catch (error) {
    yield put(
      SnackBarActions.setFailure({
        feedback: translate('myRequests.details.rescheduleRequest.submitFail'),
      }),
    );
  }
  yield put(SgwRequestActions.rescheduling.fetch({ requestId: payload.requestId }));
  yield put(SgwRequestActions.fetch(payload.requestId));
}

export default function* sgwRequestsReschedulingSagas(): SagaIterator {
  yield takeEvery(SgwRequestActions.rescheduling.fetch.type, genericErrorHandler(onFetchRequestRescheduling));
  yield takeEvery(SgwRequestActions.rescheduling.create.type, genericErrorHandler(onCreateRequestRescheduling));
  yield takeEvery(SgwRequestActions.rescheduling.remove.type, genericErrorHandler(onDeleteRequestRescheduling));
}
