import { OverviewList } from '../../atoms';
import { SgwWorkflowStep, TRouterWithId } from '../../../types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { translate, translateHtml } from '../../../translations/translate';
import { FC, useEffect } from 'react';

import { formatToDisplayDate } from '../../../common/utils/dateUtils';
import { getInitialSgwValues, getProjectOptions } from '../../../store/selectors/sgw';
import { SgwRequestActions } from '../../../store/actions';
import { SpinnerLoader } from '../../atoms/SpinnerLoader/SpinnerLoader.component';

interface IProps {
  disableStep?: boolean;
}
export const TypeInfo: FC<IProps> = ({ disableStep }) => {
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const request = useSelector(getInitialSgwValues(requestId));
  const dispatch = useDispatch();
  const projects = useSelector(getProjectOptions);

  useEffect(() => {
    dispatch(SgwRequestActions.fetchProjects());
  }, [dispatch]);

  const {
    workType,
    workDescription,
    bonuNumber,
    dateFrom,
    dateUntil,
    mainLocation,
    isUrgentRequest,
    sgwProject,
    crane,
    gipodId,
    utilityCompanyNameResponsible,
    utilityCompanyPhoneResponsible,
    utilityCompanyEmailResponsible,
    utilityCompanyName,
  } = request;
  return (
    <SpinnerLoader loading={!request}>
      <OverviewList>
        <OverviewList.Title step={disableStep ? undefined : SgwWorkflowStep.Type}>
          {translate('sgw.overview.type.title')}
        </OverviewList.Title>

        <OverviewList.Item
          title={translate('sgw.overview.type.workType')}
          // @ts-ignore
          description={translateHtml(`sgw.workType.type.options.${workType}`)}
        />
        <OverviewList.Item title={translate('sgw.overview.type.bonuNumber')} description={bonuNumber || ''} />
        <OverviewList.Item title={translate('sgw.overview.type.gipodId')} description={gipodId || ''} />
        <OverviewList.Item
          title={translate('sgw.overview.type.utilityCompanyNameResponsible')}
          description={utilityCompanyNameResponsible || ''}
        />
        <OverviewList.Item
          title={translate('sgw.overview.type.utilityCompanyPhoneResponsible')}
          description={utilityCompanyPhoneResponsible || ''}
        />
        <OverviewList.Item
          title={translate('sgw.overview.type.utilityCompanyEmailResponsible')}
          description={utilityCompanyEmailResponsible || ''}
        />
        <OverviewList.Item
          title={translate('sgw.overview.type.utilityCompanyName')}
          description={utilityCompanyName || ''}
        />
        <OverviewList.Item
          title={translate('sgw.overview.type.startEndDate')}
          description={dateFrom && dateUntil && `${formatToDisplayDate(dateFrom)} - ${formatToDisplayDate(dateUntil)}`}
        />
        <OverviewList.Item
          title={translate('sgw.overview.type.mainAddress')}
          description={
            mainLocation && `${mainLocation.street} ${mainLocation.streetNumberFrom} - ${mainLocation.streetNumberTo}`
          }
        />
        <OverviewList.Item
          title={translate('sgw.overview.type.mainCity')}
          description={mainLocation && `${mainLocation.city} ${mainLocation.zipCode}`}
        />
        <OverviewList.Item
          title={translate('sgw.overview.type.urgentRequest')}
          description={translate(isUrgentRequest ? 'general.yes' : 'general.no')}
        />
        <OverviewList.Item
          title={translate('sgw.overview.type.sgwProject')}
          description={sgwProject && projects.find(({ value }) => value === sgwProject)?.label}
        />
        <OverviewList.Item
          title={translate('sgw.overview.type.useCrane')}
          description={translate(crane?.isUsed === 'true' ? 'general.yes' : 'general.no')}
        />
        <OverviewList.Item
          title={translate('sgw.overview.type.craneType')}
          visible={crane?.isUsed === 'true'}
          description={crane?.type || undefined}
        />
        <OverviewList.Item
          title={translate('sgw.overview.type.moreThan25Tons')}
          visible={crane?.isUsed === 'true'}
          description={translate(crane?.moreThan25Tons ? 'general.yes' : 'general.no')}
        />
        <OverviewList.Item
          title={translate('sgw.overview.type.maxHeight')}
          visible={crane?.isUsed === 'true'}
          description={`${crane?.maxHeight}`}
        />
        <OverviewList.Item title={translate('sgw.overview.type.description')} description={workDescription} />
      </OverviewList>
    </SpinnerLoader>
  );
};
