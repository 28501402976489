import { useAtom } from 'jotai';
import { FC } from 'react';
import { ConflictsShownOnMapAtom } from '../../pages/MapPage/Map.page';
import { ConflictDrawing } from '../ConflictDawing/ConflictDrawing.component';

export const ConflictDrawingOverlay: FC = () => {
  const [selectedConflictGroups] = useAtom(ConflictsShownOnMapAtom);

  return (
    <>
      {selectedConflictGroups.map((id, index) => (
        <ConflictDrawing conflictGroupId={id} key={id + index} />
      ))}
    </>
  );
};
