export const COLORS = {
  blue: '#0064b4',
  lightBlue: '#86d3ff',
  ligtherBlue: '#e6f0f8',
  borderGrey: '#b0b0b0',
  lightGrey: '#f3f3f3',
  purple: '#800080',
  red: '#da291c',
  white: 'white',
  oliveBlack: '#414140',
  lightSilver: '#d8d8d8',
  monza: '#cf0039',
  apple: '#4aa32c',
  sun: '#f9b01e',
  alizarinCrimson: '#da291c',
  beer: '#f18c21',
  rowHover: '#cce0f0',
  yellow: '#FFC657',
  backdrop: '#01010140',
};

export const ICON_COLORS = {
  primary: COLORS.blue,
  secondary: COLORS.monza,
  success: COLORS.apple,
  info: COLORS.blue,
  warning: COLORS.sun,
  danger: COLORS.alizarinCrimson,
  disabled: COLORS.borderGrey,
};
