import { FC, useEffect } from 'react';
import { IWithComponentName, PartnerType } from '../../../types';
import { PartnerActions } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { translate, translateIgnoreTS } from '../../../translations/translate';
import { OverviewList } from '../../atoms';
import { getPartner } from '../../../store/selectors';

interface IProps extends IWithComponentName {
  variant: PartnerType;
  id?: number;
}

const PartnerInfoComponent: FC<IProps> = ({ variant, id }) => {
  const dispatch = useDispatch();
  const partner = useSelector(getPartner(`${id}`));

  useEffect(() => {
    id && dispatch(PartnerActions.fetch(`${id}`));
  }, [dispatch, id]);

  if (!partner) return null;

  const { contactFirstName, contactLastName, contactPhoneNumber, contactEmailAddress } = partner;

  return (
    <OverviewList.Sublist
      title={translate('sgw.overview.partners.type')}
      description={translateIgnoreTS(`sgw.partners.accordion.title.${variant}`)}
    >
      <OverviewList.Item
        title={translate('sgw.overview.requestor.contactName')}
        description={`${contactFirstName} ${contactLastName}`}
      />
      <OverviewList.Item
        title={translate('sgw.overview.requestor.contactPhoneNumber')}
        description={contactPhoneNumber}
      />
      <OverviewList.Item title={translate('sgw.overview.requestor.contactEmail')} description={contactEmailAddress} />
    </OverviewList.Sublist>
  );
};

PartnerInfoComponent.defaultProps = { componentName: 'OverviewSublist' };

export { PartnerInfoComponent as PartnerInfo };
