import * as React from 'react';
import intl from 'react-intl-universal';

import * as A from 'antwerp-core-react-branding';

import { OverviewEditLink, OverviewItem, OverviewList, OverviewSection } from './overviewElements';
import { OverviewProperties } from './overViewType';

export type ReasonInfoProperties = OverviewProperties;

/**
 * React Component AddressInfo
 */
export default class AddressInfo extends React.Component<ReasonInfoProperties, {}> {
  public render(): any {
    const request = this.props.request;
    return (
      <OverviewSection>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.NoMargin} className="row between-md">
          <h6>{intl.get('paymentform.addressdata')}</h6>
          <OverviewEditLink {...this.props} />
        </A.Spacing>
        <OverviewList>
          <OverviewItem
            title={intl.get('paymentform.address')}
            description={
              <div>
                <div>
                  {request.street} {request.streetNumber} {request.bus}
                </div>
                <div>
                  {request.zipCode} {request.city}
                </div>
                <div>{request.country.name}</div>
              </div>
            }
          />
        </OverviewList>
      </OverviewSection>
    );
  }
}
