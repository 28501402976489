import { Actions } from './generic.actions';
import { IPartner, ISaveRequestPartner } from '../../types';

class PartnerActionsClass extends Actions {
  fetch = this.createPayloadAction<string>('fetch');
  fetchBulk = this.createPayloadAction<string[]>('fetchBulk');
  saveRequestPartner = this.createPayloadAction<ISaveRequestPartner>('save');
  set = this.createPayloadAction<IPartner>('set');
  setList = this.createPayloadAction<IPartner[]>('setList');
}

export const PartnerActions = new PartnerActionsClass('partner');
