import { OverviewList } from '../../atoms';
import { translate } from '../../../translations/translate';
import { FC } from 'react';
import { MessagesForm } from '../../forms/MessagesForm/MessagesForm.component';
import { useGenericStyles } from '../../../hooks';
import { useSelector } from 'react-redux';
import { selectMessageList } from '../../../store/selectors/sgw/messages.selectors';
import { MessageList } from '../../molecules/MessageList/MessageList.component';

export const Messages: FC = () => {
  const G = useGenericStyles();
  const messages = useSelector(selectMessageList);

  return (
    <OverviewList>
      <OverviewList.Title>{translate('sgw.overview.messages.title')}</OverviewList.Title>
      <OverviewList.CustomItem childrenClassName={G.flex.fullWidth}>
        <div className={G.flex.column}>
          <MessageList messages={messages!} />
          <MessagesForm acl={messages!.acl} />
        </div>
      </OverviewList.CustomItem>
    </OverviewList>
  );
};
