import React, { FC } from 'react';
import { historyPush } from '../../../common/utils/historyUtils';
import './Success.component.scss';
import { translate } from '../../../translations/translate';
import { Button } from '../../atoms';

interface IProps {
  path: string;
}

export const Success: FC<IProps> = ({ path }) => {
  const onClick = () => historyPush(path);

  return (
    <div className="submit-page submit-success">
      <div className="row submit-page__icon animated bounceIn">
        <i className="fa fa-check" aria-hidden="true" title={translate('submit.success.title')} />
      </div>
      <h1>{translate('submit.success.title')}</h1>
      <div className="submit-page__text">{translate('submit.success.message')}</div>
      <div className="row submit-page__buttons">
        <div className="col-xs-12">
          <Button onClick={onClick}>{translate('myRequests.title')}</Button>
        </div>
      </div>
    </div>
  );
};
