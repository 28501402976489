import { useFormContext } from 'react-hook-form';
import { FC } from 'react';
import { useGenericStyles } from '../../../hooks';
import { ISgwPhase } from '../../../types';
import { translate } from '../../../translations/translate';
import { TextArea } from '../../common/forms/TextArea.component';

interface IProps {
  id: string;
}

export const TrafficMeasureFields: FC<IProps> = ({ id }) => {
  const { spacing, flex } = useGenericStyles();
  const { register, formState } = useFormContext<ISgwPhase>();
  const { errors, touchedFields } = formState;

  return (
    <div className={spacing.marginBottom.LG}>
      <TextArea
        ariaLabel={translate('sgw.phases.general.trafficMeasureClarification')}
        label={
          <div className={flex.column}>
            {translate('sgw.phases.general.trafficMeasureClarification')}
            <small>{translate('sgw.phases.general.trafficMeasureExtraInfo')}</small>
          </div>
        }
        TextAreaProps={register('trafficMeasureClarification')}
        meta={{
          error: !!errors.trafficMeasureClarification,
          touched: !!touchedFields.trafficMeasureClarification || !!errors.trafficMeasureClarification,
        }}
      />
    </div>
  );
};
