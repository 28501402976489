import React, { FC } from 'react';
import { Visible } from '../../common/layout/Visible.component';
import { TextInput } from '../../atoms';
import { validateSSN } from '../../../common/validation/validateSSN';
import { useFormContext } from 'react-hook-form';
import { IPerson } from '../../../types';
import { translate } from '../../../translations/translate';

interface IProps {
  showSsn: boolean;
}

export const PersonalFormFields: FC<IProps> = ({ showSsn }) => {
  const { register } = useFormContext<IPerson>();

  return (
    <>
      <Visible visible={showSsn}>
        <div className="row">
          <TextInput
            className="col-xs-6"
            {...register('ssn', { required: showSsn, validate: validateSSN(showSsn) })}
            label={translate('personalform.ssn')}
            description={translate('fields.withoutspaces')}
            required={showSsn}
            validate={validateSSN(showSsn)}
          />
        </div>
      </Visible>
      <div className="row">
        <TextInput
          className="col-xs-6"
          {...register('firstName', { required: true })}
          label={translate('personalform.firstname')}
          required
        />
        <TextInput
          className="col-xs-6"
          {...register('lastName', { required: true })}
          label={translate('personalform.lastname')}
          required
        />
      </div>
    </>
  );
};
