import {
  ICountry,
  IPublicDomainIntake,
  IPublicDomainIntakeType,
  IRequest,
  IRequestLicencePlate,
  CarFreeZoneType,
} from '../../../types';
import React, { FC, useCallback, useEffect } from 'react';
import { pincodeFormatter } from '../../../common/utils/requestUtils';
import * as A from 'antwerp-core-react-branding';
import { OverviewItem, OverviewList, OverviewSubtitle } from './overviewElements';
import { intakeToAddressString } from '../../../common/utils/geometry.util';
import { Visible } from '../layout/Visible.component';
import { PublicDomainType } from '../../../common/enums';
import { convertStringToTimestamp, formatToDisplayDate } from '../../../common/utils/dateUtils';
import moment from 'moment';
import { createPublicDomainIntakeLicencePlatePatch } from '../../../common/actions/creators/publicDomainIntake';
import { useDispatch, useSelector } from 'react-redux';
import { translate, translateIgnoreTS } from '../../../translations/translate';
import { getGateById } from '../../../store/selectors/gates.selectors';
import { selectCarFreeZones } from '../../../store/selectors';
import { CarFreeZoneActions } from '../../../store/actions/carFreeZone.actions';
import { LicencePlateField } from '../../organisms';
import { APP_DEFAULT_DATE_FORMAT } from '../../../common/constants';

interface IProps {
  index: number;
  publicDomainIntake: IPublicDomainIntake;
  publicDomainIntakeTypes: IPublicDomainIntakeType[];
  request: IRequest;
  countries: ICountry[];
  isLicensePlateEnabled: boolean;
}

export const LocationItem: FC<IProps> = ({
  index,
  request,
  publicDomainIntake,
  publicDomainIntakeTypes,
  countries,
  isLicensePlateEnabled,
}) => {
  const carFreeZones = useSelector(selectCarFreeZones);
  const dispatch = useDispatch();
  const entrance = useSelector(getGateById(publicDomainIntake.carFreeZoneGateEntrance));
  const exit = useSelector(getGateById(publicDomainIntake.carFreeZoneGateExit));

  useEffect(() => {
    if (!carFreeZones) {
      dispatch(CarFreeZoneActions.get());
    }
  }, [carFreeZones, dispatch]);

  const licencePlateStartDate = useCallback((): string => {
    const currentDate = new Date().getTime();
    const startDate = convertStringToTimestamp(request.dateFrom);

    // If the request is ongoing, the startdate of the plates
    // is today minus two dates if the difference is greather than two
    if (moment(currentDate).diff(moment(startDate), 'd') > 2) {
      return moment().add(-2, 'd').format(APP_DEFAULT_DATE_FORMAT);
    } else {
      // Else it is the startdate of the request
      return request.dateFrom;
    }
  }, [request.dateFrom]);

  const onChangeParkedPlates = (permittedPlates: IRequestLicencePlate[], intake: IPublicDomainIntake) => {
    dispatch(createPublicDomainIntakeLicencePlatePatch(request, { ...intake, permittedPlates }));
  };

  const getIntakeTypeName = (intake: IPublicDomainIntake): string => {
    if (typeof intake.type === 'string') {
      return publicDomainIntakeTypes.filter((x) => x.type === (intake.type as any))[0].name;
    } else {
      return intake.type.name;
    }
  };

  return (
    //@ts-ignore
    <A.Spacing type={A.SpacingStyle.MarginTop} key={index}>
      <OverviewSubtitle title={`Locatie ${index + 1}`} />
      <OverviewList>
        <OverviewItem title={translate('paymentform.type')} description={getIntakeTypeName(publicDomainIntake)} />
        <OverviewItem
          title={translate('paymentform.address')}
          description={intakeToAddressString(publicDomainIntake, carFreeZones)}
        />
        <Visible visible={publicDomainIntake.type.type === PublicDomainType.CarfreeZone}>
          <OverviewItem
            title={translate('locationsform.allowedparkingplates')}
            description={translate('myRequests.details.licensePlateValidFromTo', {
              from: formatToDisplayDate(request.dateFrom),
              to: formatToDisplayDate(request.dateUntil),
            })}
          />
        </Visible>
        {!!request.pincode && publicDomainIntake.type.type === PublicDomainType.CarfreeZone && (
          <OverviewItem
            title={translate('myRequests.details.pincode.title')}
            description={translate('myRequests.details.pincode.description', {
              value: pincodeFormatter(request.pincode, publicDomainIntake.carFreeZone),
              from: formatToDisplayDate(request.pincode.dateFrom),
              to: formatToDisplayDate(request.pincode.dateUntil),
            })}
          />
        )}
        {entrance?.name && <OverviewItem title={translate('paymentform.entrance')} description={entrance.name} />}
        {exit?.name && <OverviewItem title={translate('paymentform.exit')} description={exit.name} />}
      </OverviewList>
      <Visible visible={isLicensePlateEnabled}>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginTop}>
          {/*@ts-ignore*/}
          <A.Paragraph type={A.ParagraphStyle.Alert} level={A.Levels.Warning}>
            {publicDomainIntake.carFreeZone?.type === CarFreeZoneType.krautli ||
            publicDomainIntake.carFreeZone?.type === CarFreeZoneType.anpr
              ? translateIgnoreTS(`myRequests.details.licensePlateWarning.${publicDomainIntake.carFreeZone.type}`)
              : translate('myRequests.details.licensePlateWarning.general')}
          </A.Paragraph>
        </A.Spacing>
      </Visible>
      <Visible visible={publicDomainIntake.type.type === PublicDomainType.CarfreeZone}>
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginTop}>
          <LicencePlateField
            countries={countries}
            disabled={!isLicensePlateEnabled}
            label={translate('locationsform.parkedplates')}
            onChangeLicensePlates={(plates: IRequestLicencePlate[]) => onChangeParkedPlates(plates, publicDomainIntake)}
            startDate={licencePlateStartDate()}
            requestLicensePlates={publicDomainIntake.permittedPlates || []}
            className="focusable"
          />
        </A.Spacing>
      </Visible>
    </A.Spacing>
  );
};
