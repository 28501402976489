import moment from 'moment';

import { StateLabel } from '../../components';
import { translateIgnoreTS } from '../../translations/translate';
import {
  CarFreeZoneType,
  IPincode,
  IPublicDomainIntake,
  IRequest,
  IRequestBE,
  IRequestState,
  ISgwRequest,
  MessageState,
  TCarFreeZone,
} from '../../types';
import { appUrls, WORKFLOW_MIN_STEP, WORKFLOW_STATE_NEW, WORKFLOW_STATE_UNPAID } from '../constants';
import { ApplicantType, PublicDomainType } from '../enums';
import { convertStringToTimestamp, formatToDisplayDate } from './dateUtils';
import { intakeToAddressString } from './geometry.util';

export function isRequestId(id: string) {
  if (id) {
    return /^[1-9]\d*$/.test(id);
  }
  return false;
}

export function cleanRequestForApi(request: IRequest, carFreeZones: TCarFreeZone[]): IRequest {
  // Clean data for saving
  const cleanedRequest = { ...request };

  // Partial requests can also be saved
  if (cleanedRequest.publicDomainIntakes) {
    // Fill in streetNumberTo if not filled in
    cleanedRequest.publicDomainIntakes.forEach((x, i) => {
      if (!x.streetNumberFrom) {
        x.streetNumberFrom = '0';
      }

      if (!x.streetNumberTo) {
        x.streetNumberTo = x.streetNumberFrom;
      }

      const zone = getCarFreeZoneByGisId(carFreeZones, x.gisId);
      if (zone) {
        // @ts-ignore // for API we expect carFreeZone parameter to be a number, not the carFreeZone
        x.carFreeZone = zone.id;
      }
    });

    // Convert the types to strings, normally those are returned as objects
    if (cleanedRequest.publicDomainIntakes) {
      cleanedRequest.publicDomainIntakes = request.publicDomainIntakes
        .filter((x) => x.valid)
        .map((x) => ({ ...x, type: x.type.type } as any));
    }
  }

  // Delete unwanted priorities
  // @ts-ignore
  delete cleanedRequest.priority;
  delete cleanedRequest.applicantType;

  if (request) {
    // AS-3819 Error message when making a request for a parking ban as a FO-user
    // @TODO Sync BE and FO requests/responses
    if (request.reason) {
      // @ts-ignore
      cleanedRequest.reason = request.reason.reason;
    }
    if (request.state) {
      // @ts-ignore
      cleanedRequest.state = request.state.state;
    }
  }
  return cleanedRequest;
}

export function cleanRequestForCostCalculationApi(request: IRequest, carFreeZones: TCarFreeZone[]): IRequest {
  const cleanedRequest = cleanRequestForApi(request, carFreeZones);
  cleanedRequest.publicDomainIntakes = cleanedRequest.publicDomainIntakes?.map(({ attachments, ...pbi }) => pbi);

  return cleanedRequest;
}

export const alignCarFreeZoneIds = (request: IRequest, carFreeZones: TCarFreeZone[]): IRequest => ({
  ...request,
  applicantType: request.companyId ? ApplicantType.Company : ApplicantType.Citizen,
  publicDomainIntakes: request.publicDomainIntakes.map((intake, index) => {
    const zone = getCarFreeZoneByGisId(carFreeZones, intake.gisId);
    return {
      ...intake,
      carFreeZoneId: zone ? zone.id : undefined, // need to save carFreeZoneId as separate number, so it can be used as a form field in locationsFormItem.component.tsx
      index,
      valid: true,
    };
  }),
});

export const convertBackendRequest = (request: IRequestBE): IRequest => ({
  ...request,
  publicDomainIntakes: request.publicDomainIntakes.map((intake) => {
    return {
      ...intake,
      carFreeZoneGateEntrance: intake.carFreeZoneGateEntrance?.id,
      carFreeZoneGateExit: intake.carFreeZoneGateExit?.id,
    };
  }),
});

export const cleanRequestForApp = (request: IRequestBE, carFreeZones: TCarFreeZone[]): IRequest =>
  alignCarFreeZoneIds(convertBackendRequest(request), carFreeZones);

export function getRequestDetailsPath(request: IRequest): string {
  return appUrls.myRequests.detail.replace(':id', `${request.id}`);
}

export function getRequestExtensionPath(request: IRequest): string {
  return appUrls.myRequests.extension.replace(':id', `${request.id}`);
}

export function getWorkflowInitialPath(request?: IRequest): string {
  return `${request?.id ? request.id : WORKFLOW_STATE_NEW}/${WORKFLOW_MIN_STEP}`;
}

export const getSgwRequestDetailsPath = (request: ISgwRequest) => appUrls.myRequests.sgwDetail(`${request.id}`);
export const getSgwWorkflowInitialPath = (request?: ISgwRequest) =>
  `${appUrls.sgw.request.base}${appUrls.sgw.request.edit(`${WORKFLOW_MIN_STEP}`, `${request?.id}`)}`;

export function requestHasCarFreeZonesWithoutPlates(request?: IRequest): boolean {
  if (request && request.publicDomainIntakes) {
    const intakes = request.publicDomainIntakes.filter(
      (x: IPublicDomainIntake) => x.type.type === PublicDomainType.CarfreeZone,
    );

    return (
      intakes.length > 0 &&
      intakes.filter((x: IPublicDomainIntake) => !x.permittedPlates || x.permittedPlates.length === 0).length === 0
    );
  }
  return false;
}

export function calculateUntilDate(request?: IRequest): string {
  if (!request) return '';
  let untilDate = request.dateUntil;
  if (requestHasExtensions(request)) {
    untilDate = moment(
      request
        .extensions!.filter((x) => x.state!.state !== WORKFLOW_STATE_UNPAID)
        .map((x) => convertStringToTimestamp(x.dateUntil))
        .reduce((p, c) => (c > p ? c : p), convertStringToTimestamp(request.dateUntil)),
    ).format('YYYY-MM-DD');
  }
  return formatToDisplayDate(untilDate);
}

export function requestHasExtensions(request: IRequest): boolean {
  if (request.extensions && request.extensions!.filter((x) => x.state!.state !== WORKFLOW_STATE_UNPAID).length > 0) {
    return true;
  }
  return false;
}

export function requestContainsOnlyWeekendDays(request: IRequest): boolean {
  if (request.dateFrom && request.dateUntil) {
    let dateFrom = moment(convertStringToTimestamp(request.dateFrom)).startOf('day');
    const dateUntil = moment(convertStringToTimestamp(request.dateUntil));

    while (dateFrom.diff(dateUntil, 'd') <= 0) {
      if (dateFrom.isoWeekday() < 6) {
        return false;
      }
      dateFrom = dateFrom.add(1, 'd');
    }
    return true;
  }
  return false;
}

export function periodFormatter(request: IRequest): string {
  const dateFromFormatted = formatToDisplayDate(request.dateFrom);
  const dateuntilFormatted = calculateUntilDate(request);
  if (dateFromFormatted === dateuntilFormatted) {
    return dateFromFormatted;
  }
  return dateFromFormatted + ' / ' + dateuntilFormatted;
}

export function stateFormatter(state: IRequestState): JSX.Element | string {
  if (state && state.name) {
    return <StateLabel {...state} small />;
  }
  return '';
}

export const locationFormatter =
  (zones: TCarFreeZone[]) =>
  (intakes: IPublicDomainIntake[]): string => {
    if (intakes && intakes.length > 0) {
      return intakeToAddressString(intakes[0], zones);
    }
    return '';
  };

export const getCarFreeZoneByGisId = (carFreeZones: TCarFreeZone[] = [], gisId?: string): TCarFreeZone | undefined =>
  carFreeZones.find((zone) => zone.gisId === gisId);

export const pincodeFormatter = ({ value }: IPincode, zone?: TCarFreeZone): string =>
  zone?.type === CarFreeZoneType.krautli ? `C${value}E` : `${value}#`;

export function getRequestState(request: IRequest): IRequestState {
  // AS-4545 Add virtual status to request
  const state = { ...request.state } as IRequestState;
  if (state && request.messageState && request.messageState !== MessageState.NotInFeedbackLoop) {
    state.name = translateIgnoreTS('myRequests.messageState.' + request.messageState);
    state.state = request.messageState;
  }
  return state;
}
