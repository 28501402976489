import * as React from 'react';
import intl from 'react-intl-universal';
import * as A from 'antwerp-core-react-branding';
import classNames from 'classnames';
import moment from 'moment';
import { PAYMENT_STATE_NEW, PAYMENT_STATE_PARTIALLY_PAID } from '../../../common/constants';
import { formatPrice } from '../../../common/utils/priceUtils';
import { IRequestPayment, ITenant } from '../../../types';
import { OverviewItem, OverviewList, OverviewSection, OverviewSubtitle } from './overviewElements';
import { PaymentButton } from './PaymentButton.component';

export type PaymentInfoProperties = {
  payments: IRequestPayment[];
  onPay?: (payment: IRequestPayment) => void;
  tenant: ITenant;
};

/**
 * React Component PaymentInfo
 */
export default class PaymentInfo extends React.Component<PaymentInfoProperties, {}> {
  public render(): any {
    const { payments } = this.props;
    if (payments && payments.length > 0) {
      return <OverviewSection>{this.renderPayments(payments || [])}</OverviewSection>;
    }
    return null!;
  }

  private renderPayments(payments: IRequestPayment[] & { index?: number }, isExtension?: boolean): JSX.Element[] {
    return payments.map((x, i) => (
      //@ts-ignore
      <A.Spacing type={A.SpacingStyle.MarginTop} key={x.id}>
        <OverviewSubtitle
          title={
            <>
              {intl.get('paymentform.paymentsubtitle', {
                extension: isExtension ? intl.get('general.extension') : '',
                i: i + 1,
                state: x.state.name!,
              })}{' '}
              {this.renderPaymentWarning(x)}{' '}
            </>
          }
        />
        <OverviewList>
          <OverviewItem title={intl.get('paymentform.paymentlabel')} description={this.formattedPaymentType(x)} />
          {x.ogm && [
            <OverviewItem
              key="beneficiary"
              title={intl.get('general.bankaccount')}
              description={this.props.tenant.bankTransferAccount}
            />,
            <OverviewItem
              key="beneficiaryaccount"
              title={intl.get('general.banktransferbeneficiary')}
              description={this.props.tenant.bankTransferBeneficiary}
            />,
            <OverviewItem key="ogm" title={intl.get('general.ogm')} description={x.ogm} />,
          ]}
          <OverviewItem title={intl.get('general.tobepaid')} description={formatPrice(x.amountToBePaid)} />
          <OverviewItem title={intl.get('general.nonpayed')} description={this.renderNonPayedOverviewItem(x)} />
          {(x.canPay || x.state.state === 'new') && x.lastPaymentDate && (
            <OverviewItem
              title={intl.get('general.lastPaymentDate')}
              description={moment.utc(x.lastPaymentDate).local().format('DD/MM/Y HH:mm:ss')}
            />
          )}
        </OverviewList>
        <PaymentButton payment={x} onPay={this.props.onPay} />
      </A.Spacing>
    ));
  }

  private renderNonPayedOverviewItem(payment: IRequestPayment): JSX.Element {
    const nonPayed = payment.amountToBePaid - payment.amountPaid;
    return <span className={classNames({ 'u-text-danger': nonPayed > 0 })}>{formatPrice(nonPayed)}</span>;
  }

  private formattedPaymentType(payment: IRequestPayment): string {
    if (payment && payment.type) {
      return intl.get(`paymenttype.${payment.type.type.toLowerCase()}`);
    } else {
      return intl.get('paymenttype.online');
    }
  }

  private renderPaymentWarning(payment: IRequestPayment): JSX.Element {
    if (payment.state.state === PAYMENT_STATE_NEW || payment.state.state === PAYMENT_STATE_PARTIALLY_PAID) {
      return <A.Icon name={'exclamation-circle'} style={{ fontSize: 25, paddingLeft: 10 }} />;
    }
    return null!;
  }
}
