import * as A from 'antwerp-core-react-branding';
import * as React from 'react';
import { FC } from 'react';
import { OverviewEditLink, OverviewSection } from './overviewElements';
import { OverviewProperties } from './overViewType';
import { LocationInfoItem } from './LocationInfoItem.component';
import { translate } from '../../../translations/translate';

export const LocationInfo: FC<OverviewProperties> = (props) => {
  const { request } = props;
  return (
    <OverviewSection>
      {/*@ts-ignore*/}
      <A.Spacing type={A.SpacingStyle.NoMargin} className="row between-md">
        <h6>{translate('paymentform.locations')}</h6>
        <OverviewEditLink {...props} />
      </A.Spacing>
      {request?.publicDomainIntakes?.map((intake, i) => (
        <LocationInfoItem showSubtitle={request.publicDomainIntakes.length > 1} index={i} intake={intake} />
      ))}
    </OverviewSection>
  );
};
