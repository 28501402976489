import React, { FC } from 'react';
import { IWorkflowButtonProps, WorkflowButtonOrientations } from '../../../types';
import * as A from 'antwerp-core-react-branding';
import { Visible } from '../../common/layout/Visible.component';
import { IconButton } from '../IconButton/IconButton.component';
import { ButtonVariant } from '../../../types/button.types';

export const WorkflowButton: FC<IWorkflowButtonProps> = ({
  disabled,
  label,
  onClick,
  orientation = WorkflowButtonOrientations.left,
  visible = true,
  ...buttonProps
}) => (
  <Visible visible={visible}>
    <IconButton
      disabled={disabled}
      icon={`chevron-${orientation}`}
      level={A.Levels.Primary}
      location={orientation === WorkflowButtonOrientations.left ? A.Locations.Left : A.Locations.Right}
      onClick={onClick}
      type={orientation === WorkflowButtonOrientations.right && !onClick ? 'submit' : 'button'}
      variant={orientation === WorkflowButtonOrientations.left ? ButtonVariant.Negative : ButtonVariant.Default}
      {...buttonProps}
    >
      {label}
    </IconButton>
  </Visible>
);
