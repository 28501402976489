import { Actions } from './generic.actions';
import { IApplicationFeatures, IProfile, IRequest, ITokenResponse } from '../../types';
import { AxiosRequestConfig } from 'axios';

class ApplicationActionsClass extends Actions {
  applicationFeatures = this.createPayloadAction<IApplicationFeatures>('applicationFeatures');
  authenticated = this.createAction('authenticated');
  init = this.createAction('init');
  login = this.createAction('login');
  logout = this.createAction('logout');
  profileReceived = this.createPayloadAction<IProfile>('profileReceived');
  requestNextStep = this.createPayloadAction<Partial<IRequest>>('requestNextStep');
  refreshToken = this.createPayloadAction<AxiosRequestConfig>('refreshToken');
  tokenReceived = this.createPayloadAction<ITokenResponse>('tokenReceived');
}

export const ApplicationActions = new ApplicationActionsClass('app');
