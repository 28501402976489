import { call, Effect, put, takeLatest } from 'redux-saga/effects';

import {
  createCountriesReceivedAction,
  createDataLoadedAction,
  createMetadataReceivedAction,
  createPDITypesReceivedAction,
  createRequestReasonsReceivedAction,
  createTenantReceivedAction,
} from '../../common/actions/creators/data';
import { createDraftsReceivedAction } from '../../common/actions/creators/requests';
import { getMetadata, getMyRequests } from '../../common/api';
import { ICall } from '../../types';
import { SagaIterator } from 'redux-saga';
import { ApplicationActions } from '../actions/workflow.actions';

function* fetchData(): IterableIterator<Effect> {
  try {
    const metadata: ICall<typeof getMetadata> = yield call(getMetadata);
    yield put(createMetadataReceivedAction(metadata!));
    yield put(createRequestReasonsReceivedAction(metadata!.reasons));
    yield put(createTenantReceivedAction(metadata!.tenant));
    yield put(createPDITypesReceivedAction(metadata!.types));
    yield put(createCountriesReceivedAction(metadata!.countries));

    const drafts: ICall<typeof getMyRequests> = yield call(getMyRequests, 'desc', 'id');
    yield put(createDraftsReceivedAction(drafts!));
  } catch (e) {
    /* Show some alerts */
  } finally {
    yield put(createDataLoadedAction());
  }
}

export default function* dataSaga(): SagaIterator {
  yield takeLatest(ApplicationActions.authenticated, fetchData);
}
