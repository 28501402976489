import './WaitText.component.scss';
import React, { FC } from 'react';
import intl from 'react-intl-universal';
import * as A from 'antwerp-core-react-branding';
import { WaitAnim } from './WaitAnim.component';

export const WaitText: FC = () => (
  // @ts-ignore
  <A.Paragraph className="waittext">
    <WaitAnim />
    {` ${intl.get('general.appisloading')}`}
  </A.Paragraph>
);
