import { takeLatest } from 'redux-saga/effects';

import * as T from '../../common/actions/types';
import { scrollToTop } from '../../common/utils/windowUtils';
import { SagaIterator } from 'redux-saga';

function toTop() {
  setTimeout(() => scrollToTop(), 0);
}

export default function* layoutSaga(): SagaIterator {
  yield takeLatest(
    [T.BUSYSTATE_CHANGED, T.DATA_LOADED, T.NEXT_STEP_GRANTED, T.PREVIOUS_STEP_GRANTED, T.STEP_GRANTED],
    toTop,
  );
}
