import React, { FC } from 'react';
import { Visible } from '../../common/layout/Visible.component';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

export interface IFieldsetProps extends React.FieldsetHTMLAttributes<HTMLFieldSetElement> {
  legend?: React.ReactNode;
  noBottomBorder?: boolean;
}

const useStyles = createUseStyles({
  fieldset: {
    borderBottom: '1px solid #b0b0b0',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: '1px solid #b0b0b0',
    margin: 0,
    minWidth: 0,
    padding: '3rem 0',
    position: 'relative',
  },
  legend: {
    background: '#d8d8d8',
    color: 'black',
    display: 'block',
    fontWeight: 600,
    padding: '0.25rem 0.5rem',
    position: 'absolute',
    top: 0,
  },
  noBottomBorder: {
    borderBottom: 'none',
  },
});

export const Fieldset: FC<IFieldsetProps> = ({ children, className, legend, noBottomBorder = false, ...props }) => {
  const C = useStyles();

  return (
    <fieldset className={classNames(C.fieldset, { [`${C.noBottomBorder}`]: noBottomBorder }, className)} {...props}>
      <Visible visible={!!legend}>
        <legend className={C.legend}>{legend}</legend>
      </Visible>
      {children}
    </fieldset>
  );
};
