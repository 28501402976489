import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from '@mui/material';
import { ITokenResponse } from '../../../types';
import { useDispatch } from 'react-redux';
import { ApplicationActions } from '../../../store/actions/workflow.actions';

const useStyles = createUseStyles({
  root: {
    margin: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  input: {
    width: '50%',
  },
  button: {
    width: '100px',
  },
});

export const AlternativeLoginPage = () => {
  const C = useStyles();
  const [tokenInput, setTokenInput] = useState('');
  const dispatch = useDispatch();

  const onSubmit = () => {
    const tokenResponse: ITokenResponse = JSON.parse(tokenInput);
    dispatch(ApplicationActions.tokenReceived(tokenResponse));
  };

  return (
    <div className={C.root}>
      Digipolis login is unavailable on staging, copy-paste JSON response from the GET endpoint
      <a href="https://rombit-studio-a-team.postman.co/workspace/%25F0%259F%2585%25B0%25EF%25B8%258F-ASign-backend~fcf31dff-b855-43f5-a79e-7fa3d622b9b8/request/15425049-bb0bede7-9fb6-4754-b94e-b7beb63ca0cf">
        Postman
      </a>
      <input className={C.input} value={tokenInput} onChange={(e) => setTokenInput(e.target.value)} />
      <Button variant="outlined" className={C.button} onClick={onSubmit}>
        Login
      </Button>
    </div>
  );
};
