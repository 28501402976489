import React, { ReactNode } from 'react';
import intl from 'react-intl-universal';

interface IState {
  hasError: boolean;
}

interface IProps {
  children: ReactNode;
}

export class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.error(error, errorInfo);
  }

  render() {
    return this.state.hasError ? (
      <>
        <h1>{intl.get('error.boundary.title')}</h1>
        <div>
          <span>{intl.get('error.boundary.message')}</span>
        </div>
      </>
    ) : (
      this.props.children
    );
  }
}
