import { OverviewList } from '../../atoms';
import { SgwWorkflowStep, TRouterWithId } from '../../../types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FC, useEffect } from 'react';
import { getRecurringPhaseTemplateIds, getRequestPartnersId, getStandardPhaseIds } from '../../../store/selectors/sgw';
import { PhaseActions } from '../../../store/actions/phase.actions';
import { PhaseInfo } from '../PhaseInfo/PhaseInfo.component';
import { translate } from '../../../translations/translate';
import { SpinnerLoader } from '../../atoms/SpinnerLoader/SpinnerLoader.component';

export const PhasesInfo: FC = () => {
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const dispatch = useDispatch();
  const standardPhases = useSelector(getStandardPhaseIds(requestId!));
  const recurringPhaseTemplates = useSelector(getRecurringPhaseTemplateIds(requestId!));

  useEffect(() => {
    requestId && dispatch(PhaseActions.fetchList(requestId));
  }, [dispatch, requestId]);

  const partnerIds = useSelector(getRequestPartnersId(requestId!));

  return (
    <SpinnerLoader loading={!partnerIds}>
      <OverviewList>
        <OverviewList.Title step={SgwWorkflowStep.Phases}>{translate('sgw.overview.phases.title')}</OverviewList.Title>
        {standardPhases.map((phase, index) => (
          <PhaseInfo index={index + 1} id={phase} key={phase} />
        ))}
        {recurringPhaseTemplates.map((phase, index) => (
          <PhaseInfo index={index + 1} id={phase} key={phase} />
        ))}
      </OverviewList>
    </SpinnerLoader>
  );
};
