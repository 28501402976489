import { FC, useMemo, useState } from 'react';
import { EditControl } from 'react-leaflet-draw';
import { IGeometry, MapDrawTypes } from '../../../types';
import { setLeafletDrawTranslations } from '../../../utils';
import { ControlPosition, LatLng } from 'leaflet';
import { FeatureGroup, useMap, useMapEvent } from 'react-leaflet';
import { getDistanceBetweenLatLng } from '../../../utils';

interface IProps {
  onCreated?(geometry: IGeometry): void;
  position?: ControlPosition;
  startDrawing?(): void;
}

const defaultDrawTypes: MapDrawTypes = {
  circle: false,
  circlemarker: false,
  marker: false,
  polygon: true,
  polyline: true,
  rectangle: false,
};

const defaultEditMode = {
  edit: false,
  remove: false,
};

export const MapDrawOverlay: FC<IProps> = ({ onCreated, position = 'topleft', startDrawing }) => {
  const map = useMap();
  const [lastPoint, setLastPoint] = useState<LatLng>();
  const [mousePoint, setMousePoint] = useState<LatLng>();
  const currentDistance = useMemo(() => getDistanceBetweenLatLng(lastPoint, mousePoint), [lastPoint, mousePoint]);

  useMapEvent('mousemove', (e) => {
    setMousePoint(map.mouseEventToLatLng(e.originalEvent));
  });

  useMapEvent('click', (e) => {
    setLastPoint(map.mouseEventToLatLng(e.originalEvent));
  });

  setLeafletDrawTranslations(currentDistance);

  const _onCreated = (event: any) => {
    const geometry: IGeometry = event?.layer.toGeoJSON().geometry;
    onCreated?.(geometry);
    event.layer.remove();
  };

  const _onDrawStart = () => startDrawing?.();

  return (
    <FeatureGroup>
      <EditControl
        position={position}
        onCreated={_onCreated}
        onDrawStart={_onDrawStart}
        draw={defaultDrawTypes}
        // @ts-ignore
        edit={defaultEditMode}
      />
    </FeatureGroup>
  );
};
