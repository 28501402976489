import * as React from 'react';
import intl from 'react-intl-universal';

import * as A from 'antwerp-core-react-branding';

import { FC, useCallback } from 'react';
import { IControllableForm } from '../../../common/form';
import { RadiobuttonsProperties } from 'antwerp-core-react-branding/dist/typings/lib/atoms/form/radiobuttons';
import { useController, useFormContext } from 'react-hook-form';
import { Required } from '../../common/forms/Required.component';

interface IProps extends IControllableForm, RadiobuttonsProperties {
  value?: string;
  input?: any;
  extensiveLabel?: string | JSX.Element;
}

export const RadioButtonsControlled: FC<IProps> = ({ input, validate, name, ...props }) => {
  const { control, trigger, formState } = useFormContext();
  const { errors } = formState;
  const { field } = useController({
    name,
    control,
    rules: { required: intl.get('fields.labelRequired', { label: props.label }), validate },
  });

  const _onChange = useCallback(
    (event: any) => {
      field.onChange?.(event);
      trigger(name);
    },
    [field, name, trigger],
  );

  return (
    <>
      {/*@ts-ignore*/}
      <A.Radiobuttons
        {...field}
        {...props}
        {...input}
        onChange={_onChange}
        label={props.extensiveLabel || props.label}
      />
      <Required required={!!errors[name]} message={intl.get('fields.labelRequired', { label: props.label })} />
    </>
  );
};
