import IState from '../../state';
import { createSelector } from '@reduxjs/toolkit';
import { getCompletePhase } from './sgw';

export const selectFeatures = ({ metadata }: IState) => metadata?.features;
export const selectSettings = ({ metadata }: IState) => metadata?.sgw?.settings;
export const selectSgwMinDateFrom = ({ metadata }: IState) => metadata?.sgw?.settings.requestMinDate;
export const selectSgwStates = ({ metadata }: IState) => metadata?.sgw?.states;

export const selectNatureOfWorks = ({ metadata }: IState) => metadata?.sgw?.natureofworks || [];
export const selectTrafficMeasures = ({ metadata }: IState) => metadata?.sgw?.trafficmeasures || [];

export const getNatureOfWorks = createSelector(
  [selectNatureOfWorks],
  (natureOfWorks) => natureOfWorks?.filter(({ deleted }) => !deleted) || [],
);

export const getTrafficMeasures = createSelector(
  [selectTrafficMeasures],
  (trafficMeasures) => trafficMeasures?.filter(({ deleted }) => !deleted) || [],
);

export const getTrafficMeasuresOptions = createSelector(
  [selectTrafficMeasures],
  (trafficMeasures) =>
    trafficMeasures
      ?.filter(({ deleted }) => !deleted)
      .map((trafficMeasure) => ({ label: trafficMeasure.name, value: `${trafficMeasure.id!}` })) || [],
);

export const getTrafficMeasuresByIds = (ids: string[]) =>
  createSelector(
    [getTrafficMeasures],
    (trafficMeasures) => trafficMeasures?.filter(({ id }) => id && ids?.includes(`${id}`)) || [],
  );

export const getDistinctTrafficMeasuresForPhase = (id: string) =>
  createSelector([selectTrafficMeasures, getCompletePhase(id)], (trafficMeasures, phase) =>
    phase?.sgwGeoDrawings
      .flatMap((geodrawing) => geodrawing.sgwTrafficMeasures)
      .filter((value, index, self) => self.indexOf(value) === index && trafficMeasures.some(({ id }) => id === value))
      .map((tmId) => trafficMeasures.find(({ id }) => id === tmId)),
  );

export const getNatureOfWorksByIds = (ids: string[]) =>
  createSelector(
    [selectNatureOfWorks],
    (natureOfWorks) => natureOfWorks?.filter(({ id }) => id && ids?.includes(`${id}`)) || [],
  );
