export const privacyStatementHtml = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
\t{font-family:Wingdings;
\tpanose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
\t{font-family:"Cambria Math";
\tpanose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
\t{font-family:Calibri;
\tpanose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
\t{font-family:Tahoma;
\tpanose-1:2 11 6 4 3 5 4 4 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
\t{margin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:10.0pt;
\tmargin-left:0in;
\tline-height:115%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
\t{color:blue;
\ttext-decoration:underline;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
\t{margin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:10.0pt;
\tmargin-left:.5in;
\tline-height:115%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
\t{margin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:0in;
\tmargin-left:.5in;
\tline-height:115%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
\t{margin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:0in;
\tmargin-left:.5in;
\tline-height:115%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
\t{margin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:10.0pt;
\tmargin-left:.5in;
\tline-height:115%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
.MsoChpDefault
\t{font-family:"Calibri",sans-serif;}
.MsoPapDefault
\t{margin-bottom:10.0pt;
\tline-height:115%;}
@page WordSection1
\t{size:595.3pt 841.9pt;
\tmargin:70.85pt 70.85pt 70.85pt 70.85pt;}
div.WordSection1
\t{page:WordSection1;}
 /* List Definitions */
 ol
\t{margin-bottom:0in;}
ul
\t{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link=blue vlink=purple style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal style='text-align:justify;line-height:15.95pt'><b><span
lang=NL-BE>PRIVACYVERKLARING PARKEERVERBOD.ANTWERPEN.BE</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:15.95pt'><b><span
lang=NL-BE>HOE VERWERKEN WIJ UW PERSOONSGEGEVENS?</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
lang=NL-BE>Uw persoonsgegevens worden door de stad Antwerpen als
verwerkingsverantwoordelijke en het AG Mobiliteit en Parkeren Antwerpen (MPA)
als verwerker, Grote Markt 1, 2000 Antwerpen, <a
href="mailto:tijdelijke.verkeerssignalisatie@antwerpen.be">tijdelijke.verkeerssignalisatie@antwerpen.be</a>
verwerkt.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
lang=NL-BE>Uw gegevens zullen uitsluitend worden gebruikt voor het doel
waarvoor ze ontvangen en/of meegedeeld werden, met name het bekomen van één of
meerdere van volgende vergunningen in het kader van de inname van het openbaar
domein en de handhaving op de uitgereikte vergunning door de Lokale Politie
Antwerpen:</span></p>

<p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-.25in;
line-height:15.95pt'><span lang=NL-BE style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=NL-BE>Vergunning tijdelijk parkeerverbod</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in;
line-height:15.95pt'><span lang=NL-BE style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=NL-BE>Vergunning inname openbaar domein bij bouwwerken</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-.25in;
line-height:15.95pt'><span lang=NL-BE style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=NL-BE>Vergunning werken met beperkte signalisatie</span></p>

<p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-.25in;
line-height:15.95pt'><span lang=NL-BE style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=NL-BE>Vergunning toegang tot autovrije zones,
desgevallend uitgerust met ANPR-controle en/of verdwijnpalen</span></p>

<p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
lang=NL-BE>De verwerking is noodzakelijk om te voldoen aan een wettelijke
verplichting die op de stad Antwerpen rust ingevolge artikel 135, §2 Nieuwe
Gemeentewet, met name het voorzien in een goede politie, over de zindelijkheid,
de gezondheid, de veiligheid en de rust op openbare wegen en, meer in het
algemeen, het beheer (met inbegrip van de innames) van het openbaar domein
zoals voorzien in de Politiecodex. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
lang=NL-BE>Voor de verwerking van persoonsgegevens werken we samen met IT-partners.
Die ondersteunen ons als verwerker bij het verwerken van je persoonsgegevens.
Ze handelen daarbij altijd volgens de richtlijnen van de stad Antwerpen. Met
alle verwerkers worden strikte schriftelijke afspraken gemaakt met betrekking
tot de verwerking van persoonsgegevens. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
lang=NL-BE>Naast de persoonsgegevens die door de aanvrager van de vergunning
desgevallend bij de aanvraag zelf worden verstrekt, worden per type van vergunning
in elk geval de hierna volgende persoonsgegevens verwerkt in het kader van de
hierboven genoemde doelstelling: </span></p>

<p class=MsoNormal style='text-align:justify;line-height:15.95pt'><u><span
lang=NL-BE>Vergunning tijdelijk parkeerverbod</span></u></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  background:#D9D9D9;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:15.95pt'><b><span
  lang=NL-BE>Persoonsgegeven</span></b></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-left:none;background:#D9D9D9;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:15.95pt'><b><span
  lang=NL-BE style='color:black'>Concreet doeleinde van de verwerking</span></b></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Naam, voornaam, adres en rijksregisternummer en/of
  ondernemingsnummer van de aanvrager</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Identificatie van de aanvrager en controle op het rechtmatig
  gebruik van de vergunning.</span></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Locatie- en tijdsgegevens</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Afbakening van de geldigheid van de vergunning. </span></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Financiële gegevens (bv. bankrekeningnummer)</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Verwerking van de betaling van de verschuldigde retributie.  </span></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Contactgegevens (bv. e-mail en telefoonnummer)</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Communicatie met de aanvrager over de aanvraag, de uitreiking en
  de handhaving van een uitgereikte vergunning.</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='text-align:justify;line-height:15.95pt'><u><span
lang=NL-BE>Vergunning inname openbaar domein bij bouwwerken</span></u></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  background:#D9D9D9;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:15.95pt'><b><span
  lang=NL-BE>Persoonsgegeven</span></b></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-left:none;background:#D9D9D9;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:15.95pt'><b><span
  lang=NL-BE style='color:black'>Concreet doeleinde van de verwerking</span></b></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Naam, voornaam, adres en rijksregisternummer en/of
  ondernemingsnummer van de aanvrager</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Identificatie van de aanvrager en controle op het rechtmatig
  gebruik van de vergunning.</span></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Locatie- en tijdsgegevens</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Afbakening van de geldigheid van de vergunning. </span></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Financiële gegevens (bv. bankrekeningnummer)</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Verwerking van de betaling van de verschuldigde retributie.  </span></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Contactgegevens (bv. e-mail en telefoonnummer)</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Communicatie met de aanvrager over de aanvraag, de uitreiking en
  de handhaving van een uitgereikte vergunning.</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='text-align:justify;line-height:15.95pt'><u><span
lang=NL-BE>Vergunning werken met beperkte signalisatie</span></u></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  background:#D9D9D9;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:15.95pt'><b><span
  lang=NL-BE>Persoonsgegeven</span></b></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-left:none;background:#D9D9D9;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:15.95pt'><b><span
  lang=NL-BE style='color:black'>Concreet doeleinde van de verwerking</span></b></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Naam, voornaam, adres en rijksregisternummer en/of
  ondernemingsnummer van de aanvrager</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Identificatie van de aanvrager en controle op het rechtmatig
  gebruik van de vergunning.</span></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Locatie- en tijdsgegevens</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Afbakening van de geldigheid van de vergunning. </span></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Financiële gegevens (bv. bankrekeningnummer)</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Verwerking van de betaling van de verschuldigde retributie.  </span></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Contactgegevens (bv. e-mail en telefoonnummer)</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Communicatie met de aanvrager over de aanvraag, de uitreiking en
  de handhaving van een uitgereikte vergunning.</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='text-align:justify;line-height:15.95pt'><u><span
lang=NL-BE>Vergunning toegang autovrije zone </span></u></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  background:#D9D9D9;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:15.95pt'><b><span
  lang=NL-BE>Persoonsgegeven</span></b></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-left:none;background:#D9D9D9;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=center style='text-align:center;line-height:15.95pt'><b><span
  lang=NL-BE style='color:black'>Concreet doeleinde van de verwerking</span></b></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Naam, voornaam, adres en rijksregisternummer en/of ondernemingsnummer
  van de aanvrager</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Identificatie van de aanvrager en controle op het rechtmatig
  gebruik van de vergunning.</span></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Locatie- en tijdsgegevens</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Afbakening van de geldigheid van de vergunning. </span></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Financiële gegevens (bv. bankrekeningnummer)</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Verwerking van de betaling van de verschuldigde retributie.  </span></p>
  </td>
 </tr>
 <tr>
  <td width=302 valign=top style='width:226.55pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Contactgegevens (bv. e-mail en telefoonnummer)</span></p>
  </td>
  <td width=302 valign=top style='width:226.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
  lang=NL-BE>Communicatie met de aanvrager over de aanvraag, de uitreiking en
  de handhaving van een uitgereikte vergunning.</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal><b><span lang=NL-BE>&nbsp;</span></b></p>

<p class=MsoNormal><b><span lang=NL-BE>Doorgifte aan andere partijen</span></b></p>

<p class=MsoNormal style='text-align:justify'><a name="_Hlk121822534"><span
lang=NL-BE>Stad Antwerpen en Mobiliteit en Parkeren Antwerpen zullen uw persoonsgegevens
nooit doorsturen of bekendmaken aan een derde partij zonder dat deze doorgifte
noodzakelijk is voor de daadwerkelijke verwerking binnen de beschreven
doeleinden, tenzij u daarvoor de toestemming verleent en/of deze doorgifte
voortvloeit uit een wettelijke verplichting. </span></a></p>

<p class=MsoNormal style='text-align:justify'><span lang=NL-BE>Binnen de
doeleinden van de verwerking zijn er verschillende categorieën van ontvangers
zoals: interne medewerkers, de lokale politie Antwerpen (in functie van handhaving
van de vergunning) en verwerkers van de stad (bv. IT-partners). </span></p>

<p class=MsoNormal><b><span lang=NL-BE>Bewaartermijn</span></b></p>

<p class=MsoNormal><span lang=NL-BE>Uw persoonsgegevens worden verwerkt en
opgeslagen zolang dat nodig is voor het doel waarvoor ze zijn verzameld. Uw
gegevens zullen gedurende maximaal vijf jaar bijgehouden worden door stad
Antwerpen. Boekhoudkundige -en fiscale documenten zullen gedurende de
wettelijke bewaartermijn worden bewaard. </span></p>

<p class=MsoNormal><span lang=NL-BE>Na afloop van de bewaartermijn worden uw
persoonsgegevens door stad Antwerpen gewist.</span></p>

<p class=MsoNormal><b><span lang=NL-BE>Uw rechten</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:15.95pt'><span
lang=NL-BE>Ingevolge de VERORDENING (EU) 2016/679 van 27 april 2016, de
zogenaamde Algemene Verordening Gegevensbescherming heeft u een recht van
inzage, verbetering en desgevallend het wissen van uw gegevens. Neem voor de
uitoefening van deze rechten contact op met <a
href="mailto:sw_informatieveiligheid@antwerpen.be">sw_informatieveiligheid@antwerpen.be</a>.</span></p>

<p class=MsoNormal><span lang=NL-BE>Verder hebt u ook het recht om een klacht
in te dienen bij de toezichthoudende overheden, als u vindt dat uw gegevens op
een foutieve manier verwerkt zouden worden.</span></p>

<p class=MsoNormal><span lang=NL-BE>U kan hiervoor terecht bij de Vlaamse
Toezichtscommissie of de Gegevensbeschermingsautoriteit. </span></p>

<p class=MsoNormal><span lang=NL-BE><a
href="https://overheid.vlaanderen.be/klachtenprocedure-vtc">Vlaamse
Toezichtscommissie</a></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white;
vertical-align:baseline'><span lang=NL-BE style='color:#333333'>Koning Albert
II Laan 15</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white;
vertical-align:baseline'><span lang=NL-BE style='color:black'>1210 Brussel</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white;
vertical-align:baseline'><span lang=NL-BE style='color:black'>België</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white;
vertical-align:baseline'><span lang=NL-BE style='color:black'>Tel. 02 553 20 85</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white;
vertical-align:baseline'><span lang=NL-BE style='color:black'><a
href="mailto:contact@toezichtcommissie.be"><span style='color:#333333;
text-decoration:none'>contact@toezichtcommissie.be</span></a></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal;background:white;
vertical-align:baseline'><span lang=NL-BE>&nbsp;</span></p>

<p class=MsoNormal><span lang=NL-BE><a
href="https://www.gegevensbeschermingsautoriteit.be/burger/acties/klacht-indienen">Gegevensbeschermingsautoriteit</a></span></p>

<p class=MsoNormal><span lang=NL-BE>Drukpersstraat 35&nbsp;<br>
1000 BRUSSEL<br>
Tel. 02/274.48.00<br>
Fax. 02/274.48.35<br>
<a href="mailto:contact@apd-gba.be"><span style='color:windowtext;text-decoration:
none'>contact@apd-gba.be</span></a></span></p>

<p class=MsoNormal><span lang=NL-BE>Indien u vragen zou hebben over de
verwerking van uw persoonsgegevens zoals omschreven in deze verklaring, kan u
steeds onze functionaris voor de gegevensbescherming contacteren via <a
href="mailto:informatieveiligheid@antwerpen.be"><span style='color:windowtext;
text-decoration:none'>informatieveiligheid@antwerpen.be</span></a>.</span></p>

<p class=MsoNormal><span lang=NL-BE>&nbsp;</span></p>

</div>

</body>

</html>
`;
