import React, { FC } from 'react';
import { Route, Routes } from 'react-router';
import { appUrls } from '../../common/constants';
import { StartRequestPage, PartnersPage, TypeOfWorkPage } from '../pages';
import { SgwWorkflowStep } from '../../types';
import { PhasesPage } from './PhasesPage/Phases.page';
import { MapPage } from './MapPage/Map.page';
import { OverviewPage } from './OverviewPage/Overview.page';
import { AttachmentsPage } from './AttachmentsPage/Attachments.page';
import { Success } from '../routes/submit/Success.component';

export const SgwWorkflowComponent: FC = () => (
  <Routes>
    <Route path={appUrls.sgw.request.create} element={<StartRequestPage />} />
    <Route path={appUrls.sgw.request.edit(`${SgwWorkflowStep.StartRequest}`)} element={<StartRequestPage />} />
    <Route path={appUrls.sgw.request.edit(`${SgwWorkflowStep.Type}`)} element={<TypeOfWorkPage />} />
    <Route path={appUrls.sgw.request.edit(`${SgwWorkflowStep.Partners}`)} element={<PartnersPage />} />
    <Route path={appUrls.sgw.request.edit(`${SgwWorkflowStep.Phases}`)} element={<PhasesPage />} />
    <Route path={appUrls.sgw.request.edit(`${SgwWorkflowStep.Map}`)} element={<MapPage />} />
    <Route path={appUrls.sgw.request.edit(`${SgwWorkflowStep.Attachments}`)} element={<AttachmentsPage />} />
    <Route path={appUrls.sgw.request.edit(`${SgwWorkflowStep.Overview}`)} element={<OverviewPage />} />
    <Route path={appUrls.sgw.request.submitted} element={<Success path={appUrls.myRequests.base} />} />
  </Routes>
);
