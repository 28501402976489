import { takeLatest } from 'redux-saga/effects';
import { call, Effect, put, select } from 'redux-saga/effects';

import { CalculateExtensionCostType, createCostReceivedAction } from '../../common/actions/creators/cost';
import * as T from '../../common/actions/types';
import { getCostCalculation, getExtensionCostCalculation } from '../../common/api';
import debug from '../../common/debug';
import { convertStringToTimestamp } from '../../common/utils/dateUtils';
import { IGenericAction } from '../actions/actions';
import { selectCarFreeZones } from '../selectors';
import IState from '../../state';
import { redirectIfUnauthorized } from './login.sagas';
import { SagaIterator } from 'redux-saga';
import { genericErrorHandler } from './errors.sagas';
import { PublicDomainIntakeActions } from '../actions';
import { ICall, IRequest, ISelect, AsignWorkflowSteps } from '../../types';

function* calculateCost(action: IGenericAction<IRequest>): IterableIterator<Effect> {
  try {
    const step: number = (yield select((state: IState) => state.step))!;
    if (step >= AsignWorkflowSteps.Period) {
      // No need for check on emergency procedure. This is set within the form
      const carFreeZones: ISelect<typeof selectCarFreeZones> = yield select(selectCarFreeZones);
      const cost: ICall<typeof getCostCalculation> = yield call(getCostCalculation, action.payload, carFreeZones || []);
      yield put(createCostReceivedAction(cost!));
    }
  } catch (e: any) {
    yield call(redirectIfUnauthorized, e);
    debug(`Calculate cost failed: ${e.message}`);
    throw e;
  }
}

function* calculateExtensionCost(action: IGenericAction<CalculateExtensionCostType>): IterableIterator<Effect> {
  try {
    const request = action.payload.request;
    const extension = action.payload.extension;
    if (extension && extension.dateUntil) {
      // Check if the extension is an emergency procedure
      debug(request.latestRequestDateForExtension);
      if (convertStringToTimestamp(request.latestRequestDateForExtension) < new Date().getTime()) {
        extension.emergencyProcedure = true;
      }

      const cost: ICall<typeof getExtensionCostCalculation> = yield call(
        getExtensionCostCalculation,
        request,
        extension,
      );
      yield put(createCostReceivedAction(cost!));
    }
  } catch (e) {
    yield call(redirectIfUnauthorized, e);
    debug(e);
  }
}

export default function* costSaga(): SagaIterator {
  yield takeLatest(T.CALCULATE_EXTENSION_COST, genericErrorHandler(calculateExtensionCost));
  yield takeLatest(
    [
      T.CALCULATE_COST,
      T.CALCULATE_EXTENSION_COST,
      T.NEXT_STEP_GRANTED,
      T.PREVIOUS_STEP_GRANTED,
      T.REQUEST_RECEIVED,
      PublicDomainIntakeActions.save.type,
      T.REQUEST_DELETE_PDI,
    ],
    genericErrorHandler(calculateCost),
  );
}
