import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getShouldWarnAboutIntersectingZones } from '../../../store/selectors';
import * as A from 'antwerp-core-react-branding';
import * as React from 'react';
import { translate } from '../../../translations/translate';
import { Visible } from '../../common/layout/Visible.component';

export const PublicDomainIntakeIntersectionAlert: FC = () => {
  const shouldShowWarning = useSelector(getShouldWarnAboutIntersectingZones);

  return (
    <Visible visible={shouldShowWarning}>
      {/* @ts-ignore */}
      <A.Paragraph type={A.ParagraphStyle.Alert}>{translate('locationsform.intersectingZones')}</A.Paragraph>
    </Visible>
  );
};
