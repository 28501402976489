import { IDefaultAction, IGenericAction } from '../../../store/actions/actions';
import { defaultAction, genericAction } from '../../../store/actions';
import { ICost, IRequest, IRequestExtension } from '../../../types';
import { CALCULATE_COST, CALCULATE_EXTENSION_COST, CLEAR_COST, COST_RECEIVED } from '../types';

export function createCalculateCostAction(payload: Partial<IRequest>): IGenericAction<Partial<IRequest>> {
  return genericAction(CALCULATE_COST, payload);
}

export type CalculateExtensionCostType = { request: IRequest; extension: Partial<IRequestExtension> };
export function createCalculateExtensionCostAction(
  payload: CalculateExtensionCostType,
): IGenericAction<CalculateExtensionCostType> {
  return genericAction(CALCULATE_EXTENSION_COST, payload);
}

export function createCostReceivedAction(payload: ICost): IGenericAction<ICost> {
  return genericAction(COST_RECEIVED, payload);
}

export function createClearCostAction(): IDefaultAction {
  return defaultAction(CLEAR_COST);
}
