import './MyRequestsDetails.page.scss';

import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as A from 'antwerp-core-react-branding';
import { createOnlinePaymentAction } from '../../../../common/actions/creators/payments';
import { createClearRequestAction, createRequestRequestedAction } from '../../../../common/actions/creators/requests';
import { IRequestPayment } from '../../../../types';
import NotFound from '../../../common/errors/notFound';
import ViewMap from '../../../common/leaflet/viewmap';
import Messages from '../../../common/messaging/messages.component';
import ExtensionInfo from '../../../common/overview/extensionInfo';
import PaymentInfo from '../../../common/overview/paymentInfo';
import PersonalInfo from '../../../common/overview/personalInfo';
import { ReasonInfo } from '../../../common/overview/ReasonInfo.component';
import StateInfo from '../../../common/overview/stateInfo';
import { StateTimeLine } from '../../../common/overview/stateTimeLine';
import { StateLabel } from '../../../atoms';
import { LocationInfoAndForm } from '../../../common/overview/locationInfoAndForm';
import {
  selectBusy,
  selectCountries,
  selectPublicDomainIntakeTypes,
  selectRequest,
  selectRequestMessages,
  selectTenant,
} from '../../../../store/selectors';
import { useNavigate, useParams } from 'react-router';
import { TRouterWithId } from '../../../../types';
import { Visible } from '../../../common/layout/Visible.component';
import { getRequestState } from '../../../../common/utils/requestUtils';
import { translate } from '../../../../translations/translate';
import { CarFreeZoneActions } from '../../../../store/actions/carFreeZone.actions';

export const MyRequestsDetails: FC = () => {
  const dispatch = useDispatch();

  const busy = useSelector(selectBusy);
  const countries = useSelector(selectCountries);
  const publicDomainIntakeTypes = useSelector(selectPublicDomainIntakeTypes);
  const request = useSelector(selectRequest);
  const requestMessages = useSelector(selectRequestMessages);
  const tenant = useSelector(selectTenant);

  const history = useNavigate();
  const { id: requestId } = useParams<keyof TRouterWithId>();

  useEffect(() => {
    if (requestId) {
      dispatch(createClearRequestAction());
      dispatch(createRequestRequestedAction(requestId));
      dispatch(CarFreeZoneActions.get());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPay = useCallback(
    (payment: IRequestPayment) => {
      dispatch(createOnlinePaymentAction(payment));
    },
    [dispatch],
  );

  const hasExtensions = useCallback(() => !!request?.extensions?.length, [request]);

  const getTitle = useCallback(() => `${translate('general.request')} ${request?.referenceId ?? ''}`, [request]);

  const _getRequestState = useCallback(() => getRequestState(request!), [request]);

  return (
    <Visible visible={!busy}>
      <Visible visible={!!request?.id}>
        <section className="my-requests__details">
          <div className="row my-requests__details__header">
            <h3>{getTitle()}</h3>
            <Visible visible={!hasExtensions && _getRequestState()}>
              <StateLabel {..._getRequestState()} />
            </Visible>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <StateTimeLine />
              <Visible visible={!!requestMessages}>
                <Messages requestId={`${request?.id}`} messages={requestMessages!} dispatch={dispatch} />
              </Visible>
              <ReasonInfo request={request!} showExtension />
              <LocationInfoAndForm
                request={request!}
                countries={countries || []}
                publicDomainIntakeTypes={publicDomainIntakeTypes || []}
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <ViewMap request={request!} />
              <PersonalInfo request={request!} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {/*@ts-ignore*/}
              <A.Spacing type={A.SpacingStyle.NoMargin} className="row between-md">
                <h6>{translate('general.state')}</h6>
              </A.Spacing>

              {/*@ts-ignore*/}
              <A.Spacing type={A.SpacingStyle.MarginTop}>
                <StateInfo
                  title={`${translate('general.request')}: ${request!.state?.name}`}
                  dateUntil={request!.dateUntil}
                  state={request!.state!}
                />
              </A.Spacing>
            </div>
            <div className="col-xs-12 col-md-6">
              {/*@ts-ignore*/}
              <A.Spacing type={A.SpacingStyle.NoMargin} className="row between-md">
                <h6>&nbsp;</h6>
              </A.Spacing>
              <PaymentInfo payments={request!.payments} tenant={tenant!} onPay={onPay} />
            </div>
            {request!.extensions?.map((ext, i) => (
              <>
                <div className="col-xs-12 col-md-6">
                  <ExtensionInfo request={request!} extension={ext} index={i} />
                </div>
                <div className="col-xs-12 col-md-6">
                  <PaymentInfo payments={ext.payments} tenant={tenant!} onPay={onPay} />
                </div>
              </>
            ))}
          </div>
        </section>
      </Visible>
      <Visible visible={!request?.id}>
        <NotFound description={translate('myRequests.details.notFound')} goBack={() => history(-1)} />
      </Visible>
    </Visible>
  );
};
