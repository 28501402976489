import React, { FC, PropsWithChildren } from 'react';
import { Required } from '../../common/forms/Required.component';
import { InputProperties } from 'antwerp-core-react-branding/dist/typings/lib/atoms/form/inputProperties';
import { useGenericStyles } from '../../../hooks';
import classNames from 'classnames';
import { IWithClassName, IWithComponentName } from '../../../types';
import { useRadioButtonListStyles } from './RadioButtonList.styles';
import { Children } from '../Children/Children.component';
import { Visible } from '../../common/layout/Visible.component';

interface IListProps extends InputProperties<string>, IWithClassName {
  initialValue?: string;
}

interface IHeaderProps extends IWithComponentName, IWithClassName, PropsWithChildren {
  id?: string;
}

interface IItemProps extends Partial<IListProps>, IWithClassName, IWithComponentName, PropsWithChildren {
  value: string;
}

interface IRequiredErrorProps extends IWithComponentName, PropsWithChildren {
  show?: boolean;
}

interface RadioButtonListFC extends FC<IListProps>, PropsWithChildren {
  Header: FC<IHeaderProps>;
  Item: FC<IItemProps>;
  RequiredError: FC<IRequiredErrorProps>;
}

const RadioButtonList: RadioButtonListFC = ({ children, className, id, value, ...props }) => {
  const { flex } = useGenericStyles();
  const C = useRadioButtonListStyles();

  return (
    <div className={classNames(flex.column, C.list, className)} id={id}>
      <Children<IHeaderProps> componentNames={['RadioButtonListHeader']} id={id}>
        {children}
      </Children>
      <Children<IListProps> {...props} componentNames={['RadioButtonListItem']} id={id}>
        {children}
      </Children>
      <Children componentNames={['RadioButtonListRequiredError']}>{children}</Children>
    </div>
  );
};

const RadioButtonListHeader: FC<IHeaderProps> = ({ children, className, id }) => {
  const { spacing } = useGenericStyles();
  return (
    <Visible visible={!!children}>
      <label htmlFor={id} className={classNames(spacing.marginBottom.XXS, className)}>
        {children}
      </label>
    </Visible>
  );
};

const RadioButtonListItem: FC<IItemProps> = ({ children, className, componentName, initialValue, id, ...props }) => {
  const C = useRadioButtonListStyles();
  const { flex } = useGenericStyles();
  const fullId = `${id}-${props.value}`;

  return (
    <div className={classNames(flex.row, flex.centerVertical, className)}>
      <input {...props} type="radio" id={fullId} className={C.input} checked={initialValue === props.value} />
      <label htmlFor={fullId} className={C.radio}>
        {children}
      </label>
    </div>
  );
};

const RadioButtonListRequiredError: FC<IRequiredErrorProps> = ({ children, show = false }) => (
  <Required required={show} message={typeof children === 'string' ? children : undefined} />
);

RadioButtonListHeader.defaultProps = { componentName: 'RadioButtonListHeader' };
RadioButtonListItem.defaultProps = { componentName: 'RadioButtonListItem' };
RadioButtonListRequiredError.defaultProps = { componentName: 'RadioButtonListRequiredError' };

RadioButtonList.Header = RadioButtonListHeader;
RadioButtonList.Item = RadioButtonListItem;
RadioButtonList.RequiredError = RadioButtonListRequiredError;
export { RadioButtonList };
