import * as React from 'react';
import intl from 'react-intl-universal';

import * as A from 'antwerp-core-react-branding';
import { TextInput } from '../../atoms';
import { FC } from 'react';
import { PersonalFormFields } from '../../../common/form';
import { useVATValidationGenerator } from '../../../hooks';
import { ApplicantType } from '../../../common/enums';
import { useWatch } from 'react-hook-form';

interface IProps {}

export const CompanyInput: FC<IProps> = () => {
  const applicantType = useWatch({ name: 'applicantType' });
  const validateVat = useVATValidationGenerator(
    applicantType === ApplicantType.Company,
    PersonalFormFields.companyId,
    PersonalFormFields.companyName,
  );
  return (
    <div>
      <div className="row animated fadeIn">
        <div className="col-xs-12 col-md-6">
          {/*@ts-ignore*/}
          <A.Spacing type={A.SpacingStyle.MarginBottom}>
            <TextInput
              validate={validateVat}
              name={PersonalFormFields.companyId}
              required
              label={intl.get('personalform.companyid')}
            />
          </A.Spacing>
        </div>
        <div className="col-xs-12 col-md-6">
          {/*@ts-ignore*/}
          <A.Spacing type={A.SpacingStyle.MarginBottom}>
            <TextInput name={PersonalFormFields.companyName} required label={intl.get('personalform.companyname')} />
          </A.Spacing>
        </div>
      </div>
    </div>
  );
};
