import { COUNTRIES_RECEIVED } from '../../common/actions/types';
import { IGenericAction } from '../actions/actions';
import { ICountry } from '../../types/address.types';

const INITIAL_STATE: ICountry[] = [];

export default function countries(state: ICountry[] = INITIAL_STATE, action: IGenericAction<ICountry[]>): ICountry[] {
  switch (action.type) {
    case COUNTRIES_RECEIVED:
      return action.payload;
    default:
      return state;
  }
}
