import * as A from 'antwerp-core-react-branding';

import { ALERT, ALERT_RESET } from '../../common/actions/types';
import { IGenericAction } from '../actions/actions';

const INITIAL_STATE: A.AlertProperties | {} = {};

export default function alert(
  state: A.AlertProperties | {} = INITIAL_STATE,
  action: IGenericAction<A.AlertProperties>,
): A.AlertProperties | {} {
  switch (action.type) {
    case ALERT:
      return { ...action.payload, timestamp: new Date().getTime() };
    case ALERT_RESET:
      return {};
    default:
      return state;
  }
}
