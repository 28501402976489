import React, { FC } from 'react';
import classNames from 'classnames';
import { Visible } from '../../common/layout/Visible.component';
import { GeoDrawingListItem } from '../GeoDrawingListItem/GeoDrawingListItem.component';
import { useGenericStyles, useGeoDrawingContext } from '../../../hooks';
import { getCurrentGeoDrawingNumberingByType } from '../../../common/utils/sgwPhaseUtils';

interface IProps {
  readOnly?: boolean;
}
export const GeoDrawingList: FC<IProps> = ({ readOnly = false }) => {
  const { flex } = useGenericStyles();
  const { isAddNew, geoDrawings, newGeoDrawing, geoDrawingIndexInEdit } = useGeoDrawingContext();

  const newCountOfType =
    (isAddNew && geoDrawings?.filter((d) => d.geoDrawingType === newGeoDrawing?.geoDrawingType).length + 1) || 1;

  return (
    <>
      <Visible visible={!!geoDrawings?.length}>
        <div className={classNames(flex.column)}>
          {geoDrawings
            ?.filter((geoDrawing) => !!geoDrawing.geoDrawingType)
            .map((geoDrawing, index) => {
              const countOfType = getCurrentGeoDrawingNumberingByType(geoDrawings, geoDrawing);
              return (
                <GeoDrawingListItem
                  readOnly={readOnly}
                  key={geoDrawing.id}
                  index={index}
                  countOfType={countOfType}
                  disableControls={(geoDrawingIndexInEdit !== undefined && geoDrawingIndexInEdit !== index) || isAddNew}
                  isEditMode={geoDrawingIndexInEdit === index}
                  geoDrawing={geoDrawing}
                />
              );
            })}
        </div>
      </Visible>
      <Visible visible={isAddNew}>
        <GeoDrawingListItem
          readOnly={readOnly}
          key={-1}
          index={geoDrawings?.length}
          isEditMode
          countOfType={newCountOfType}
          geoDrawing={newGeoDrawing!}
        />
      </Visible>
    </>
  );
};
