import { FC } from 'react';
import { Polygon, LineString } from 'geojson';
import { useGeoDrawingContext } from '../../../hooks';
import { GeoDrawingType, ISgwGeoDrawing } from '../../../types';
import { MapConstructionZoneOverlay, MapParkingBanOverlay, MapDetourOverlay } from '../../molecules';
import { getCurrentGeoDrawingNumberingByType } from '../../../common/utils/sgwPhaseUtils';
import { translate, translateIgnoreTS } from '../../../translations/translate';
import { Visible } from '../../common/layout/Visible.component';

interface IProps {
  drawing: ISgwGeoDrawing;
  index: number;
}
const OverlayByDrawing: FC<IProps> = ({ drawing, index }) => {
  const { geoDrawings, isAddNew, calculateSurfaceArea, geoDrawingIndexInEdit } = useGeoDrawingContext();

  const isNewDrawing = isAddNew && !drawing?.id;
  const draggable = index === geoDrawingIndexInEdit || isNewDrawing;
  const number = getCurrentGeoDrawingNumberingByType(geoDrawings, drawing, isNewDrawing);
  const info = translate(`sgw.map.drawOnMap.phase.geoDrawing.numberingLabel`, {
    type: translateIgnoreTS(
      `sgw.map.drawOnMap.phase.geoDrawing.types.${drawing.geoDrawingType || GeoDrawingType.constructionzone}`,
    ),
    number,
  });

  switch (drawing.geoDrawingType) {
    case GeoDrawingType.constructionzone:
      return (
        <MapConstructionZoneOverlay
          coords={(drawing.geometry as Polygon)?.coordinates}
          info={info}
          draggable={draggable}
          onDragEnd={calculateSurfaceArea}
        />
      );
    case GeoDrawingType.parkingban:
      return (
        <MapParkingBanOverlay
          coords={(drawing.geometry as LineString)?.coordinates}
          info={info}
          draggable={draggable}
          onDragEnd={calculateSurfaceArea}
        />
      );
    case GeoDrawingType.detour:
      return (
        <MapDetourOverlay
          coords={(drawing.geometry as LineString)?.coordinates}
          info={info}
          draggable={draggable}
          onDragEnd={calculateSurfaceArea}
        />
      );
    default:
      return null;
  }
};

export const GeoDrawingOverlays: FC = () => {
  const { geoDrawings, isAddNew, newGeoDrawing } = useGeoDrawingContext();

  return (
    <>
      {geoDrawings.map((drawing, index) => (
        <OverlayByDrawing key={drawing.id} drawing={drawing} index={index} />
      ))}
      <Visible visible={isAddNew && !!newGeoDrawing?.geometry?.coordinates}>
        <OverlayByDrawing
          key={`new-${newGeoDrawing?.geoDrawingType}`}
          drawing={newGeoDrawing!}
          index={geoDrawings.length}
        />
      </Visible>
    </>
  );
};
