import './static-info-page.scss';

import * as React from 'react';
import { FC } from 'react';
import { onEnter } from '../../../common/utils/keyboardUtils';

interface GdprPageProperties extends React.PropsWithChildren {
  onClose: () => void;
}

export const StaticInfoPage: FC<GdprPageProperties> = ({ onClose, children }) => {
  return (
    <>
      <div className={'static_info_overlay'} />
      <div className={'static_info_wrapper'} onClick={onClose}>
        <div className={'content'}>
          <div className={'col-xs-12 col-sm-8 col-sm-offset-2 col-md-8 col-md-offset-2 u-margin-bottom-xx'}>
            <div className={'static_info_content'} onClick={(e) => e.stopPropagation()}>
              <div
                role="button"
                tabIndex={0}
                style={{ float: 'right' }}
                className={'static_info_close'}
                onClick={onClose}
                onKeyPress={onEnter(onClose)}
              />
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
