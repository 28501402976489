import { createUseStyles } from 'react-jss';

export const usePartnerAccordionHeaderStyles = createUseStyles({
  title: {
    textTransform: 'uppercase',
    fontSize: 18,
    fontWeight: 700,
  },
  checkBoxContractor: { marginTop: '0.5rem' },
  checkbox: {
    width: 'fit-content',
  },
});
