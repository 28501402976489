import * as A from 'antwerp-core-react-branding';
import { OverviewItem, OverviewList, OverviewSubtitle } from './overviewElements';
import { intakeToAddressString } from '../../../common/utils/geometry.util';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCarFreeZones } from '../../../store/selectors';
import { IPublicDomainIntake } from '../../../types';
import { getGateById } from '../../../store/selectors/gates.selectors';
import { translate } from '../../../translations/translate';
import { CarFreeZoneActions } from '../../../store/actions/carFreeZone.actions';

interface IProps {
  index: number;
  intake: IPublicDomainIntake;
  showSubtitle: boolean;
}

export const LocationInfoItem: FC<IProps> = ({ index, intake, showSubtitle }) => {
  const carFreeZones = useSelector(selectCarFreeZones);
  const dispatch = useDispatch();
  const entrance = useSelector(getGateById(intake.carFreeZoneGateEntrance));
  const exit = useSelector(getGateById(intake.carFreeZoneGateExit));

  useEffect(() => {
    if (!carFreeZones) {
      dispatch(CarFreeZoneActions.get());
    }
  }, [carFreeZones, dispatch]);

  return (
    // @ts-ignore
    <A.Spacing type={A.SpacingStyle.MarginTop} key={index}>
      {showSubtitle && (
        <OverviewSubtitle isPrimary title={translate('paymentform.locationSubtitle', { index: index + 1 })} />
      )}
      <OverviewList>
        <OverviewItem title={translate('paymentform.type')} description={intake.type.name} />
        <OverviewItem
          title={translate('paymentform.address')}
          description={intakeToAddressString(intake, carFreeZones)}
        />
        {entrance?.name && <OverviewItem title={translate('paymentform.entrance')} description={entrance.name} />}
        {exit?.name && <OverviewItem title={translate('paymentform.exit')} description={exit.name} />}
      </OverviewList>
    </A.Spacing>
  );
};
