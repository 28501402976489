import './workflowStepper.scss';

import * as React from 'react';
import intl from 'react-intl-universal';

import { Stepper, StepperProperties } from '../../molecules';
import { AsignWorkflowSteps } from '../../../types';

export type WorkflowStepperProperties = Partial<StepperProperties>;

/**
 * React Component WorkflowStepper
 */
export default class WorkflowStepper extends React.Component<WorkflowStepperProperties, {}> {
  public render(): any {
    return (
      <div className="workflow__stepper">
        <Stepper {...this.props} steps={this.getAllSteps()} activeStep={this.getActiveStep()} />
        <hr aria-hidden />
      </div>
    );
  }

  private getActiveStep(): number {
    if (this.props.activeStep !== undefined) {
      return this.props.activeStep;
    }
    return 0;
  }

  private getAllSteps(): string[] {
    return Object.keys(AsignWorkflowSteps)
      .filter((x) => isNaN(parseInt(x, 10)))
      .map((x) => intl.get(`workflowsteps.${x.toLowerCase()}`));
  }
}
