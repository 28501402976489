import { ValidateResult } from 'react-hook-form/dist/types/validator';
import { PartnerType, WorkflowType } from '../../types';

export function getFormName(step: number): string {
  return `${step}-form`;
}

// because any string is truthy, and ValidateResult might return a string when the field is invalid,
// use this function when using ValidateResults in conditional statements.
export const isValid = (value: ValidateResult | Promise<ValidateResult>): boolean => {
  if (typeof value === 'string' || value?.constructor === Array) {
    return false;
  }
  return !!value;
};

export const submitId = (workflowType: WorkflowType, step?: number) =>
  step ? `submit-${step}-${workflowType}` : undefined;

export const submitPartnerFormId = (partnerType: PartnerType) => `submit-partner-${partnerType}`;
export const submitPhaseFormId = (phaseId: number, recurringPhase?: boolean) =>
  recurringPhase ? `submit-recurring-phase-${phaseId}` : `submit-phase-${phaseId}`;

export const createOption = (label: string, value: string) => ({
  label,
  value,
});
