import React, { FC, useCallback, SyntheticEvent, useMemo } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { Autocomplete } from '../../molecules';
import { translate } from '../../../translations/translate';
import { IWithClassName } from '../../../types';
import { useStreetSuggestions } from '../../../hooks';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

interface StreetFieldProps extends IWithClassName {
  name: string;
  required?: boolean;
  onClear?(): void;
  onSelectOption?(street: string): void;
  initialValue?: string;
  validationRules?: RegisterOptions;
}

export const StreetField: FC<StreetFieldProps> = ({
  className,
  name,
  required = false,
  onClear,
  onSelectOption,
  initialValue,
  validationRules,
}) => {
  const { control, setValue, trigger } = useFormContext();
  const {
    field,
    fieldState: { error, isTouched },
  } = useController({
    name,
    control,
    rules: { required, minLength: 3 },
  });

  const { streetSuggest, streetSuggestions } = useStreetSuggestions(name, field.value);

  const onChangeStreet = useCallback(
    async (e: SyntheticEvent<HTMLInputElement>): Promise<void> => {
      const value = e.currentTarget.value;
      streetSuggest(value);
      !value.length && onClear?.();

      e.stopPropagation();
    },
    [onClear, streetSuggest],
  );

  const _onSelectOption = useCallback(
    (street: string): void => {
      setValue(name, street);
      onSelectOption?.(street);
      trigger(name);
    },
    [setValue, name, onSelectOption, trigger],
  );

  const options = useMemo(() => {
    const allStreetSuggestions = field.value && initialValue ? [...streetSuggestions, initialValue] : streetSuggestions;
    return (
      allStreetSuggestions
        // remove duplicates
        .filter((option, index, self) => index === self?.findIndex((value) => value === option))
        .map((suggestion) => ({
          label: suggestion,
          value: suggestion,
        }))
    );
  }, [field.value, initialValue, streetSuggestions]);

  return (
    <div className={className}>
      <Autocomplete
        {...field}
        filterOnType
        label={translate('sgw.phases.mainLocation.street')}
        meta={{ error: !!error, touched: !!error || isTouched }}
        onChange={(e: SyntheticEvent<HTMLInputElement>) => onChangeStreet(e)}
        onBlur={(e) => {
          const existingOption = options?.find(({ label }) => e.currentTarget.value === label);
          if (existingOption) {
            _onSelectOption(existingOption.value);
          }
        }}
        onSelectOption={_onSelectOption}
        options={options}
        required={required}
        validationRules={validationRules}
      />
    </div>
  );
};
