import { useCallback, FC, useEffect } from 'react';
import { validateDate, validateDateInterval } from '../../../common/validation/validateTime';
import { DatePicker } from '../../atoms';
import { useFormContext } from 'react-hook-form';
import { calculateInterval } from '../../../common/utils/dateUtils';
import { translate } from '../../../translations/translate';
import { useSelector } from 'react-redux';
import { selectSettings } from '../../../store/selectors';
import { ISgwRequest } from '../../../types';

export const PeriodFormFields: FC = () => {
  const { requestMaxDate, requestMinDate } = useSelector(selectSettings) || {};
  const { watch, register, setValue, trigger } = useFormContext<ISgwRequest>();
  const dateFrom = watch('dateFrom');
  const dateUntil = watch('dateUntil');
  const isUrgentRequest = watch('isUrgentRequest');
  const calculatedMinDate = isUrgentRequest ? undefined : requestMinDate;

  const validateFromDate = useCallback(
    (value: string) =>
      validateDate(
        translate('sgw.startRequest.period.startDate'),
        value,
        calculatedMinDate,
        requestMaxDate,
        isUrgentRequest,
      ),
    [calculatedMinDate, requestMaxDate, isUrgentRequest],
  );

  const validateUntilDate = useCallback(
    (value: string) =>
      validateDateInterval(translate('sgw.startRequest.period.endDate'), value, { dateFrom, dateUntil }),
    [dateFrom, dateUntil],
  );

  useEffect(() => {
    if (!dateFrom && calculatedMinDate) {
      setValue('dateFrom', calculatedMinDate);
      setValue('dateUntil', calculatedMinDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedMinDate]);

  useEffect(() => {
    if (!dateFrom && !dateUntil) {
      return;
    }

    const interval = calculateInterval(dateFrom, dateUntil);
    if (interval < 0) {
      setValue('dateUntil', dateFrom);
    }

    trigger('dateFrom');
    trigger('dateUntil');

    // only need to be triggered when dateFrom is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, trigger]);

  useEffect(() => {
    if (!!dateUntil) {
      trigger('dateUntil');
    }
  }, [dateUntil, trigger]);

  return (
    <div className="row">
      <div className="col-xs-12 col-md-3">
        <DatePicker
          label={translate('sgw.startRequest.period.startDate')}
          minDate={calculatedMinDate}
          maxDate={requestMaxDate}
          validationLabel={translate('sgw.startRequest.period.startDate')}
          validate={validateFromDate}
          required
          {...register('dateFrom', { required: true, validate: validateFromDate })}
          validateOnInit={false}
        />
      </div>
      <div className="col-xs-12 col-md-3">
        <DatePicker
          label={translate('sgw.startRequest.period.endDate')}
          minDate={dateFrom || calculatedMinDate}
          validationLabel={translate('sgw.startRequest.period.endDate')}
          validate={validateUntilDate}
          required
          {...register('dateUntil', { required: true, validate: validateUntilDate })}
          validateOnInit={false}
        />
      </div>
    </div>
  );
};
