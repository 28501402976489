import * as React from 'react';
import { HeroItem, HeroItemProperties, Spacing, SpacingStyle } from 'antwerp-core-react-branding';
import classNames from 'classnames';

export interface HeroProperties extends React.PropsWithChildren {
  color?: string;
  subtitle?: string | JSX.Element;
  title: string | JSX.Element;
}

/**
 * Organisms: Hero
 */
// AS-6376: this file was copied from antwerp-core-react-branding, updated to be WCAG compliant
export class HeroComponent extends React.Component<HeroProperties, {}> {
  public render() {
    return (
      <div className={'aui-hero'} style={{ backgroundColor: this.props.color }}>
        <div className="inner has-cta">
          <div className="aui-hero-card">
            {/* @ts-ignore */}
            <Spacing type={SpacingStyle.MarginTop}>
              <h1 className={classNames('h3')}>{this.props.title}</h1>
              {this.props.subtitle && <h2 className="h5">{this.props.subtitle}</h2>}
            </Spacing>
          </div>
          <div className="aui-hero-cta">
            <div className="aui-hero-navigation">
              <ul className="m-nav-tabs">{this.renderChildren()}</ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private renderChildren(): JSX.Element[] {
    // Filter out the allowed children
    const allowedChildren: any[] = (
      React.Children.toArray(this.props.children) as Array<React.ReactElement<HeroItemProperties>>
    ).filter((x: any) => (x.type as any).name === HeroItem.name);

    // Send out a warning
    if (allowedChildren.length > 0 && allowedChildren.length < (this.props.children as any[]).length) {
      // tslint:disable-next-line:no-console
      console.warn(`${HeroItem.name}: Only children of type ${HeroItem.name} are allowed.`);
    }

    return allowedChildren.map((x: HeroItem, i) => <HeroItem key={i} {...x.props} />);
  }
}
