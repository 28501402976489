import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogProps,
  Button,
  Backdrop,
  styled,
} from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { useGenericStyles } from '../../../hooks';
import { COLORS } from '../../../theme';
import { translate } from '../../../translations/translate';
import { Visible } from '../../common/layout/Visible.component';

interface IProps {
  visible: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  title: string;
  confirmButtonText?: string;
  discardButtonText?: string;
  message?: string | ReactNode;
  size?: DialogProps['maxWidth'];
}

const useStyles = createUseStyles({
  title: { fontFamily: 'Antwerpen Regular', marginRight: '20px' },
  modal: {
    '& .MuiPaper-root': {
      background: COLORS.ligtherBlue,
      border: `1px solid ${COLORS.blue}`,
      borderRadius: 0,
    },
    '& .MuiDialogContentText-root': {
      color: 'black',
      fontSize: 'smaller',
      fontWeight: 'light',
      marginTop: '5px',
    },
    '& .MuiButton-outlinedPrimary': {
      borderRadius: 0,
      borderColor: COLORS.blue,
      color: COLORS.blue,
      marginRight: '10px',
    },
    '& .MuiDialogActions-root ': {
      paddingRight: '24px',
      paddingBottom: '16px',
    },
    '& .MuiButton-contained': {
      borderRadius: 0,
      borderColor: COLORS.blue,
      backgroundColor: COLORS.blue,
    },
  },
});

export const Alert: FC<IProps> = ({
  visible,
  onClose,
  onConfirm,
  title,
  message,
  discardButtonText,
  confirmButtonText,
  size = 'sm',
}) => {
  const G = useGenericStyles();
  const C = useStyles();
  return (
    <Dialog
      maxWidth={size}
      open={visible}
      onClose={onClose}
      className={C.modal}
      BackdropComponent={styled(Backdrop)({
        backgroundColor: COLORS.backdrop,
      })}
    >
      <DialogTitle className={G.flex.spaceBetween}>
        <div className={C.title}>{title}</div>
      </DialogTitle>
      <DialogContent>
        <Visible visible={!!message}>
          <DialogContentText>{message}</DialogContentText>
        </Visible>
      </DialogContent>
      <DialogActions>
        {onClose && (
          <Button variant="outlined" onClick={onClose}>
            {discardButtonText || translate('general.cancel')}
          </Button>
        )}
        <Button variant="contained" onClick={onConfirm}>
          {confirmButtonText || translate('general.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
