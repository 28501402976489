import * as React from 'react';
import { Visible } from '../../common/layout/Visible.component';
import { useEffect } from 'react';
import { Step } from '../../atoms';

export type StepperProperties = {
  steps: string[];
  activeStep?: number;
};

export const Stepper = ({ steps, activeStep = -1 }: StepperProperties) => {
  useEffect(() => {
    document.getElementById(`step-${activeStep}`)?.focus();
  }, [activeStep]);

  return (
    <ol className="m-step-indicator wcag-compliance" data-testid="Stepper">
      <Visible visible={steps?.length > 0}>
        {steps.map((step, i) => (
          <Step key={step} step={step} index={i + 1} activeStep={activeStep} />
        ))}
      </Visible>
    </ol>
  );
};
