import React, { FC, useEffect, useState } from 'react';

const maxCount = 3;
const timerInterval = 333;

export const WaitAnim: FC = () => {
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    const timerId = setInterval(incrementCount, timerInterval);
    return () => {
      clearInterval(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const incrementCount = () => {
    setCount((prevCount) => (prevCount + 1) % maxCount);
  };

  return <i className={`fa fa-hourglass-${count + 1}`} />;
};
