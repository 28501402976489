import { useAtom } from 'jotai';
import React, { FC } from 'react';
import { translate } from '../../../translations/translate';
import { Modal } from '../../molecules';
import { replanModalAtom } from '../../pages';
import { ReschedulingRequestForm } from './RescheduleRequestForm.component';

export const RescheduleRequest: FC = () => {
  const [replanModel, setReplanModal] = useAtom(replanModalAtom);
  const form = 'rescheduleRequest';

  return (
    <Modal
      open={replanModel}
      onClose={() => setReplanModal(false)}
      title={translate('myRequests.details.rescheduleRequest.title')}
      description={translate('myRequests.details.rescheduleRequest.description')}
      confirmButtonText={translate('myRequests.details.rescheduleRequest.confirmButton')}
      discardButtonText={translate('myRequests.details.rescheduleRequest.discardButton')}
      form={form}
      size="lg"
    >
      <ReschedulingRequestForm />
    </Modal>
  );
};
