import * as React from 'react';
import intl from 'react-intl-universal';

import * as A from 'antwerp-core-react-branding';

export type NotFoundProperties = {
  title?: string | JSX.Element;
  description: string | JSX.Element;
  goBack?: () => void;
};

/**
 * React component NotFound
 */
export default class NotFound extends React.Component<NotFoundProperties, {}> {
  public render(): any {
    return (
      <div>
        {this.renderTitle()}
        {/*@ts-ignore*/}
        <A.Spacing type={A.SpacingStyle.MarginTop}>
          {/*@ts-ignore*/}
          <A.Paragraph type={A.ParagraphStyle.Alert} level={A.Levels.Warning}>
            {this.props.description}
          </A.Paragraph>
        </A.Spacing>
        {this.renderGoBack()}
      </div>
    );
  }

  private renderTitle(): JSX.Element {
    return <h1>{this.props.title || intl.get('general.notfound')}</h1>;
  }

  private renderGoBack(): JSX.Element {
    if (this.props.goBack) {
      return (
        // @ts-ignore
        <A.Spacing type={A.SpacingStyle.MarginTop}>
          {/*@ts-ignore*/}
          <A.IconButton
            icon="chevron-left"
            location={A.Locations.Left}
            onClick={this.props.goBack}
            text={intl.get('general.goback')}
            type={A.ButtonType.Default}
          />
        </A.Spacing>
      );
    }
    return null!;
  }
}
