import './WaitText.component.scss';

import * as React from 'react';
import intl from 'react-intl-universal';

import * as A from 'antwerp-core-react-branding';

import { WaitAnim } from './WaitAnim.component';

export type WaitTextProperties = {};

/**
 * React Component WaitText
 */
export default class NotAuthText extends React.Component<WaitTextProperties, {}> {
  public render(): any {
    return (
      // @ts-ignore
      <A.Paragraph className="authtext">
        <WaitAnim /> {intl.get('general.appnotauthenticated')}
      </A.Paragraph>
    );
  }
}
